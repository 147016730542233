import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { theme } from "../../../App";
import MultipleCheckboxComponent from "../../../Component/MultipleCheckboxComponent";
import { cloneJson } from "../../../Tool/CloneCommon";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { Zoom } from '@material-ui/core';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

const StayTime = (props) => {
    const seconds = (props.duration - props.timeSpent);
    const [initTime, setInitTime] = React.useState(seconds);
    const minutes = Math.floor(initTime / 60);
    const remainingSeconds = initTime % 60;

    React.useEffect(() => {
        const interval = setInterval(() => {
            setInitTime(initTimes => {
                const updatedTime = initTimes - 1;
                if (updatedTime <= 0) {
                    clearInterval(interval);
                }
                return updatedTime;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <span style={{ background: ((minutes <= 0) && (remainingSeconds <= 0)) ? '#6FD08C' : theme.colorPrimary, fontWeight: 'bold', color: '#FFF', padding: '6px 15px', borderRadius: 6, fontSize: 11, margin: '0 5px', display: 'flex', gap: 5, alignItems: 'center' }}>
            {((minutes <= 0) && (remainingSeconds <= 0)) ? 'Temps validé' : minutes + ' min ' + remainingSeconds + 's restant'} <AccessTimeIcon style={{ fontSize: 16 }} />
        </span>
    )
}

function RegistrationSurveyPrePost(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isScreen, setIsScreen] = React.useState(false);
    const [isCurrentSurveyCompleted, setCurrentSurveyCompleted] = React.useState(false);
    const [isPreSurveyCompleted, setPreSurveyCompleted] = React.useState(false);
    const [currentForm, setCurrentForm] = React.useState();
    const [prevForm, setPrevForm] = React.useState();
    const [responses, setResponses] = React.useState();
    const [info, setInfo] = React.useState({});
    const checkboxRefs = React.useRef([]);

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
        }, 600);
    };
    const handler = formHandlerInit(currentForm, setCurrentForm);
    const prevHandler = formHandlerInit(prevForm, setPrevForm);


    React.useEffect(handler.start, []);
    React.useEffect(prevHandler.start, []);

    const scrollToTop = () => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
            let checkbox = [];
            for (let index in handler.form) {
                if (handler.form[index].error) {
                    checkbox.push(checkboxRefs.current[handler.form[index].orderBy - 1])
                }
            }
            checkbox[0].scrollIntoView({ behavior: 'smooth', block: 'center', });
            dispatch(
                SnackbarOpen({
                    text: 'Certaines questions n\'ont pas été répondues.',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            let data = handler.getData();

            Api.post({
                route: 'learner_registration_survey_pre_post_add',
                data: { "response": data, "type": props.match.params.type },
                params: { registration: props.match.params.registration }
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'L\'enregistrement du questionnaire a été un succès.',
                            variant: 'success',
                        })
                    );
                    setCurrentSurveyCompleted(true);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const [isButtonTop, setIsButtonTop] = React.useState(false);
    const divRef = React.useRef(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsButtonTop(false)
                    }
                    else setIsButtonTop(true);
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.3,
            }
        );

        if (divRef.current) observer.observe(divRef.current);
    }, [loading]);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'learner_registration_survey_pre_post_get',
            params: { registration: props.match.params.registration, type: props.match.params.type }
        },
            (response) => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let dataReceive = response.data;
                    let data = cloneJson(dataReceive);
                    let dataClone = cloneJson(dataReceive);
                    setCurrentForm(data.questions);
                    setPrevForm(dataClone.questions);
                    setPreSurveyCompleted(data.isPreSurveyCompleted)
                    setCurrentSurveyCompleted(data.isCurrentSurveyCompleted);
                    setResponses(data.responses)
                    setInfo(response.data)
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    React.useEffect(() => {
        setIsScreen(screenReducer.size <= 1210);
    }, [screenReducer.size]);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Questionnaire pre-formation',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_registration', { id: props.match.params.registration }), label: 'Sommaire' }
            ],
            html: <>
                {(((props.match.params.type === 'pre') || (props.match.params.type === 'post')) && info && info.durationPrePost && info.financeType !== '6') &&
                    <div style={{ display: 'flex' }} >
                        {props.match.params.type === 'pre' ?
                            <StayTime duration={info.durationPrePost.durationPre} timeSpent={info.timeSpentPrePost.timeSpentPre} {...props} />
                            : <StayTime duration={info.durationPrePost.durationPost} timeSpent={info.timeSpentPrePost.timeSpentPost} />
                        }
                    </div>
                }
            </>
        });
    }, [screenReducer, info]);

    React.useEffect(() => {
        if (!loading) {
            handler.setDataApi(getResponsesByType(props.match.params.type, responses));
            prevHandler.setDataApi(getResponsesByType("pre", responses));
        }
    }, [responses]);

    const getResponsesByType = (type, responses) => {
        for (let index in responses) {
            if (responses[index].type === type) {
                return responses[index]['responses'];
            }
        }
    }

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <div style={{ textAlign: 'center',  margin: 'auto' }}>
                <div>
                    <Fade in={show} {...{ timeout: 1000 }}>
                        <div>
                            <div ref={divRef}>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack} style={isSmallView ? { width: 'calc(100% - 30px)', marginLeft: 15 } : {}}>
                                    <ArrowLeftIcon className={classes.shadowBoxBackIcon} />
                                    <span className={classes.shadowBoxBackText}>Revenir au sommaire</span>
                                </ShadowBoxComponent>
                            </div>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                {
                                    isPreSurveyCompleted && isCurrentSurveyCompleted ?
                                        <>
                                            <TitleComponent title={'Synthèse questionnaire pre / questionnaire post'} />
                                            <p style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px' }}>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation, ainsi que votre progression. Le même questionnaire vous sera soumis en fin de formation pour vous permettre de comparer vos réponses.</p>
                                            <Grid container spacing={2}>
                                                <Grid item xs={6}>
                                                    {handler.form && Object.keys(prevHandler.form).map((id, index) => (
                                                        <Grid ref={(el) => (checkboxRefs.current[index] = el)} key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px' }}>
                                                            {prevHandler.form[id].component === 'checkBox' ?
                                                                <MultipleCheckboxComponent key={id} name={prevHandler.form[id].name} options={prevHandler.form[id].optionsCheckBox} handler={prevHandler} hasFeedback={true} disabled={isCurrentSurveyCompleted} />
                                                                : prevHandler.form[id].component === 'textField' ? <TextFieldComponent key={id} name={prevHandler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={prevHandler} /> :
                                                                    ""
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid >
                                                <Grid item xs={6}>
                                                    {handler.form && Object.keys(handler.form).map((id, index) => (
                                                        <Grid ref={(el) => (checkboxRefs.current[index] = el)} key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px' }}>
                                                            {handler.form[id].component === 'checkBox' ?
                                                                <MultipleCheckboxComponent key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} hasFeedback={true} disabled={isCurrentSurveyCompleted} />
                                                                : handler.form[id].component === 'textField' ? <TextFieldComponent key={id} name={handler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={handler} /> :
                                                                    ""
                                                            }
                                                        </Grid>
                                                    ))}
                                                </Grid >
                                            </Grid>
                                        </>
                                        :
                                        <>
                                            <TitleComponent title={'Questionnaire ' + props.match.params.type + '-formation'} />
                                            <p style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px' }}>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation ainsi que votre progression.
                                                {props.match.params.type === 'pre' && <span> Le même questionnaire vous sera soumis en fin de formation pour vous permettre de comparer vos réponses.</span>}
                                            </p>
                                            {isCurrentSurveyCompleted && <p style={{ textAlign: 'center' }}><b>Vous avez déjà répondu à ce questionnaire.</b><br /><b>Vous pouvez uniquement le consulter à nouveau.</b></p>}
                                            <Grid container>
                                                {handler.form && Object.keys(handler.form).map((id, index) => (
                                                    <Grid ref={(el) => (checkboxRefs.current[index] = el)} key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px' }}>
                                                        {handler.form[id].component === 'checkBox' ?
                                                            <MultipleCheckboxComponent key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} disabled={isCurrentSurveyCompleted} />
                                                            : handler.form[id].component === 'textField' ? <TextFieldComponent name={handler.form[id].name} multiline={true} nullable={true} disabled={isCurrentSurveyCompleted} handler={handler} /> :
                                                                ""
                                                        }
                                                    </Grid>
                                                ))}
                                            </Grid >
                                        </>
                                }
                                <div className={classes.buttonGroup}>
                                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={isCurrentSurveyCompleted} />
                                </div>
                            </ShadowBoxComponent>
                            {isButtonTop &&
                                <ShadowBoxComponent
                                    onClick={scrollToTop}
                                    style={
                                        isScreen ? { width: 'max-content', position: 'sticky', bottom: 10, margin: '0px 0px 10px auto', cursor: 'pointer' } :
                                            { width: 'max-content', position: 'sticky', bottom: 10, margin: '-50px 0px 10px auto', transform: 'translate(70px)', cursor: 'pointer' }
                                    }>
                                    <ArrowLeftIcon style={{ transform: 'rotate(90deg)' }} className={classes.shadowBoxBackIcon} />
                                </ShadowBoxComponent>
                            }
                        </div>
                    </Fade>
                </div>
            </div>
        </ContentViewLearnerComponent>
    );
}

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
});

export default RegistrationSurveyPrePost;
