import React from 'react';
import Api from "../../../../Api";
import { Box, IconButton, Tooltip, makeStyles, Drawer as DrawerMUI, Grid, Select, MenuItem, Button, Menu } from '@material-ui/core';
import TableComponent from '../../../../Component/TableComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from 'react-redux';
import { TableReload } from '../../../../Action/TableAction';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import ButtonComponent from '../../../../Component/ButtonComponent';
import SubjectIcon from '@material-ui/icons/Subject';
import { getRoutePathname } from '../../../../Config/Route';
import { OpenInBrowser } from '@material-ui/icons';

export default function ProspectList(props) {

    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isOpen, setIsOpen] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isScreen, setIsScreen] = React.useState(true);
    const [filtersData, setFiltersData] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "fullName",
            label: "Nom/Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "mobile",
            label: "Mobile",
            options: { filter: true, sort: true }
        },
        {
            name: "institutionsName",
            label: "Établissements",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Lien vers un établissement",
            options: { filter: false, sort: false }
        }
    ];

    const GetAction = ({ row }) => {
        const [anchorEl, setAnchorEl] = React.useState(null);
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
        const handleClose = () => {
            setAnchorEl(null);
        };
        const handleSelect = (institution) => {
            window.open(getRoutePathname('institution_health_crm_institution', {institution: institution.id}), '_blank');
            handleClose();
        };
        return (
            <div>
                <div style={{ minWidth: 90, textAlign: 'center' }}>
                    <Tooltip title={'Aller à l\'établissement'} placement="left">
                        <IconButton onClick={handleClick}>
                            <OpenInBrowser />
                        </IconButton>
                    </Tooltip>
                </div >

                <Menu
                    keepMounted
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    getContentAnchorEl={null}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                    transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    PaperProps={{
                        style: {
                            maxHeight: 100,
                            minWidth: 120
                        },
                    }}
                >
                    {row.institutions.map((institution) => (
                        <MenuItem key={institution.id} onClick={() => handleSelect(institution)} style={{ fontSize: '12px' }}>
                            {institution.name}
                        </MenuItem>
                    ))}
                </Menu>
            </div>
        );
    };

    const [formSearch, setFormSearch] = React.useState({
        text: {
            name: 'text',
            label: 'Recherche',
            textHelper: 'Rechercher par email, nom, prénom...',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_prospect_client_list'] && cacheTableServer['institution_health_crm_prospect_client_list'].text ? cacheTableServer['institution_health_crm_prospect_client_list'].text : '',
            options: {}
        }
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_crm_prospect_client_list'] = 0;
        cacheTableServer['institution_health_crm_prospect_client_list'].page = 0;
        cacheTableServer['institution_health_crm_prospect_client_list'][index] = value;
        dispatch(TableReload('institution_health_crm_prospect_client_list'));
    };

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box className={classes.filterStyle}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'text'} handler={handlerSearch} onChange={(val) => inputSearchChange('text', val)} />
                    </Grid>
                </Grid>
            </Box>
        )
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<span style={{ display: 'flex', gap: 5, alignItems: 'center' }}><FilterListIcon style={{ color: '#FFFFFF' }} />Filtre</span>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        setReadyContent(true);
        setBreadcrumbs({
            title: 'Salariés',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des salariés.',
            html: <GetActionMore />
        });
    }, [isOpen, filtersData]);



    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_crm_prospect_client_list'}
                        title={
                            <h1 style={{ margin: 0, fontSize: 17, display: 'flex', alignItems: 'center', gap: 5 }}>
                                <SubjectIcon style={{ width: 20, height: 20 }} />
                                Liste des salariés.
                            </h1>
                        }
                        search={false}
                        columns={columns}
                        promiseServerData={(resolve, options) => {
                            let data = handlerSearch.getData();
                            let filters = {
                                limit: options.rowsPerPage,
                                offset: options.page * options.rowsPerPage,
                                sortName: options.sortOrder.name,
                                sortDirection: options.sortOrder.direction,
                                text: data.text ? data.text : '',
                            };

                            Api.get({
                                route: 'institution_health_crm_prospect_client_list',
                                data: { ...filters, organism: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        for (let index in data) {
                                            data[index].action = <GetAction row={data[index]} />;
                                        }
                                        resolve(data, response.data.count);
                                    } else resolve([]);
                                });
                        }}
                    />
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    filterStyle: {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        padding: '19px 19px 10px 19px',
        color: '#5E6E82', opacity: 0.7, fontSize: 14
    }
}))
