import React from "react";
import { Box, IconButton, Popover, Typography, makeStyles } from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import ShadowBoxComponent from "../../../../../Component/ShadowBoxComponent";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from "moment";

const CardDiscountComponent = (props) => {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isTimer, setIsTimer] = React.useState(false);
    const handleClick = (event) => setAnchorEl(event.currentTarget);
    const handleClose = () => setAnchorEl(null);
    const open = Boolean(anchorEl);
    const idPopover = open ? 'simple-popover' : undefined;
    const viewAction = (row) => {
        return (
            <div style={{ margin: '0 10px 0 auto' }}>
                <IconButton style={{ padding: 0 }} onClick={handleClick}>
                    <MoreHorizIcon style={{ color: "#5E6E82" }} />
                </IconButton>
                <Popover
                    id={idPopover}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    className={classes.popover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Typography style={{ padding: 8 }}>
                        {getAction(row)}
                    </Typography>
                </Popover>
            </div>
        )
    }
    const getAction = (row) => {
        return (
            <>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => { props.setIsEdition(true); props.setDialogAddDiscount(true); props.setId(row.id); handleClose() }}>
                    <Edit style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Modifier</span>
                </IconButton>
                <IconButton
                    className={classes.iconButton}
                    onClick={() => { props.setIsAction(true); props.setId(row.id); handleClose() }}>
                    <Delete style={{ width: 'auto', height: 15, margin: '0 8px 0 0', }} /> <span>Supprimer</span>
                </IconButton>
            </>
        )
    }

    React.useEffect(() => {
        if (props.data.endAt) {
            var date = new Date();
            var dateExpired = new Date(moment(props.data.endAt, 'D/M/Y').format('YYYY-MM-DD'));
            setIsTimer(date >= dateExpired)
        } else setIsTimer(false)
    }, [props.data.endAt]);

    return (
        <>
            <ShadowBoxComponent style={{ height: 'auto', display: 'flex', background: 'rgb(255, 255, 255)', color: 'rgb(94, 110, 130)', alignItems: 'center', width: '90%', margin: '20px auto', gap: 5 }}>
                <Box style={{ height: '100%' }}>
                    <p style={{ margin: 0, fontSize: 12, display: 'flex', alignItems: 'center', gap: 5 }}>Réduction {isTimer && <span className={classes.expired}>Expiré <AccessTimeIcon style={{ fontSize: 18 }} /></span>}</p>
                    <p style={{ margin: '5px 0', fontSize: 13, fontWeight: 'bold' }}>{props.data.comment}</p>
                    {props.data.valueToApply && <p style={{ margin: 0, fontSize: 12 }}>Reduction de <b>{props.data.valueToApply}</b></p>}
                    <p style={{ margin: 0, fontSize: 12 }}><b>Date de début :</b> {props.data.startAt}</p>
                    <p style={{ margin: 0, fontSize: 12 }}><b>Date de fin :</b> {props.data.endAt ?? '-'}</p>
                </Box>
                {viewAction(props.data)}
            </ShadowBoxComponent >
        </>
    )
}

const useStyles = makeStyles({
    iconButton: {
        borderRadius: 5,
        fontSize: 14,
        margin: 0,
        padding: '7px 10px',
        display: 'block',
        width: '100%',
        color: '#5E6E82',
        "& span": {
            justifyContent: 'left'
        }
    },
    expired: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        background: '#dc3545',
        color: '#FFF',
        width: 'fit-content',
        padding: '3px 10px',
        borderRadius: 5,
        fontWeight: 'bold',
    }
})

export default CardDiscountComponent;