import React from 'react';
import { AppBar, Box, Dialog, DialogActions, DialogContent, DialogTitle, Fade, Grid, IconButton, Slide, Tab, Tabs, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TabsComponent from '../../../../Component/TabsComponent';
import InstitutionEmployeeList from '../InstitutionEmployeeList/InstitutionEmployeeList';
import RegistrationList from '../RegistrationList/RegistrationList';
import { getRoutePathname } from '../../../../Config/Route';
import ButtonComponent from '../../../../Component/ButtonComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import { theme } from "../../../../App";
import { Delete } from '@material-ui/icons';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TitleComponent from '../../../../Component/TitleComponent';
import SweetAlert from "sweetalert-react";
import MultipleSelectComponent from '../../../../Component/MultipleSelectComponent';
import SelectComponent from '../../../../Component/SelectComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import Api from '../../../../Api';
import { OrganismIndex, Organisms } from '../../../../Organism';
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { hasRight } from '../../../../Tool/UserTool';
import { HighlightOff, HowToReg } from '@material-ui/icons';
import SelectAutocompleteComponent from '../../../../Component/SelectAutocompleteComponent';
import { TableReload } from '../../../../Action/TableAction';

export default function Institution(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [isOpen, setIsOpen] = React.useState(false);
    const [info, setInfo] = React.useState({});
    const [optionInstitutionTypes, setOptionInstitutionTypes] = React.useState([]);
    const [optionInstitutionGroups, setOptionInstitutionGroups] = React.useState([]);
    const [optionInstitutionPoles, setOptionInstitutionPoles] = React.useState([]);
    const [optionStates, setOptionStates] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionPoles, setOptionPoles] = React.useState([]);
    const classes = useStyles(isEdition)();

    const isAdmin = hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_ADMIN');

    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de l\’établissement',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'email de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        fax: {
            name: 'fax',
            label: 'Fax',
            textHelper: 'Saisissez un fax.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le numéro de téléphone de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        siret: {
            name: 'siret',
            label: 'SIRET',
            textHelper: 'Saisissez le numéro SIRET de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez l\'adresse de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville de l\’établissement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        terminateAt: {
            name: 'terminateAt',
            label: 'Date de fermeture',
            textHelper: 'Saisissez la date de fermeture.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        },
        note: {
            name: 'note',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez le commercial de l\’établissement.',
            type: 'integer',
            defaultValue: 0,
            options: { validation: ['required'] }
        },
        institutionGroup: {
            name: 'institutionGroup',
            label: 'Groupement d\’établissement',
            textHelper: 'Choisissez le Groupement de l\’établissement.',
            type: 'integer',
            options: {}
        },
        institutionType: {
            name: 'institutionType',
            label: 'Type d’établissement',
            textHelper: 'Choisissez le type de l\’établissement.',
            type: 'integer',
            defaultValue: selectOrganismReducer.organism.value === 5 ? 1 : '',
            options: { validation: ['required'] }
        },
        institutionState: {
            name: 'institutionState',
            label: 'État',
            textHelper: 'Choisissez l\'état de l\’établissement.',
            type: 'integer',
            defaultValue: '',
            options: {}
        }
    });

    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const [formPole, setFormPole] = React.useState({
        name: {
            name: 'name',
            label: 'Pôle',
            textHelper: 'Saisissez un pôle.',
            type: 'Array',
            options: { validation: ['required'] }
        },
    });

    const handlerPole = formHandlerInit(formPole, setFormPole);
    React.useEffect(handlerPole.start, []);

    const save = () => {
        if (handler.checkError()) {
            const errorLabels = Object.values(handler.form).filter(item => item.error).map(item => item.label);
            dispatch(
                SnackbarOpen({
                    text: errorLabels.length === 1 ? 'Le champ "' + errorLabels + '" est obligatoire' : 'Les champs "' + errorLabels.join(', ') + '" sont obligatoires',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                if (props.match.params.institution) {
                    Api.post({
                        route: 'institution_health_crm_institution_edit',
                        data: { ...handler.getData(), organism: selectOrganismReducer.organism.value },
                        params: { id: props.match.params.institution }
                    }, (response) => {
                        handler.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (response.status === 200) {
                            getData();
                            dispatch(TableReload('institution_health_crm_institution_employee_list'))
                            dispatch(
                                SnackbarOpen({
                                    text: 'Établissement modifiée.',
                                    variant: 'success',
                                })
                            );
                        } else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'institution_health_crm_institution_add',
                    data: { ...datas, organism: selectOrganismReducer.organism.value }
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname('institution_health_crm_institution', { institution: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Établissement enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const saveInstitutionPole = () => {
        if (handlerPole.checkError()) {
            const errorLabels = Object.values(handlerPole.form).filter(item => item.error).map(item => item.label);
            dispatch(
                SnackbarOpen({
                    text: errorLabels.length === 1 ? 'Le champ "' + errorLabels + '" est obligatoire' : 'Les champs "' + errorLabels.join(', ') + '" sont obligatoires',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (props.match.params.institution) {
                Api.post({
                    route: 'institution_health_crm_institution_pole_add',
                    data: handlerPole.getData(),
                    params: { institution: props.match.params.institution }
                }, (response) => {
                    handlerPole.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setIsOpen(false);
                        getData()
                        dispatch(
                            SnackbarOpen({
                                text: 'Pôle ajouter.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handlerPole.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });

            }
        }
    };

    const assign = (isAssign) => {
        dispatch(LinearProgressMainLayoutActivate());
        handler.setFormLoading(true);
        setLoading(true);

        Api.post({
            route: 'institution_health_crm_institution_assign_me',
            params: { id: props.match.params.institution }
        },
            (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(TableReload('institution_health_crm_institution_employee_list'))
                    setInfo({
                        ...info,
                        commercial: isAssign ? authenticationReducer.id : null
                    });
                    dispatch(
                        SnackbarOpen({
                            text: isAssign ? 'Établissement attribué.' : 'Établissement désattribué.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const getAction = () => {
        return (
            <>
                <Tooltip title={'Tableau de bord'} placement="left">
                    <IconButton style={{ padding: 5 }} onClick={() => { props.history.push(getRoutePathname('institution_health_crm_institution_manager', { institution: props.match.params.institution })) }}>
                        <DashboardIcon style={{ fontSize: 16, color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>
                {(hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN')) &&
                    ((info.commercial === authenticationReducer.id) ?
                        <Tooltip title="Me désattribuer l'établissement" aria-label="Désattribuer">
                            <IconButton style={{ padding: 5 }} aria-label="Désattribuer" onClick={() => assign(false)}>
                                <HighlightOff style={{ fontSize: 16, color: '#5E6E82' }} />
                            </IconButton>
                        </Tooltip>
                        :
                        !info.commercial ?
                            <Tooltip title="M'attribuer l'établissement" aria-label="Attribuer">
                                <IconButton style={{ padding: 5 }} aria-label="Attribuer" onClick={() => assign(true)}>
                                    <HowToReg style={{ fontSize: 16, color: '#5E6E82' }} />
                                </IconButton>
                            </Tooltip>
                            :
                            <></>
                    )
                }
                {!info.deleteAt && <Tooltip title={'Supprimer'} placement="left">
                    <IconButton style={{ padding: 5 }} onClick={() => { setShowConfirm(true) }}>
                        <Delete style={{ fontSize: 16, color: '#5E6E82' }} />
                    </IconButton>
                </Tooltip>}
            </>
        )
    }

    const [value, setValue] = React.useState('');
    const handleChange = (event, newValue) => {
        newValue !== value && dispatch(TableReload('institution_health_crm_institution_employee_list'))
        setValue(newValue);
    };
    const getData = () => {
        Api.get({
            route: 'institution_health_crm_institution_get',
            params: { id: props.match.params.institution },
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    setInfo({ ...info, ...data });
                    setValue(data.poles[0].value)
                    setOptionInstitutionPoles(data.poles);
                    handler.setDataApi(data);
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('institution_health_crm_institution_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setTimeout(() => setReadyContent(true), 250);
            });

    }
    React.useEffect(() => {
        if (props.match.params.institution) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setIsEdition(true);
            getData();
        } else setReadyContent(true);
    }, [props.match.params.institution]);

    React.useEffect(() => {
        setBreadcrumbs({
            title: 'Établissement',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'formulaire',
            links: [
                { path: getRoutePathname('institution_health_crm_institution_list'), label: 'Liste des établissements' }
            ],
        });
        Api.get({
            route: 'select_institution_groups'
        },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionInstitutionGroups(data);
                }
            });

        Api.get({
            route: 'select_institution_types'
        },
            (response) => {
                if (response && response.data) {
                    let data = response.data;
                    setOptionInstitutionTypes(data);
                }
            });

        Api.get({
            route: 'institution_health_crm_commercial_list',
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials([{ value: 0, label: 'Aucun' }, ...response.data]);
                }
            });

        Api.get({
            route: 'select_institution_states'
        },
            (response) => {
                if (response && response.data) {
                    setOptionStates(response.data);
                }
            });
    }, []);
    React.useEffect(() => {
        handler.setDataApi(info)
    }, [info]);
    React.useEffect(() => {
        if (!isEdition) {
            handler.setValue('commercial', optionCommercials.find(item => item.value === authenticationReducer.id)?.value ?? 0);
        }
    }, [optionCommercials])
    const [loadingPole, setLoadingPole] = React.useState(true);
    React.useEffect(() => {
        setLoadingPole(true)
        Api.get({
            route: 'select_institution_pole_names'
        },
            (response) => {
                if (response && response.data) {
                    setLoadingPole(false);
                    let data = response.data;
                    const newData = data.filter(item1 => !optionInstitutionPoles.some(item2 => item1.label === item2.label));
                    setOptionPoles(newData);
                }
            });
    }, [optionInstitutionPoles])
    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.boxComponent} style={{ height: 'auto', padding: '30px 0' }}>
                    <Box style={{ height: '100%' }}>
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Grid container spacing={3} style={{ margin: 'auto', height: '100%', width: '100%' }}>
                                <Grid item xs={12} sm={12} md={isEdition ? 8 : 12} lg={isEdition ? 8 : 12} xl={isEdition ? 8 : 12}>
                                    <ShadowBoxComponent style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '12px 25px' }}>
                                        <TitleComponent title={
                                            <Box style={{ display: 'flex', alignItems: 'center' }}>
                                                {isEdition ? 'Formulaire d\'édition d\’établissement' : 'Formulaire création d\’établissement'}
                                                {Organisms[OrganismIndex[selectOrganismReducer.organism.value]] &&
                                                    <img src={'/asset/images/' + Organisms[OrganismIndex[selectOrganismReducer.organism.value]].logo} alt="logo" style={{ height: 35, margin: 'auto 0 0 auto' }} />
                                                }
                                            </Box>
                                        } />
                                        <Grid container spacing={2} style={{ width: '100%', margin: 'auto', minHeight: '70%' }}>
                                            <Grid item xs={12} sm={12} md={isEdition ? 12 : 6} lg={isEdition ? 12 : 6} xl={isEdition ? 12 : 6}>
                                                <TextFieldComponent name={'name'} handler={handler} />
                                            </Grid>

                                            {!isEdition &&
                                                <Grid item xs={12} sm={12} md={isEdition ? 12 : 6} lg={isEdition ? 12 : 6} xl={isEdition ? 12 : 6}>
                                                    <SelectComponent name={'commercial'} handler={handler} options={optionCommercials} nullable={true} />
                                                </Grid>
                                            }

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextFieldComponent name={'email'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextFieldComponent name={'address'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                <TextFieldComponent name={'city'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent name={'zipCode'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent name={'phone'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent name={'fax'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <TextFieldComponent name={'siret'} handler={handler} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <SelectAutocompleteComponent name={'institutionGroup'} handler={handler} options={optionInstitutionGroups} />
                                            </Grid>

                                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                <SelectComponent name={'institutionType'} handler={handler} options={optionInstitutionTypes} />
                                            </Grid>

                                        </Grid>

                                        <Box style={{ margin: 'auto 0 0 auto' }}>
                                            <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={() => { save() }} disabled={loading} />
                                        </Box>
                                    </ShadowBoxComponent>
                                </Grid>
                                {isEdition &&
                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4} >
                                        <Slide direction={'left'} in={true} {...{ timeout: 500 }}>
                                            <div style={{ height: '100%' }}>
                                                <ShadowBoxComponent style={{ height: '100%', display: 'flex', padding: '12px 0' }}>
                                                    <Box style={{ padding: '0 12px' }}>
                                                        <TitleComponent title={
                                                            <Box style={{ display: 'flex' }}>
                                                                <p style={{ margin: 0 }}>Infos générales</p>
                                                            </Box>
                                                        } />
                                                        <Grid container spacing={2} style={{ width: '100%', margin: '0', }}>
                                                            <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <SelectComponent name={'commercial'} handler={handler} options={optionCommercials} nullable={true} />
                                                            </Grid>
                                                            <Grid style={{ padding: '0 14px 14px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <Box style={{ padding: '10px 0', background: '#EFF2F4', borderRadius: 10, boxShadow: 'inset 0px 0px 4px #0000001a', overflow: 'auto', height: 276 }}>
                                                                    {(info.holders && info.holders.length != 0) ? info.holders.map((data) => (
                                                                        <CardHolders data={data} key={data.prospectId} />
                                                                    )) : <p style={{ textAlign: 'center', margin: 0, justifyContent: 'center', display: 'flex', alignItems: 'center', height: '100%', fontSize: 13 }}>Aucun Titulaire.</p>}
                                                                </Box>
                                                            </Grid>
                                                            <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <SelectComponent name={'institutionState'} handler={handler} options={optionStates} nullable={false} />
                                                            </Grid>
                                                            <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <TextFieldComponent name={'note'} handler={handler} multiline={true} />
                                                            </Grid>
                                                            <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <DatePickerComponent name={'terminateAt'} handler={handler} />
                                                            </Grid>
                                                        </Grid>
                                                    </Box>
                                                    <Box style={{ borderLeft: '1px solid #5E6E8236', padding: '0 5px', }}>{getAction()}</Box>
                                                </ShadowBoxComponent>
                                            </div>
                                        </Slide>
                                    </Grid>
                                }
                            </Grid>
                        </Fade>
                    </Box>
                    {
                        isEdition &&
                        <>
                            <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                                <div style={{ padding: 12 }}>
                                    <TabsComponent
                                        tabDefault={'propect'}
                                        tabs={[
                                            { id: 'propect', label: 'Salariés' },
                                            { id: 'registration', label: 'Inscriptions' }
                                        ]}
                                    >
                                        <div key={'propect'}>
                                            <div className={classes.root}>
                                                <Box style={{ display: 'grid', alignItems: 'center', height: '100%', gridTemplateColumns: 'max-content auto max-content', }}>
                                                    <Box style={{ height: '100%', background: '#5E6E82', color: '#FFF', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                                                        <p style={{ margin: 0, padding: '0 40px' }}>Pôle</p>
                                                    </Box>
                                                    <AppBar position="static">
                                                        <Tabs
                                                            variant="scrollable"
                                                            scrollButtons="auto"
                                                            value={value}
                                                            onChange={handleChange}
                                                        >
                                                            {optionInstitutionPoles.length !== 0 && optionInstitutionPoles.map((element) => (
                                                                <Tab label={element.label} key={element.value} value={element.value} />
                                                            ))}
                                                        </Tabs>
                                                    </AppBar>
                                                    {selectOrganismReducer.organism.value != 5 &&
                                                        <Box style={{ height: '100%', }}>
                                                            <ButtonComponent
                                                                label={'Pôle +'}
                                                                style={{ width: '100%', height: '100%', borderRadius: 0, }}
                                                                onClick={() => { setIsOpen(true) }}
                                                                disabled={loadingPole}
                                                            />
                                                        </Box>
                                                    }
                                                </Box>
                                                <Box>
                                                    {optionInstitutionPoles.length !== 0 &&
                                                        <InstitutionEmployeeList pole={value} getData={getData} poleList={optionInstitutionPoles} isForm={true} {...props} />
                                                    }
                                                </Box>
                                            </div>
                                        </div>
                                        <div key={'registration'}>
                                            <div className={classes.root}>
                                                <RegistrationList {...props} />
                                            </div>
                                        </div>
                                    </TabsComponent>
                                </div>
                            </Slide>
                            <Dialog open={isOpen} maxWidth={'lg'} onClose={() => { setIsOpen(false); }}>
                                <Box>
                                    <h1 style={{ color: '#5E6E82', fontSize: 23, margin: 0, padding: 20, display: 'flex', }} >
                                        Ajouter un pôle
                                    </h1>
                                    <hr style={{ margin: 0, opacity: 0.2 }} />
                                </Box>
                                <DialogContent>
                                    {optionPoles.length != 0 ?
                                        <Grid container spacing={2} style={{ width: '100%', margin: 'auto', minHeight: '70%' }}>
                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                                <SelectComponent name={'name'} handler={handlerPole} options={optionPoles} />
                                            </Grid>
                                        </Grid> : <p style={{ color: '#5E6E82', textAlign: 'center' }}><b>Aucun pôle n'a été créé.</b><br />(Voir avec le support technique)</p>
                                    }
                                </DialogContent>
                                <DialogActions>
                                    <ButtonComponent disabled={loading} onClick={() => { setIsOpen(false) }} color={'#5E6E82'} label={'Annuler'} />
                                    <ButtonComponent disabled={loading || optionPoles.length === 0} onClick={saveInstitutionPole} label={'Enregistrer'} />
                                </DialogActions>
                            </Dialog>
                        </>
                    }
                </Box>
            </ContentViewComponent>
            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cet établissement ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    dispatch(LinearProgressMainLayoutActivate());
                    setShowConfirm(false);
                    Api.delete({
                        route: 'institution_health_crm_institution_delete',
                        params: { id: props.match.params.institution }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Établissement supprimé.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('institution_health_crm_institution_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </Box>
    )
}
const CardHolders = (props) => {
    return (
        <>
            <ShadowBoxComponent style={{ height: 'auto', display: 'flex', background: 'rgb(255, 255, 255)', color: 'rgb(94, 110, 130)', alignItems: 'center', width: '90%', margin: '5px auto', gap: 5 }}>
                <Box style={{ height: '100%' }}>
                    <p style={{ margin: '5px 0', fontSize: 13 }}><b>Titulaire :</b> {props.data.firstName} {props.data.lastName}</p>
                    <p style={{ margin: 0, fontSize: 12 }}><b>Email :</b> {props.data.email ? props.data.email : '-'}</p>
                    <p style={{ margin: 0, fontSize: 12 }}><b>Téléphone :</b> {props.data.mobile ? props.data.mobile : '-'}</p>
                </Box>
            </ShadowBoxComponent >
        </>
    )
}

const useStyles = (isEdition) => makeStyles({
    boxComponent: {
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        height: isEdition ? '' : '100%',
        overflowX: 'hidden',
        '& .ContentView-root': {
            height: '100%',
            display: isEdition ? 'block' : 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    infoSpanTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10
    },
    infoSpanText: {
        fontSize: 13
    },
    root: {
        '& header': {
            background: '#fff',
            zIndex: 0
        },
        '& .MuiPaper-root': {
            boxShadow: 'none',
        }
    },
})