import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Box, Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import Api, { LibraryResourceImage, LibraryResourceTypeIcon } from '../../../Api';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LazyLoading from './Component/LazyLoading';
import { Rating } from '@material-ui/lab';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import CardResource from './Component/CardResource';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import ImgSvgComponent from '../../../Component/LearnerImgSvgComponent';

const LibraryResource = (props) => {

    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [cardSmall, setCardSmall] = React.useState({ grid: 3, width: 1102 });
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [libraryResources, setLibraryResources] = React.useState([]);

    const cardSpace = (data, countSpaces) => {
        return (
            <div style={{ display: 'flex', gridColumn: `span ${countSpaces}` }} >
                <Fade in={true} {...{ timeout: 500 }}>
                    <Box className={classes.cardAccesRoot}>
                        <Box style={{ textAlign: 'center', margin: (countSpaces === 1) ? 'auto' : '', zIndex: 1, position: 'relative' }}>
                            <h1 style={{ color: theme.colorPrimary, margin: 5, fontSize: (countSpaces != 1) ? 40 : 30 }}>{data.label}</h1>
                        </Box>
                        <p className={classes.textLink} style={{ margin: countSpaces === 1 ? 0 : 10, zIndex: 1, position: 'relative' }}
                            onClick={() => { props.history.push(getRoutePathname('learner_resource_type', { id: data.id })) }}
                        ><span style={{ padding: '5px 50px' }}>Accéder</span></p>
                        {(countSpaces != 1) && <ImgSvgComponent style={{ width: '100%' }} />}
                    </Box>
                </Fade>
            </div>
        )
    }

    const countEmptySpaces = (data, grid) => {
        const formationsLength = data?.length;
        const remainder = formationsLength % grid;
        return remainder === 0 ? 0 : grid - remainder;
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        Api.get({
            route: 'learner_library_resource_list',
            data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
        },
            (response) => {
                if (response && response.data) {
                    setLibraryResources(response.data);
                }
                setLoading(false);
            });
    }, []);

    React.useEffect(() => {
        setBreadcrumbs({
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ],
            title: <p style={{ color: '#FFF', margin: 0 }}>
                <span style={{ fontSize: screenReducer.size >= 540 ? 50 : 30, display: 'block', fontWeight: 'bold' }}>Bibliothèque de ressources</span>
            </p>,
            html: <FeaturedResourceComponent organism={authenticationReducer.organism} formationGroup={authenticationReducer.formationGroup} history={props.history} />
        });

        setCardSmall((prev) => {
            const values = {
                1: { width: 355, grid: 1 },
                2: { width: 732, grid: 2 },
                default: { width: 1102, grid: 3 }
            };

            const conditions = [
                { condition: screenReducer.size <= 925, type: 1 },
                { condition: screenReducer.size <= 1216, type: 2 },
            ];

            const valueType = conditions.find(cond => cond.condition)?.type || 'default';
            return {
                grid: values[valueType].grid,
                width: values[valueType].width
            };
        });

    }, [screenReducer]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            {(libraryResources && libraryResources.length > 0) ?
                libraryResources.map((libraryResource) => (
                    <div key={libraryResource.id}>
                        <BoxLearnerComponent
                            title={libraryResource.label}
                            styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                            styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
                        >
                            {libraryResource.resources &&
                                <>
                                    <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: (screenReducer.size <= 395) ? '100%' : cardSmall.width, padding: '20px 0' }}>
                                        {libraryResource.resources.map((resource) => (
                                            <div style={{ display: 'flex' }} key={resource.id}>
                                                {resource.format === 6 || resource.format === 2 ?
                                                    <Fade in={true} {...{ timeout: 500 }}>
                                                        <MediaLinkComponent
                                                            path={resource.link}
                                                            type={resource.format}
                                                            isLibrary={true}
                                                            className={classes.cardRoot}
                                                            style={(screenReducer.size <= 395) ? { width: '100%' } : {}}
                                                        >
                                                            <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                        </MediaLinkComponent>
                                                    </Fade>
                                                    :
                                                    <Fade in={true} {...{ timeout: 500 }}>
                                                        <div className={classes.cardRoot} style={(screenReducer.size <= 395) ? { width: '100%' } : {}} onClick={() => { props.history.push(getRoutePathname('learner_resource_type_resource', { id: libraryResource.id, resource: resource.id })) }}>
                                                            <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                        </div>
                                                    </Fade>
                                                }
                                            </div>
                                        ))}
                                        {(countEmptySpaces(libraryResource.resources, cardSmall.grid) != 0) && cardSpace(libraryResource, countEmptySpaces(libraryResource.resources, cardSmall.grid))}
                                    </div>
                                    {(countEmptySpaces(libraryResource.resources, cardSmall.grid) === 0) &&
                                        <p className={classes.textLink}
                                            onClick={() => { props.history.push(getRoutePathname('learner_resource_type', { id: libraryResource.id })) }}
                                        >
                                            <span>{`Voir ${libraryResource.label}`}</span>
                                        </p>
                                    }
                                </>
                            }
                        </BoxLearnerComponent>
                    </div>
                ))
                :
                <p>Aucune ressources disponible pour le moment...</p>
            }
        </ContentViewLearnerComponent>
    );
};

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    textLink: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block'
        }
    },
    cardRoot: {
        border: '2px solid #E6E9F0',
        cursor: 'pointer',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: 'min-content',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        minHeight: 330,
        gridTemplateRows: 'max-content max-content auto',
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    imagePreload: {
        height: 160,
        width: '100%',
        maxWidth: 170,
        position: 'absolute',
        transform: 'translate(-50%, -50%)',
        top: '50%',
        left: '50%',
        zIndex: 1
    },
    resourceImage: {
        width: '100%',
        height: 170,
        objectFit: 'cover'
    },
    styleStats: {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0 0 0',
        gap: 10,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: 5,
            border: '1px solid rgba(0, 0, 0, 0.15)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
            fontSize: 17,
        },
    },
    cardAccesRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        gap: 10,
        padding: 40,
        width: '100%',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    textLink: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        transition: 'all .4s !important',
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block',
            width: 'max-content',
            fontSize: 13
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
});

export default LibraryResource;
