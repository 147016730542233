
import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, Tooltip } from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { TableReload } from "../../../Action/TableAction";
import { Delete } from "@material-ui/icons";
import SweetAlert from "sweetalert-react";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";
import { dispatch } from '../../../App';
import { useSelector } from 'react-redux';

export default function FormationSession(props = {}) {
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const [dialog, setDialog] = React.useState(false);
    const [optionSessions, setOptionSessions] = React.useState([false]);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    // LIST
    const columns = [
        {
            name: 'sessionFormationId',
            label: 'Référence de la session formation',
            options: { filter: true, sort: true }
        },
        {
            name: 'sessionId',
            label: 'Référence de la session',
            options: { filter: true, sort: true }
        },
        {
            name: 'startAt',
            label: 'Début',
            options: { filter: true, sort: true }
        },
        {
            name: 'endAt',
            label: 'Fin',
            options: { filter: true, sort: true }
        },
        {
            name: 'organism',
            label: 'Organisme',
            options: { filter: true, sort: true }
        },
        {
            name: 'financeType',
            label: 'Type de financement',
            options: { filter: true, sort: true }
        },
        {
            name: "registrations",
            label: "Participants",
            options: { filter: false, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }

    ];
    // FORM
    const [form, setForm] = React.useState({
        sessions: {
            id: '',
            name: 'sessions',
            label: 'sessions',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const getAction = (row) => {
        return (
            <>
                <Tooltip title={'Supprimer'} placement="left">
                    <IconButton onClick={() => {
                        setActionRow(row);
                        setShowConfirm(true);
                    }}>
                        <Delete />
                    </IconButton>
                </Tooltip>
            </>
        );
    };

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            setLoading(true);

            Api.post({
                route: 'content_manager_formation_formation_session_add',
                params: { formation: props.match.params.id },
                data: handler.getData()
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Relation session enregistré.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('content_manager_formation_formation_session_list'));
                    setDialog(false);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    // Use Effect
    React.useEffect(handler.start, []);

    React.useEffect(() => {
        if (props.match.params.id) {
            Api.get({
                route: 'content_manager_formation_formation_session_select_sessions',
                params: { formation: props.match.params.id }
            },
                (response) => {
                    if (response && response.data) {
                        setOptionSessions(response.data);
                    }
                });
        }

    }, []);

    return (
        <>
            <TableComponent
                noCache={true}
                id={'content_manager_formation_formation_session_list'}
                title={'Sessions lié à la formation : '}
                columns={columns}
                actionFirst={{
                    label: 'Ajouter une relation session',
                    onClick: () => {
                        handler.reset();
                        setDialog(true);
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({
                            route: 'content_manager_formation_formation_session_list',
                            data: { organism: props.isInstitution ? selectOrganismReducer.organism.value : null },
                            params: { formation: props.match.params.id }
                        }, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].startAt = moment(data[index].startAt).format('L');
                                    data[index].endAt = moment(data[index].endAt).format('L');
                                    data[index].action = getAction(data[index]);
                                }
                                resolve(data);
                            } else {
                                resolve([]);
                            }
                        })
                    } else {
                        resolve([]);
                    }
                }}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{ fontSize: 15 }}>{<>Ajouter une relation session</>}</DialogTitle>
                <DialogContent style={{ minWidth: '60vw' }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <SelectAutocompleteComponent name={'sessions'} handler={handler} options={optionSessions} />
                        </Grid>
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} />
                    <ButtonComponent label={'Enregistrer'} onClick={save} />
                </DialogActions>
            </Dialog>


            <SweetAlert
                show={showConfirm}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette liaison ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    Api.delete({
                        route: 'content_manager_formation_formation_session_delete',
                        params: { id: actionRow.sessionFormationId, formation: props.match.params.id }
                    },
                        (response) => {

                            if (response.status === 200) {
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Relation session supprimée.',
                                        variant: 'success',
                                    })
                                );
                                dispatch(TableReload('content_manager_formation_formation_session_list'));
                            } else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}
