import React from "react";
import { BrightnessAuto, SupervisedUserCircle, Ballot, TrendingUp, Business } from "@material-ui/icons";
import DashboardIcon from '@material-ui/icons/Dashboard';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { getRoutes } from "./Route";
const menu = [
    {
        id: 'institution_health_home',
        label: 'Tableau de bord',
        icon: <DashboardIcon />
    },
    {
        id: 'institution_health_investor',
        label: 'Investisseur',
        icon: <TrendingUp />,
    },
    {
        id: 'institution_health_crm',
        label: 'Établissement de santé',
        icon: <SupervisedUserCircle />,
        children: [
            { id: 'institution_health_crm_institution_list', label: 'Vos Établissements' },
            { id: 'institution_health_crm_institution_group_list', label: 'Groupement d\'établissements' },
            //{ id: 'institution_health_crm_reporting', label: 'Reporting' },
            { id: 'institution_health_crm_dashboard_manager', label: 'Tableau Manager' },
            //{ id: 'institution_health_crm_relaunch_list', label: 'Suivi des inscriptions' },
            { id: 'institution_health_crm_hotline_list', label: 'Mes hotlines' },
            { id: 'institution_health_crm_prospect_list', label: 'Liste des salariés' },
            { id: 'institution_health_crm_log_mailer_tracking', label: 'Suivi des emails' },
        ]
    },
    {
        id: 'institution_health_content_manager',
        label: 'Gestion de contenu',
        icon: <Ballot />,
        children: [
            { id: 'institution_health_content_manager_formation_list', label: 'Formations' },
            { id: 'institution_health_content_manager_chapter_list', label: 'Chapitres' },
            { id: 'institution_health_content_manager_course_list', label: 'Cours' },
            { id: 'institution_health_content_manager_resource_list', label: 'Ressource' },
            { id: 'institution_health_content_manager_resource_type_list', label: 'Ressource Type' },
            { id: 'institution_health_content_manager_resource_group_list', label: 'Ressource Groupe' },
            { id: 'institution_health_content_manager_virtual_classroom_list', label: 'Classes virtuelles' },
            { id: 'institution_health_content_manager_virtual_classroom_period_list', label: 'Classes virtuelles Périodes' },
        ]
    },
    // {
    //     id: 'institution_health_marketing_manager',
    //     label: 'Gestion marketing',
    //     icon: <Business />,
    //     children: [
    //         { id: 'institution_health_marketing_manager_thematic_list', label: 'Thématique' },
    //         { id: 'institution_health_marketing_manager_library_resource_list', label: 'Ressource' },
    //         { id: 'institution_health_marketing_manager_library_resource_type_list', label: 'Ressource Type' },
    //         { id: 'institution_health_marketing_manager_featured_resource', label: 'Ressource à la une' },
    //         { id: 'institution_health_marketing_manager_catalog_list', label: 'Gestion du catalogue' },
    //         { id: 'institution_health_marketing_manager_hubspot_queue', label: "Liste d'attente Hubspot" }
    //     ]
    // },
    {
        id: 'institution_health_admin',
        label: 'Administrateur',
        icon: <BrightnessAuto />,
        children: [
            { id: 'institution_health_admin_user_list', label: 'Utilisateurs' },
            { id: 'institution_health_admin_session_list', label: 'Sessions' },
        ]
    },
    {
        id: 'institution_health_financial_management_billing',
        label: 'Gestion financière',
        icon: <LibraryBooksIcon />,
        children: [
            { id: 'institution_health_financial_management_billing_institution_list', label: 'Facturation établissements' },
            { id: 'institution_health_financial_management_billing_formation_list', label: 'Facturation formations' },
            { id: 'institution_health_financial_management_billing', label: 'Suivi de facture' },
        ]
    },
];

export const getMenu = () => {
    let routes = getRoutes();
    let menuTmp = [];

    for (let index1 in menu) {
        let children = [];
        for (let index2 in menu[index1].children) {
            if (routes[menu[index1].children[index2].id]) {
                let c = menu[index1].children[index2];
                c.path = routes[menu[index1].children[index2].id].path;
                c = params(c);
                children.push(c);
            }
        }

        let m = {
            id: menu[index1].id,
            label: menu[index1].label,
            icon: menu[index1].icon,
        };

        if (routes[menu[index1].id]) {
            let p = menu[index1];
            p.path = routes[menu[index1].id].path;
            m.path = params(p).path;
        };

        if (children.length > 0) m.children = children;
        if (m.children || m.path) menuTmp.push(m);
    }

    return menuTmp;
}

const params = (data) => {
    if (data.params) {
        for (let key in data.params) {
            if (data.params[key]) {
                let regEx = new RegExp(':' + key + '[?]?', 'gi');
                data.path = data.path.replace(regEx, data.params[key]);
            } else {
                let regEx = new RegExp('/:' + key + '[?]?', 'gi');
                data.path = data.path.replace(regEx, '');
            }
        }
    }
    return data;
}
