import React from 'react';
import { Box, IconButton, Tooltip, makeStyles } from '@material-ui/core';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import TableComponent from '../../../../Component/TableComponent';
import { useDispatch, useSelector } from 'react-redux';
import { getRoutePathname } from '../../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { TableReload } from '../../../../Action/TableAction';
import { Delete } from '@material-ui/icons';
import FormatIndentIncreaseIcon from '@material-ui/icons/FormatIndentIncrease';
import InvoiceDialogComponent from './Component/InvoiceDialogComponent';

export default function BillingInstitutionContractList(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [institutionName, setInstitutionName] = React.useState('-');
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [typeShow, setTypeShow] = React.useState({});
    const [actionRow, setActionRow] = React.useState({});
    const [dialog, setDialog] = React.useState(false);

    const columns = [
        {
            name: "title",
            label: "Titre du contrat",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "countRegistration",
            label: "Nombre d'inscriptions",
            options: { filter: true, sort: true }
        },
        {
            name: "countConvention",
            label: "Conventions",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        return (
            <Box style={{ width: 'max-content' }}>
                <Tooltip title={'Suivi de contrat'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            props.history.push(getRoutePathname(
                                row.isValidated ? 'institution_health_financial_management_billing_institution_contract_invoice_list' :
                                    'institution_health_financial_management_billing_institution_contract'
                                , { institution: props.match.params.institution, id: row.uuid }))
                        }}>
                            <LibraryBooksIcon style={{ color: '#5E6E82' }} />
                        </IconButton>
                    </span>
                </Tooltip>
                {/*<Tooltip title={row.cancelAt ? '' : 'Supprimer'} placement="left">
                    <span>
                        <IconButton disabled={row.cancelAt} onClick={() => {
                            setTypeShow({ title: 'Supprimer', text: '\nÊtes-vous sûr de vouloir supprimer ce contrat ?', type: 'deleteContract' });
                            setActionRow(row);
                            setShowConfirm(true);
                        }}>
                            <Delete style={{ color: row.cancelAt ? '' : '#5E6E82' }} />
                        </IconButton>
                    </span>
                </Tooltip>*/}
            </Box >
        )
    }
    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    onClick={() => props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_contract', { institution: props.match.params.institution, id: null }))}
                    label={<>Créer un contrat</>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: institutionName ?? '-',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste des contrats',
            html: <GetActionMore />,
            links: [
                { label: 'Gestion financière' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation' }
            ],
        });

        setReadyContent(true);
    }, [institutionName]);

    React.useEffect(() => {
        if (!props.match.params.institution) {
            props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_list'));
            dispatch(
                SnackbarOpen({
                    text: 'Aucun établissement n\'a été trouvé.',
                    variant: 'warning',
                })
            );
        }
    }, [props.match.params.institution]);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id='institution_health_financial_management_billing_institution_contract_list'
                        title={
                            <h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5 }}>
                                Vos contrats
                            </h1>
                        }
                        columns={columns}
                        search={true}
                        promiseData={(resolve) => {
                            Api.get({
                                route: 'institution_health_financial_management_billing_institution_contract_list',
                                params: { institution: props.match.params.institution }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        const data = response.data.data.map(item => {
                                            return {
                                                ...item,
                                                title: item.title ?? '-',
                                                action: getAction(item),
                                                countConvention: item.countConvention ?? 0,
                                                state: <span style={{ color: item.statusColor, width: 'max-content', display: 'block', fontWeight: 'bold' }}>{item.statusLabel ?? '-'}</span>
                                            };
                                        });
                                        setInstitutionName(response.data.institutionName);
                                        resolve(data);
                                    } else {
                                        resolve([]);
                                    }
                                });
                        }}
                    />
                </Box>
            </ContentViewComponent>
            <SweetAlert
                show={showConfirm}
                title={typeShow.title}
                text={typeShow.text}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    dispatch(LinearProgressMainLayoutActivate());
                    if (typeShow.type === 'deleteContract') {
                        Api.delete({
                            route: 'institution_health_financial_management_billing_institution_contract_delete',
                            params: { institution: props.match.params.institution, contractId: actionRow.uuid }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                if (response.status === 201) {
                                    dispatch(TableReload('institution_health_financial_management_billing_institution_contract_invoice_list'))
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Contrat supprimer.',
                                            variant: 'success',
                                        })
                                    );
                                } else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    } else if (typeShow.type === 'cancelContract') {
                        Api.delete({
                            route: 'institution_health_financial_management_billing_institution_contract_cancel',
                            params: { institution: props.match.params.institution, contract: actionRow.uuid }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());
                                if (response.status === 201) {
                                    dispatch(TableReload('institution_health_financial_management_billing_institution_contract_invoice_list'))
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Contrat annuler.',
                                            variant: 'success',
                                        })
                                    );
                                } else if (response.status === 400) {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                } else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </Box>
    );

}

const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    iconButton: {
        height: 'auto',
        display: 'flex',
        background: 'rgb(255, 255, 255)',
        color: 'rgb(94, 110, 130)',
        alignItems: 'center',
        width: '100%',
        margin: '0',
        gap: 0,
        padding: 4,
        borderRadius: 5,
        "& span": {
            width: 'max-content',
            fontSize: 12
        }
    },
}))