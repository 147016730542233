import React from "react";
import SelectComponent from "../../../../../Component/SelectComponent";

const FinanceTypeComponent = (props) => {
    const [option, setOption] = React.useState([]);
    React.useEffect(() => {
        const employeeLabel = props.employee;
        const poste = employeeLabel.split('-').pop().trim();
        if (poste == 'Titulaire') {
            const filteredData = props.option.filter(option => ['1', '3', '6'].includes(option.value));
            setOption(filteredData);
        } else {
            const filteredData = props.option.filter(option => ['6', '8'].includes(option.value));
            setOption(filteredData);
        }
    }, []);

    return ((option.length != 0) &&
        < SelectComponent name={props.name} handler={props.handler} options={option} />)
}

export default FinanceTypeComponent;