import React from 'react';
import { Box, makeStyles } from "@material-ui/core";
import FormationComponent from '../../../ContentManager/Formation/Formation';

const Formation = (props) => {
    const classes = useStyles();
    return (
        <Box className={classes.styleContentView}>
            <FormationComponent classNameForm={classes.boxComponent} isInstitution={true} {...props} />
        </Box>
    )
}

const useStyles = makeStyles({
    boxComponent: {
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        height: '100%',
        '& .ContentView-root >div:nth-of-type(2)': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto auto',
            '& >div:nth-of-type(1)': {
                width: '100%',
                margin: 'auto'
            },
            '& >div:nth-of-type(2)': {
                margin: 'auto auto 20px auto',
                zIndex: 1,
                width: '99%',
                '& >div': {
                    display: 'grid'
                }
            },
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
});

export default Formation;
