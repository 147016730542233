import React from 'react';
import { Box, Fade, IconButton, makeStyles } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import EmojiEventsIcon from '@material-ui/icons/EmojiEvents';
import LockIcon from '@material-ui/icons/Lock';
import { theme } from "../../../../App";
import { OrganismIndex, Organisms } from "../../../../Organism";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { useSelector } from 'react-redux';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DateRangeIcon from '@material-ui/icons/DateRange';
import ShadowBoxComponent from '../../../../Component/ShadowBoxComponent';

function CardFormation(props) {
    const classes = useStyles(theme)();
    const [load, setLoad] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);

    const checkRegistrationOrganism = () => {
        let organism = '';
        let logo = '';
        if (props.organism) organism = OrganismIndex[props.organism];
        if (Organisms[organism]) {
            logo = Organisms[organism].logo;
        }
        return logo;
    };

    const stateCard = () => {
        const states = {
            1: { icon: <CheckCircleOutlineIcon className={classes.cardStateIcon} />, text: 'Disponible' },
            2: { icon: <LockIcon className={classes.cardStateIcon} />, text: 'Bientôt disponible' },
            3: { icon: <EmojiEventsIcon className={classes.cardStateIcon} />, text: 'Terminée' },
            4: { icon: <HourglassEmptyIcon className={classes.cardStateIcon} />, text: 'ANDPC en attente' },
            5: { icon: <EmojiEventsIcon className={classes.cardStateIcon} />, text: 'Terminée' },
            6: { icon: <HourglassEmptyIcon className={classes.cardStateIcon} />, text: 'Financement en attente' },
            7: { icon: <ErrorOutlineIcon className={classes.cardStateIcon} />, text: 'Conflit de paiement' },
        };

        if (props.availableAt) {
            return (
                <div style={{ alignItems: 'center', display: 'flex', gap: 5, }} className={classes.cardState}>
                    <LockIcon className={classes.cardStateIcon} />
                    <p style={{ fontWeight: '600', margin: 0, fontSize: 12 }}>Bientôt disponible</p>
                </div>
            );
        }

        const currentState = states[props.state] || { icon: <LockIcon className={classes.cardStateIcon} />, text: 'Bientôt disponible' };

        return (
            <div style={{ alignItems: 'center', display: 'flex', gap: 5, }} className={classes.cardState}>
                {currentState.icon}
                <p style={{ fontWeight: '600', margin: 0, fontSize: 12 }}>{currentState.text}</p>
            </div>
        );
    };

    React.useEffect(() => {
        if ((screenReducer.screen === 'XS' || screenReducer.screen === 'SM')) {
            setLoad(false);
        }
    }, [screenReducer.screen]);

    return (
        <Fade in={props.show} {...{ timeout: 500 }}>
            <Box onClick={() => (props.onClick && props.typeView != 'carousel') && props.onClick()}
                className={screenReducer.size >= 611 ? classes.cardRoot : classes.cardRoot2} style={{ cursor: props.typeView != 'carousel' ? 'pointer' : '' }}>
                <Box className={`${classes.pictureRoot}`}>
                    {(!load) && <img src="/asset/images/preload_image.png" alt={'Image formation'} />}
                    {props.image && <img src={props.image} alt={'Image formation'} onLoad={() => { setLoad(true) }} />}
                </Box>
                <ShadowBoxComponent style={{ margin: '-25px auto 0 auto', borderRadius: 8, padding: '5px 24px', zIndex: 2 }}>
                    {stateCard()}
                </ShadowBoxComponent>
                <Box style={{ margin: 10 }}>
                    <Typography className={screenReducer.size >= 611 ? classes.cardText : classes.cardText2} variant="subtitle2" component="p">
                        {props.title && props.title}
                    </Typography>
                    <Box style={{ alignItems: 'center', display: 'flex', gap: 5, padding: '5px 0' }}>
                        <DateRangeIcon className={classes.cardHourIcon} />
                        <Typography className={classes.cardText} style={{ fontSize: 12 }} variant="subtitle2" component="p">
                            {props.textSession}
                        </Typography>
                    </Box>
                </Box>
                <Box style={{ margin: 'auto 0 0 0' }}>
                    <Box style={{ alignItems: 'center', display: 'flex', gap: 5, margin: '10px', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                        {props.hours && <div className={classes.cardHour}>
                            <AccessTimeIcon className={classes.cardHourIcon} />
                            <p style={{ fontWeight: '600', margin: 0 }}>Temps : {props.hours} heures</p>
                        </div>}

                        {props.organism && <img src={'/asset/images/' + checkRegistrationOrganism()} alt="logo" style={{ width: '40%' }} />}
                    </Box>
                    <IconButton
                        className={classes.iconButton}
                        disabled={props.disabled}
                        onClick={() => { (props.onClick && !props.disabled) && props.onClick() }}>
                        {(props.state === 1) && <p className={classes.access}>Continuer</p>}
                        {(props.state === 2) && <p className={classes.access}>Visualiser</p>}
                        {(props.state === 3 || props.state === 5) && <p className={classes.access}>Revoir la formation</p>}
                        {(props.state === 4 || props.state === 6) && <p className={classes.access}>Validez l'inscription</p>}
                    </IconButton>
                </Box>
            </Box>
        </Fade>
    );
}

const useStyles = (theme) => makeStyles(({
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: 'min-content',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        gridTemplateRows: 'max-content max-content max-content auto',
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    cardRoot2: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: 'min-content',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        gridTemplateRows: 'max-content max-content max-content auto',
    },
    cardText: {
        margin: 0,
        fontSize: 15,
        lineHeight: '17px',
        letterSpacing: 'initial'
    },
    cardText2: {
        margin: 0,
        fontSize: 15,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        lineHeight: '17px',
        letterSpacing: 'initial'
    },
    pictureRoot: {
        background: '#393939',
        width: 230,
        height: 160,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        margin: 10,
        '& img': {
            position: 'absolute',
            width: '120%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        },
        '&::before': {
            content: "' '",
            width: '100%',
            height: '100%',
            position: 'absolute',
            background: '#39393914',
            zIndex: 2
        }
    },
    cardHour: {
        width: 'max-content',
        fontSize: 12,
        alignItems: 'center',
        display: 'flex',
        gap: 5
    },
    cardStateIcon: {
        fontSize: 18,
        color: '#5F6577'
    },
    cardHourIcon: {
        fontSize: 18,
        color: '#5F6577'
    },
    iconButton: {
        padding: '0 10px',
        height: '100%',
        width: '100%',
        borderRadius: '0 0 12px 12px',
        background: '#F6F6F6'
    },
    access: {
        display: 'flex',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 'bold',
        padding: 10,
        color: '#5F6577',
        margin: 0
    }
}));

export default CardFormation;
