import { theme } from "../App"

export const CongratulationIcon = (props) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg"
            style={{ width: '100%', height: '120px', }} viewBox="0 0 430 300">
            <defs>
                <clipPath id="i">
                    <path d="M0 0h430v430H0z" />
                </clipPath>
                <clipPath id="p">
                    <path d="M0 0h430v430H0z" />
                </clipPath>
                <clipPath id="n">
                    <path d="M0 0h430v430H0z" />
                </clipPath>
                <clipPath id="l">
                    <path d="M0 0h430v430H0z" />
                </clipPath>
                <clipPath id="j">
                    <path d="M0 0h430v430H0z" />
                </clipPath>
                <filter id="a" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
                    <feComponentTransfer in="SourceGraphic">
                        <feFuncA tableValues="1.0 0.0" type="table" />
                    </feComponentTransfer>
                </filter>
                <filter id="c" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
                    <feComponentTransfer in="SourceGraphic">
                        <feFuncA tableValues="1.0 0.0" type="table" />
                    </feComponentTransfer>
                </filter>
                <filter id="e" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
                    <feComponentTransfer in="SourceGraphic">
                        <feFuncA tableValues="1.0 0.0" type="table" />
                    </feComponentTransfer>
                </filter>
                <filter id="g" width="100%" height="100%" x="0%" y="0%" filterUnits="objectBoundingBox">
                    <feComponentTransfer in="SourceGraphic">
                        <feFuncA tableValues="1.0 0.0" type="table" />
                    </feComponentTransfer>
                </filter>
                <path id="b" style={{ display: 'none' }} />
                <path id="d" fill="red"
                    d="m-108.205 0 23.215-27.618-2.551-35.993 35.033-8.676 19.064-30.648L0-89.338l33.444-13.597 19.064 30.649 35.034 8.676-2.552 35.992L108.205 0 84.99 27.618l2.551 35.993-35.033 8.676-19.064 30.648L0 89.338l-33.444 13.597-19.064-30.649-35.034-8.676 2.552-35.992z"
                    style={{ display: 'block' }} transform="translate(215 157.42)" />
                <path id="f" style={{ display: 'none' }} />
                <path id="h" style={{ display: 'none' }} />
                <mask id="q" mask-type="alpha">
                    <g filter="url(#a)">
                        <path fill="#fff" d="M0 0h430v430H0z" opacity="0" />
                    </g>
                </mask>
                <mask id="o" mask-type="alpha">
                    <g filter="url(#c)">
                        <path fill="#fff" d="M0 0h430v430H0z" opacity="0" />
                    </g>
                </mask>
                <mask id="m" mask-type="alpha">
                    <g filter="url(#e)">
                        <path fill="#fff" d="M0 0h430v430H0z" opacity="0" />
                    </g>
                </mask>
                <mask id="k" mask-type="alpha">
                    <g filter="url(#g)">
                        <path fill="#fff" d="M0 0h430v430H0z" opacity="0" />
                    </g>
                </mask>
            </defs>
            <g clip-path="url(#i)" style={{ transform: 'translate(0, -18%)' }}>
                <g clip-path="url(#n)" style={{ display: 'block' }}>
                    <g mask="url(#o)" style={{ display: 'block' }}>
                        <path stroke-linecap="round" stroke-linejoin="round"
                            stroke-width="12"
                            style={{ fill: `${props.color ?? theme.colorPrimary + '61'}`, stroke: 'none', transform: 'translate(0,-8%)' }}
                            d="m284.672 149.297-.292 225.82-69.38-54.79-69.38 54.79.292-225.82"
                            class="primary" />
                    </g>
                    <g fill="#FFF" stroke-linecap="round" stroke-linejoin="round" stroke-width="12"
                        style={{ display: 'block' }}>
                        <path
                            style={{
                                fill: `${props.color ?? theme.colorPrimary}`,
                                stroke: '#FFF9',
                                strokeWidth: 16
                            }}
                            d="m106.795 157.42 23.215-27.618-2.551-35.993 35.033-8.676 19.064-30.648L215 68.082l33.444-13.597 19.064 30.649 35.034 8.676-2.552 35.992 23.215 27.618-23.215 27.618 2.551 35.993-35.033 8.676-19.064 30.648L215 246.758l-33.444 13.597-19.064-30.649-35.034-8.676 2.552-35.992z"
                            class="primary" />
                        <path
                            style={{
                                fill: `${props.color ?? theme.colorPrimary}`,
                                stroke: 'FFFFFF61',
                                strokeWidth: 17
                            }}
                            d="M152.644 157.42c0 34.438 27.918 62.356 62.356 62.356s62.356-27.918 62.356-62.356S249.438 95.064 215 95.064s-62.356 27.918-62.356 62.356"
                            class="primary" />
                    </g>
                    <g stroke-linejoin="round" stroke-linecap="round" stroke-width="12" fill="none"
                        style={{ display: 'block' }}>
                        <path style={{ stroke: '#FFFFFFD9', }} d="m189.64 166.801 12.687 12.686 38.033-38.032" class="secondary"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}