import { Checkbox, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import React from "react";

export const GetActionConventionCheck = (props) => {
    const data = props.data;
    const [isChecked, setIsChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataSweetAlert, setDataSweetAlert] = React.useState({});
    const [show, setShow] = React.useState(false);

    const handleCheckboxChange = (checked) => {
        const isPaid = props.invoiceData && props.invoiceData.isPaid;
        const isConvention = data.isConvention;
        const dataAfter = props.dataAfterList.find((item) => item.id === data.id && !item.isDeleted);
        const invoices = props.invoicesList ? props.invoicesList.filter(invoice => invoice.registrations.some(reg => reg.id === data.id && !reg.isDeleted)) : null;
        const updateConventionList = () => {
            props.setConventionList((prevData) =>
                prevData.map(item =>
                    item.id === data.id ? { ...item, isConvention: checked } : item
                )
            );
        };

        const showSweetAlert = () => {
            setShow(true);
            setDataSweetAlert({
                title: isConvention ? "Annuler la convention" : "Valider la convention",
                text: `Êtes-vous sûr de vouloir ${isConvention ? "annuler" : "valider"} la convention ? \nSi c'est le cas, l'inscription sera ${isConvention ? "désactivée" : "activée"}.`,
                checked
            });
        };

        if (props.type === 'p1') {
            if (isPaid) {
                if (dataAfter && dataAfter?.financeType === "1") {
                    if (dataAfter.isAvailableAndpc) {
                        updateConventionList();
                        showSweetAlert();
                    } else {
                        setIsChecked(checked);
                        updateConventionList();
                        props.save(data, checked, setIsLoading);
                    }
                } else {
                    updateConventionList();
                    showSweetAlert();
                }
            } else {
                setIsChecked(checked);
                updateConventionList();
                props.save(data, checked, setIsLoading);
            }
        }

        if (props.type === 'p2') {
            const allPaid = invoices.every((invoice) => invoice.isPaid);
            if (data.financeType === "1") {
                if (invoices) {
                    if (allPaid && data.isAvailableAndpc) {
                        setShow(true);
                        showSweetAlert();
                    } else {
                        setIsChecked(checked);
                        props.save(data, checked, setIsLoading);
                    }
                } else if (data.isAvailableAndpc) {
                    setShow(true);
                    showSweetAlert();
                } else {
                    setIsChecked(checked);
                    props.save(data, checked, setIsLoading);
                }
            } else {
                if (invoices) {
                    if (allPaid) {
                        setShow(true);
                        showSweetAlert();
                    } else {
                        setIsChecked(checked);
                        props.save(data, checked, setIsLoading);
                    }
                } else {
                    setIsChecked(checked);
                    props.save(data, checked, setIsLoading);
                }
            }

        }
    };


    React.useEffect(() => {
        setIsChecked(data.isConvention);
    }, [data, data.isConvention]);

    return (
        <>
            <div style={{
                background: (props.loading || isLoading) ? 'rgba(0, 0, 0, 0.12)' :
                    (isChecked ? '#28a745' : (data.isForced ? '#dd984e' : '#DD4E4E')), height: '100%', width: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden'
            }}>
                <Tooltip title={
                    (props.loading || isLoading) ? '' :
                        !isChecked ?
                            `Valider la convention \n ${data.isForced ? '(Cette formation est accessible.)' : ''}` :
                            `Annuler la convention \n ${data.isForced ? '(Attention, si vous la décochez,\n cette formation restera tout de même accessible.)' : ''}`
                } placement="left">
                    <span style={{ width: '100%' }}>
                        <IconButton disabled={props.loading || isLoading} onClick={() => handleCheckboxChange(!isChecked)} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                            {isChecked ?
                                <CheckCircleOutlineIcon style={{ color: '#FFF' }} /> :
                                <HighlightOffIcon style={{ color: '#FFF' }} />}
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            <SweetAlert
                show={show}
                title={dataSweetAlert.title ?? '-'}
                text={dataSweetAlert.text ?? '-'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setIsChecked(dataSweetAlert.checked);
                    props.save(data, dataSweetAlert.checked, setIsLoading);
                    setShow(false)
                }}
                onCancel={() => setShow(false)}
            />
        </>
    );
};

export const GetActionPaidCheck = (props) => {
    const data = props.data;
    const [isChecked, setIsChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [dataSweetAlert, setDataSweetAlert] = React.useState({});
    const [show, setShow] = React.useState(false);
    const [showPayement, setShowPayement] = React.useState(false);


    const handleCheckboxChange = (checked) => {
        const conventionValid = props?.conventionList.filter((item) => item.isConvention && !item.isDeleted);
        const registrationsAndpcList = data.registrations.filter(item => item.financeType === '1' && item.isConvention && !item.isDeleted);
        const registrationsOtherList = data.registrations.filter(item => item.financeType != '1' && item.isConvention && !item.isDeleted);
        const dataAfterList = props.dataAfterList.filter(item =>
            registrationsAndpcList.some(registration => item.id === registration.id && !item.isDeleted)
        );

        const showSweetAlert = () => {
            setShowPayement(true);
            setDataSweetAlert({
                title: data.isPaid ? "Annuler le paiement" : "Valider le paiement",
                text: `Êtes-vous sûr de vouloir ${data.isPaid ? "annuler" : "valider"} le paiement ? \nSi c'est le cas, les inscriptions conventionnées seront ${data.isPaid ? "désactivée" : "activée"}.`,
                checked: checked
            });
        };

        const availableAndpcAndConventionValid = dataAfterList.filter(item => item.isAvailableAndpc && item.isConvention && !item.isDeleted);
        if (dataAfterList.length != 0 && registrationsOtherList.length === 0) {
            if (availableAndpcAndConventionValid.length != 0) {
                showSweetAlert();
            } else {
                setIsChecked(checked);
                props.setIsPaid(checked);
                props.save(data, checked, setIsLoading);
            }
        } else {
            if (conventionValid.length != 0 && !data.isPaid) {
                showSweetAlert()
            } else if (conventionValid.length != 0 && data.isPaid) {
                showSweetAlert()
            } else {
                setIsChecked(checked);
                props.setIsPaid(checked);
                props.save(data, checked, setIsLoading);
            }
        }
    }

    React.useEffect(() => {
        setIsChecked(data.isPaid);
    }, [data.isPaid]);

    React.useEffect(() => {
        props.setLoading(isLoading);
    }, [isLoading]);

    return (
        <>
            <div style={{
                background: (props.loading || (props.isDevis && data.isPaid) || isLoading) ? 'rgba(0, 0, 0, 0.12)' :
                    (isChecked ? '#28a745' : '#DD4E4E'), height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden'
            }}>
                <Tooltip title={(props.loading || (props.isDevis && data.isPaid) || isLoading) ? '' : !isChecked ? `Valider` : `Annuler`} placement="left">
                    <span style={{ width: '100%' }}>
                        <IconButton disabled={props.loading || (props.isDevis && data.isPaid) || isLoading}
                            onClick={() => {
                                if (props.isDevis) { setShow(true) } else handleCheckboxChange(!isChecked)
                            }} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                            {isChecked ?
                                <CheckCircleOutlineIcon style={{ color: '#FFF' }} /> :
                                <HighlightOffIcon style={{ color: '#FFF' }} />}
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            <SweetAlert
                show={showPayement}
                title={dataSweetAlert.title ?? '-'}
                text={dataSweetAlert.text ?? '-'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setIsChecked(dataSweetAlert.checked);
                    props.setIsPaid(dataSweetAlert.checked);
                    props.save(data, dataSweetAlert.checked, setIsLoading);
                    setShowPayement(false)
                }}
                onCancel={() => setShowPayement(false)}
            />
            <SweetAlert
                show={show}
                title={`Paiement du devis`}
                text={`Êtes-vous sûr de vouloir valider le paiement ? Une fois confirmé, un mail avec la facture du devis sera envoyé, il ne pourra plus être annulé.`}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShow(false);
                    setIsChecked(!isChecked);
                    props.setIsPaid(!isChecked);
                    props.save(data, !isChecked, setIsLoading);
                }}
                onCancel={() => setShow(false)}
            />
        </>
    );
};

export const GetActionAndpcCheck = (props) => {
    const data = props.data;
    const [isChecked, setIsChecked] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const isAvailableAndpc = data.isAvailableAndpc;
    const [show, setShow] = React.useState(false);
    const [dataSweetAlert, setDataSweetAlert] = React.useState({});

    const handleCheckboxChange = (checked) => {
        const invoices = props.invoicesList ? props.invoicesList.filter(invoice => invoice.registrations.some(reg => reg.id === data.id)) : null;
        const showSweetAlert = () => {
            setShow(true);
            setDataSweetAlert({
                title: isAvailableAndpc ? "Annuler la participation ANDPC" : "Valider la participation ANDPC",
                text: `Êtes-vous sûr de vouloir ${isAvailableAndpc ? "annuler" : "valider"} la participation ANDPC ? \nSi c'est le cas, l'inscription sera ${isAvailableAndpc ? "désactivée" : "activée"}.`,
                checked
            });
        };
        if (data.financeType === "1") {
            if (invoices) {
                const allPaid = invoices.every((invoice) => invoice.isPaid);
                if (allPaid && data.isConvention) {
                    showSweetAlert()
                } else {
                    setIsChecked(checked);
                    props.save(data, checked, setIsLoading);
                }
            } else {
                if (data.isConvention) {
                    showSweetAlert()
                } else {
                    setIsChecked(checked);
                    props.save(data, checked, setIsLoading);
                }
            }
        } else {
            setIsChecked(checked);
            props.save(data, checked, setIsLoading);
        }
    }

    React.useEffect(() => {
        setIsChecked(data.isAvailableAndpc);
    }, [data, data.isAvailableAndpc]);

    return (
        <>
            <div style={{
                background: (props.loading || isLoading) ? 'rgba(0, 0, 0, 0.12)' :
                    (isChecked ? '#28a745' : '#DD4E4E'), height: '100%', width: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden'
            }}>
                <Tooltip title={
                    (props.loading || isLoading) ? '' :
                        !isChecked ?
                            `Valider la participation` :
                            `Annuler la participation`
                } placement="left">
                    <span style={{ width: '100%' }}>
                        <IconButton disabled={props.loading || isLoading} onClick={() => handleCheckboxChange(!isChecked)} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                            {isChecked ?
                                <CheckCircleOutlineIcon style={{ color: '#FFF' }} /> :
                                <HighlightOffIcon style={{ color: '#FFF' }} />}
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
            <SweetAlert
                show={show}
                title={dataSweetAlert.title ?? '-'}
                text={dataSweetAlert.text ?? '-'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setIsChecked(dataSweetAlert.checked);
                    props.save(data, dataSweetAlert.checked, setIsLoading);
                    setShow(false)
                }}
                onCancel={() => setShow(false)}
            />
        </>
    );
};

export const GetActionCreditCheck = (props) => {
    const data = props.data;
    const classes = useStyles();
    const [isChecked, setIsChecked] = React.useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        props.onChange((prevData) => {
            return prevData.includes(data.id)
                ? prevData.filter(id => id !== data.id)
                : [...prevData, data.id];
        });
    }

    return (
        <div className={classes.checkbox}>
            <Checkbox
                key={data.id}
                disabled={props.loading}
                checked={isChecked}
                onChange={(value) => handleCheckboxChange(value)}
            />
        </div>
    );
};

const useStyles = makeStyles(style => ({
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '5px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
}))