import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Box, Fade, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import FeaturedResourceComponent from '../../../Component/FeaturedResourceComponent';
import Api, { LibraryResourceImage } from '../../../Api';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import LayersIcon from '@material-ui/icons/Layers';
import CardResourceType from './Component/CardResourceType';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LazyLoading from './Component/LazyLoading';
import { inArray } from '../../../Tool/ArrayCommon';
import { Rating } from '@material-ui/lab';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import PaginationComponent from '../../../Component/PaginationComponent';
import CardResource from './Component/CardResource';
import LearnerImgSvgComponent from '../../../Component/LearnerImgSvgComponent';

const ResourceType = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const classes = useStyles(theme)();
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [cardSmall, setCardSmall] = React.useState({ grid: 3, width: 1102 });
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [resources, setResources] = React.useState();
    const [typeLabel, setTypeLabel] = React.useState();
    const [libraryResourceTypes, setLibraryResourceTypes] = React.useState([]);
    const [thematicsList, setThematicsList] = React.useState([]);
    const [thematicActive, setThematicActive] = React.useState();

    const viewList = (data) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <CardResourceType
                    key={data.value}
                    libraryResourceType={data}
                    onClick={() => {
                        props.history.push(getRoutePathname('learner_resource_type', { id: data.value }))
                    }}
                />
            </Fade>
        )
    }

    const cardSpace = (countSpaces) => {
        return (
            <div style={{ display: 'flex', gridColumn: `span ${countSpaces}` }} >
                <Fade in={true} {...{ timeout: 500 }}>
                    <Box className={classes.cardAccesRoot}>
                        <Box style={{ textAlign: 'center', margin: (countSpaces === 1) ? 'auto' : '', zIndex: 1, position: 'relative' }}>
                            <h1 style={{ color: theme.colorPrimary, margin: 5, fontSize: (countSpaces != 1) ? 60 : 30 }}>Tableau de bord</h1>
                        </Box>
                        <p className={classes.textLink} style={{ margin: countSpaces === 1 ? 0 : 10, zIndex: 1, position: 'relative' }}
                            onClick={() => { props.history.push(getRoutePathname('learner_home')) }}
                        ><span style={{ padding: '5px 50px' }}>{(countSpaces != 1) ? 'Revenir au Tableau de bord' : 'Accéder'}</span></p>
                        {(countSpaces != 1) && <LearnerImgSvgComponent style={{ width: '100%' }} />}
                    </Box>
                </Fade>
            </div>
        )
    }

    const countEmptySpaces = (data, grid) => {
        const newData = data?.filter((prevData) => (!thematicActive || inArray(thematicActive, prevData.thematicsId)));
        const formationsLength = newData?.length;
        const remainder = formationsLength % grid;
        return remainder === 0 ? 0 : grid - remainder;
    };

    React.useEffect(() => {
        setBreadcrumbs({
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_library_resource'), label: 'Bibliothèque de ressources' },
            ],
            title: <p style={{ color: '#FFF', margin: 0 }}>
                <span style={{ fontSize: screenReducer.size >= 540 ? 50 : 30, display: 'block', fontWeight: 'bold' }}>{typeLabel}</span>
            </p>,
            html: <FeaturedResourceComponent organism={authenticationReducer.organism} formationGroup={authenticationReducer.formationGroup} history={props.history} />
        });

        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));

        setCardSmall((prev) => {
            const values = {
                1: { width: 355, grid: 1 },
                2: { width: 732, grid: 2 },
                default: { width: 1102, grid: 3 }
            };

            const conditions = [
                { condition: screenReducer.size <= 925, type: 1 },
                { condition: screenReducer.size <= 1216, type: 2 },
            ];

            const valueType = conditions.find(cond => cond.condition)?.type || 'default';
            return {
                grid: values[valueType].grid,
                width: values[valueType].width
            };
        });
    }, [screenReducer, typeLabel]);

    React.useEffect(() => {
        setLoading(true);

        Api.get({
            route: 'learner_resource_type_resources_list',
            params: { id: props.match.params.id }
        },
            (response) => {
                if (response && response.data) {
                    setLoading(false);
                    setResources(response.data.data);
                    setTypeLabel(response.data.typeLabel);
                    let data = [];
                    let listThematic = [];
                    let dataResource = response.data.data;

                    for (let index in dataResource) {
                        for (let i in dataResource[index].thematics) {
                            if (!(inArray(dataResource[index].thematics[i].id, data))) {
                                data.push(dataResource[index].thematics[i].id);
                                listThematic.push(dataResource[index].thematics[i]);
                            }
                        }
                    }
                    setThematicsList(listThematic);
                }
            });

        Api.get({
            route: 'learner_resource_type_list',
            data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
        },
            (response) => {
                if (response && response.data) {
                    setLoading(false);
                    setLibraryResourceTypes(response.data);
                }
            });
    }, [props.match.params.id]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <>
                <div style={{ margin: 'auto' }}>
                    {((thematicsList.length > 0) && resources) &&
                        <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 500 }}>Recherchez par thématique</p>
                    }
                    {resources &&
                        <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', marginBottom: 25 }}>
                            {thematicsList?.map((thematic) => (
                                <div
                                    key={thematic.id}
                                    className={classes.btnThematic}
                                    onClick={() => {
                                        if (thematicActive === thematic.id) {
                                            setThematicActive(null);
                                        }
                                        else {
                                            setThematicActive(thematic.id);
                                        }
                                    }}
                                    style={thematicActive === thematic.id
                                        ? { border: `2px solid ${theme.colorPrimary}`, color: '#FFF', background: theme.colorPrimary } :
                                        { border: `2px solid ${theme.colorPrimary}`, color: theme.colorPrimary }
                                    }
                                >
                                    <span style={{ fontWeight: 500 }}>{thematic.label}</span>
                                </div>
                            ))}
                        </div>
                    }
                </div>

                <BoxLearnerComponent
                    title={(resources && resources.length > 0) ? typeLabel : 'Aucune ressource de ce type est disponible'}
                    styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                    styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
                >
                    {resources &&
                        <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width, padding: '20px 0' }}>
                            {resources.map((resource, index) => (
                                (!thematicActive || inArray(thematicActive, resource.thematicsId)) &&
                                    <div style={{ display: 'flex' }} key={index}>
                                        {resource.format === 6 || resource.format === 2 ?
                                            <Fade in={true} {...{ timeout: 500 }}>
                                                <MediaLinkComponent
                                                    path={resource.link}
                                                    type={resource.format}
                                                    isLibrary={true}
                                                    className={classes.cardRoot2}
                                                    style={(screenReducer.size <= 395) ? { width: '100%' } : {}}
                                                >
                                                    <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                </MediaLinkComponent>
                                            </Fade>
                                            :
                                            <Fade in={true} {...{ timeout: 500 }}>
                                                <div className={classes.cardRoot2} style={(screenReducer.size <= 395) ? { width: '100%' } : {}} onClick={() => {
                                                    props.history.push(getRoutePathname('learner_resource_type_resource', { id: props.match.params.id, resource: resource.id }));
                                                }}>
                                                    <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                </div>
                                            </Fade>
                                        }
                                    </div>
                            ))}
                            {(countEmptySpaces(resources, cardSmall.grid) != 0) && cardSpace(countEmptySpaces(resources, cardSmall.grid))}
                        </div>
                    }

                </BoxLearnerComponent>
                <br />
                {(libraryResourceTypes && libraryResourceTypes.length > 0) &&
                    <Box id={'stepLibraryResource'} className={classes.cardRoot} >
                        <BoxLearnerComponent
                            title={'Bibliothèque de ressources'}
                            styleContent={{ flexDirection: 'column' }}
                            styleContentSmall={{ flexDirection: 'column' }}
                            action={
                                <p id={'stepAllLibraryResource'}
                                    className={classes.textLink}
                                    onClick={() => { props.history.push(getRoutePathname('learner_library_resource')) }}
                                >
                                    <span>Voir toutes les ressources</span>
                                </p>
                            }
                        >
                            <div className={classes.PaginationResource} style={{
                                alignItems: 'center',
                                display: 'flex', gap: 10, flexWrap: 'wrap',
                                width: screenReducer.size <= 420 ? 'auto' : screenReducer.size <= 800 ? 333 : screenReducer.size <= 1186 ? 668 : 1002, margin: '22px auto auto'
                            }}>
                                <PaginationComponent
                                    dataList={libraryResourceTypes}
                                    pageNumber={screenReducer.size >= 1186 ? 3 : 4}
                                    viewList={viewList}
                                    error={'Aucune ressouses'}
                                />
                            </div>
                        </BoxLearnerComponent>
                    </Box>
                }
            </>
        </ContentViewLearnerComponent>
    );
};

const useStyles = (theme) => makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    card: {
        height: 300,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            textAlign: 'left'
        }
    },
    cardAccesRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        gap: 10,
        padding: 40,
        width: '100%',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    textLink: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        transition: 'all .4s !important',
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block',
            width: 'max-content',
            fontSize: 13
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    cardRoot2: {
        border: '2px solid #E6E9F0',
        cursor: 'pointer',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: 'min-content',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        minHeight: 330,
        gridTemplateRows: 'max-content max-content auto',
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        padding: 5,
        background: '#FFF',
        margin: '50px auto',
        '& >div': {
            width: '95%',
            margin: '20px auto'
        }
    },
    btnThematic: {
        cursor: 'pointer',
        width: 'max-content',
        padding: '5px 10px',
        margin: 5,
        borderRadius: 5,
        fontSize: 12,
        transition: 'all .4s !important',
        '&:hover': {
            color: '#FFF !important',
            background: theme.colorPrimary + '99 !important',
        }
    },
    styleStats: {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0 0 0',
        gap: 10,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: 5,
            border: '1px solid rgba(0, 0, 0, 0.15)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
            fontSize: 17,
        },
    },
    PaginationResource: {
        '& .MuiPagination-root': {
            margin: 'auto !important'
        }
    }
});

export default ResourceType;