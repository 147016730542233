import { Box, Grid, makeStyles } from "@material-ui/core"
import TextFieldComponent from "../../../../../Component/TextFieldComponent"
import SelectComponent from "../../../../../Component/SelectComponent"
import ButtonComponent from "../../../../../Component/ButtonComponent"
import React from "react"
import { formHandlerInit } from "../../../../../Tool/FormHandlerCommon"

const FormAutocompleteComponent = (props) => {
    const classes = useStyles();
    const loading = props.loading;
    const optionUnit = [
        { value: 1, label: '%' },
        { value: 2, label: '€' }
    ];
    const [formAuto, setFormAuto] = React.useState({
        feeSupport_Opco: {
            name: 'feeSupport_Opco',
            label: 'Prise en charge OPCO',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        feeSupport_Andpc: {
            name: 'feeSupport_Andpc',
            label: 'Prise en charge ANDPC',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        feeSupport_Fifpl: {
            name: 'feeSupport_Fifpl',
            label: 'Prise en charge FIFPL',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionFeeHt: {
            name: 'inscriptionFeeHt',
            label: 'Frais d’inscription',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionDiscount: {
            name: 'inscriptionDiscount',
            label: 'Remise d’inscription',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        inscriptionDiscount_Unit: {
            name: 'inscriptionDiscount_Unit',
            label: 'Unité',
            type: 'integer',
            defaultValue: 2,
            options: {}
        }
    });
    const handlerAuto = formHandlerInit(formAuto, setFormAuto);
    React.useEffect(handlerAuto.start, []);

    const calculateHT = (price) => {
        const htValue = price / (1 + props.tva / 100);
        return htValue.toFixed(2)
    };

    const saveHandlerAuto = () => {
        const autoData = handlerAuto.getData();

        props.setBillingList((prevData) =>
            prevData.map((item) => {
                const updatedPrice = { ...item.price };
                if (autoData.inscriptionFeeHt !== undefined && autoData.inscriptionFeeHt !== null) {
                    updatedPrice.inscriptionFee = autoData.inscriptionFeeHt;
                }
                if (autoData.inscriptionDiscount !== undefined && autoData.inscriptionDiscount !== null) {
                    updatedPrice.inscriptionDiscount = `${autoData.inscriptionDiscount}${autoData.inscriptionDiscount_Unit === 1 ? '%' : '€'}`
                }
                const feeSupportMapping = {
                    1: autoData.feeSupport_Andpc,
                    3: autoData.feeSupport_Fifpl,
                    8: autoData.feeSupport_Opco,
                };

                if (item.financeType in feeSupportMapping) {
                    const supportValue = feeSupportMapping[item.financeType];
                    if (supportValue !== undefined && supportValue !== null) {
                        updatedPrice.feeSupport = supportValue; //calculateHT(supportValue);
                    }
                }
                return { ...item, price: updatedPrice };
            })
        );
    };


    return (
        <Box className={classes.formAuto}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }} >
                    <h1 style={{ fontSize: 20, margin: 0 }}>Saisie automatique</h1>
                    <hr style={{ margin: 0, opacity: 0.5 }} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '0 0 5px 0' }}>
                            <fieldset className={classes.fieldsetStyle}>
                                <legend style={{ margin: '0 0 0 auto', padding: '0 12px' }}><span>Prix HT</span></legend>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name='feeSupport_Opco' handler={handlerAuto} disabled={loading} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name='feeSupport_Andpc' handler={handlerAuto} disabled={loading} />
                                    </Grid>
                                    {/*<Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                                        <TextFieldComponent name='feeSupport_Fifpl' handler={handlerAuto} disabled={loading} />
                                    </Grid>*/}
                                </Grid>
                            </fieldset>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.discountText} style={{ display: 'grid', gridTemplateColumns: 'auto 53px' }}>
                            <TextFieldComponent name='inscriptionDiscount' handler={handlerAuto} disabled={loading} />
                            <SelectComponent name={'inscriptionDiscount_Unit'} handler={handlerAuto} options={optionUnit} disabled={loading} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto 230px' }} >
                            <TextFieldComponent name='inscriptionFeeHt' handler={handlerAuto} disabled={loading} />
                            <ButtonComponent onClick={() => { saveHandlerAuto() }} label={'valider'} disabled={loading} color={"#5E6E82"} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    )
}

const useStyles = makeStyles(({
    formAuto: {
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    fieldsetStyle: {
        borderRadius: 5,
        borderColor: '#5E6E825E',
        fontSize: 11,
        color: '#5E6E82',
        fontWeight: 'bold',
        padding: '5px 10px 10px'
    },
    discountText: {
        '& .MuiTextField-root fieldset': {
            borderRadius: '4px 0 0 4px'
        },
        '& .MuiFormControl-root .MuiOutlinedInput-root': {
            borderRadius: '0 4px 4px 0'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
}));

export default FormAutocompleteComponent;