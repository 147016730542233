import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import { Box, Fade, Grid, makeStyles } from "@material-ui/core";
import activity from "react-useanimations/lib/activity";
import UseAnimations from "react-useanimations";
import { theme } from "../../../../App";
import BreadcrumbsComponent from "../../../../Component/BreadcrumbsComponent";
import { getRoutePathname } from "../../../../Config/Route";
import ApartmentIcon from '@material-ui/icons/Apartment';
import FilterPaginationComponent from "../../../../Component/FilterPaginationComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import Api from "../../../../Api";
import ContentViewLearnerComponent from "../../../../Component/ContentViewLearnerComponent";

const InstitutionList = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [institution, setInstitution] = React.useState([]);

    const viewInstitutionList = (data) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box className={classes.cardStyle} style={{ color: "#5E6E82", textAlign: "left", padding: '22px 15px' }}>
                        <Grid container spacing={0} style={{ alignItems: ' center', }}>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display: 'flex' }}>
                                <Box style={{ padding: '0 10px' }}>
                                    <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{data.title}</p>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "0px", display: 'flex' }} >
                                    <ButtonComponent
                                        small={true}
                                        color='#03a9f4'
                                        style={{ margin: "10px 0 10px auto", display: "block" }}
                                        className={classes.buttonPost}
                                        label={'Accéder a l\'établissement'}
                                        onClick={() => { props.history.push(getRoutePathname('learner_institution_manager', { institution: data.id })) }}
                                    />
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Fade>
        )
    }

    const loadData = () => {
        setLoad(true);
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'learner_institution_list',
        },
            (response) => {
                setLoad(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    let data = response.data;
                    setInstitution(data);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        loadData()
        setBreadcrumbs({
            currentPage: 'Mes établissements',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });
    }, []);
    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <Box style={{ textAlign: 'center', maxWidth: 992, margin: 'auto' }}>
                <Box className={classes.pagination}>
                    <FilterPaginationComponent
                        data={institution}
                        title={'Mes établissements'}
                        icon={<ApartmentIcon />}
                        pageNumber={5}
                        error={'Vous n\'êtes affilié à aucun établissement.'}
                        viewList={viewInstitutionList}
                        loading={load}
                        loadData={loadData}
                        className={classes.Institution}
                    />
                </Box>
            </Box>
        </ContentViewLearnerComponent>
    )
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    pagination: {
        padding: '30px 0',
        '& .pageError': {
            //fontSize: '30px !important'
        }
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "#FFF !important",
        boxShadow: "none",
        border: "solid 1px " + theme.colorPrimary,
        color: theme.colorPrimary,
        margin: "0px 39px"
    },
    cardStyle: {
        position: 'relative',
        overflow: 'initial',
        border: '1px solid rgb(204, 204, 204)',
        boxShadow: 'initial',
        filter: 'initial',
        opacity: 1,
        borderRadius: 5,
        background: 'linear-gradient(90deg, rgb(255, 255, 255), 20%, rgb(255, 255, 255), 77%, rgba(210, 210, 210, 0.86)), url(/asset/images/wallpaper_institution.webp)',
        backgroundSize: ' cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center'
    }
}));

export default InstitutionList;