import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import Api, { learnerInvoice } from "../../../Api";
import { Fade, Grid, makeStyles } from "@material-ui/core";
import { useDispatch, useSelector } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../Action/LinearProgressMainLayoutAction';
import FilterPaginationComponent from '../../../Component/FilterPaginationComponent';
import moment from 'moment';
import ButtonComponent from '../../../Component/ButtonComponent';
import { MediaShow } from '../../../Action/MediaAction';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

const Invoice = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [infos, setInfos] = React.useState([]);

    const filter = [
        {
            data: 'year',
            typeValue: 'date',
            type: 'select'
        },
    ];
    const viewList = (data) => {
        return (
            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                <ShadowBoxComponent className={classes.shadowBox}>

                    {!isSmallView && <img src="/asset/images/invoice.webp" style={{ float: 'left', width: 130 }} alt={'Image invoice'} />}

                    <p style={{ fontWeight: 700, fontSize: 14, margin: "0", padding: "10px 0 0 0", height: 50 }}>{data.formationName}</p>
                    <p style={{ fontWeight: 500, fontSize: 12 }}>Date de facture: {moment(data.date).format('LL')}</p>

                    <ButtonComponent
                        small={true}
                        color='#03a9f4'
                        className={classes.btn}
                        style={{ right: 5 }}
                        label={'Télécharger'}
                        onClick={() => {
                            window.open(Api.route({
                                route: 'learner_invoice_registration_invoice_download',
                                params: { id: data.registration },
                            }), '_blank');
                        }}
                    />
                    <ButtonComponent
                        small={true}
                        color='#03a9f4'
                        className={classes.btn}
                        style={{ right: 100 }}
                        label={'Voir'}
                        onClick={() => {
                            dispatch(MediaShow({
                                type: 1,
                                path: learnerInvoice + '/registration/' + data.registration + '/invoice/view'
                            }));
                        }}
                    />
                </ShadowBoxComponent>
            </Grid>
        )
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {

        setBreadcrumbs({
            currentPage: 'Mes Factures',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'learner_invoice_info'
        },
            (response) => {
                setInfos(response.data);
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                }, 1000);
            });

    }, []);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <div style={{ textAlign: 'center', maxWidth: '80%', margin: 'auto' }}>
                <div style={{ overflow: 'SM', paddingTop: 20, paddingBottom: 20 }}>
                    <Grid container spacing={3}>
                        <FilterPaginationComponent
                            data={infos}
                            title={'Mes factures disponibles'}
                            filter={filter}
                            pageNumber={6}
                            viewList={viewList}
                            displayFilter={false}
                            error={'Vous ne disposez pour l\'instant d\'aucune facture.'}
                        />
                    </Grid>
                </div>
            </div>
        </ContentViewLearnerComponent>
    );
};

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        border: '1px solid rgba(0,0,0, 15%)',
        boxShadow: 'initial',
        height: 150
    },
    btn: {
        bottom: 10,
        position: "absolute",
        textTransform: "initial",
        fontSize: 11,
        padding: "3px 11px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)"
    },
});

export default Invoice;