import { CardWelcome, CardHotlines, CardBilling, CardDashboardManager, CardSessions, CardFormations, CardInstitution, CardInstitutionGroup, CardRelaunch, CardRelaunchV2, CardAllProspect, CardProspect, CardInvestisor, CardVirtualClassroom, CardRessource, CardCourse, CardInstitutionGroup2 } from './ExportCard';
export const listCard = [
    {
        roles: ['ROLE_SUPER_ADMIN'],
        view: ` 'welcome welcome institution formations'
                'welcome welcome institution investisor'
                'welcome welcome institution dashboardManager'
                'welcome welcome institutionGroup dashboardManager'
                'prospect billing institutionGroup dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            billing: CardBilling,
            prospect: CardProspect,
            institution: CardInstitution,
            formations: CardFormations,
            institutionGroup: CardInstitutionGroup,
            investisor: CardInvestisor,
            dashboardManager: CardDashboardManager,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_ADMIN'],
        view: ` 'welcome welcome institution virtualClassroom'
                'welcome welcome institution sessions'
                'welcome welcome institution dashboardManager'
                'welcome welcome institution dashboardManager'
                'institutionGroup2 billing investisor dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            institutionGroup2: CardInstitutionGroup2,
            institution: CardInstitution,
            sessions: CardSessions,
            investisor: CardInvestisor,
            virtualClassroom: CardVirtualClassroom,
            dashboardManager: CardDashboardManager,
            billing: CardBilling,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_CONTENT_MANAGER'],
        view: ` 'welcome welcome institution formations'
                'welcome welcome institution course'
                'welcome welcome virtualClassroom ressource'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
            formations: CardFormations,
            virtualClassroom: CardVirtualClassroom,
            ressource: CardRessource,
            course: CardCourse,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_CRM', 'ROLE_INSTITUTION_CRM_SUPER_ADMIN', 'ROLE_INSTITUTION_CRM_ADMIN'],
        view: ` 'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institutionGroup dashboardManager'
                'hotlines prospectAll institutionGroup dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
            institutionGroup: CardInstitutionGroup,
            prospectAll: CardAllProspect,
            hotlines: CardHotlines,
            dashboardManager: CardDashboardManager,
        }
    },
    {
        roles: ['ROLE_INSTITUTION_ACCOUNTANT', 'ROLE_INSTITUTION_ACCOUNTANT_ADMIN'],
        view: ` 'welcome welcome institution billing'
                'welcome welcome institution billing'
                'welcome welcome institution dashboardManager'
                'welcome welcome institution dashboardManager'
                'welcome welcome prospect dashboardManager'`,
        card_list: {
            welcome: CardWelcome,
            prospect: CardProspect,
            institution: CardInstitution,
            dashboardManager: CardDashboardManager,
            billing: CardBilling,
        }
    },
    {
        roles: ['ROLE_INSTITUTION'],
        view: ` 'welcome welcome institution institution'
                'welcome welcome institution institution'
                'welcome welcome institution institution'`,
        card_list: {
            welcome: CardWelcome,
            institution: CardInstitution,
        }
    },
];

