import React from 'react';
import {makeStyles} from "@material-ui/core";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import {useDispatch, useSelector} from "react-redux";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import {theme} from "../../../App";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import Api from "../../../Api";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {AuthenticationLogin} from "../../../Action/AuthenticationAction";
import {LoginPage} from "../../../Action/LoginPageAction";
import SelectComponent from "../../../Component/SelectComponent";
import ReCAPTCHA from "react-google-recaptcha";
import ParticlesComponent from "../../../Component/ParticlesComponent";
import MediaLinkComponent from "../../../Component/MediaLinkComponent";

function Register(props) {

    const classes = useStyles();
    const dispatch = useDispatch();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [show, setShow] = React.useState(true);
    const [isReCAPTCHA, setIsReCAPTCHA] = React.useState(null);

    // FORM
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([
        {value: 9, label: 'Orthophoniste'},
        {value: 13, label: 'Particulier'},
    ]);
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        formationGroup: {
            name: 'formationGroup',
            label: 'Profession',
            textHelper: 'Choisissez votre profession.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required']}
        },
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez votre nom.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez votre prénom.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required']}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez votre email.',
            type: 'text',
            defaultValue: '',
            options: {validation: ['required', 'email']}
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez votre numéro de téléphone.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ips: {
            name: 'ips',
            label: 'ADELI ou RPPS',
            textHelper: 'Saisissez votre identifiant professionnel de santé du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        password: {
            name: 'password',
            label: 'Mot de passe',
            textHelper: 'Votre mot de passe.',
            type: 'text',
            defaultValue: '',
            options: {
                validation: ['required'],
                min: 6,
                max: 32
            }
        },
        conditions: {
            name: 'conditions',
            label: <span style={{fontSize: 12}}>
                En cochant la case ci-contre, je déclare avoir lu et accepté la déclaration de
                <MediaLinkComponent
                    style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                    path={theme.dataProtection}
                    type={1}
                > protection des données </MediaLinkComponent>
                ainsi que les
                <MediaLinkComponent
                    style={{color: theme.colorPrimary, fontSize: 14, lineHeight: '20px', cursor: 'pointer'}}
                    path={theme.termsConditions}
                    type={1}
                > conditions générales </MediaLinkComponent>
                de {theme.name}.
            </span>,
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    // SUBMIT
    const [disabled, setDisabled] = React.useState(false);
    const register = () => {
        if (isReCAPTCHA === null) return;

        if (handler.checkError() < 1) {
            handler.setFormLoading(true);
            setLoading(true);

            let data = handler.getData();

            Api.post({
                route: 'authentication_register_create',
                data: {...data, organism: theme.id}
            }, (response) => {
                if (response.status === 201) {
                    Api.post({
                        route: 'authentication_login',
                        data: {
                            email: data.email,
                            password: data.password,
                            isAdmin: false,
                            organism: theme.id
                        }
                    }, (response) => {
                        if (response.status === 200) {
                            setTimeout(() => {
                                dispatch(AuthenticationLogin(response.data))
                                dispatch(LoginPage('learner'))
                            }, 1200);
                        }
                        else {
                            handler.setFormLoading(false);
                            setLoading(false);
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });

                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                    handler.setFormLoading(false);
                    setLoading(false);
                }
                else {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }
    React.useEffect(() => {
        setDisabled(!form.conditions.value);
    }, [form.conditions.value]);

    // LOGIN
    const login = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push('/authentication/login');
        }, 750)
    }

    // SCREEN
    const [isSmallScreen, setSmallScreen] = React.useState(false);
    const [hasShowImage, setHasShowImage] = React.useState(false);
    React.useEffect(() => {
        if (screenReducer.screen) {
            let isSmallScreenTmp = screenReducer.screen === 'SM' || screenReducer.screen === 'XS';
            if (isSmallScreenTmp !== isSmallScreen) setSmallScreen(isSmallScreenTmp);

            let hasShowImageTmp = screenReducer.screen === 'LG' || screenReducer.screen === 'XL';
            // if (hasShowImageTmp !== hasShowImage) setHasShowImage(hasShowImageTmp);
        }
    }, [screenReducer.screen]);

    return (
        <div className={classes.content}>

            {!isSmallScreen && <ParticlesComponent loading={loading}/>}

            <Fade in={show} {...{timeout: 750}}>
                <ShadowBoxComponent className={`${isSmallScreen ? classes.shadowBoxSmall : classes.shadowBox}`}>

                    <div style={{width: hasShowImage ? 'calc(100% - 388px)' : '100%'}}>
                        <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'lastname'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'firstname'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldComponent name={'email'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'phone'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'ips'} handler={handler}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup}/>
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                <TextFieldComponent name={'password'} handler={handler} password/>
                            </Grid>
                            <Grid item xs={12}>
                                <CheckboxComponent name={'conditions'} handler={handler} direction={'right'}/>
                            </Grid>
                            <Grid item xs={12}>
                                <div style={{margin: 'auto', width: 304, height: 78}}>
                                    <ReCAPTCHA
                                        sitekey="6LcGd24qAAAAAF0JZw5ee6Cnp_Nx0yLG1jgx0aGH"
                                        onChange={(value) => setIsReCAPTCHA(value)}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <ButtonComponent style={{marginTop: 14, background: theme.colorPrimary}} type="submit" label={'S\'inscrire'} className={classes.button} onClick={register} disabled={loading || disabled || isReCAPTCHA === null}/>
                        <div style={{fontSize: 12, marginTop: 14}}>
                            Vous avez dèjà un compte ?
                            <span style={{color: theme.colorPrimary, cursor: 'pointer'}} onClick={login}> Connectez-vous</span>
                        </div>
                    </div>

                    {hasShowImage && <img className={classes.image} src="/asset/images/image_register.png" alt=""/>}
                </ShadowBoxComponent>
            </Fade>
        </div>
    );
}

const useStyles = makeStyles({
    content: {
        position: 'absolute',
        height: '100%',
        width: '100%',
        margin: 0,
        padding: 0,
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        overflow: 'hidden',
        boxSizing: 'border-box'
    },
    shadowBox: {
        padding: 25,
        height: 600,
        margin: 'auto',
        width: 'calc(100vw - 200px)',
        maxWidth: 1000,
        marginTop: 'calc(50vh - 300px)',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    shadowBoxSmall: {
        padding: 25,
        height: '100vh',
        overflow: 'scroll',
        width: '100vw',
        margin: 'auto',
        textAlign: 'center',
        boxSizing: 'border-box',
    },
    image: {
        height: '100%',
        width: 388,
        position: 'absolute',
        right: 0,
        top: 0,
        borderTopRightRadius: 5,
        borderBottomRightRadius: 5
    },
    logo: {
        width: '65%',
        maxWidth: 130,
        margin: 'auto',
        marginBottom: 14,
        marginTop: -5
    }
});

export default Register;
