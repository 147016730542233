import { cloneJson } from "./Tool/CloneCommon";
import moment from "moment";
import axios from 'axios';
import { dispatch } from "./App";
import { AuthenticationLogout } from "./Action/AuthenticationAction";
import { SnackbarOpen } from "./Action/SnackbarAction";
import { routeInstitution } from "./ApiInstitution";

const host = '/api';
const routeApi = {
    default: '/',

    // Stripe
    stripe_public_key_get: '/stripe/public-key',
    stripe_session_status: '/stripe/session/status',
    stripe_promotion_code_verify: '/stripe/promotion-code/verify',

    connected_user_counter_get: '/connected-user-counter/get',
    connected_user_counter_infos: '/connected-user-counter/infos',

    // Authentication
    authentication_login: '/authentication/login',
    authentication_logout: '/authentication/logout',
    authentication_register_create: '/authentication/register/create',
    authentication_password_reset_request: '/authentication/password/reset-request',
    authentication_password_check_validity_code: '/authentication/password/check-validity-code',
    authentication_password_change: '/authentication/password/change',
    authentication_password_first_change: '/authentication/password/first-change',

    // Investor
    investor_dashboard_info: '/investor/dashboard/info',
    investor_dashboard_v2_info: '/investor/dashboard-v2/info',

    // Reporting Marketing
    reporting_marketing_dashboard_info: '/reporting-marketing/dashboard/info',
    reporting_marketing_dashboard_form_origin_list: '/reporting-marketing/dashboard/form-origin/list',
    reporting_marketing_dashboard_formation_group_list: '/reporting-marketing/dashboard/formation-group/list',
    reporting_marketing_dashboard_formation_list: '/reporting-marketing/dashboard/formation/list',
    reporting_marketing_dashboard_campaign_list: '/reporting-marketing/dashboard/campaign/list',
    reporting_marketing_campaign_list: '/reporting-marketing/campaign/list',
    reporting_marketing_campaign_add: '/reporting-marketing/campaign/add',
    reporting_marketing_campaign_type_list: '/reporting-marketing/campaign-type/list',
    reporting_marketing_campaign_type_add: '/reporting-marketing/campaign-type/add',
    reporting_marketing_form_send: '/reporting-marketing/form/send',

    // Reporting pedagogic
    reporting_pedagogic_survey_satisfaction_list: '/reporting-pedagogic/survey-satisfaction/list',
    reporting_pedagogic_epp_survey_list: '/reporting-pedagogic/epp/survey-epp/list',
    reporting_pedagogic_epp_session_formation_list: '/reporting-pedagogic/epp/session-formation/list',
    reporting_pedagogic_epp_session_formation_registration_list: '/reporting-pedagogic/epp/session-formation/{id}/registration/list',
    reporting_pedagogic_epp_session_formation_download_form: '/reporting-pedagogic/epp/session-formation/{id}/download/form',
    reporting_pedagogic_epp_session_formation_download_form_excel: '/reporting-pedagogic/epp/session-formation/{id}/download/form/excel',

    // CRM
    crm_reporting_info: '/crm/reporting/info',

    crm_dashboard_registrations: '/crm/dashboard/registrations',

    crm_prospect_list: '/crm/prospect/list',
    crm_prospect_check: '/crm/prospect/organism/{organism}/check/{emailIPS}',
    crm_prospect_add: '/crm/prospect/add',
    crm_prospect_fusion: '/crm/prospect/{id}/fusion',
    crm_prospect_get: '/crm/prospect/{id}',
    crm_prospect_edit: '/crm/prospect/{id}',
    crm_prospect_delete: '/crm/prospect/{id}',
    crm_prospect_cancel_deletion: '/crm/prospect/{id}/cancel-deletion',
    crm_prospect_import: '/crm/prospect/import',
    crm_prospect_assign: '/crm/prospect/assign',
    crm_prospect_form_list: '/crm/prospect/{id}/form/list',
    crm_prospect_prospect_activity_list: '/crm/prospect/{id}/prospect-activity/list',
    crm_prospect_assign_me: '/crm/prospect/{id}/assign-me',
    crm_prospect_unassign_me: '/crm/prospect/{id}/unassign-me',
    crm_prospect_note_edit: '/crm/prospect/{id}/note',
    crm_prospect_reset_password: '/crm/prospect/{id}/reset-password',
    crm_prospect_prospect_state_edit: '/crm/prospect/{id}/prospect-state',

    crm_prospect_prospect_hotline_list: '/crm/prospect/{prospect}/hotline/list',
    crm_prospect_prospect_hotline_add: '/crm/prospect/{prospect}/hotline/add',

    crm_prospect_prospect_comment_list: '/crm/prospect/{id}/comment/list',
    crm_prospect_prospect_comment_add: '/crm/prospect/{id}/comment/add',
    crm_prospect_commercial_list: '/crm/prospect/{id}/commercial/list',
    crm_prospect_commercial: '/crm/prospect/{id}/commercial',
    crm_prospect_commercial_commission: '/crm/commercial/commission',

    crm_prospect_registration_list: '/crm/prospect/{prospect}/registration/list',
    crm_prospect_registration_add: '/crm/prospect/{prospect}/registration/add',
    crm_prospect_registration_get: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_edit: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_cancel: '/crm/prospect/{prospect}/registration/{registration}/cancel',
    crm_prospect_registration_delete: '/crm/prospect/{prospect}/registration/{registration}',
    crm_prospect_registration_re_enrollment: '/crm/prospect/{prospect}/registration/{registration}/re-enrollment',
    crm_prospect_registration_formation_session_formation_list: '/crm/prospect/{prospect}/registration/formation/{formation}/session-formation/list',
    crm_prospect_registration_formation_list: '/crm/prospect/{prospect}/registration/formation/list',
    crm_prospect_registration_logs_list: '/crm/prospect/{prospect}/registration/{registration}/logs/list',
    crm_prospect_registration_formation_payment_type_list: '/crm/prospect/{prospect}/registration/formation/{formation}/payment-type/list',
    crm_prospect_registration_formation_promotion_code_list: '/crm/prospect/{prospect}/registration/formation/{formation}/promotion-code/list',
    crm_prospect_registration_score_admin_list: '/crm/prospect/{prospect}/registration/{registration}/score-admin/list',

    crm_commercial_list: '/crm/commercial/list',

    crm_commercial_task_list: '/crm/commercial-task/list',
    crm_commercial_task_add: '/crm/commercial-task/add',
    crm_commercial_task_edit: '/crm/commercial-task/{id}',
    crm_commercial_task_delete: '/crm/commercial-task/{id}',

    crm_hotline_list: '/crm/hotline/list',

    crm_relaunch_registration_list: '/crm/relaunch/registration/list',
    crm_relaunch_registration_phone: '/crm/relaunch/registration/{id}/phone',
    crm_relaunch_registration_mail: '/crm/relaunch/registration/{id}/mail',
    crm_relaunch_registration_marketing: '/crm/relaunch/registration/{id}/marketing',
    
    crm_personal_relaunch_registration_list: '/crm/personal/relaunch/registration/list',
    crm_personal_relaunch_registration_mail: '/crm/personal/relaunch/registration/{registration}/mail',

    crm_commercial_management_registration_list: '/crm/commercial-management/registration/list',
    crm_commercial_management_registration_assign: '/crm/commercial-management/registration/{registration}/assign',
    crm_commercial_management_registration_commission: '/crm/commercial-management/registration/{registration}/commission',
    crm_commercial_management_registration_get_percent: '/crm/commercial-management/{commercial}/registration/{registration}/get-percent',

    crm_commercial_users_management_list: '/crm/commercial-users-management/list',
    crm_commercial_users_management_get: '/crm/commercial-users-management/{id}',
    crm_commercial_users_management_commission_percent_add: '/crm/commercial-users-management/{id}/commission-percent/add',
    crm_commercial_users_management_commission_percent_edit: '/crm/commercial-users-management/{id}/commission-percent/{percent}/edit',
    crm_commercial_users_management_commission_percent_delete: '/crm/commercial-users-management/{id}/commission-percent/{percent}/delete',
    crm_commercial_users_management_export_commission: '/crm/commercial-users-management/{id}/export-commission',


    crm_dashboard_manager_registrations: '/crm/dashboard-manager/registrations',

    // RH
    human_resources_organism_director_add: "/human-resources-organism/add",
    human_resources_organism_director_edit: "/human-resources-organism/{organism}",
    human_resources_organism_director_get: "/human-resources-organism/{organism}",

    human_resources_relationship_list: "/human-resources/organism/{organism}/pole/{pole}/list",
    human_resources_relationship_get: "/human-resources/organism/{organism}/pole/{pole}/{id}",
    human_resources_relationship_add: "/human-resources/organism/{organism}/pole/{pole}/add",
    human_resources_relationship_delete: "/human-resources/organism/{organism}/pole/{pole}/{id}",

    human_resources_organism_chart: "/human-resources-chart/organism/{organism}/chart",

    // Content Manager
    content_manager_formation_list: '/content-manager/formation/list',
    content_manager_formation_list_download: '/content-manager/formation/list/download',
    content_manager_formation_download: '/content-manager/formation/download/{id}',
    content_manager_formation_add: '/content-manager/formation/add',
    content_manager_formation_get: '/content-manager/formation/{id}',
    content_manager_formation_edit: '/content-manager/formation/{id}',
    content_manager_formation_delete: '/content-manager/formation/{id}',
    content_manager_formation_andpc_list: '/content-manager/formation/{id}/andpc/list',
    content_manager_formation_andpc: '/content-manager/formation/{id}/andpc',
    content_manager_formation_fifpl_list: '/content-manager/formation/{id}/fifpl/list',
    content_manager_formation_fifpl: '/content-manager/formation/{id}/fifpl',
    content_manager_formation_cpf_list: '/content-manager/formation/{id}/cpf/list',
    content_manager_formation_cpf: '/content-manager/formation/{id}/cpf',
    content_manager_formation_personal_list: '/content-manager/formation/{id}/personal/list',
    content_manager_formation_personal: '/content-manager/formation/{id}/personal',
    content_manager_formation_banner: '/content-manager/formation/{id}/banner',

    content_manager_chapter_list: '/content-manager/chapter/list',
    content_manager_chapter_add: '/content-manager/chapter/add',
    content_manager_chapter_get: '/content-manager/chapter/{id}',
    content_manager_chapter_edit: '/content-manager/chapter/{id}',
    content_manager_chapter_delete: '/content-manager/chapter/{id}',

    content_manager_formation_formation_course_list: '/content-manager/formation/{formation}/chapter-course/list',
    content_manager_formation_formation_course_add: '/content-manager/formation/{formation}/chapter-course/add',
    content_manager_formation_formation_course_delete: '/content-manager/formation/{formation}/chapter-course/{id}',

    content_manager_chapter_chapter_course_list: '/content-manager/chapter/{chapter}/chapter-course/list',
    content_manager_chapter_chapter_course_add: '/content-manager/chapter/{chapter}/chapter-course/add',
    content_manager_chapter_chapter_course_get: '/content-manager/chapter/{chapter}/chapter-course/{id}',
    content_manager_chapter_chapter_course_edit: '/content-manager/chapter/{chapter}/chapter-course/{id}',
    content_manager_chapter_chapter_course_delete: '/content-manager/chapter/{chapter}/chapter-course/{id}',

    content_manager_formation_formation_chapter_list: '/content-manager/formation/{formation}/formation-chapter/list',
    content_manager_formation_formation_chapter_add: '/content-manager/formation/{formation}/formation-chapter/add',
    content_manager_formation_formation_chapter_order: '/content-manager/formation/{formation}/formation-chapter/order',
    content_manager_formation_formation_chapter_get: '/content-manager/formation/{formation}/formation-chapter/{id}',
    content_manager_formation_formation_chapter_edit: '/content-manager/formation/{formation}/formation-chapter/{id}',
    content_manager_formation_formation_chapter_delete: '/content-manager/formation/{formation}/formation-chapter/{id}',

    content_manager_formation_formation_chapter_timer: '/content-manager/formation/{formation}/formation-chapter/timer',

    content_manager_formation_formation_chapter_course_list: '/content-manager/formation/{formation}/chapter/{chapter}/chapter-course/list',
    content_manager_formation_formation_chapter_course_action: '/content-manager/formation/{formation}/chapter/{chapter}/chapter-course/action',

    content_manager_formation_formation_question_list: '/content-manager/formation/{formation}/formation-question/list',
    content_manager_formation_formation_question_add: '/content-manager/formation/{formation}/formation-question/add',
    content_manager_formation_formation_question_action: '/content-manager/formation/{formation}/formation-question/action',
    content_manager_formation_formation_question_cancel: '/content-manager/formation/{formation}/formation-question/cancel',

    content_manager_formation_formation_question_answer_list: '/content-manager/formation/{formation}/formation-question/{question}/question-answer/list',
    content_manager_formation_formation_question_answer_action: '/content-manager/formation/{formation}/formation-question/{question}/question-answer/action',
    content_manager_formation_formation_question_comment_list: '/content-manager/formation/{formation}/formation-question/{question}/question-comment/list',
    content_manager_formation_formation_question_comment_action: '/content-manager/formation/{formation}/formation-question/{question}/question-comment/action',

    content_manager_formation_formation_question_epp_list: '/content-manager/formation/{formation}/formation-question-epp/list',
    content_manager_formation_formation_question_epp_add: '/content-manager/formation/{formation}/formation-question-epp/add',
    content_manager_formation_formation_question_epp_action: '/content-manager/formation/{formation}/formation-question-epp/action',

    content_manager_question_question_comment_list: '/content-manager/question/{question}/question-comment/list',
    content_manager_question_question_comment_add: '/content-manager/question/{question}/question-comment/add',
    content_manager_question_question_comment_get: '/content-manager/question/{question}/question-comment/{id}',
    content_manager_question_question_comment_edit: '/content-manager/question/{question}/question-comment/{id}',
    content_manager_question_question_comment_delete: '/content-manager/question/{question}/question-comment/{id}',

    content_manager_formation_formation_resource_list: '/content-manager/formation/{formation}/formation-resource/list',
    content_manager_formation_formation_resource_add: '/content-manager/formation/{formation}/formation-resource/add',
    content_manager_formation_formation_resource_order: '/content-manager/formation/{formation}/formation-resource/order',
    content_manager_formation_formation_resource_get: '/content-manager/formation/{formation}/formation-resource/{id}',
    content_manager_formation_formation_resource_edit: '/content-manager/formation/{formation}/formation-resource/{id}',
    content_manager_formation_formation_resource_delete: '/content-manager/formation/{formation}/formation-resource/{id}',

    content_manager_formation_formation_session_list: '/content-manager/formation/{formation}/formation-session/list',
    content_manager_formation_formation_session_add: '/content-manager/formation/{formation}/formation-session/add',
    content_manager_formation_formation_session_delete: '/content-manager/formation/{formation}/formation-session/{id}',
    content_manager_formation_formation_session_select_sessions: '/content-manager/formation/{formation}/formation-session/select-sessions',

    content_manager_formation_virtual_classroom_list: '/content-manager/formation/{formation}/virtual-classroom/list',

    content_manager_formation_unit_list: '/content-manager/formation/{formation}/unit/list',
    content_manager_formation_unit_get_formations_chapters: '/content-manager/formation/{formation}/unit/get-formations-chapters',
    content_manager_formation_unit_add: '/content-manager/formation/{formation}/unit/add',
    content_manager_formation_unit_edit: '/content-manager/formation/{formation}/unit/{unit}',
    content_manager_formation_unit_delete: '/content-manager/formation/{formation}/unit/{unit}',

    content_manager_chapter_formation_chapter_list: '/content-manager/chapter/{chapter}/formation-chapter/list',
    content_manager_course_chapter_course_list: '/content-manager/course/{course}/chapter-course/list',

    content_manager_virtual_classroom_list: '/content-manager/virtual-classroom/list',
    content_manager_virtual_classroom_add: '/content-manager/virtual-classroom/add',
    content_manager_virtual_classroom_get: '/content-manager/virtual-classroom/{id}',
    content_manager_virtual_classroom_active: '/content-manager/virtual-classroom/active/{id}',
    content_manager_virtual_classroom_edit: '/content-manager/virtual-classroom/{id}',
    content_manager_virtual_classroom_delete: '/content-manager/virtual-classroom/{id}',

    content_manager_virtual_classroom_period_list: '/content-manager/virtual-classroom/period/list',
    content_manager_virtual_classroom_period_add: '/content-manager/virtual-classroom/period/add',
    content_manager_virtual_classroom_period_edit: '/content-manager/virtual-classroom/period/{id}',
    content_manager_virtual_classroom_period_delete: '/content-manager/virtual-classroom/period/{id}',
    content_manager_virtual_classroom_formation_list: '/content-manager/virtual-classroom/formation/list',
    content_manager_virtual_classroom_sessions_formation_list: '/content-manager/virtual-classroom/sessions-formation/list',
    content_manager_virtual_classroom_delete_session_formation: '/content-manager/virtual-classroom/{virtualClassroom}/delete-session-formation',
    content_manager_virtual_classroom_all_sessions_formation: '/content-manager/virtual-classroom/{virtualclassroom}/all-sessions-formation',
    content_manager_virtual_classroom_user_list: '/content-manager/virtual-classroom/{virtualclassroom}/user-virtual-classroom/list',

    content_manager_course_list: '/content-manager/course/list',
    content_manager_course_add: '/content-manager/course/add',
    content_manager_course_get: '/content-manager/course/{id}',
    content_manager_course_edit: '/content-manager/course/{id}',
    content_manager_course_delete: '/content-manager/course/{id}',

    content_manager_resource_list: '/content-manager/resource/list',
    content_manager_resource_add: '/content-manager/resource/add',
    content_manager_resource_get: '/content-manager/resource/{id}',
    content_manager_resource_edit: '/content-manager/resource/{id}',
    content_manager_resource_delete: '/content-manager/resource/{id}',
    content_manager_resource_formation_resource_list: '/content-manager/resource/{id}/formation-resource/list',

    content_manager_resource_type_list: '/content-manager/resource/type/list',
    content_manager_resource_type_add: '/content-manager/resource/type/add',
    content_manager_resource_type_delete: '/content-manager/resource/type/{id}',
    content_manager_resource_type_edit: '/content-manager/resource/type/{id}',

    content_manager_resource_group_list: '/content-manager/resource/group/list',
    content_manager_resource_group_add: '/content-manager/resource/group/add',
    content_manager_resource_group_delete: '/content-manager/resource/group/{id}',
    content_manager_resource_group_edit: '/content-manager/resource/group/{id}',

    //Marketing Manager
    marketing_manager_featured_resource_info: '/marketing-manager/featured-resource/info',
    marketing_manager_featured_resource_resource_get: '/marketing-manager/featured-resource/resource/{id}',
    marketing_manager_featured_resource_edit: '/marketing-manager/featured-resource/edit',

    marketing_manager_thematic_list: '/marketing-manager/thematic/list',
    marketing_manager_thematic_add: '/marketing-manager/thematic/add',
    marketing_manager_thematic_get: '/marketing-manager/thematic/{id}',
    marketing_manager_thematic_edit: '/marketing-manager/thematic/{id}',
    marketing_manager_thematic_delete: '/marketing-manager/thematic/{id}',

    marketing_manager_stripe_promotion_code_list: '/marketing-manager/stripe-promotion-code/list',
    marketing_manager_stripe_promotion_code_add: '/marketing-manager/stripe-promotion-code/add',
    marketing_manager_stripe_promotion_code_edit: '/marketing-manager/stripe-promotion-code/{id}',
    marketing_manager_stripe_promotion_code_delete: '/marketing-manager/stripe-promotion-code/{id}',

    marketing_manager_thematic_thematic_formation_list: '/marketing-manager/thematic/{id}/thematic-formation/list',
    marketing_manager_thematic_thematic_formation_add: '/marketing-manager/thematic/{id}/thematic-formation/add',
    marketing_manager_thematic_thematic_formation_delete: '/marketing-manager/thematic/{id}/thematic-formation/{formation}',

    marketing_manager_thematic_thematic_formation_group_list: '/marketing-manager/thematic/{id}/thematic-formation-group/list',
    marketing_manager_thematic_thematic_formation_group_add: '/marketing-manager/thematic/{id}/thematic-formation-group/add',
    marketing_manager_thematic_thematic_formation_group_delete: '/marketing-manager/thematic/{id}/thematic-formation-group/{formationGroup}',

    marketing_manager_thematic_thematic_library_resource_list: '/marketing-manager/thematic/{id}/thematic-library-resource/list',
    marketing_manager_thematic_thematic_library_resource_add: '/marketing-manager/thematic/{id}/thematic-library-resource/add',
    marketing_manager_thematic_thematic_library_resource_delete: '/marketing-manager/thematic/{id}/thematic-library-resource/{libraryResource}',

    marketing_manager_library_resource_type_list: '/marketing-manager/library-resource-type/list',
    marketing_manager_library_resource_type_get: '/marketing-manager/library-resource-type/{id}',
    marketing_manager_library_resource_type_add: '/marketing-manager/library-resource-type/add',
    marketing_manager_library_resource_type_edit: '/marketing-manager/library-resource-type/{id}',
    marketing_manager_library_resource_type_delete: '/marketing-manager/library-resource-type/{id}',

    marketing_manager_library_resource_list: '/marketing-manager/library-resource/list',
    marketing_manager_library_resource_get: '/marketing-manager/library-resource/{id}',
    marketing_manager_library_resource_add: '/marketing-manager/library-resource/add',
    marketing_manager_library_resource_edit: '/marketing-manager/library-resource/{id}',
    marketing_manager_library_resource_delete: '/marketing-manager/library-resource/{id}',

    marketing_manager_library_resource_command_execute_livestorm: '/marketing-manager/library-resource/command/execute-livestorm',

    marketing_manager_catalog_list: '/marketing-manager/catalog/list',
    marketing_manager_catalog_add: '/marketing-manager/catalog/add',
    marketing_manager_hubspot_queue_list: '/marketing-manager/hubspot-queue/list',

    // Financial Management
    financial_management_billing_v2_session_formation_list: '/financial-management/billing-v2/session-formation/list',
    financial_management_billing_v2_session_formation_get: '/financial-management/billing-v2/session-formation/{id}',
    financial_management_billing_v2_session_formation_edit: '/financial-management/billing-v2/session-formation/{id}',
    financial_management_billing_v2_session_formation_billable: '/financial-management/billing-v2/session-formation/{id}/billable',
    financial_management_billing_v2_session_formation_send_participation_certificate: '/financial-management/billing-v2/session-formation/{id}/send/participation-certificate',
    financial_management_billing_v2_session_formation_download_participation_certificate: '/financial-management/billing-v2/session-formation/{id}/download/participation-certificate',
    financial_management_billing_v2_session_formation_download_kit_participation: '/financial-management/billing-v2/session-formation/{id}/download/kit-participation',
    financial_management_billing_v2_session_formation_download_follow_up_certificate: '/financial-management/billing-v2/session-formation/{id}/download/follow-up-certificate',
    financial_management_billing_v2_session_formation_invoice: '/financial-management/billing-v2/session-formation/{id}/invoice',
    financial_management_billing_v2_invoice_download: '/financial-management/billing-v2/invoice/{id}/download',
    financial_management_billing_v2_credit_download: '/financial-management/billing-v2/credit/{id}/download',
    financial_management_billing_v2_invoice_credit: '/financial-management/billing-v2/invoice/{id}/credit',
    financial_management_billing_v2_registration_edit: '/financial-management/billing-v2/registration/{registration}',

    // Supervision
    financial_management_supervision_registration_time_balancing: '/financial-management/supervision/registration/{id}/time-balancing',
    financial_management_supervision_registration_completion_balancing: '/financial-management/supervision/registration/{id}/completion-balancing',
    financial_management_supervision_registration_time_course_clear: '/financial-management/supervision/registration/{id}/time-course-clear',

    financial_management_billing_session_formation_list: '/financial-management/billing/session-formation/list',
    financial_management_billing_session_formation_get: '/financial-management/billing/session-formation/{id}',
    financial_management_billing_session_formation_edit: '/financial-management/billing/session-formation/{id}',
    financial_management_billing_session_formation_billable: '/financial-management/billing/session-formation/{id}/billable',
    financial_management_billing_session_formation_invoice: '/financial-management/billing/session-formation/{id}/invoice',
    financial_management_billing_session_formation_invoice_v2: '/financial-management/billing/session-formation/{id}/invoice-v2',
    financial_management_billing_session_formation_download_participation_certificate: '/financial-management/billing/session-formation/{id}/download/participation-certificate',
    financial_management_billing_session_formation_send_participation_certificate: '/financial-management/billing/session-formation/{id}/send/participation-certificate',
    financial_management_billing_session_formation_download_tracking_connected_certificate: '/financial-management/billing/session-formation/{id}/download/tracking-connected-certificate',
    financial_management_billing_session_formation_download_follow_up_certificate: '/financial-management/billing/session-formation/{id}/download/follow-up-certificate',
    financial_management_billing_session_formation_download_kit_participation: '/financial-management/billing/session-formation/{id}/download/kit-participation',
    financial_management_billing_invoice_download: '/financial-management/billing/invoice/{id}/download',
    financial_management_billing_invoice_credit: '/financial-management/billing/invoice/{id}/credit',
    financial_management_billing_invoice_credit_v2: '/financial-management/billing/invoice/{id}/credit-v2',
    financial_management_billing_credit_download: '/financial-management/billing/credit/{id}/download',
    financial_management_billing_registration_time_balancing: '/financial-management/billing/registration/{id}/time-balancing',
    financial_management_billing_session_formation_download_follow_up_certificate_with_cv_half_day: '/financial-management/billing/session-formation/{id}/download/follow-up-certificate/with-cv-half-day',
    financial_management_billing_session_formation_download_follow_up_certificate_with_cv_half_day_v2: '/financial-management/billing/session-formation/{id}/download/follow-up-certificate/with-cv-half-day-v2',
    financial_management_billing_session_formation_download_follow_up_certificate_with_cv_half_day_v3: '/financial-management/billing/session-formation/{id}/download/follow-up-certificate/with-cv-half-day-v3',
    financial_management_billing_session_formation_time_balancing: '/financial-management/billing/session-formation/{id}/time-balancing',
    financial_management_billing_registration_time_courses_clear: '/financial-management/billing/registration/{id}/time-courses-clear',

    financial_management_billing_session_formation_get_time: '/financial-management/billing/session-formation/{id}/get-time',

    financial_management_billing_fifpl_session_formation_billable: '/financial-management/billing-fifpl/session-formation/{id}/billable',
    financial_management_billing_fifpl_session_formation_list: '/financial-management/billing-fifpl/session-formation/list',
    financial_management_billing_fifpl_session_formation_get: '/financial-management/billing-fifpl/session-formation/{id}',
    financial_management_billing_fifpl_registration_time_balancing: '/financial-management/billing-fifpl/registration/{id}/time-balancing',
    financial_management_billing_fifpl_cpf_session_formation_list: '/financial-management/billing-fifpl-cpf/session-formation/list',
    financial_management_billing_fifpl_session_formation_download_follow_up_certificate: '/financial-management/billing-fifpl/session-formation/{id}/download/follow-up-certificate',
    financial_management_billing_fifpl_session_formation_invoice: '/financial-management/billing-fifpl/session-formation/{id}/invoice',
    financial_management_billing_fifpl_session_formation_download_participation_certificate: '/financial-management/billing-fifpl/session-formation/{id}/download/participation-certificate',
    financial_management_billing_fifpl_invoice_download: '/financial-management/billing-fifpl/invoice/{id}/download',
    financial_management_billing_fifpl_invoice_credit: '/financial-management/billing-fifpl/invoice/{id}/credit',
    financial_management_billing_fifpl_credit_download: '/financial-management/billing-fifpl/credit/{id}/download',

    financial_management_billing_cpf_session_formation_list: '/financial-management/billing-cpf/session-formation/list',
    financial_management_billing_cpf_session_formation_get: '/financial-management/billing-cpf/session-formation/{id}',
    financial_management_billing_cpf_registration_session_cpf: '/financial-management/billing-cpf/registration/{id}/session-cpf',
    financial_management_billing_cpf_registration_time_balancing: '/financial-management/billing-cpf/registration/{id}/time-balancing',
    financial_management_billing_cpf_cpf_session_formation_list: '/financial-management/billing-cpf-cpf/session-formation/list',
    financial_management_billing_cpf_registration_download_follow_up_certificate: '/financial-management/billing-cpf/registration/{id}/download/follow-up-certificate',

    financial_management_payment_follow_invoice_list: '/financial-management/payment-follow/invoice/list',
    financial_management_payment_follow_invoice_acquitted: '/financial-management/payment-follow/invoice/{id}/acquitted',
    financial_management_relaunch_registration_list: '/financial-management/relaunch/registration/list',
    financial_management_relaunch_registration_phone: '/financial-management/relaunch/registration/{id}/phone',
    financial_management_relaunch_registration_mail: '/financial-management/relaunch/registration/{id}/mail',
    financial_management_statistics_export: '/financial-management/statistics/export',
    financial_management_relaunch_registration_cancel: '/financial-management/relaunch/registration/{registration}/cancel',
    financial_management_relaunch_registration_marketing: '/financial-management/relaunch/registration/{id}/marketing',

    financial_management_andpc_email_tracking_list: '/financial-management/andpc-email-tracking/list',
    financial_management_andpc_email_tracking_prospect_check: '/financial-management/andpc-email-tracking/{id}/prospect-check',
    financial_management_andpc_email_tracking_prospect_add: '/financial-management/andpc-email-tracking/{id}/prospect/add',
    financial_management_andpc_email_tracking_prospect_register: '/financial-management/andpc-email-tracking/{id}/prospect/{prospect}/register',
    financial_management_andpc_email_tracking_registration_validate_dpc: '/financial-management/andpc-email-tracking/{id}/registration/{registration}/validate-dpc',
    financial_management_andpc_email_tracking_request_reject: '/financial-management/andpc-email-tracking/{id}/request-reject',
    financial_management_andpc_email_tracking_state_duplicate: '/financial-management/andpc-email-tracking/{id}/state-duplicate',

    financial_management_billing_personal_registration_list: '/financial-management/billing-personal/registration/list',
    financial_management_billing_personal_prospect_registration_invoice_download: '/financial-management/billing-personal/prospect/{prospect}/registration/{registration}/invoice-download',

    // Administrator
    administrator_user_list: '/administrator/user/list',
    administrator_user_add: '/administrator/user/add',
    administrator_user_get: '/administrator/user/{id}',
    administrator_user_edit: '/administrator/user/{id}',
    administrator_user_delete: '/administrator/user/{id}',

    administrator_organism_list: '/administrator/organism/list',
    administrator_organism_add: '/administrator/organism/add',
    administrator_organism_get: '/administrator/organism/{id}',
    administrator_organism_edit: '/administrator/organism/{id}',
    administrator_organism_delete: '/administrator/organism/{id}',

    administrator_role_list: '/administrator/role/list',
    administrator_role_add: '/administrator/role/add',
    administrator_role_get: '/administrator/role/{id}',
    administrator_role_edit: '/administrator/role/{id}',
    administrator_role_delete: '/administrator/role/{id}',
    administrator_role_users: '/administrator/role/users',
    administrator_role_users_add: '/administrator/role/users-add',
    administrator_role_user_delete: '/administrator/role/{role}/user/{user}/delete',

    administrator_pole_list: '/administrator/pole/list',
    administrator_pole_add: '/administrator/pole/add',
    administrator_pole_get: '/administrator/pole/{id}',
    administrator_pole_edit: '/administrator/pole/{id}',
    administrator_pole_delete: '/administrator/pole/{id}',
    administrator_pole_users_list: '/administrator/pole/users-list',
    administrator_pole_users: '/administrator/pole/users',
    administrator_pole_users_edit: '/administrator/pole/users-edit',

    administrator_user_hotline_type_list: '/administrator/user-hotline-type/list',
    administrator_user_hotline_type_add: '/administrator/user-hotline-type/add',
    administrator_user_hotline_type_edit: '/administrator/user-hotline-type/{id}',
    administrator_user_hotline_type_delete: '/administrator/user-hotline-type/{id}',

    administrator_prospect_hotline_type_list: '/administrator/prospect-hotline-type/list',
    administrator_prospect_hotline_type_add: '/administrator/prospect-hotline-type/add',
    administrator_prospect_hotline_type_edit: '/administrator/prospect-hotline-type/{id}',
    administrator_prospect_hotline_type_delete: '/administrator/prospect-hotline-type/{id}',

    administrator_session_list: '/administrator/session/list',
    administrator_session_add: '/administrator/session/add',
    administrator_session_get: '/administrator/session/{id}',
    administrator_session_edit: '/administrator/session/{id}',
    administrator_session_delete: '/administrator/session/{id}',
    administrator_session_formation_list: '/administrator/session/formation/list',
    administrator_session_session_formation_list: '/administrator/session/{id}/session-formation/list',
    administrator_session_formations_relation: '/administrator/session/{id}/formations/relation',

    administrator_formation_group_property_list: '/administrator/formation-group-property/list',
    administrator_formation_group_property_add: '/administrator/formation-group-property/add',
    administrator_formation_group_property_get: '/administrator/formation-group-property/{id}',
    administrator_formation_group_property_edit: '/administrator/formation-group-property/{id}',
    administrator_formation_group_property_delete: '/administrator/formation-group-property/{id}',

    administrator_platform_log_list: '/administrator/platform-log/list',
    
    administrator_token_api_list: '/administrator/token-api/list',
    administrator_token_api_add: '/administrator/token-api/add',
    administrator_token_api_edit: '/administrator/token-api/{id}',
    administrator_token_api_delete: '/administrator/token-api/{id}',
    
    administrator_finance_type_list: '/administrator/finance-type/list',
    administrator_finance_type_add: '/administrator/finance-type/add',
    administrator_finance_type_edit: '/administrator/finance-type/{id}',
    administrator_finance_type_delete: '/administrator/finance-type/{id}',
    administrator_finance_type_get: '/administrator/finance-type/{id}',
    administrator_finance_type_get_formations: '/administrator/finance-type/{id}/formations',

    // community
    community_prospect_list: '/community/prospect/list',
    community_prospect_banned: '/community/prospect/banned/{id}',

    community_group_list: '/community/group/{type}/list',
    community_group_add: '/community/group/{type}/add',
    community_group_get: '/community/group/{type}/{id}',
    community_group_edit: '/community/group/{type}/{id}',
    community_group_delete: '/community/group/{type}/{id}',

    community_group_prospect_list: '/community/group/{group}/prospect/list',
    community_group_prospect_add: '/community/group/{group}/prospect/add',
    community_group_prospect_get: '/community/group/{group}/prospect/{id}',
    community_group_prospect_edit: '/community/group/{group}/prospect/{id}',
    community_group_prospect_banned: '/community/group/{group}/prospect/banned/{id}',
    community_group_prospect_delete: '/community/group/{group}/prospect/{id}',

    community_group_post_list: '/community/group/{group}/post/list',
    community_group_post_add: '/community/group/{group}/post/add',
    community_group_post_get: '/community/group/{group}/post/{id}',
    community_group_post_edit: '/community/group/{group}/post/{id}',
    community_group_post_delete: '/community/group/{group}/post/{id}',
    community_group_post_comment_list: '/community/group/{group}/post/{post}/comment/list',
    community_group_post_comment_add: '/community/group/{group}/post/{post}/comment/add',
    community_group_post_comment_get: '/community/group/{group}/post/{post}/comment/{id}',
    community_group_post_comment_edit: '/community/group/{group}/post/{post}/comment/{id}',
    community_group_post_comment_delete: '/community/group/{group}/post/{post}/comment/{id}',
    community_group_post_comment_all_delete: '/community/group/{group}/post/{post}/comment/all/{id}',

    // Webcoach
    webcoach_manager_info: '/webcoach/manager/info',
    
    webcoach_relaunch_list: '/webcoach/relaunch/list',
    webcoach_relaunch_add: '/webcoach/relaunch/add',
    webcoach_relaunch_edit: '/webcoach/relaunch/{id}',
    webcoach_relaunch_get: '/webcoach/relaunch/{id}',
    webcoach_relaunch_delete: '/webcoach/relaunch/{id}',
    webcoach_relaunch_valid: '/webcoach/relaunch/valid/{id}',
    webcoach_relaunch_ignore: '/webcoach/relaunch/ignore/{id}',
    webcoach_relaunch_prospects: '/webcoach/relaunch/prospects',
    webcoach_relaunch_registrations: '/webcoach/relaunch/prospect/{prospect}/registrations',

    webcoach_offuser_list: '/webcoach/offuser/list',
    webcoach_offuser_add: '/webcoach/offuser/add',
    webcoach_offuser_edit: '/webcoach/offuser/{id}',
    webcoach_offuser_get: '/webcoach/offuser/user/{user}',
    webcoach_offuser_delete: '/webcoach/offuser/{id}',

    // Support
    support_hotline_dashboard_info_hotline: '/support/hotline-dashboard/info-hotline',
    support_hotline_dashboard_info_hotline_type: '/support/hotline-dashboard/info-hotline-type',
    support_hotline_dashboard_no_treated: '/support/hotline-dashboard/no-treated',
    support_prospect_hotline_list: '/support/prospect-hotline/list',
    support_prospect_hotline_edit: '/support/prospect-hotline/{id}',
    support_user_hotline_list: '/support/user-hotline/list',
    support_user_hotline_add: '/support/user-hotline/add',
    support_user_hotline_edit: '/support/user-hotline/{id}',

    // IT infrastructure
    it_infrastructure_equipment_list: '/it-infrastructure/equipment/list',
    it_infrastructure_equipment_get: '/it-infrastructure/equipment/{id}',
    it_infrastructure_equipment_add: '/it-infrastructure/equipment/add',
    it_infrastructure_equipment_edit: '/it-infrastructure/equipment/{id}',
    it_infrastructure_equipment_delete: '/it-infrastructure/equipment/{id}',
    it_infrastructure_equipment_user_action: '/it-infrastructure/equipment/{id}/user-action',
    it_infrastructure_equipment_type_action: '/it-infrastructure/equipment/{id}/type-action',
    it_infrastructure_equipment_restore: '/it-infrastructure/equipment/{id}/restore',

    it_infrastructure_equipment_history_list: '/it-infrastructure/equipment/{equipment}/history/list',
    it_infrastructure_equipment_history_add: '/it-infrastructure/equipment/{equipment}/history/add',
    it_infrastructure_equipment_history_edit: '/it-infrastructure/equipment/{equipment}/history/{id}',
    it_infrastructure_equipment_history_delete: '/it-infrastructure/equipment/{equipment}/history/{id}',

    it_infrastructure_equipment_type_list: '/it-infrastructure/equipment-type/list',
    it_infrastructure_equipment_type_add: '/it-infrastructure/equipment-type/add',
    it_infrastructure_equipment_type_edit: '/it-infrastructure/equipment-type/{id}',
    it_infrastructure_equipment_type_delete: '/it-infrastructure/equipment-type/{id}',

    it_infrastructure_equipment_brand_list: '/it-infrastructure/equipment-brand/list',
    it_infrastructure_equipment_brand_add: '/it-infrastructure/equipment-brand/add',
    it_infrastructure_equipment_brand_edit: '/it-infrastructure/equipment-brand/{id}',
    it_infrastructure_equipment_brand_delete: '/it-infrastructure/equipment-brand/{id}',

    it_infrastructure_equipment_model_list: '/it-infrastructure/equipment-model/list',
    it_infrastructure_equipment_model_get: '/it-infrastructure/equipment-model/{id}',
    it_infrastructure_equipment_model_add: '/it-infrastructure/equipment-model/add',
    it_infrastructure_equipment_model_edit: '/it-infrastructure/equipment-model/{id}',
    it_infrastructure_equipment_model_delete: '/it-infrastructure/equipment-model/{id}',

    // Former classroom
    former_virtual_classroom: '/former/virtual-classroom/{id}',
    former_virtual_classroom_list: '/former/virtual-classroom/list',
    former_virtual_classroom_learner_present_edit: '/former/virtual-classroom/{id}/present/{registration}',
    former_virtual_classroom_learner_present_list: '/former/virtual-classroom/{id}/learners',

    // Institution Health
    ...routeInstitution,

    // Select
    select_roles: '/select/roles',
    select_users: '/select/users',
    select_webcoachs: '/select/webcoachs',
    select_webcoach_task_types: '/select/webcoach-task-types',
    select_webcoach_task_resolution_texts: '/select/webcoach-task-resolution-texts',
    select_organisms: '/select/organisms',
    select_formers: '/select/formers',
    select_formations: '/select/formations',
    select_poles: '/select/poles',
    select_internal_poles: '/select/internal-poles',
    select_campaign_types: '/select/campaign-types',
    select_equipment_types: '/select/equipment-types',
    select_equipment_brands: '/select/equipment-brands',
    select_equipment_models: '/select/equipment-models',
    select_equipment_states: '/select/equipment-states',
    select_equipment_actions: '/select/equipment-actions',
    select_registration_types: '/select/registration-types',
    select_prospect_states: '/select/prospect-states',
    select_commercial_task_types: '/select/commercial-task-types',
    select_formation_groups: '/select/formation-groups',
    select_prospect_hotline_types: '/select/prospect-hotline-types',
    select_user_hotline_types: '/select/user-hotline-types',
    select_user_hotline: '/select/user-hotline',
    select_course_type: '/select/course-types',
    select_resource_type: '/select/resource-types',
    select_courses: '/select/courses',
    select_chapters: '/select/chapters',
    select_resources: '/select/resources',
    select_resource_groups: '/select/resource-groups',
    select_sessions_formations_state: '/select/sessions-formations-state',
    select_sessions_formations: '/select/sessions-formations',
    select_finance_types: '/select/finance-types',
    select_registration_origins: '/select/registration-origins',
    select_prospect_origins: '/select/prospect-origins',
    select_library_resource: '/select/library-resource',
    select_library_resource_types: '/select/library-resource-types',
    select_virtual_classroom_period: '/select/virtual-classroom-period',
    select_outlook_mail_andpc_states: '/select/outlook-mail-andpc-states',
    select_personal_payment_types: '/select/personal-payment-types',
    select_commercials: '/select/commercials',
    institution_groups: '/select/institution-groups',
    institution_states: '/select/institution-states',
    institution_types: '/select/institution-types',
    select_formation_program_types: '/select/formation-program-types',

    // Leaner
    learner_timer_tic: '/learner/timer/tic',
    learner_timer_tic_epp2: '/learner/timer/tic-epp2',
    learner_my_info_get: '/learner/my-info/get',
    learner_my_info_edit: '/learner/my-info/edit',
    learner_home_time_processing: '/learner/home/time-processing',
    learner_home_library_resource_types: '/learner/home/library-resource-types',

    learner_library_resource_list: '/learner/library-resource/list',
    learner_resource_type_resources_list: '/learner/resource-type/{id}/resources/list',
    learner_resource_get: '/learner/resource-type/resource/{resource}',
    learner_resource_type_rating: '/learner/resource-type/resource/{resource}/rating',
    learner_resource_type_list: '/learner/resource-type/list',

    learner_resource_livestorm_register: '/learner/resource-type/resource/{resource}/livestorm/{sessionlivestorm}/register',
    learner_resource_livestorm_login: '/learner/resource-type/resource/{resource}/livestorm/{id}/login',

    learner_home_info: '/learner/home/info',
    learner_document_info: '/learner/document/info',
    learner_invoice_info: '/learner/invoice/info',
    learner_invoice_registration_invoice_download: '/learner/invoice/registration/{id}/invoice/download',
    learner_document_diplome: '/learner/document/download/diplome',
    learner_download_participation_certificate: '/learner/document/download/participation-certificate',
    learner_catalog_list: '/learner/catalog/list',
    learner_catalog_formation_get: '/learner/catalog/formation/{id}',
    learner_catalog_formation_session_formation_list: '/learner/catalog/formation/{formation}/session-formation/list',
    learner_catalog_register: '/learner/catalog/register',
    learner_document_download_partitipation_certificate_personal: "/learner/document/download/participation-certificate-personal",
    learner_document_download_acquisition_certificate_fifpl_tml: "/learner/document/download/acquisition-certificate-fifpl-tml",
    learner_document_download_participation_certificate_fifpl: "/learner/document/download/participation-certificate-fifpl",
    learner_document_download_convention_formation_tml: "/learner/document/download/convention-formation-tml",

    learner_institution_list: '/learner/institution/list',

    learner_community_group_list: '/learner/community/group/list',
    learner_community_group_get: '/learner/community/group/{communityGroup}',
    learner_community_group_add: '/learner/community/group/add',
    learner_community_group_delete: '/learner/community/group/{id}',

    learner_community_post_list: '/learner/community/group/{communityGroup}/post/list',
    learner_community_post_add: '/learner/community/group/{communityGroup}/post/add',
    learner_community_post_get: '/learner/community/group/{communityGroup}/post/{communityPost}',
    learner_community_post_edit: '/learner/community/group/{communityGroup}/post/{communityPost}',
    learner_community_post_delete: '/learner/community/group/{communityGroup}/post/{communityPost}',

    learner_community_comment_list: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/list',
    learner_community_comment_add: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/add',
    learner_community_comment_get: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',
    learner_community_comment_edit: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',
    learner_community_comment_delete: '/learner/community/group/{communityGroup}/post/{communityPost}/comment/{id}',

    learner_registration_get: '/learner/registration/{id}',
    
    learner_registration_survey_satisfaction_get: '/learner/registration/{registration}/survey-satisfaction/get',
    learner_registration_survey_satisfaction_add: '/learner/registration/{registration}/survey-satisfaction/add',
    learner_registration_survey_pre_post_get: '/learner/registration/{registration}/survey-pre-post/{type}/get',
    learner_registration_survey_pre_post_add: '/learner/registration/{registration}/survey-pre-post/add',
    learner_registration_survey_epp_get: '/learner/registration/{registration}/survey-epp/{type}/get',
    learner_registration_survey_epp_score: '/learner/registration/{registration}/survey-epp/{type}/score',
    learner_registration_survey_epp_add: '/learner/registration/{registration}/survey-epp/add',
    learner_registration_survey_epp_download: '/learner/registration/{registration}/survey-epp/download/{type}',
    learner_registration_course_get: '/learner/registration/{registration}/course/{course}',
    learner_registration_course_nav: '/learner/registration/{registration}/course/{course}/nav',
    learner_registration_course_rating: '/learner/registration/{registration}/course/{course}/rating',
    learner_registration_course_view: '/learner/registration/{registration}/course/{course}/view',
    learner_registration_course_score: '/learner/registration/{registration}/course/{course}/score',
    learner_registration_virtual_classroom_get: '/learner/registration/{registration}/virtual-classroom/{virtualClassroom}/get',
    learner_registration_virtual_classroom_add: '/learner/registration/{registration}/virtual-classroom/add',
    learner_registration_virtual_classroom_replay_get: '/learner/registration/{registration}/virtual-classroom-replay/{virtualClassroom}/get',
    learner_registration_survey_satisfaction_add_google_redirection: '/learner/registration/{registration}/survey-satisfaction/add/google-redirect',
    
    learner_cgu_consent: '/learner/cgu-consent',
    learner_tutorial: '/learner/tutorial',
    learner_registration_course_add_resources_seen: '/learner/registration/add-ressources-seen',

    // Enrollment
    enrollment_register_create: '/enrollment/register/create',
    enrollment_register_formation_list: '/enrollment/register/formation/list',
    enrollment_register_formations: '/enrollment/register/formations',
    enrollment_register_formation_get: '/enrollment/register/formation/{formation}/get',
};
const cacheConfig = {};

function Api() {
    let cache = {};
    let currentCall = {};

    this.host = function () {
        return window.location.protocol + '//' + window.location.host;
    };
    this.route = function (options) {
        let route = routeApi[options.route];

        if (!route) {
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                }
                else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        return host + route;
    };
    this.get = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (options.data) {
            route += '?';
            for (let index in options.data) {
                let dataGet = options.data[index] === null ? '' : options.data[index];
                dataGet = dataGet === undefined ? '' : dataGet;

                if (Array.isArray(dataGet)) {
                    for (let index1 in dataGet) {
                        route += index + "[]=" + dataGet[index1] + "&";
                    }
                }
                else {
                    if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
                }
            }
            route = route.slice(0, -1);
        }

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        if (cache[route] && cache[route].age.isAfter(moment())) {
            let data = cloneJson(cache[route].data);
            callback(data);
        }
        else {
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            currentCall[host + route] = source;

            axios.get(host + route, {
                withCredentials: true,
                cancelToken: source.token
            })
                .then(function (response) {
                    delete currentCall[host + route];

                    if (cacheConfig[options.route]) {
                        if (options.route in cacheConfig) {
                            if (!cache[route]) {
                                cache[route] = {
                                    data: cloneJson(response.data),
                                    age: moment().add(cacheConfig[options.route], 's'),
                                };
                                callback(cloneJson(response.data));
                            }
                        }
                    }
                    else {
                        callback(response.data);
                    }
                })
                .catch(err => {
                    console.log(options.route);
                    console.log(route);
                    console.log(err);
                    if (axios.isCancel(err)) {
                        console.log('Requête annulée', err.message);
                    }
                    else if (err.response.status === 401) {
                        dispatch(AuthenticationLogout())
                    }
                    else {
                        if (err.response.data.status === 403 && err.response.data.error.message) {
                            dispatch(
                                SnackbarOpen({
                                    text: err.response.data.error.message,
                                    variant: 'error',
                                })
                            );
                        }
                        callback(err.response.data);
                    }

                    delete currentCall[host + route];
                });
        }
    };
    this.post = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        }
        else {
            data = JSON.stringify(options.data);
        }
        generateLogs(host, route, data)

        axios.post(host + route, data, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                console.log(err.response.data.error.message);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.delete = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        generateLogs(host, route)
        axios.delete(host + route, { withCredentials: true })
            .then(function (response) {
                callback(response.data);
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });

    };
    this.postDownload = function (options, callback) {
        let route = routeApi[options.route];

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        let data;
        if (options.file) {
            data = new FormData();
            for (let property in options.file) {
                data.append(property, options.file[property]);
            }
            if (options.data) {
                data.append('_json', JSON.stringify(options.data));
            }
        }
        else {
            data = JSON.stringify(options.data);
        }
        generateLogs(host, route, data);
        axios.post(host + route, data, { withCredentials: true, responseType: 'blob' })
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    callback(err.response.data);
                }
            });
    };
    this.getDownload = function (options, callback) {
        let route = routeApi[options.route];

        if (!route) {
            callback({ status: 404 });
            console.log(options.route);
            console.log(route);
            dispatch(
                SnackbarOpen({
                    text: '404',
                    variant: 'error',
                })
            );
            return;
        }

        if (!options.data) {
            options.data = { download: 1 };
        }
        else {
            options.data.download = 1;
        }

        route += '?';
        for (let index in options.data) {
            let dataGet = options.data[index] === null ? '' : options.data[index];
            dataGet = dataGet === undefined ? '' : dataGet;

            if (Array.isArray(dataGet)) {
                for (let index1 in dataGet) {
                    route += index + "[]=" + dataGet[index1] + "&";
                }
            }
            else {
                if (dataGet || dataGet === false) route += index + "=" + dataGet + "&";
            }
        }
        route = route.slice(0, -1);

        for (let key in options.params) {
            let regEx = new RegExp('{' + key + '}', 'gi');
            route = route.replace(regEx, options.params[key]);
        }

        generateLogs(host, route);
        axios.get(host + route, { withCredentials: true, responseType: 'blob' })
            .then(response => new File([response.data], options.filename))
            .then(response => {
                const url = window.URL.createObjectURL(response);
                const a = document.createElement('a');
                a.style.display = 'none';
                a.href = url;
                a.download = options.filename;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                callback({ status: 200 });
            })
            .catch(err => {
                console.log(route);
                console.log(err);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                }
                else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    console.log(err.response.data);
                }
            });
    };
    this.cancelAll = function () {
        // Object.values(currentCall).forEach(source => source.cancel('Annulation de toutes les requêtes'));
        currentCall = {};
    };
}


function generateLogs(reqHost, reqRoute, reqData = null) {
    if (
        !reqRoute.includes('learner') &&
        reqRoute !== routeApi.authentication_login
    ) {
        const payload = reqData
        const data = {
            endPoint: reqRoute,
            payload: payload
        }
        axios.post(reqHost + '/administrator/platform-log/add', data, { withCredentials: true })
            .then(function (response) {
            })
            .catch(err => {
                console.log('/admin-monitoring/platform-log/add');
                console.log(err);
                console.log(err.response.data.error.message);
                if (err.response.status === 401) {
                    dispatch(AuthenticationLogout())
                } else {
                    if (err.response.data.status === 403 && err.response.data.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: err.response.data.error.message,
                                variant: 'error',
                            })
                        );
                    }
                }
            });
    }
}

const api = new Api();

export default api;

export const learnerHomeFormationImageBanner = host + '/learner/home/formation-image/';
export const contentManagerFormationImageBanner = host + '/content-manager/formation/image/';
export const enrollmentRegisterFormationImageBanner = host + '/enrollment/register/formation-image/';
export const resource = host + '/resource/';
export const learnerInvoice = host + '/learner/invoice';

export const LibraryResourceImage = host + '/resource/library-resource-image/';
export const LibraryResourceTypeImage = host + '/resource/library-resource-type-image/';
export const LibraryResourceTypeIcon = host + '/resource/library-resource-type-icon/';

export const formationChapterImage = host + '/content-manager/chapter/image/';
