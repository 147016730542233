import { Box, Fade, IconButton, LinearProgress, Tooltip, Typography, makeStyles } from '@material-ui/core';
import { Assignment, PersonAddDisabled } from '@material-ui/icons';
import moment from 'moment';
import React from 'react';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import TableComponent from '../../../../../../Component/TableComponent';
import { getRoutePathname } from '../../../../../../Config/Route';
import { hourBySecondClassic } from "../../../../../../Tool/convert";
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import PaymentIcon from '@material-ui/icons/Payment';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import ListAltIcon from '@material-ui/icons/ListAlt';
import Action from './Action';
import { useSelector } from 'react-redux';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { dispatch } from "../../../../../../App";
import { SnackbarOpen } from "../../../../../../Action/SnackbarAction";
import Api from "../../../../../../Api";

function LinearProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        } else if (value >= 33 && value < 66) {
            return '#db770a';
        } else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}


function RegistrationsINSTITUTION(props = {
    reloadList: () => { }
}) {
    const classes = useStyles();
    const [propsReady, setPropsReady] = React.useState(false);
    const [dialog, setDialog] = React.useState(false);
    const [registrationData, setRegistrationData] = React.useState([]);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    let columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "prospectName",
            label: "Nom / Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeName",
            label: "Financement",
            options: { filter: true, sort: true }
        },
        {
            name: "finishAtFormat",
            label: "Formation terminée le",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAtFormat",
            label: "Date d\'inscription",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "completionProgress",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "amount",
            label: "Montant HT",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const cancel = (data, isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'institution_health_financial_management_billing_session_formation_billable_cancel',
            data: { type: 4, isBillable: isBillable, organismId: selectOrganismReducer.organism.value },
            params: { uuid: data.institutionInvoiceRegistrationContract, sessionFormation: props.match.params.id },
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );
                    props.reloadList();
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    };

    const getAction = (row) => {
        return (
            <div style={{ width: 'max-content' }}>
                {
                    /*<Tooltip title={'Visualiser la fiche contact'} placement="left">
                        <span>
                            <IconButton onClick={() => {
                                window.open(getRoutePathname('institution_health_crm_institution_employee', { institution: row.institutionId, id: row.employee }), '_blank').focus();
                            }} disabled={row.state.value === 3}>
                                <Assignment />
                            </IconButton>
                        </span>
                    </Tooltip>*/
                }
                <Tooltip title={'Facture avant formation'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname(
                                !row.isValidated ?
                                    'institution_health_financial_management_billing_institution_contract'
                                    : 'institution_health_financial_management_billing_institution_contract_invoice_list'
                                , { institution: row.institutionId, id: row.contractId }))
                        }} disabled={[2, 3].includes(row.state.value)} >
                            <LibraryBooksIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={'Action'} placement="left">
                    <span>
                        <IconButton onClick={() => { setDialog(true); setRegistrationData(row) }} >
                            <ListAltIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={row.isBillable ? 'Passer en facturable' : 'Passer en non facturable'} placement="left">
                    <span>
                        <IconButton
                            disabled={row.state.value === 9}
                            onClick={() => { cancel(row, !row.isBillable); }}>
                            {row.isBillable ?
                                <PersonAddIcon />
                                : <PersonAddDisabled />
                            }
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    };

    React.useEffect(() => {
        setPropsReady(true);
        setRegistrationData((rowData) => {
            return props.billing?.registrations.find((val) => val.id === rowData?.id);
        });
    }, [props.billing.registrations]);

    const getDataTable = (registrations, billing) => {
        let r = registrations;
        for (let index in r) {
            r[index]['finishAtFormat'] = r[index]['finishAt'] !== '-' ? moment(r[index]['finishAt']).format('lll') : '-';
            r[index]['availableAtFormat'] = r[index]['availableAt'] !== '-' ? moment(r[index]['availableAt']).format('lll') : '-';
            r[index]['amount'] = <span style={{ color: 'rgb(0, 123, 255)', fontWeight: 'bold' }}>{r[index]['initialAmount'] ?? 0} €</span>;
            r[index]['stateText'] = <span style={{ color: r[index]['state']['color'] }}>{r[index]['state']['text']}</span>;
            r[index].completionProgress = <div style={{ width: 150 }}><LinearProgressWithLabel value={r[index].completion} /></div>;
            r[index]['action'] = getAction(r[index], billing);
        }
        return r;
    };

    return (
        <>
            {
                propsReady ?
                    <div className={classes.tabStyle}>
                        {(props && props.billing && props.billing.registrations) &&
                            <>
                                <TableComponent
                                    id={'institution_health_financial_management_billing_registration_institution_list'}
                                    minHeight={'calc(100vh - 515px)'}
                                    title={<span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>Liste des inscriptions Établissement</span>}
                                    onBeforeRefresh={props.reloadList}
                                    columns={columns}
                                    search={false}
                                    promiseData={(resolve) => {
                                        if (props.billing && props.billing.registrations) {
                                            setTimeout(() => {
                                                resolve(getDataTable(props.billing.registrations, props.billing));
                                            }, 50);
                                        }
                                        else {
                                            resolve([])
                                        }
                                    }}
                                />
                                <Action open={dialog} onClick={() => { setDialog(false); setRegistrationData([]) }} row={registrationData} {...props} />
                            </>
                        }
                    </div>
                    :
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Box className={classes.loader}>
                            <Box className={classes.loaderContent}>
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={70} />
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
            }
        </>
    );
};

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    chip: {
        color: '#fff',
        fontSize: 13,
        padding: '3px 10px',
        display: 'block',
        width: 'fit-content'
    },
    tabStyle: {
        position: 'relative',
        height: '100%',
        '& span': {
            margin: 0,
        },
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                boxShadow: 'none',
                borderRadius: 0,
                height: '100%',
            }
        }
    }
});

export default RegistrationsINSTITUTION;
