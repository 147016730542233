import React from 'react';
import ContentViewComponent from "../../../../Component/ContentViewComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles } from "@material-ui/core";
import ButtonComponent from "../../../../Component/ButtonComponent";
import Api from "../../../../Api";
import { getRoutePathname } from "../../../../Config/Route";
import TitleComponent from "../../../../Component/TitleComponent";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@material-ui/core/Slide";
import MultipleSelectComponent from "../../../../Component/MultipleSelectComponent";
import Fade from "@material-ui/core/Fade";
import TabsComponent from "../../../../Component/TabsComponent";
import ProspectActivity from "./ProspectActivity";
import { TableReload } from "../../../../Action/TableAction";
import { inArray } from "../../../../Tool/ArrayCommon";
import SweetAlert from "sweetalert-react";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { Assignment, Edit, LowPriority, Send, ArrowLeft, ArrowRight } from "@material-ui/icons";
import UseAnimations from "react-useanimations";
import archive from "react-useanimations/lib/archive";
import RubberBand from 'react-reveal/RubberBand';
import LoopComponent from "../../../../Component/LoopComponent";
import moment from 'moment';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import SelectComponent from "../../../../Component/SelectComponent";
import ProspectHotline from "./ProspectHotline";
import ProspectTask from "./ProspectTask";
import DatePickerComponent from "../../../../Component/DatePickerComponent";
import { hasRight } from "../../../../Tool/UserTool";
import LoginAsComponent from '../../../../Component/LoginAsComponent';
import { OrganismIndex, Organisms } from '../../../../Organism';

function InstitutionEmployee(props) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [formationGroup, setFormationGroup] = React.useState(false);
    const [institutionPole, setInstitutionPole] = React.useState(null);

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const [prospectId, setProspectId] = React.useState(null);
    const classes = useStyles(isEdition)();

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'MD' || screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
    }, [screenReducer.screen]);

    const isAdmin = hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_INSTITUTION_ADMIN');

    // Form Prospect
    const [loading, setLoading] = React.useState(false);
    const [form, setForm] = React.useState({
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez le nom du contact.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez le prénom du contact.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Saisissez l\'email du contact.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required', 'email'] }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez le numéro de téléphone du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        mobile: {
            name: 'mobile',
            label: 'Téléphone Mobile',
            textHelper: 'Saisissez le numéro de téléphone mobile du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ips: {
            name: 'ips',
            label: 'IPS',
            textHelper: 'Saisissez l\'Identifiant Professionnel de Santé du contact. (ADELI ou RPPS)',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        siret: {
            name: 'siret',
            label: 'SIRET',
            textHelper: 'Saisissez le numéro SIRET du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        poles: {
            name: 'poles',
            label: 'Pôles',
            textHelper: 'Choisissez les pôles du contact.',
            type: 'array',
            defaultValue: selectOrganismReducer.organism.value === 5 ? [25] : '', // TODO: ID a validé une fois en prod
            options: { validation: ['required'] }
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Choisissez le groupe de formation du contact.',
            type: 'integer',
            defaultValue: selectOrganismReducer.organism.value === 5 ? 5 : '',
            options: { validation: ['required'] }
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez l\'adresse du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez le code postal du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez la ville du contact.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        birthdayAt: {
            name: 'birthdayAt',
            label: 'Date d\'anniversaire',
            textHelper: 'Saisissez la date d\'anniversaire du contact.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        }
    });

    const [formInstitution, setFormInstitution] = React.useState({
        function: {
            name: 'function',
            label: 'Fonction',
            textHelper: 'Choisissez une fonction pour cette établisement.',
            type: 'integer',
            options: { validation: ['required'] }
        },
        institutionPole: {
            name: 'institutionPole',
            label: 'Pôle établissement',
            textHelper: 'Choisissez un pôle pour cette établisement.',
            type: 'integer',
            defaultValue: '',
            options: selectOrganismReducer.organism.value !== 5 ? { validation: ['required'] } : {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de fonction',
            textHelper: 'Saisissez la date de fin de fonction.',
            type: 'date',
            options: { validation: ['date'] }
        },
        startAt: {
            name: 'startAt',
            label: 'Début de fonction',
            textHelper: 'Saisissez la date de début de fonction.',
            type: 'date',
            options: { validation: ['date'] }
        },
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    const handlerInstitution = formHandlerInit(formInstitution, setFormInstitution);
    React.useEffect(handlerInstitution.start, []);

    const save = () => {
        selectOrganismReducer.organism.value === 5 && handlerInstitution.setValue('institutionPole', institutionPole)
        if ((handlerInstitution.checkError() && handler.checkError()) || handlerInstitution.checkError() || handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            handlerInstitution.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                let dataProspect = handler.getData();
                let dataEmployee = handlerInstitution.getData();
                //Prospect
                Api.post({
                    route: 'institution_health_crm_prospect_edit',
                    params: { id: prospectId },
                    data: { ...dataProspect, organism: selectOrganismReducer.organism.value }
                }, (response) => {
                    if (response.status === 200) {
                        editEmployee(dataEmployee, response.data)
                    }
                    else if (response.status === 400) {
                        handler.setFormLoading(false);
                        handlerInstitution.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        handler.setErrorApi(response.error);
                    }
                    else {
                        handler.setFormLoading(false);
                        handlerInstitution.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
            else {
                let dataProspect = handler.getData();
                let dataEmployee = handlerInstitution.getData();
                Api.post({
                    route: 'institution_health_crm_institution_employee_add',
                    params: { institution: props.match.params.institution },
                    data: {
                        ...dataProspect,
                        ...dataEmployee,
                        organism: selectOrganismReducer.organism.value,
                    }
                }, (response) => {
                    handler.setFormLoading(false);
                    handlerInstitution.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        setIsEdition(true);
                        props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: props.match.params.institution, id: response.data.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contact créé avec succès.',
                                variant: 'success',
                            })
                        );

                        setInfoProspect({ ...infoProspect, createdAt: response.data.createdAt, updatedAt: response.data.updatedAt });
                    }
                    else if (response.status === 400 || response.status === 404) {
                        handler.setErrorApi(response.error);
                        handlerInstitution.setErrorApi(response.error);
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'error',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };

    const editEmployee = (dataEmployee, dataProspect) => {
        //Employee
        Api.post({
            route: 'institution_health_crm_institution_employee_edit',
            params: { institution: props.match.params.institution, id: props.match.params.id },
            data: dataEmployee
        }, (response) => {
            handler.setFormLoading(false);
            handlerInstitution.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Les modifications ont bien été pris en compte.',
                        variant: 'success',
                    })
                );

                setInfoProspect({ ...infoProspect, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), commercialId: response.data.commercialId });
                setFormationGroup(dataProspect.formationGroup);
                dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
            }
            else if (response.status === 400) {
                handlerInstitution.setErrorApi(response.error);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    // infoProspect Prospect
    const [infoProspect, setInfoProspect] = React.useState({});
    const [infoEmployee, setInfoEmployee] = React.useState({});
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showTask, setShowTask] = React.useState(false);
    const [formProspectState, setFormProspectState] = React.useState({
        prospectState: {
            name: 'prospectState',
            label: 'État',
            textHelper: 'Choisissez l\'état du salarié.',
            type: 'integer',
            defaultValue: 1,
            options: {}
        }
    });
    const handlerProspectState = formHandlerInit(formProspectState, setFormProspectState);
    const [formCommercial, setFormCommercial] = React.useState({
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez le commercial du salarié.',
            type: 'integer',
            options: {}
        }
    });
    const handlerCommercial = formHandlerInit(formCommercial, setFormCommercial);
    React.useEffect(handlerCommercial.start, []);
    React.useEffect(handlerProspectState.start, []);
    React.useEffect(() => {
        handlerCommercial.setValue('commercial', infoEmployee.commercialId);
    }, [infoEmployee.commercialId]);

    const archived = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'institution_health_crm_prospect_delete',
            params: { id: prospectId }
        },
            (response) => {
                if (response.status === 200) {
                    setInfoProspect({ ...infoProspect, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), deletedAt: moment().format('YYYY-MM-DD HH:mm:ss') });
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Contact archivé.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };

    const unarchived = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'institution_health_crm_prospect_cancel_deletion',
            params: { id: prospectId }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    setInfoProspect({ ...infoProspect, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'), deletedAt: '' });
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Salarié désarchivé.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };


    const prospectState = (value) => {
        if (handlerProspectState.checkError()) {
            console.log('Error');
        }
        else {
            handlerProspectState.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'institution_health_crm_prospect_prospect_state_edit',
                params: { id: prospectId },
                data: { prospectState: value }
            }, (response) => {
                handlerProspectState.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le changement d\'état a bien été pris en compte.',
                            variant: 'success',
                        })
                    );
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    setInfoProspect({ ...infoProspect, updatedAt: moment().format('YYYY-MM-DD HH:mm:ss') });
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    // infoProspect Prospect : Note
    const [formNote, setFormNote] = React.useState({
        note: {
            name: 'note',
            label: 'Note',
            textHelper: 'Modifier la note générale de la fiche du salarié.',
            type: 'text',
            defaultValue: '',
            options: {
                max: 2000
            }
        }
    });
    const [dialogNote, setDialogNote] = React.useState(false);
    const handlerNote = formHandlerInit(formNote, setFormNote);
    React.useEffect(handlerNote.start, []);
    const note = () => {
        dispatch(LinearProgressMainLayoutActivate());

        let data = handlerNote.getData();
        setInfoProspect({
            ...infoProspect,
            updatedAt: moment().format('YYYY-MM-DD HH:mm:ss'),
            note: data.note
        });

        Api.post({
            route: 'institution_health_crm_prospect_note_edit',
            data: data,
            params: { id: prospectId }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Note modifiée.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                    setInfoProspect({ ...infoProspect, note: '' });
                }
            });
    }

    const resetPasswordSend = () => {
        dispatch(LinearProgressMainLayoutActivate());

        Api.post({
            route: 'institution_health_crm_prospect_reset_password',
            params: { id: prospectId }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Le mot de passe a été transmis.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }
    // infoProspect Prospect : Fusion
    const [formFusion, setFormFusion] = React.useState({
        prospect: {
            name: 'prospect',
            label: 'Référence du salarié',
            textHelper: 'Saisissez la référence du salarié à fusionner.',
            type: 'integer',
            defaultValue: ''
        }
    });
    const [dialogFusion, setDialogFusion] = React.useState(false);
    const handlerFusion = formHandlerInit(formFusion, setFormFusion);
    React.useEffect(handlerFusion.start, []);
    const fusion = () => {
        dispatch(LinearProgressMainLayoutActivate());
        handlerFusion.setFormLoading(true);

        let data = handlerFusion.getData();

        Api.post({
            route: 'institution_health_crm_prospect_fusion',
            data: data,
            params: { id: prospectId }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                handlerFusion.setFormLoading(false);
                setDialogFusion(false);

                if (response.status === 200) {
                    dispatch(TableReload('institution_health_crm_prospect_registration_list'));
                    dispatch(TableReload('institution_health_crm_prospect_registration_free_list'));
                    dispatch(TableReload('institution_health_crm_prospect_prospect_hotline_list'));
                    dispatch(TableReload('institution_health_crm_prospect_prospect_activity_list'));
                    dispatch(TableReload('institution_health_crm_prospect_prospect_comment_list'));
                    dispatch(TableReload('institution_health_crm_prospect_form_list'));
                    dispatch(
                        SnackbarOpen({
                            text: 'Les 2 contacts ont bien été fusionnés.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    const getAction = () => {
        return (
            (!infoProspect.commercialId || infoProspect.commercialId === authenticationReducer.id || isAdmin) &&
            <div className={classes.buttonActionGroup} style={isTall ? {} : { position: 'relative', top: 5, right: 5 }}>
                {infoProspect.deletedAt ?
                    <Tooltip title="Désarchiver" aria-label="Désarchiver">
                        <IconButton className={`${classes.buttonAction} ${classes.buttonActionUseAni}`} aria-label="Désarchiver" onClick={unarchived}>
                            <LoopComponent>
                                <RubberBand>
                                    <UseAnimations className={classes.buttonActionIcon} animation={archive} />
                                </RubberBand>
                            </LoopComponent>
                        </IconButton>
                    </Tooltip>
                    :
                    <Tooltip title="Archiver" aria-label="Archiver">
                        <IconButton className={`${classes.buttonAction} ${classes.buttonActionUseAni}`} style={{ padding: '4px 8px 10px' }} aria-label="Archiver" onClick={() => setShowConfirm(true)}>
                            <UseAnimations className={classes.buttonActionIcon} animation={archive} />
                        </IconButton>
                    </Tooltip>
                }
                {
                    ((
                        hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') ||
                        hasRight(authenticationReducer, 'ROLE_INSTITUTION_CRM_LOGIN_AS')) &&
                        infoEmployee.learnerId
                    ) &&
                    <LoginAsComponent
                        className={`${classes.buttonAction} ${classes.buttonActionUseAni}`}
                        email={infoProspect.email}
                        organism={infoProspect.organism}
                    />
                }
                <Tooltip title="Ajouter une tâche" aria-label="Tâche">
                    <IconButton className={classes.buttonAction} aria-label="Tâche" onClick={() => setShowTask(true)}>
                        <Assignment className={classes.buttonActionIcon} />
                    </IconButton>
                </Tooltip>
                {(infoEmployee.learnerId && isAdmin) &&
                    <Tooltip title="Envoyer mot de passe" aria-label="Réinitialiser">
                        <IconButton className={classes.buttonAction} aria-label="Réinitialiser" onClick={() => resetPasswordSend()}>
                            <Send className={classes.buttonActionIcon} />
                        </IconButton>
                    </Tooltip>
                }

                <Tooltip title="Éditer la note" aria-label="Note">
                    <IconButton className={classes.buttonAction} aria-label="Note" onClick={() => setDialogNote(true)}>
                        <Edit className={classes.buttonActionIcon} />
                    </IconButton>
                </Tooltip>
                { // HACK
                    false && <Tooltip title="Fusionner" aria-label="Fusionner">
                        <IconButton className={classes.buttonAction} aria-label="Fusionner" onClick={() => setDialogFusion(true)}>
                            <LowPriority className={classes.buttonActionIcon} />
                        </IconButton>
                    </Tooltip>
                }
            </div>

        )
    }

    // Select
    const [optionPoles, setOptionPoles] = React.useState([]);
    const [optionProspectStates, setOptionProspectStates] = React.useState([]);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);

    const [optionFunctions, setOptionFunctions] = React.useState([]);
    const [optionInstitutionPoles, setOptionInstitutionPoles] = React.useState([]);
    React.useEffect(() => {
        Api.get({
            route: 'select_poles'
        },
            (response) => {
                if (response && response.data) {
                    let data = [];
                    for (let index in response.data) {
                        if (inArray(response.data[index].value, authenticationReducer.poles)) {
                            data.push(response.data[index]);
                        }
                    }
                    setOptionPoles(data);
                }
            });
        Api.get({
            route: 'select_prospect_states'
        },
            (response) => {
                if (response && response.data) {
                    setOptionProspectStates(response.data);
                }
            });
        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });

        Api.get({
            route: 'select_institution_functions'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFunctions(response.data);
                }
            });

        Api.get({
            route: 'select_institution_poles',
            params: { institution: props.match.params.institution }
        },
            (response) => {
                if (response && response.data) {
                    setInstitutionPole(response.data[0].value)
                    setOptionInstitutionPoles(response.data);
                }
            });

        Api.get({
            route: 'select_users',
        },
            (response) => {
                if (response && response.data) {
                    setOptionCommercials(response.data);
                }
            });
    }, []);

    // Prev/Next
    const [next, setNext] = React.useState(null);
    const [prev, setPrev] = React.useState(null);

    const SwitchProspect = ({ next, prev }) => {
        return (
            <div style={{ display: 'flex', gap: 10 }}>
                {prev && <Tooltip title="Passer à la fiche précédente" aria-label="next">
                    <IconButton style={{ padding: '0px 7px', backgroundColor: 'white', border: '2px solid #5E6E8254', borderRadius: 6 }} className={classes.buttonAction} aria-label="next"
                        onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: props.match.params.institution, id: prev }), { list: props.location.state.list })}>
                        <ArrowLeft style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                    </IconButton>
                </Tooltip>}
                {next && <Tooltip title="Passer à la fiche suivante" aria-label="next">
                    <IconButton style={{ padding: '0px 7px', backgroundColor: 'white', border: '2px solid #5E6E8254', borderRadius: 6 }} className={classes.buttonAction} aria-label="next"
                        onClick={() => props.history.push(getRoutePathname('institution_health_crm_institution_employee', { institution: props.match.params.institution, id: next }), { list: props.location.state.list })}>
                        <ArrowRight style={{ fontSize: 25 }} className={classes.buttonActionIcon} />
                    </IconButton>
                </Tooltip>}
            </div>
        )
    }

    // ALL
    React.useEffect(() => {
        setShowTask(false);
        setReadyContent(false);

        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setIsEdition(true);

            //Employee
            Api.get({
                route: 'institution_health_crm_institution_employee_get',
                params: { institution: props.match.params.institution, id: props.match.params.id }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handlerInstitution.setDataApi(data)
                        setProspectId(data.prospectId);
                        setInfoEmployee({ ...infoEmployee, ...response.data });
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_crm_institution', { id: props.match.params.institution }));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        } else { setTimeout(() => setReadyContent(true), 250); setIsEdition(false); }

        if (props.location.state && props.location.state.list) {
            let id = parseInt(props.match.params.id);
            for (let index in props.location.state.list) {
                if (props.location.state.list[index].id === id) {
                    const dataNext = props.location.state.list[(parseInt(index) + 1)];
                    const dataPrev = props.location.state.list[(parseInt(index) - 1)];
                    setNext(dataNext && dataNext.id);
                    setPrev(dataPrev && dataPrev.id);
                    break;
                }
            }
        }

    }, [props.match.params.id]);

    React.useEffect(() => {
        if (prospectId) {
            //Prospect   
            Api.get({
                route: 'institution_health_crm_prospect_get',
                params: { id: prospectId }
            },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        let data = response.data;
                        handler.setDataApi(data);
                        handlerNote.setValue('note', response.data.note);
                        setTimeout(() => handlerProspectState.setValue('prospectState', response.data.prospectState), 50);
                        setFormationGroup(response.data.formationGroup);
                        setInfoProspect({ ...infoProspect, ...response.data });
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('institution_health_crm_institution', { id: props.match.params.institution }));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    setTimeout(() => setReadyContent(true), 250);
                });
        }
    }, [prospectId])

    React.useEffect(() => {
        setBreadcrumbs({
            title: props.match.params.id ? 'Fiche du salarié' : 'Ajouter un salarié',
            context: 'Portail' + (isAdmin ? ' (Admin)' : ''),
            path: getRoutePathname('institution_health_home'),
            description: '',
            links: [
                { path: getRoutePathname('institution_health_crm_institution', { institution: props.match.params.institution }), label: 'Établissement' }
            ],
            html: <SwitchProspect next={next} prev={prev} />
        });
    }, [next, prev]);

    React.useEffect(() => {
        if (props.location.state && props.location.state.emailIPS) {
            if ((props.location.state.emailIPS.toString().match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) === null)) {
                handler.setValue('ips', props.location.state.emailIPS);
            }
            else {
                handler.setValue('email', props.location.state.emailIPS);
            }
        }
    }, []);

    return (
        <Box className={classes.styleContentView} style={{ height: 'auto', overflow: 'hidden' }}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.boxComponent} style={{ height: 'auto', padding: '30px 0' }}>
                    <Box style={{ height: '100%' }}>
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Grid container spacing={3} style={{ margin: 'auto', height: '100%', width: '100%' }}>
                                <Fade in={true} {...{ timeout: 750 }}>
                                    <Grid style={{ height: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 8 : 12} xl={isEdition ? 8 : 12}>
                                        <ShadowBoxComponent style={{ height: '100%', display: 'flex', flexDirection: 'column', padding: '12px 25px' }}>
                                            <TitleComponent title={
                                                <Box style={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}>
                                                    {isEdition ? 'Formulaire d\’éditer d\'un salarié' : 'Formulaire de création d\'un salarié'}
                                                    {Organisms[OrganismIndex[selectOrganismReducer.organism.value]] &&
                                                        <img src={'/asset/images/' + Organisms[OrganismIndex[selectOrganismReducer.organism.value]].logo} alt="logo" style={{ height: 35, margin: 'auto 0 0 auto' }} />
                                                    }
                                                </Box>
                                            } />

                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'lastname'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'firstname'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                    <TextFieldComponent name={'address'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'zipCode'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'city'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'phone'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'mobile'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'email'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <DatePickerComponent name={'birthdayAt'} handler={handler} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'ips'} handler={handler} />
                                                </Grid>

                                                {/*<Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <TextFieldComponent name={'siret'} handler={handler} />
                                                </Grid>*/}

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                                                    <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup} disabled={true}/>
                                                </Grid>

                                                {selectOrganismReducer.organism.value !== 5 &&
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <MultipleSelectComponent name={'poles'} handler={handler} options={optionPoles} disabled={!!props.match.params.id && !isAdmin} />
                                                    </Grid>
                                                }
                                            </Grid>
                                            <TitleComponent title={
                                                <Box style={{ display: 'flex' }}>
                                                    <p style={{ margin: '7px 0px', fontWeight: 'bold' }}>Info établisement</p>
                                                </Box>
                                            } />
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} sm={12}
                                                    md={selectOrganismReducer.organism.value !== 5 ? 6 : 12}
                                                    lg={selectOrganismReducer.organism.value !== 5 ? 6 : 12}
                                                    xl={selectOrganismReducer.organism.value !== 5 ? 6 : 12}
                                                >
                                                    <SelectComponent name={'function'} handler={handlerInstitution} options={optionFunctions} />
                                                </Grid>

                                                {selectOrganismReducer.organism.value !== 5 &&
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <SelectComponent name={'institutionPole'} handler={handlerInstitution} options={optionInstitutionPoles} />
                                                    </Grid>
                                                }

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <DatePickerComponent name={'startAt'} handler={handlerInstitution} />
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                    <DatePickerComponent name={'endAt'} handler={handlerInstitution} />
                                                </Grid>
                                            </Grid>

                                            <Box style={{ margin: 'auto 0 0 auto' }}>
                                                <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={() => { save() }} disabled={loading} />
                                            </Box>
                                        </ShadowBoxComponent>
                                    </Grid>
                                </Fade>

                                {isEdition &&
                                    <Slide direction={'left'} in={true} {...{ timeout: 750 }}>
                                        <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={isEdition ? 4 : 12} xl={isEdition ? 4 : 12}>
                                            <ShadowBoxComponent style={{ height: '100%', display: 'flex', padding: '12px 0' }}>
                                                <Box style={{ padding: '0 12px' }}>
                                                    <TitleComponent title={
                                                        <Box style={{ display: 'flex' }}>
                                                            <p style={{ margin: '7px 0px', fontWeight: 'bold' }}>Info générales</p>
                                                        </Box>
                                                    } />
                                                    <Grid container spacing={1}>

                                                        <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <SelectComponent name={'commercial'} handler={handlerCommercial} options={optionCommercials} nullable={true} disabled={true} />
                                                        </Grid>

                                                        <Grid style={{ padding: '0 14px 10px' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            {
                                                                infoProspect.deletedAt ?
                                                                    <span style={{ color: '#a47d09', fontSize: 12 }}>Archivé depuis le {moment(infoProspect.deletedAt).format('ll')}</span>
                                                                    :
                                                                    <SelectComponent name={'prospectState'} handler={handlerProspectState} options={optionProspectStates} nullable={false} onChange={prospectState} />
                                                            }
                                                        </Grid>

                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                            <span className={classes.infoProspectSpanTitle}>Date de création : </span>
                                                            <span className={classes.infoProspectSpanText}>{infoProspect.createdAt ? moment(infoProspect.createdAt).format('lll') : '-'}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                            <span className={classes.infoProspectSpanTitle}>Dernière mise à jour : </span>
                                                            <span className={classes.infoProspectSpanText}>{infoProspect.updatedAt ? moment(infoProspect.updatedAt).format('lll') : '-'}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                            <span className={classes.infoProspectSpanTitle}>Commercial : </span>
                                                            <span className={classes.infoProspectSpanText}>{
                                                                infoEmployee.commercialId === authenticationReducer.id ? 'Vous' : infoEmployee.commercialId ? infoEmployee.commercialName : '-'
                                                            }</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                            <span className={classes.infoProspectSpanTitle}>Client depuis le : </span>
                                                            <span className={classes.infoProspectSpanText}>{infoProspect.clientAt ? moment(infoProspect.clientAt).format('lll') : '-'}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
                                                            <span className={classes.infoProspectSpanTitle}>Provenance : </span>
                                                            <span className={classes.infoProspectSpanText}>{infoProspect.prospectOrigin ? infoProspect.prospectOrigin : '-'}</span>
                                                        </Grid>
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                            <span className={classes.infoProspectSpanTitle}>Note : </span>
                                                            <p className={classes.infoProspectSpanText} style={{ padding: '0 30px' }}>{infoProspect.note ? infoProspect.note : '-'}</p>
                                                        </Grid>
                                                    </Grid>
                                                </Box>
                                                <Box style={{ borderLeft: '1px solid #5E6E8236', padding: '0 5px', }}>{getAction()}</Box>
                                            </ShadowBoxComponent>
                                        </Grid>
                                    </Slide>
                                }
                            </Grid>
                        </Fade>
                    </Box>

                    {isEdition &&
                        <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                            <div style={{ padding: 12 }}>
                                <TabsComponent
                                    tabDefault={'prospectHotline'}
                                    tabs={[
                                        { id: 'prospectActivities', label: 'Activités' },
                                        { id: 'prospectHotline', label: 'Hotlines' },
                                    ]}
                                >
                                    <div key={'prospectActivities'}>
                                        <ProspectActivity prospectId={prospectId} {...props} />
                                    </div>
                                    <div key={'prospectHotline'}>
                                        <ProspectHotline prospectId={prospectId} {...props} />
                                    </div>
                                </TabsComponent>
                            </div>
                        </Slide>
                    }

                    <SweetAlert
                        show={showConfirm}
                        title={'Archiver'}
                        text={form.firstname.value + ' ' + form.lastname.value + '\nÊtes-vous sur de vouloir archiver ce salarié?'}
                        type={'warning'}
                        cancelButtonText={'Non'}
                        confirmButtonText={'Oui'}
                        showCancelButton={true}
                        onConfirm={() => {
                            archived();
                            setShowConfirm(false);
                        }}
                        onCancel={() => setShowConfirm(false)}
                    />

                    <Dialog open={dialogNote} maxWidth={'xl'} onClose={() => setDialogNote(false)}>
                        <DialogTitle style={{ fontSize: 15 }}>Note générale</DialogTitle>
                        <DialogContent style={{ minWidth: '30vw' }}>
                            <TextFieldComponent name={'note'} handler={handlerNote} multiline={true} />
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogNote(false)} />
                            <ButtonComponent label={'Enregistrer'} onClick={() => {
                                note();
                                setDialogNote(false);
                            }} />
                        </DialogActions>
                    </Dialog>

                    <Dialog open={dialogFusion} maxWidth={'xl'} onClose={() => setDialogFusion(false)}>
                        <DialogTitle style={{ fontSize: 15, paddingBottom: 0 }}>
                            Fusion
                            <p style={{ fontSize: 14 }}>Attention, cette action est irréversible !</p>
                        </DialogTitle>
                        <DialogContent style={{ minWidth: '30vw' }}>
                            <TextFieldComponent name={'prospect'} handler={handlerFusion} />
                        </DialogContent>
                        <DialogActions>
                            <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialogFusion(false)} />
                            <ButtonComponent label={'Fusionner'} onClick={() => fusion()} />
                        </DialogActions>
                    </Dialog>

                    <Dialog open={showTask} maxWidth={'lg'} onClose={() => setShowTask(false)}>
                        <DialogContent>
                            <ProspectTask prospectId={prospectId} {...props} />
                        </DialogContent>
                    </Dialog>
                </Box>
            </ContentViewComponent >
        </Box >
    );
}

const useStyles = (isEdition) => makeStyles({
    boxComponent: {
        width: '95%',
        margin: 'auto',
    },
    styleContentView: {
        '& .ContentView-root': {
            height: '100%',
            display: isEdition ? 'block' : 'grid',
            gridTemplateRows: 'min-content auto',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    infoProspectSpanTitle: {
        fontSize: 14,
        fontWeight: 'bold',
        marginLeft: 10
    },
    infoProspectSpanText: {
        fontSize: 13
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
    buttonActionGroup: {
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
    },
    buttonAction: {
        padding: 10,
        margin: 0,
        float: 'right'
    },
    buttonActionUseAni: {
        padding: '7px 8px 10px',
        color: '#5E6E82',
    },
    buttonActionIcon: {
        fontSize: 18,
        opacity: 1,
        color: '#5E6E82',
        '& g > path': {
            stroke: '#5E6E82'
        }
    },
    logo: {
        height: 50,
        padding: '15px 0'
    },
});

export default InstitutionEmployee;
