import React from "react";
import { dispatch, theme } from "../../../../App";
import ButtonComponent from "../../../../Component/ButtonComponent";
import Countdown from "./Countdown";
import moment from "moment";
import Api from "../../../../Api";
import { makeStyles } from "@material-ui/core";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";

const VirtualClassroomComponent = (props) => {
    const virtualClassroomsInfos = props.virtualClassroomsInfos
    const classes = useStyles();
    const [countdownFinish, setCountdownFinish] = React.useState(false);

    const goVirtualClassroom = (joinUrl, virtualClassroom) => {
        saveCompletedVirtualClassroom(virtualClassroom);
        window.open(joinUrl, "_blank");
    };

    const goVirtualClassroomReplay = (ReplayUrl, virtualClassroom) => {
        window.open(ReplayUrl, "_blank");
    };

    const countdownFinishCallback = (childData) => {
        setCountdownFinish(childData);
    };

    const saveCompletedVirtualClassroom = (id) => {
        Api.post({
            route: 'learner_registration_virtual_classroom_add',
            data: { "id": id },
            params: { registration: props.match.params.id }
        }, (response) => {
            if (response.status === 200) {
                //TODO ? Valid progress bar virtualClassroom
            }
            else if (response.status === 400) {
                dispatch(
                    SnackbarOpen({
                        text: 'Erreur',
                        variant: 'error',
                    })
                );
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    return (!virtualClassroomsInfos.meetingFinish ?
        countdownFinish ?
            <>
                <p className={classes.virtualClassroomIntro}>Rejoindre la classe vitruelle :</p>
                <div className={classes.buttonGroup}>
                    <ButtonComponent color={theme.colorPrimary} label={'Rejoindre'} onClick={() => {
                        goVirtualClassroom(virtualClassroomsInfos.joinUrl, virtualClassroomsInfos.id)
                    }} disabled={props.loading} />
                </div>
            </>
            :
            <Countdown callback={countdownFinishCallback} unixEndDate={moment(virtualClassroomsInfos.meetingAt).valueOf() / 1000} />
        :
        <>
            <p className={classes.virtualClassroomIntro}>La classe virtuelle s'est déjà tenue.</p>
            {
                virtualClassroomsInfos.replayUrl != '' ?
                    <>
                        <p>Pour rejouer cette classe virtuelle, cliquez sur le bouton ci-dessous :</p>

                        <ButtonComponent color={theme.colorPrimary} label={'Visionner'} onClick={() => {
                            goVirtualClassroomReplay(virtualClassroomsInfos.replayUrl, virtualClassroomsInfos.id)
                        }} disabled={props.loading} />
                    </>
                    :
                    <p>La rediffusion de cette classe virtuelle sera prochainement disponible.</p>
            }

        </>
    )
}

const useStyles = makeStyles(style => ({
    virtualClassroom: {
        margin: '30px 20px',
        padding: 22,
        borderRadius: 14,
        color: 'rgb(95, 101, 119)',
        textAlign: 'center',
        border: '2px solid #E6E9F0',
        '& div': {
            color: 'rgb(95, 101, 119) !important',
        }
    },
    virtualClassroomIntro: {
        margin: 0,
        fontWeight: 'bold'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
}))

export default VirtualClassroomComponent;