import React from 'react';
import { Box, Fade, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ButtonComponent from "../../../../../../Component/ButtonComponent";
import Api from "../../../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { formHandlerInit } from '../../../../../../Tool/FormHandlerCommon';
import DatePickerComponent from '../../../../../../Component/DatePickerComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { dispatch } from '../../../../../../App';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import moment from 'moment';

function Invoice(props) {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const [addBillingList, setAddBillingList] = React.useState([]);
    const [registrationList, setRegistrationList] = React.useState([]);
    const [total, setTotal] = React.useState(0);
    const [countRegistration, setCountRegistration] = React.useState(0);
    const [formInvoiceAt, setFormInvoiceAt] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: moment(),
            options: { validation: ['required', 'date'] }
        }
    });
    const handlerInvoiceAt = formHandlerInit(formInvoiceAt, setFormInvoiceAt);
    const [form, setForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);

    const addBilling = (data) => {
        setAddBillingList((prev) => {
            return [...prev, data];
        });
    };

    const removeBilling = (data) => {
        setAddBillingList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });

        setRegistrationList((prev) => {
            return [...prev, data];
        });
    };

    const calcTotal = () => {
        let data = handler.getData();
        let total = 0;
        let countRegistration = 0;

        for (let index in data) {
            let split = index.split("_");
            if (split && split[0] === 'price') {
                total += parseFloat(data[index]);
                countRegistration++;
            }
        }

        setTotal((Number(total) === total) ? total.toFixed(2) : 0);
        setCountRegistration(countRegistration);
    };

    React.useEffect(handlerInvoiceAt.start, []);
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        setLoading(false);
        if (addBillingList.length !== 0) {
            setForm((prevData) => {
                const newData = addBillingList.reduce((acc, registration) => {
                    acc['price_' + registration.id] = {
                        name: 'price_' + registration.id,
                        label: 'Prix',
                        textHelper: '',
                        type: 'float',
                        defaultValue: '',
                        value: registration.initialAmount ?? '0',
                        options: {},
                        registration: registration.id,
                        institutionInvoiceRegistrationContract: registration.institutionInvoiceRegistrationContract
                    };

                    acc['contract_' + registration.id] = {
                        name: 'contract_' + registration.id,
                        value: registration.contractId,
                        registration: registration.id
                    };

                    acc['number_' + registration.id] = {
                        name: 'number_' + registration.id,
                        label: 'Numéro OPCO',
                        textHelper: '',
                        type: 'text',
                        defaultValue: '',
                        options: { validation: ['required'] },
                        registration: registration.id
                    };

                    acc['comment_' + registration.id] = {
                        name: 'comment_' + registration.id,
                        label: 'Commentaire',
                        textHelper: '',
                        type: 'text',
                        defaultValue: '',
                        options: {},
                        registration: registration.id
                    };
                    return acc;
                }, {});

                const mergedData = { ...prevData };
                Object.keys(newData).forEach(key => {
                    mergedData[key] = {
                        ...newData[key],
                        ...prevData[key]
                    };
                });

                return mergedData;
            });

            setRegistrationList((prev) => {
                return prev.filter(item => !addBillingList.some(reg => reg.id === item.id));
            });
        }

        setForm((prev) => {
            const result = {};
            addBillingList.forEach(item => {
                const registrationId = item.id.toString();
                for (const key in prev) {
                    if (prev[key].registration.toString() === registrationId) {
                        result[key] = prev[key];
                    }
                }
            });
            return result;
        });

    }, [addBillingList]);

    React.useEffect(() => {
        calcTotal()
    }, [handler.getData()]);

    const save = () => {
        if (handler.checkError() || handlerInvoiceAt.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = handler.getData();
            let lines = [];

            Object.keys(data).forEach((key) => {
                if (key.startsWith('price_')) {
                    const id = key.split('_')[1];
                    const price = data[key];
                    const numberKey = 'number_' + id;
                    const contractKey = 'contract_' + id;

                    if (data[numberKey]) {
                        lines.push({
                            contract: data[contractKey],
                            number: data[numberKey],
                            amount: price,
                            registration: id,
                            institutionInvoiceRegistrationContract: form[key].institutionInvoiceRegistrationContract
                        });
                    }
                }
            });

            Api.post({
                route: 'institution_health_financial_management_billing_session_formation_invoice',
                params: { sessionFormation: props.sessionFormation.id },
                data: {
                    lines: lines,
                    invoiceAt: handlerInvoiceAt.getData().invoiceAt,
                    type: 3,
                }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        props.reloadList();
                        setAddBillingList([]);
                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );

                        const url = Api.route({
                            route: 'institution_health_financial_management_billing_session_formation_invoice_download',
                            params: { sessionFormation: props.sessionFormation.id, id: response.data.id }
                        });
                        window.open(url, '_blank');
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }

    };

    const getAddRegistrations = () => {
        if (registrationList) {
            let registrations = registrationList.reduce((acc, registration) => {
                const priceLabel = `${registration.initialAmount}€`;

                const group = acc.find(item => item.label === priceLabel);

                if (group) {
                    group.registrations.push(registration);
                } else {
                    acc.push({
                        label: priceLabel,
                        registrations: [registration]
                    });
                }
                return acc;
            }, []);

            registrations.sort((a, b) => parseFloat(b.label) - parseFloat(a.label));

            return (
                (registrations.length != 0) ?
                    <Box style={{ position: 'absolute', padding: '10px 0', width: '95%', left: '50%', top: 0, transform: 'translate(-50%)' }}>
                        {registrations.map((group, groupIndex) => (
                            <Box key={groupIndex}>
                                <p style={{ display: 'grid', gridTemplateColumns: 'auto max-content auto', gap: 10, }} >
                                    <hr style={{ width: '100%', height: 0, opacity: .3 }} />
                                    <b style={{ color: '#007bff' }}>{`Groupement de ${group.label}`}</b>
                                    <hr style={{ width: '100%', height: 0, opacity: .3 }} />
                                </p>
                                <Box style={{ display: 'flex', flexDirection: 'column', gap: 10, }}>
                                    {
                                        group.registrations.map((registration, regIndex) => (
                                            <Box key={regIndex} style={{ background: '#FFF', width: 'auto', fontSize: 13, textAlign: 'center', alignItems: 'center', padding: 10, borderRadius: 10, border: '1px solid #A3ADB7', display: 'grid', gridTemplateColumns: 'max-content auto max-content max-content' }}>
                                                <p style={{ margin: 0, padding: '0 10px' }}>{registration.id}</p>
                                                <p style={{ margin: 0, padding: '0 10px', borderLeft: '1px solid #A3ADB7', borderRight: '1px solid #A3ADB7' }}><b>{registration.firstname} {registration.lastname}</b></p>
                                                <p style={{ margin: 0, padding: '0 10px', borderRight: '1px solid #A3ADB7' }}>Prix: <b>{registration.initialAmount}</b></p>
                                                <Box style={{ padding: '0 10px' }}>
                                                    <ButtonComponent disabled={loading} onClick={() => addBilling(registration)} color={'#5E6E82'} label={'Ajouter'} />
                                                </Box>
                                            </Box>
                                        ))
                                    }
                                </Box>
                            </Box>
                        ))}
                    </Box> :
                    <Box style={{ position: 'absolute', padding: '10px 0', width: '95%', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }}>
                        <p style={{ color: '#5E6E82', margin: 'auto', fontSize: 12, textAlign: 'center' }}>Aucun résultat.</p>
                    </Box>
            );
        }
    }

    const getRenderRegistrations = () => {
        if (addBillingList) {
            let render = [];
            let registrations = addBillingList;
            if (registrations.length != 0) {
                for (let index in registrations) {
                    render.push(
                        (handler.form[`number_${registrations[index].id}`] && handler.form[`number_${registrations[index].id}`]) &&
                        <Fade key={registrations[index].id} in={true} {...{ timeout: 1000 }}>
                            <TableRow>
                                <TableCell className={classes.td}>{registrations[index].id}</TableCell>
                                <TableCell className={classes.td}><b>{registrations[index].firstname} {registrations[index].lastname}</b></TableCell>
                                <TableCell className={classes.td}>
                                    <TextFieldComponent name={`number_${registrations[index].id}`} handler={handler} />
                                </TableCell>
                                <TableCell className={classes.td}>
                                    <TextFieldComponent name={`price_${registrations[index].id}`} handler={handler} />
                                </TableCell>
                                <TableCell className={classes.td}><ButtonComponent disabled={loading} color={'#DD4E4E'} onClick={() => removeBilling(registrations[index])} label={'Retirer'} /></TableCell>
                            </TableRow>
                        </Fade>
                    );
                }
            } else {
                render.push(
                    <TableRow>
                        <TableCell colSpan={5} className={classes.td} >Aucun résultat.</TableCell>
                    </TableRow>
                )
            }
            return render;
        } else return <></>;
    };

    React.useEffect(() => {
        setRegistrationList(props.registrations.length != 0 ? props.registrations : [])
    }, [props.registrations]);

    return (
        <>
            <Dialog open={props.open} className={classes.dialogStyle} maxWidth={'xl'} onClose={props.close}>
                <Box style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto 450px', padding: 10, height: 600 }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ fontSize: 15, color: '#5E6E82', }}>
                            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                                <h1 style={{ fontSize: 20, margin: 0 }}>Facture - OPCO</h1>
                                <hr style={{ margin: 0, opacity: 0.5 }} />
                            </Box>
                            <Box style={{ padding: '10px 20px' }}>
                                <DatePickerComponent name={'invoiceAt'} handler={handlerInvoiceAt} />
                            </Box>
                            <hr style={{ margin: 0 }} />
                        </Box>
                        <DialogContent style={{ padding: '0px 24px' }}>
                            <TableContainer style={{ position: 'relative', height: '100%' }}>
                                <Table style={{ position: 'absolute' }}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.th}>Référence</TableCell>
                                            <TableCell className={classes.th}>Nom</TableCell>
                                            <TableCell className={classes.th}>Numéro OPCO</TableCell>
                                            <TableCell className={classes.th}>Prix</TableCell>
                                            <TableCell className={classes.th}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {getRenderRegistrations()}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </DialogContent>
                        <Box>
                            <hr style={{ margin: 0 }} />
                            <div style={{ textAlign: 'end', padding: '10px 20px', color: '#5E6E82', }}>
                                Nombre total participant : <span style={{ fontWeight: 600 }}>{countRegistration}</span> -- Total : <span style={{ fontWeight: 600 }}>{total} €</span>
                            </div>
                        </Box>
                        <DialogActions>
                            <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'} />
                            <ButtonComponent disabled={loading || countRegistration === 0} onClick={save} label={'Générer la facture'} />
                        </DialogActions>
                    </Box>
                    <Box style={{ display: 'grid', gridTemplateRows: 'max-content auto', gap: 10, color: '#5E6E82', height: '100%' }}>
                        <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                            <h1 style={{ fontSize: 20, margin: 0 }}>Liste des inscription</h1>
                            <hr style={{ margin: 0, opacity: 0.5 }} />
                        </Box>
                        <Box style={{ display: 'flex', flexDirection: 'column', background: '#EFF3F4', borderRadius: 10, gap: 10, border: '1px solid rgba(163, 173, 183, 0.36)', position: 'relative', overflow: 'hidden auto' }}>
                            {getAddRegistrations()}
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center',
        color: '#5E6E82',
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center',
        color: '#5E6E82',
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 1500,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #deposit': {
            textAlign: 'center'
        }
    },
    containerCheckbox: {
        display: 'flex',
        justifyContent: 'space-around'
    }
});

export default Invoice;
