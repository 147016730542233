import React from 'react';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Box, Dialog, DialogActions, DialogContent, Fade, Grid, makeStyles, Zoom } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { dispatch, theme } from "../../../App";
import { getRoutePathname } from '../../../Config/Route';
import Api, { learnerHomeFormationImageBanner, LibraryResourceImage } from '../../../Api';
import Iframe from './Component/Iframe';
import CardFormation from './Component/CardFormation';
import MediaLinkComponent from '../../../Component/MediaLinkComponent';
import Rating from '@material-ui/lab/Rating';
import LazyLoading from './Component/LazyLoading';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ButtonComponent from '../../../Component/ButtonComponent';
import { SnackbarOpen } from '../../../Action/SnackbarAction';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../Tool/FormHandlerCommon';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import LearnerImgSvgComponent from '../../../Component/LearnerImgSvgComponent';
import CardResource from './Component/CardResource';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Zoom ref={ref} {...props} />
});

const Resource = (props) => {

    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [info, setInfo] = React.useState([]);
    const [moreInfo, setMoreInfo] = React.useState([]);
    const [isFormation, setIsFormation] = React.useState(false);
    const [loadLivestorm, setLoadLivestorm] = React.useState(false);
    const [showCountdownFinish, setShowCountdownFinish] = React.useState(false);
    const [showRating, setShowRating] = React.useState(false);
    const [loadRating, setLoadRating] = React.useState(false);
    const [average, setAverage] = React.useState(0);
    const [cardSmall, setCardSmall] = React.useState('');
    const [isAverage, setIsAverage] = React.useState(true);
    const [rating, setRating] = React.useState(0);
    const [countSpaces, setCountSpaces] = React.useState({
        formationsCatalogLeaner: '',
        formationsCatalogLeanerNotAvailable: ''
    });
    const [hover, setHover] = React.useState(-1);

    const customIcons = {
        1: <SentimentVeryDissatisfiedIcon />,
        2: <SentimentDissatisfiedIcon />,
        3: <SentimentSatisfiedIcon />,
        4: <SentimentSatisfiedAltIcon />,
        5: <SentimentVerySatisfiedIcon />,
    };

    React.useEffect(() => {
        setCardSmall((prev) => {
            const values = {
                1: { width: 355, grid: 1 },
                2: { width: 732, grid: 2 },
                default: { width: 1102, grid: 3 }
            };

            const conditions = [
                { condition: screenReducer.size <= 925, type: 1 },
                { condition: screenReducer.size <= 1216, type: 2 },
            ];

            const valueType = conditions.find(cond => cond.condition)?.type || 'default';
            return {
                grid: values[valueType].grid,
                width: values[valueType].width
            };
        });
    }, [screenReducer]);

    React.useEffect(() => {
        setLoading(true);
        setLoad(false);
        Api.get({
            route: 'learner_resource_get',
            params: { resource: props.match.params.resource },
            data: { organism: authenticationReducer.organism, formationGroup: authenticationReducer.formationGroup }
        },
            (response) => {
                if (response.status === 200) {
                    setInfo(response.data.data);
                    setMoreInfo(response.data.moreInfo);
                    setIsFormation(response.data.isFormation);

                    setAverage(response.data.data.average);
                    setRating(response.data.data.ratingByLearner);
                    setHover(response.data.data.ratingByLearner ? response.data.data.ratingByLearner : -1);
                    setIsAverage(response.data.data.isAverage);

                    setLoading(false);
                }
            });
    }, [props.match.params.resource]);

    React.useEffect(() => {
        setBreadcrumbs({
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_library_resource'), label: 'Bibliothèque de ressources' },
                { path: getRoutePathname('learner_resource_type', { id: props.match.params.id }), label: 'Ressources' },
            ],
            title: info.title && <Box zIndex={3} textAlign={'center'} borderRadius={'10px'} border={'2px solid #E6E9F0'} padding={'15px 50px'} bgcolor={'#FFF'} width={'fit-content'} margin={'10px auto -55px'}>
                <p style={{ width: '100%', padding: '0', margin: 0, fontSize: 17, fontWeight: 700, color: theme.colorPrimary }}>{info.title ? info.title : 'Ressoures'}</p>
                {info.subTitle && <p style={{ width: '100%', padding: '0', margin: 0, fontSize: 13, fontWeight: 700, color: '#5F6577' }}>{info.subTitle}</p>}
            </Box>
        });
    }, [info, theme]);

    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    })
    const handler = formHandlerInit(form, setForm);
    const saveRating = () => {
        if (!handler.checkError()) {
            setLoadRating(true)
            Api.get({
                route: 'learner_resource_type_rating',
                params: { resource: props.match.params.resource },
                data: { comment: handler.getData().comment, rating: rating }
            }, (response) => {
                if (response.status === 200) {
                    setLoadRating(false)
                    setAverage(response.data.average);
                    setIsAverage(response.data.isAverage);
                    setRating(response.data.ratingByLearner);
                    setHover(response.data.ratingByLearner ? response.data.ratingByLearner : -1)
                    setShowRating(false)
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
                setLoadLivestorm(false);
            });
        }
    };

    const registerLivestorm = (val, action) => {
        setLoadLivestorm(true);
        Api.post({
            route: 'learner_resource_livestorm_register',
            params: { resource: props.match.params.resource, sessionlivestorm: val.sessionlivestorm },
            data: {
                idEvent: val.idEvent,
            }
        }, (response) => {
            if (response.status === 201) {
                dispatch(
                    SnackbarOpen({
                        text: 'L\'inscription au webinaire est un succès',
                        variant: 'success',
                    })
                );
                switch (val.state) {
                    case 2:
                        info.livestorm.state = 1;
                        break;
                    case 3:
                        info.livestorm.state = 4;
                        break;
                    case 6:
                        info.livestorm.state = 5;
                        break;
                }
                setTimeout(() => {
                    if (action) { goLive(val) }
                }, 500);
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'error',
                    })
                );
            }
            setLoadLivestorm(false);
        });
    }

    const goLive = (val) => {
        setLoadLivestorm(true);
        Api.get({
            route: 'learner_resource_livestorm_login',
            params: { resource: props.match.params.resource, id: val.idEvent },
            data: {
                sessionId: val.sessionlivestorm
            }
        }, (response) => {
            if (response.status === 200) {
                window.open(response.data.connectionLink, '_blank');
            }
            else {
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'error',
                    })
                );
            }
            setLoadLivestorm(false);
        });
    };

    const ButtonLivestorm = (val) => {

        if ((val.state === 3 || val.state === 4)) {
            let currentDate = new Date();
            let date = new Date(val.sessionStart.date);
            let minutes = val.estimatedDuration;
            date.setMinutes(date.getMinutes() + minutes);

            if (date.getTime() < currentDate.getTime()) {

                if (val.state === 3) {
                    info.livestorm.state = 6;
                }
                if (val.state === 4) {
                    info.livestorm.state = 5;
                }
            }
        }

        switch (val.state) {
            case 0:
                return <></>;
            case 1: // inscrit et live non démarrer

                // Compte a rebours avant début du live
                // return (
                //     <Countdown date={val.sessionStart} callbackCountdown={callbackCountdown} />
                // );
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Accéder à la salle'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 2: // non inscrit et live non démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Inscrivez-vous'}
                        disabled={loadLivestorm}
                        onClick={() => { registerLivestorm(val, null) }}
                    />
                );
            case 3: // non inscrit et live démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Inscrivez-vous et accéder au webinaire en live'}
                        disabled={loadLivestorm}
                        onClick={() => {
                            registerLivestorm(val, true);
                        }}
                    />
                );
            case 4: // inscrit et live démarrer
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Accéder au webinaire en live'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 5: // inscrit et live terminer 
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Voir le replay'}
                        disabled={loadLivestorm}
                        onClick={() => goLive(val)}
                    />
                );
            case 6: // non inscrit et live terminer 
                return (
                    <ButtonComponent
                        className={classes.button}
                        color={theme.colorPrimary}
                        label={'Voir le replay'}
                        disabled={loadLivestorm}
                        onClick={() => {
                            registerLivestorm(val, true);
                        }}
                    />
                );
        }
    };

    const callbackCountdown = () => {
        setShowCountdownFinish(true);
        info.livestorm.state = 4;
    };

    const cardSpace = (countSpaces) => {
        return (
            <div style={{ display: 'flex', gridColumn: `span ${countSpaces}` }} >
                <Fade in={true} {...{ timeout: 500 }}>
                    <Box className={classes.cardAccesRoot}>
                        <Box style={{ textAlign: 'center', margin: (countSpaces === 1) ? 'auto' : '', zIndex: 1, position: 'relative' }}>
                            <h1 style={{ color: theme.colorPrimary, margin: 5, fontSize: (countSpaces != 1) ? 60 : 30 }}>Tableau de bord</h1>
                        </Box>
                        <p className={classes.textLink2} style={{ margin: countSpaces === 1 ? 0 : 10, zIndex: 1, position: 'relative' }}
                            onClick={() => { props.history.push(getRoutePathname('learner_home')) }}
                        ><span style={{ padding: '5px 50px' }}>{(countSpaces != 1) ? 'Revenir au Tableau de bord' : 'Accéder'}</span></p>
                        {(countSpaces != 1) && <LearnerImgSvgComponent style={{ width: '100%' }} />}
                    </Box>
                </Fade>
            </div>
        )
    }

    const countEmptySpaces = (data, grid) => {
        const dataLength = data?.length;
        const remainder = dataLength % grid;
        return remainder === 0 ? 0 : grid - remainder;
    };

    React.useEffect(() => {
        setCountSpaces(countEmptySpaces(moreInfo, cardSmall.grid))
    }, [moreInfo, cardSmall]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <div className={classes.container}>
                {info &&
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3} style={{ width: 'max-content', maxWidth: 'max-content' }}>
                            <Box className={classes.cardRoot}>
                                <Box className={`${classes.pictureRoot}`}>
                                    {(!load) && <img src="/asset/images/preload_image.png" alt={'Image'} />}
                                    <img src={LibraryResourceImage + info.id + '.png'} alt='image' onLoad={() => setLoad(true)} />
                                </Box>
                                <Box style={{ margin: 'auto 0 0 5px' }}>
                                    <div className={classes.styleStats2} >
                                        <p className='view-root'><VisibilityIcon />{info.numberViews ?? 0}</p>
                                        <p className='rating-root'>
                                            <Rating
                                                name="hover-feedback"
                                                value={average}
                                                precision={1}
                                                readOnly
                                            />
                                        </p>
                                    </div>
                                    {isAverage &&
                                        <ButtonComponent
                                            color={theme.colorPrimary}
                                            style={{ display: 'block', margin: '10px auto 0px auto' }}
                                            label={'Donnez une note'}
                                            onClick={() => { setShowRating(true) }}
                                        />
                                    }
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                            {info.description &&
                                <Box className={classes.cardRoot} style={{ textAlign: 'justify', width: 'auto', padding: '30px 70px', margin: '0 0 30px' }}>
                                    <p className={classes.boxTitle}>Description : </p>
                                    <div className={classes.description} dangerouslySetInnerHTML={{ __html: info.description }} />
                                </Box>
                            }
                            {info.type === 7 ?
                                <div>
                                    <ButtonLivestorm
                                        state={info.livestorm.state}
                                        sessionlivestorm={info.livestorm.idSession}
                                        idEvent={info.livestorm.id}
                                        path={info.livestorm.room_link}
                                        sessionStart={info.livestorm.estimated_started_at}
                                        estimatedDuration={info.livestorm.estimated_duration}
                                    />
                                </div>
                                :
                                <Iframe type={info.type} path={info.link} />
                            }
                        </Grid>
                    </Grid>
                }
                {moreInfo &&
                    <BoxLearnerComponent
                        title={'Pour aller plus loin'}
                        styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                        styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
                    >
                        {
                            isFormation ?
                                <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width, padding: '20px 0' }}>
                                    {moreInfo.map((formation, index) =>
                                        <div style={{ display: 'flex' }} key={index}>
                                            <CardFormation
                                                show={true}
                                                hours={formation.hours}
                                                image={learnerHomeFormationImageBanner + formation.image}
                                                title={formation.name}
                                                description={formation.description}
                                                financeTypes={formation.financeTypes}
                                                isEPP={formation.isEPP}
                                                onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formation.id })) }}
                                            />
                                        </div>
                                    )}
                                    {(countSpaces != 0) && cardSpace(countSpaces)}
                                </div>
                                :
                                <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width, padding: '20px 0' }}>
                                    {moreInfo.map((resource, index) =>
                                        <div style={{ display: 'flex' }} key={index}>
                                            {resource.format === 6 || resource.format === 2 ?
                                                <Fade in={true} {...{ timeout: 500 }}>
                                                    <MediaLinkComponent
                                                        path={resource.link}
                                                        type={resource.format}
                                                        isLibrary={true}
                                                        className={classes.cardRoot}
                                                        style={(screenReducer.size <= 395) ? { width: '100%' } : {}}
                                                    >
                                                        <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                    </MediaLinkComponent>
                                                </Fade>
                                                :
                                                <Fade in={true} {...{ timeout: 500 }}>
                                                    <div className={classes.cardRoot2} style={(screenReducer.size <= 395) ? { width: '100%' } : {}} onClick={() => {
                                                        props.history.push(getRoutePathname('learner_resource_type_resource', { id: props.match.params.id, resource: resource.id }));
                                                    }}>
                                                        <CardResource path={LibraryResourceImage + resource.image} resource={resource} {...props} />
                                                    </div>
                                                </Fade>
                                            }
                                        </div>
                                    )}
                                    {(countSpaces != 0) && cardSpace(countSpaces)}
                                </div>
                        }
                    </BoxLearnerComponent>

                }
                <Dialog className={classes.dialog} open={showRating} onClose={() => { setShowRating(false) }} maxWidth={'xl'} TransitionComponent={Transition}>
                    <DialogContent>
                        <div className='customIcons-root'>{customIcons[hover !== -1 ? hover : (rating ? rating : 1)]}</div>
                        <h1 style={{ margin: 0, }}>Donnez une note</h1>
                        <p style={{ margin: 0, padding: '10px 0', }}><span>Donnez votre avis en quelques clics !</span><br /> Évaluez cette ressource de 0 à 5 étoiles.</p>
                        <Rating
                            name="hover-feedback"
                            value={rating}
                            precision={1}
                            style={{ padding: '0 0 10px 0', }}
                            onChange={(event, newValue) => {
                                setRating(newValue);
                            }}
                            onChangeActive={(event, newHover) => {
                                setHover(newHover);
                            }}
                            disabled={loadRating}
                        />
                        <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent color={theme.colorPrimary} style={{ margin: '10px auto', }} label={'valider'} onClick={() => { saveRating() }} disabled={loadRating} />
                    </DialogActions>
                </Dialog>
                <Dialog open={showCountdownFinish} onClose={() => setShowCountdownFinish(false)} maxWidth={'xl'} TransitionComponent={Transition}>
                    <img style={{ width: '50%', padding: 15 }} src={'/asset/images/logo-livestorm.svg'} />
                    <DialogContent>
                        <div style={{ width: '50vw', maxWidth: 500, textAlign: 'center' }}>
                            <p style={{ fontSize: 18, fontWeight: 500 }}>Le live : {info.title} commence, <br /> voulez-vouz être rediriger ?</p>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <ButtonComponent onClick={() => setShowCountdownFinish(false)} color={'#5E6E82'} label={'Non'} />
                        <ButtonComponent onClick={() => {
                            setShowCountdownFinish(false);
                            goLive({
                                state: info.livestorm.state,
                                sessionlivestorm: info.livestorm.idSession,
                                idEvent: info.livestorm.id,
                                path: info.livestorm.room_link,
                                sessionStart: info.livestorm.estimated_started_at
                            });
                        }} label={'Oui'} />
                    </DialogActions>
                </Dialog>
            </div>
        </ContentViewLearnerComponent>
    );
};

const useStyles = (theme) => makeStyles({
    container: {
        width: '100%',
        padding: '50px 0 0 0',
        boxSizing: 'border-box',
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    infoContent: {
        display: 'flex',
        flexDirection: 'row',
    },
    widthImage: {
        height: 200,
        width: 200,
    },
    widthImageSmall: {
        height: 130,
        width: 130,
    },
    pictureRoot: {
        background: '#393939',
        width: 230,
        height: 160,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        margin: '0 10px 10px',
        '& img': {
            position: 'absolute',
            width: '120%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        }
    },
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 10,
        display: 'grid',
        padding: '15px 5px',
        width: 'max-content',
        background: '#FFF',
        alignItems: 'center',
    },
    cardRoot2: {
        border: '2px solid #E6E9F0',
        cursor: 'pointer',
        borderRadius: 17,
        display: 'grid',
        padding: 5,
        width: 'min-content',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        minHeight: 330,
        gridTemplateRows: 'max-content max-content auto',
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    stateResource: {
        '& >div': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            margin: 'auto',
            gap: 5,
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
        }
    },
    styleStats2: {
        display: 'flex',
        margin: 'auto',
        width: 'max-content',
        '& p.view-root': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: '5px 0 0 5px',
            border: '2px solid rgba(0, 0, 0, 0.08)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& p.rating-root': {
            alignItems: 'center',
            width: 'fit-content',
            padding: '0 10px',
            borderRadius: '0 5px 5px 0',
            borderRight: '2px solid rgba(0, 0, 0, 0.08)',
            borderTop: '2px solid rgba(0, 0, 0, 0.08)',
            borderBottom: '2px solid rgba(0, 0, 0, 0.08)',
            margin: 0,
            display: 'flex',
            '& svg': {
                width: 16,
            },
            '& .MuiRating-root': {
                color: theme.colorPrimary,
                fontSize: 17,
            },
        },
    },
    resourceImagePreload: {
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 1,
        borderRadius: 10,
        border: 'solid 1px #DADADA',
    },
    resourceImage: {
        objectFit: 'cover',
        borderRadius: 10,
        border: 'solid 1px #DADADA',
        display: 'block',
    },
    circle: {
        borderRadius: 0,
        float: 'left',
        shapeOutside: 'margin-box',
        marginRight: 15,
        marginBottom: 7
    },
    card: {
        height: 320,
        padding: 15,
        background: '#fff',
        cursor: 'pointer',
        border: '1px solid rgba(0, 0, 0, 0.15)',
        borderRadius: 10,
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        '& > p': {
            textAlign: 'left'
        }
    },
    textLink: {
        textDecoration: 'underline',
        fontWeight: 500,
        cursor: 'pointer'
    },
    btnThematic: {
        cursor: 'pointer',
        width: 'max-content',
        boxShadow: 'rgb(204, 219, 232) 1px 1px 3px 0px inset, rgba(255, 255, 255, 0.5) -1px -1px 3px 1px inset',
        padding: '10px 20px',
        margin: 5,
        borderRadius: 5
    },
    description: {
        '& *': {
            margin: 0
        }
    },
    button: {
        fontSize: 10,
        margin: 3
    },
    dialog: {
        '& .MuiDialog-paper, .MuiDialogContent-root': {
            overflow: 'initial',
            textAlign: 'center',
        },
        '& div.customIcons-root': {
            '& svg': {
                display: 'block',
                width: 'auto',
                margin: '-44px auto auto auto',
                background: '#FFF',
                height: 50,
                borderRadius: 100
            },
        },
        '& .MuiRating-root': {
            margin: 'auto',
            width: 'fit-content',
            fontSize: 35,
            display: 'flex',
            color: theme.colorPrimary,
        }
    },
    styleStats: {
        display: 'flex',
        alignItems: 'center',
        margin: 'auto 0 0 0',
        gap: 10,
        '& p': {
            display: 'flex',
            alignItems: 'center',
            width: 'fit-content',
            fontSize: 13,
            gap: 3,
            padding: '0 10px',
            borderRadius: 5,
            border: '1px solid rgba(0, 0, 0, 0.15)',
            margin: 0,
            '& svg': {
                width: 16,
            },
        },
        '& .MuiRating-root': {
            color: theme.colorPrimary,
            fontSize: 17,
        },
    },
    boxTitle: {
        fontSize: 17,
        fontWeight: 700,
        textAlign: 'left',
        color: theme.colorPrimary,
        margin: '2px 0',
        display: 'block'
    },
    textLink2: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        transition: 'all .4s !important',
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block',
            width: 'max-content',
            fontSize: 13
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    cardAccesRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        gap: 10,
        padding: 40,
        width: '100%',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
});

export default Resource;
