import React from 'react';
import { Box, Drawer as DrawerMUI, IconButton, Slide, Tooltip, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import moment from 'moment';
import FormAutocompleteComponent from './Component/FormAutocompleteComponent';
import RegistrationListComponent from './Component/RegistrationListComponent';
import BillingRegistrationList from './BillingRegistrationList';
import InvoiceDialogComponent from './Component/InvoiceDialogComponent';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';

export default function BillingInstitutionContract(props) {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isOpenFormAutocomplete, setIsOpenFormAutocomplete] = React.useState(false);
    const classes = useStyles(screenReducer)();
    const dispatch = useDispatch();
    const [isOpenDiscount, setIsOpenDiscount] = React.useState(false);
    const [showDialog, setShowDialog] = React.useState(false);
    const [isChangeData, setIsChangeData] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const [institution, setInstitution] = React.useState([]);
    const [registrationList, setRegistrationList] = React.useState([]);
    const [billingList, setBillingList] = React.useState([]);
    const [currentBillingList, setCurrentBillingList] = React.useState([]);
    const [errorList, setErrorList] = React.useState([]);

    const [form, setForm] = React.useState({
        title: {
            name: 'title',
            label: 'Titre du contrat',
            textHelper: 'Saisissez le titre du contrat.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const addBilling = (data) => {
        setBillingList((prev) => {
            return [...prev, data];
        });

        setRegistrationList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });
    };

    const removeBilling = (data) => {
        setBillingList((prev) => {
            return prev.filter(item => item.id !== data.id);
        });

        setRegistrationList((prev) => {
            return [...prev, data];
        });
    };

    const getActionMore = () => {
        return (
            <>
                <Box style={{ display: 'flex', gap: 12 }}>
                    <ButtonComponent
                        color={"#5E6E82"}
                        onClick={() => { save() }}
                        label={props.match.params.id ? 'Enregistrer' : 'Créer le contrat'}
                        disabled={billingList.length === 0}
                    />
                    {props.match.params.id &&
                        <Tooltip title={errorList.length != 0 ? '' : isChangeData ? 'Des modifications ont été effectuées.' : ''} placement={"bottom"}>
                            <div>
                                <ButtonComponent
                                    onClick={() => { setShowDialog(true) }}
                                    label={'Facturer'}
                                    disabled={billingList.length === 0 || isChangeData}
                                />
                            </div>
                        </Tooltip>
                    }
                    <ButtonComponent
                        color={"#1c7d32"}
                        onClick={() => { setIsOpen(!isOpen) }}
                        label={'Ajouter des inscriptions'}
                    />
                </Box>
            </>
        )
    }

    const getInstitution = (id) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        if (props.match.params.institution) {
            Api.get({
                route: 'institution_health_financial_management_billing_institution_contract_get',
                data: { contract: id ?? null },
                params: { institution: props.match.params.institution }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                if (response.status === 200) {
                    let data = response.data;
                    if (data.contract) {
                        handler.setDataApi(data.contract);
                    }
                    setInstitution(data);
                    setRegistrationList((data.registrations && data.registrations.length != 0) ? data.registrations : []);
                    if (data.registrationsBilled && data.registrationsBilled.length != 0) {
                        setBillingList(data.registrationsBilled);
                        setCurrentBillingList(data.registrationsBilled);
                    }
                } else {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_contract_list', { institution: props.match.params.institution }));
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                        })
                    );
                }
            });
        }
    }

    const save = () => {
        if (errorList.length != 0 || handler.checkError()) {
            setIsError(true);
            dispatch(
                SnackbarOpen({
                    text: errorList.length != 0 ? 'Veuillez entrer tous les champs.' : 'Veuillez entrer un titre',
                    variant: 'warning'
                })
            );
        } else {
            setLoading(true);
            setIsError(false);
            dispatch(LinearProgressMainLayoutActivate());
            const data = billingList.map(({ id, price }) => ({ id, price }));
            const handlerData = handler.getData();
            if (props.match.params.id) {
                let postData = {
                    route: 'institution_health_financial_management_billing_institution_contract_edit',
                    params: { institution: props.match.params.institution, contract: props.match.params.id },
                    text: 'Contrat modifiée.',
                    data: { lines: data, title: handlerData.title }
                }

                Api.post({
                    route: postData.route,
                    data: postData.data,
                    params: postData.params
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 200) {
                        getInstitution(props.match.params.id)
                        dispatch(
                            SnackbarOpen({
                                text: postData.text,
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            } else {
                Api.post({
                    route: 'institution_health_financial_management_billing_institution_contract_add',
                    data: { lines: data, title: handlerData.title },
                    params: { institution: props.match.params.institution }
                }, (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);
                    if (response.status === 201) {
                        let data = response.data;
                        getInstitution(data.contract)
                        props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_contract', { institution: props.match.params.institution, id: data.contract }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Contrat créer.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    }

    const deleteInscription = (data) => {
        if (!data.availableAt) {
            Api.post({
                route: 'institution_health_crm_institution_registration_delete',
                params: {
                    institution: props.match.params.institution,
                    registration: data.id
                },
                data: { organism: selectOrganismReducer.organism.value }
            },
                (response) => {
                    if (response.status === 200) {
                        getInstitution(props.match.params.id)
                        dispatch(
                            SnackbarOpen({
                                text: 'L\'inscription a bien été supprimé.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        } else {
            Api.post({
                route: 'institution_health_crm_institution_registration_cancel',
                params: {
                    institution: props.match.params.institution,
                    registration: data.id
                },
                data: { organism: selectOrganismReducer.organism.value }
            },
                (response) => {
                    if (response.status === 200) {
                        getInstitution(props.match.params.id)
                        dispatch(
                            SnackbarOpen({
                                text: 'Le contact a bien été désinscrit de la formation.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }

    const compareArrays = (array1, array2) => {
        const map1 = new Map(array1.map(item => [item.id, item.price]));
        const map2 = new Map(array2.map(item => [item.id, item.price]));

        if (array1.length !== array2.length ||
            ![...map1.keys()].every(id => map2.has(id))) {
            return false;
        }

        return [...map1.keys()].every(id =>
            JSON.stringify(map1.get(id)) === JSON.stringify(map2.get(id))
        );
    };

    const GetTitle = () => {
        const classes = useStyles()();
        return <div className={classes.titleStyle}><TextFieldComponent name={'title'} handler={handler} /></div>
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: <GetTitle handler={handler} />,
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: <p style={{ margin: 0, fontWeight: 'initial' }}>Établissement - <b>{institution.name ? institution.name : '-'}</b></p>,
            links: props.match.params.institution ? [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation établissement' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_contract_list', { institution: props.match.params.institution }), label: 'Liste des contrats' }
            ] : [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation établissement' },
            ],
            html: getActionMore()
        });
    }, [institution, isOpen, billingList, errorList, isChangeData]);

    React.useEffect(() => {
        setIsChangeData(!compareArrays(billingList, currentBillingList))
    }, [billingList, currentBillingList]);

    React.useEffect(() => {
        getInstitution(props.match.params.id);
    }, [props.match.params.id]);

    return (
        <>
            <Box className={classes.styleContentView}>
                <DrawerMUI
                    variant="permanent"
                    className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                    open={isOpen}>
                    <IconButton onClick={() => { setIsOpen(!isOpen) }} style={{
                        background: '#FFF', border: '8px solid #E7E9EC', width: 'max-content', borderRadius: 100,
                        position: 'absolute', left: isOpen ? -30 : 0, padding: 3, top: 29, transition: 'all .5s'
                    }}>
                        <ArrowLeftIcon style={{ color: '#5E6E82', transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)', transition: 'all .5s' }} />
                    </IconButton>
                    <Box style={{ padding: 30, display: 'grid', gridTemplateRows: 'auto', gap: 10, color: '#5E6E82', height: '100%', overflow: 'hidden' }}>
                        <RegistrationListComponent dataList={registrationList} deleteInscription={deleteInscription} addBilling={addBilling} loading={loading} disabled={institution.cancelAt} />
                    </Box>
                </DrawerMUI>

                <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
                    <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                        <BillingRegistrationList
                            dataList={billingList}
                            setBillingList={setBillingList}
                            tva={institution.tva}
                            setError={setErrorList}
                            isError={isError}
                            removeData={removeBilling}
                            {...props}
                        />
                    </Box>

                    <Box>
                        <Box className={classes.total}>
                            <Box className='buttonList-root'>
                                {(institution.discounts && (institution.discounts.length != 0)) ?
                                    <>
                                        <Box>
                                            <Slide direction="right" in={isOpenDiscount} mountOnEnter unmountOnExit>
                                                <Box className={classes.discountPopup}>
                                                    {institution.discounts.map((discount, index) => (
                                                        <Box key={index} style={{ border: '1px solid rgba(163, 173, 183, 0.61)', borderRadius: 5, }}>
                                                            <p className='date-root'>Date : du <span>{moment(discount.startAt).format('ll')}</span> au <span>{discount.endAt ? moment(discount.endAt).format('ll') : '-'}</span></p>
                                                            {(discount.percentage || discount.value) &&
                                                                <p className='discount-root'>
                                                                    {discount.percentage && <span>Réduction : {discount.percentage}%</span>}
                                                                    {discount.value && <span>Réduction : {discount.value}€</span>}
                                                                </p>
                                                            }
                                                            <p className='comment-root'>commentaire :<span>{discount.comment}</span></p>
                                                        </Box>
                                                    ))}
                                                </Box>
                                            </Slide>
                                            <ButtonComponent onClick={() => { setIsOpenDiscount(!isOpenDiscount); setIsOpenFormAutocomplete(false) }} label={'Remise'} color={"#5E6E82"} />
                                        </Box>
                                    </> : ''
                                }
                                <Box>
                                    <Slide direction="right" in={isOpenFormAutocomplete} mountOnEnter unmountOnExit>
                                        <Box className={classes.formAutocomplete}>
                                            <FormAutocompleteComponent setBillingList={setBillingList} tva={institution.tva} loading={loading || billingList.length === 0} {...props} />
                                        </Box>
                                    </Slide>
                                    <ButtonComponent onClick={() => { setIsOpenFormAutocomplete(!isOpenFormAutocomplete); setIsOpenDiscount(false) }} label={'Saisie automatique'} />
                                </Box>
                            </Box>
                            <Box className='TextField-root'>
                                <span>Tva : {institution.tva ?? 0} %</span>
                            </Box>
                        </Box>
                    </Box>
                    <InvoiceDialogComponent dialog={showDialog} institution={institution} setDialog={setShowDialog} {...props} />
                </ContentViewComponent >
            </Box >
        </>
    )
}

const drawerOpenRight = 0;
const drawerCloseRight = -572;
const useStyles = (screenReducer) => makeStyles(style => ({
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        color: '#5E6E82',
        position: 'relative',
        overflow: 'hidden',
        '& .MuiCircularProgress-svg': {
            color: '#FFF'
        },
        '& .ContentView-root': {
            height: '100%',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto min-content',
            padding: 0,
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                padding: '15px 55px',
                borderBottom: '1px solid rgba(163, 173, 183, 0.38)',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: '572px !important',
        right: drawerCloseRight,
        height: '100%',
        flexShrink: 0,
        borderLeft: '6px solid #5E6E8226',
        background: '#FFFFFF',
        //overflowX: 'hidden',
        zIndex: 10,
        boxSizing: 'border-box',
        position: 'absolute',
        boxShadow: '-1px 0px 4px #00000036',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflow: 'initial !important',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('right', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        right: `${drawerOpenRight}px !important`,
    },
    drawerClose: {
        transition: style.transitions.create('right', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        right: drawerCloseRight,
    },
    creditStyle: {
        background: '#E5E7EA',
        position: 'relative',
        padding: 10,
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        '& input': {
            textAlign: 'center'
        },
    },
    titleStyle: {
        padding: '10px 0 0 0',
        '& .MuiFormHelperText-root': { display: 'none' },
        '& .MuiInputLabel-outlined.MuiInputLabel-shrink': { transform: 'translate(15px, -4px) scale(0.64)' },
        '& .MuiInputLabel-outlined': {
            fontSize: 15,
            transform: 'translate(10px, 13px)',
            color: '#5E6E8282'
        },
        '& input': {
            padding: '8px 10px',
            fontSize: 22,
            fontWeight: 'bold',
            color: '#5E6E82'
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    circularProgress: {
        margin: 0,
        '& svg': { color: '#FFF' }
    },
    discountPopup: {
        position: 'absolute',
        bottom: 40,
        border: '1px solid rgb(163, 173, 183)',
        minHeight: 100,
        minWidth: 270,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        '& .date-root': {
            background: '#E5E7EA',
            borderBottom: '1px solid',
            padding: 10,
            fontSize: 12,
            margin: 0,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .discount-root': {
            margin: '0 10px',
            borderBottom: '1px solid',
            padding: 10
        },
        '& .comment-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            gap: 5,
            margin: 0,
            '& span': {
                display: 'block',
                height: '100%',
                background: 'rgb(239, 243, 244)',
                border: '1px solid rgba(163, 173, 183, 0.36)',
                borderRadius: 5,
                padding: '5px 10px',
                fontSize: 14
            }
        }
    },
    formAutocomplete: {
        position: 'absolute',
        bottom: 40,
        border: '1px solid rgb(163, 173, 183)',
        minHeight: 100,
        minWidth: 450,
        left: 0,
        background: '#FFF',
        borderRadius: '5px',
        padding: '20px 30px',
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
    },
    total: {
        margin: 0,
        display: 'flex',
        padding: '10px 55px',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        background: '#FFF',
        gap: 5,
        '& .TextField-root': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 10,
            width: 'fit-content',
            margin: '0 0 0 auto',
            '& .MuiFormHelperText-root': {
                display: 'none'
            }
        },
        '& .buttonList-root': {
            display: 'flex',
            position: 'relative',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 10,
            width: 'fit-content',
            '& .MuiFormHelperText-root': {
                display: 'none'
            }
        },
    },
}))
