import React from 'react'
import TableComponent from '../../../Component/TableComponent';
import Api from '../../../Api';
import moment from 'moment';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import ButtonComponent from "../../../Component/ButtonComponent";
import {formHandlerInit} from "../../../Tool/FormHandlerCommon";
import SelectComponent from "../../../Component/SelectComponent";
import Grid from "@material-ui/core/Grid";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import SweetAlert from "sweetalert-react";
import {SnackbarOpen} from "../../../Action/SnackbarAction";
import {useDispatch, useSelector} from "react-redux";
import {LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate} from "../../../Action/LinearProgressMainLayoutAction";
import {TableReload} from "../../../Action/TableAction";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import {Autorenew, Delete, Edit, PersonAddDisabled, Assignment, Visibility} from "@material-ui/icons";
import {inArray} from "../../../Tool/ArrayCommon";
import {hasRight} from "../../../Tool/UserTool";
import SelectAutocompleteComponent from "../../../Component/SelectAutocompleteComponent";
import RateReviewIcon from '@material-ui/icons/RateReview';
import DatePickerComponent from '../../../Component/DatePickerComponent';
import DateTimePickerComponent from '../../../Component/DateTimePickerComponent';
import RegistrationLogs from './Component/RegistrationLogs';
import RegistrationScoreAdmin from './Component/RegistrationScoreAdmin';

export default function Registration(props = {}) {
    const dispatch = useDispatch();
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    // Table
    const columnsRegistrations = [
        {
            name: 'id',
            label: 'Référence',
            options: {filter: true, sort: true}
        },
        {
            name: 'formationName',
            label: 'Formation',
            options: {filter: true, sort: true}
        },
        {
            name: 'organismName',
            label: 'Organisme',
            options: {filter: true, sort: true}
        },
        {
            name: 'commercialName',
            label: 'Commercial',
            options: {filter: true, sort: true}
        },
        {
            name: props.financeType === 6 ? 'personalPaymentAt' : 'startAt',
            label: props.financeType === 6 ? 'Date de paiement' : 'Début de session',
            options: {filter: true, sort: true}
        },
        {
            name: props.financeType === 6 ? 'availableEndAt' : 'endAt',
            label: props.financeType === 6 ? 'Fin disponibilité' : 'Fin de session',
            options: {filter: true, sort: true}
        },
        {
            name: 'createdAt',
            label: 'Date création',
            options: {filter: true, sort: true}
        },
        {
            name: 'availableAt',
            label: 'Date d\'inscription',
            options: {filter: true, sort: true}
        },
        {
            name: 'cancelAt',
            label: 'Date de désinscription',
            options: {filter: true, sort: true}
        },
        {
            name: 'available',
            label: props.labelAvailable,
            options: {filter: true, sort: true}
        },
        {
            name: "stateText",
            label: "État",
            options: {filter: true, sort: true}
        },
        {
            name: "origin",
            label: "Origine de la registration",
            options: {filter: true, sort: true}
        },
        {
            name: 'action',
            label: 'Action',
            options: {filter: false, sort: false}
        }
    ];

    // Form
    const [optionOrganisms, setOptionOrganisms] = React.useState([]);
    const [optionYears, setOptionYears] = React.useState([
        {value: (parseInt(moment().format('YYYY')) - 1), label: (parseInt(moment().format('YYYY')) - 1)},
        {value: parseInt(moment().format('YYYY')), label: parseInt(moment().format('YYYY'))},
        {value: (parseInt(moment().format('YYYY')) + 1), label: (parseInt(moment().format('YYYY')) + 1)}
    ]);
    const [optionsFormation, setOptionsFormation] = React.useState([]);
    const [optionsPersonalPaymentType, setOptionsPersonalPaymentType] = React.useState([]);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [registrationEditing, setRegistrationEditing] = React.useState({});
    const [dialog, setDialog] = React.useState(false);
    const [sweetAlertFormationGroup, setSweetAlertFormationGroup] = React.useState(false);
    const [registrationCancel, setRegistrationCancel] = React.useState(0);
    const [reRegistrationCancel, setReRegistrationCancel] = React.useState(0);
    const [registrationDelete, setRegistrationDelete] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [fullSessions, setFullSessions] = React.useState(false);
    const [optionCommercials, setOptionCommercials] = React.useState([]);
    const [isCrmAdmin, setIsCrmAdmin] = React.useState(true);
    const [isEditCommercialOnly, setIsEditCommercialOnly] = React.useState(false);
    const [isCheck, setIsCheck] = React.useState(false);
    const [showLogs, setShowLogs] = React.useState(false);
    const [showScoreAdmin, setShowScoreAdmin] = React.useState(false);
    const [optionsPromotionCode, setOptionsPromotionCode] = React.useState(false);
    const [hasRightAccountant, setHasRightAccountant] = React.useState(false);

    const [form, setForm] = React.useState({
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Choisissez une année.',
            type: 'integer',
            defaultValue: parseInt(moment().format('YYYY')),
            options: {validation: ['required'],}
        },
        organism: {
            name: 'organism',
            label: 'Organisme',
            textHelper: 'Choisissez l\'organisme de facturation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Choisissez une formation.',
            type: 'integer',
            defaultValue: '',
            options: {validation: ['required'],}
        },
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        available: {
            name: 'available',
            label: props.labelAvailable,
            textHelper: 'Validez la disponibilité de la formation.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        availableAt: {
            name: 'availableAt',
            label: 'Début de la disponibilité',
            textHelper: 'Saisissez la date de la disponibilité de la formation.',
            type: 'datetime',
            defaultValue: '',
            options: {}
        },
        availableEndAt: {
            name: 'availableEndAt',
            label: 'Fin de la disponibilité',
            textHelper: 'Veuillez saisir la date de fin de disponibilité. Si aucune date n\'est indiquée, une date par défaut correspondant à un an après la date de début sera attribuée.',
            type: 'datetime',
            defaultValue: '',
            options: {}
        },
        personalPaymentAt: {
            name: 'personalPaymentAt',
            label: 'Date de paiement',
            textHelper: 'saisissez la date de paiement de la formation.',
            type: 'datetime',
            defaultValue: '',
            options: {}
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Choisissez un commercial assigné à cette inscription.',
            type: 'integer',
            options: {}
        },
        startCpfAt: {
            name: 'startCpfAt',
            label: 'Début de session CPF',
            textHelper: 'Saisissez une date de début de la session CPF',
            type: 'date',
            defaultValue: '',
            options: {validation: ['date']}
        },
        endCpfAt: {
            name: 'endCpfAt',
            label: 'Fin de session CPF',
            textHelper: 'Saisissez une date de fin de la session CPF',
            type: 'date',
            defaultValue: '',
            options: {validation: ['date']}
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Choisissez un type de paiement assigné à cette inscription.',
            type: 'integer',
            options: {}
        },
        promotionCodeStripe: {
            name: 'promotionCodeStripe',
            label: 'Code promotionnel',
            textHelper: 'Choisissez un code promo assigné à cette inscription.',
            type: 'integer',
            options: {}
        },
        isFifpl: {
            name: 'isFifpl',
            label: 'Cocher pour formation FIFPL non Conventionné.',
            textHelper: 'Cocher pour formation FIFPL non Conventionné.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        dispatch(TableReload('crm_prospect_registration_list'));
    }, [props.formationGroup]);
    React.useEffect(() => {
        if (props.organism) {
            Api.get({
                    route: 'select_organisms',
                    data: {'isFull': true, 'idForced': props.organism}
                },
                (response) => {
                    if (response && response.data) {
                        let data = [];
                        for (let index in response.data) {
                            if (inArray(response.data[index].value, authenticationReducer.organisms)) {
                                data.push(response.data[index]);
                            }
                        }
                        if (data.length > 0) {
                            setOptionOrganisms(data);
                        }
                    }
                });
        }
    }, [props.organism]);
    React.useEffect(() => {
        changeCommercialList();
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_ADMIN') ) {
            setIsCrmAdmin(false);
        }

        if (hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN')) setHasRightAccountant(true)
        if (hasRight(authenticationReducer, 'CRM_SESSION_EDIT')) setHasRightAccountant(true)
        if (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN')) setHasRightAccountant(true)
        if (hasRight(authenticationReducer, 'ROLE_ACCOUNTANT')) setHasRightAccountant(true)
    }, []);

    // Save
    const changeYear = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['year'].value = value;
        newForm['year'].error = handler.getErrorByField(newForm['year']);
        handler.set(newForm);
        if (mustReload) loadFormation(formationId, formationSessionId);
    };
    const changeOrganism = (value, mustReload, formationId = '', formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['organism'].value = value;
        newForm['organism'].error = handler.getErrorByField(newForm['organism']);
        handler.set(newForm);
        if (mustReload) loadFormation(formationId, formationSessionId);
    };

    const changeCommercial = (value) => {
        let newForm = {...handler.form};
        newForm['commercial'].value = value;
        newForm['commercial'].error = handler.getErrorByField(newForm['commercial']);
        handler.set(newForm);
    };

    const loadFormation = (formationId, formationSessionId) => {
        let organism = form['organism'].value;
        let year = form['year'].value;

        if (organism && year) {
            changeFormation(formationId, formationSessionId);
            handler.setFormLoading(true);
            Api.get({
                    route: 'crm_prospect_registration_formation_list',
                    params: {prospect: props.match.params.id},
                    data: {organism: organism, year: year, financeType: props.financeType}
                },
                (response) => {
                    if (response && response.data) {
                        setOptionsFormation(response.data);
                    }
                    handler.setFormLoading(false);
                });
        }
        else {
            changeFormation('', '');
        }
    }
    const changeFormation = (value, formationSessionId = '') => {
        let newForm = {...handler.form};
        newForm['formation'].value = value;
        newForm['formation'].error = value !== '' ? handler.getErrorByField(newForm['formation']) : '';
        handler.set(newForm);

        if (props.financeType === 6) {
            if (value) {
                loadPaymentType(value);
                loadPromotionCode(value);
            }
        }
        else {
            loadSession(fullSessions, formationSessionId);
        }
    };
    const loadPromotionCode = (formation) => {
        handler.setFormLoading(true);
        let organismId = form['organism'].value;
        Api.get({
            route: 'crm_prospect_registration_formation_promotion_code_list',
            params: {prospect: props.match.params.id, formation: formation},
            data: {organism: organismId}
        },
        (response) => {
            handler.setFormLoading(false);
            if (response && response.data) {
                setOptionsPromotionCode(response.data);
            }
        });
    };
    const loadPaymentType = (formation) => {
        handler.setFormLoading(true);
        let organismIdTMP = form['organism'].value;
        Api.get({
            route: 'crm_prospect_registration_formation_payment_type_list',
            params: {prospect: props.match.params.id, formation: formation},
            data: {organism: organismIdTMP}
        },
        (response) => {
            handler.setFormLoading(false);
            if (response && response.data) {
                setOptionsPersonalPaymentType(response.data);
            }
        });
    };
    const loadSession = (full = false, formationSessionId = '') => {
        setFullSessions(full);

        let formationIdTMP = form['formation'].value;
        let organismIdTMP = form['organism'].value;
        let yearTMP = form['year'].value;

        let newForm = {...handler.form};
        newForm['sessionFormation'].value = formationSessionId;
        newForm['sessionFormation'].error = formationSessionId !== '' ? handler.getErrorByField(newForm['sessionFormation']) : '';
        handler.set(newForm);

        if (formationIdTMP && organismIdTMP) {
            handler.setFormLoading(true);
            Api.get({
                    route: 'crm_prospect_registration_formation_session_formation_list',
                    params: {prospect: props.match.params.id, formation: formationIdTMP},
                    data: {full: full ? 1 : 0, organism: organismIdTMP, year: yearTMP, financeType: props.financeType}
                },
                (response) => {
                    handler.setFormLoading(false);
                    if (response && response.data) {
                        setOptionsSessionFormation(response.data);
                    }
                });
        }
        else {
            setOptionsSessionFormation([]);
        }
    };
    const cancel = () => {
        setRegistrationCancel(0);
        Api.get({
                route: 'crm_prospect_registration_cancel',
                params: {
                    prospect: props.match.params.id,
                    registration: registrationCancel
                }
            },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );

                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const reEnrollment = () => {
        setReRegistrationCancel(0);
        Api.get({
                route: 'crm_prospect_registration_re_enrollment',
                params: {
                    prospect: props.match.params.id,
                    registration: reRegistrationCancel
                }
            },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été réinscrit à la formation.',
                            variant: 'success',
                        })
                    );

                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    };
    const remove = () => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.delete({
                route: 'crm_prospect_registration_delete',
                params: {
                    prospect: props.match.params.id,
                    registration: registrationDelete
                }
            },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Inscription supprimée.',
                            variant: 'success',
                        })
                    );

                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        setRegistrationDelete(0);
    }
    const showDialog = () => {
        if (props.formationGroup) setDialog(true);
        else setSweetAlertFormationGroup(true)
    };
    const getCheckbox = (row) => {
        return (
            <Checkbox
                key={row.id + new Date().getTime()}
                defaultChecked={!!row.availableAt}
                disabled={row.isLock || !row.formationSessionId}
                onChange={(event) => {
                    if (!props.formationGroup) {
                        setSweetAlertFormationGroup(true);
                        dispatch(TableReload('crm_prospect_registration_list'));
                        return;
                    }

                    Api.post({
                            route: 'crm_prospect_registration_edit',
                            data: {
                                'formation': row.formationId,
                                'sessionFormation': row.formationSessionId,
                                'organism': row.organismId,
                                'year': row.year,
                                'available': event.target.checked,
                                'financeType': props.financeType
                            },
                            params: {
                                prospect: props.match.params.id,
                                registration: row.id
                            }
                        },
                        (response) => {
                            handler.setFormLoading(false)
                            dispatch(LinearProgressMainLayoutDeactivate());
                            setLoading(false);

                            if (response.status === 200) {
                                setDialog(false);
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Modification enregistrée.',
                                        variant: 'success',
                                    })
                                );
                            }
                            else if (response.error && response.error.field && response.error.field[0].message) {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error.field[0].message ? response.error.field[0].message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                            else {
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }

                            dispatch(TableReload('crm_prospect_registration_list'));
                            dispatch(TableReload('crm_prospect_prospect_activity_list'));
                        });
                }}
            />
        );
    };
    const getAction = (row) => {
        return (
            <div style={{minWidth: 90}}>
                {
                    !row.isLock &&
                    <Tooltip title={'Supprimer'} placement="left">
                        <IconButton onClick={() => setRegistrationDelete(row.id)}>
                            <Delete/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (
                        !row.isLock ||
                        (
                            hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') ||
                            hasRight(authenticationReducer, 'CRM_SESSION_EDIT')  ||
                            hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN')
                        )
                        || props.financeType === 6
                    ) &&
                    <Tooltip title={'Modifier'} placement="left">
                        <IconButton onClick={() => {
                            changeCommercialList();
                            setIsEditCommercialOnly(false);
                            setRegistrationEditing(row);
                            changeYear(row.year);
                            changeOrganism(row.organismId, true, row.formationId, row.formationSessionId);
                            changeCommercial(row.commercialId);
                            handler.setValue('available', row.availableAt !== '-');
                            handler.setValue('availableAt', row.availableAt !== '-' ? moment(row.availableAt, "DD MMM. YYYY HH:mm") : moment());
                            setIsCheck(row.availableAt !== '-');
                            handler.setValue('startCpfAt', row.startCpfAt);
                            handler.setValue('endCpfAt', row.endCpfAt);
                            handler.setValue('personalPaymentType', row.personalPaymentType);
                            handler.setValue('personalPaymentAt', row.personalPaymentAt !== '-' ? moment(row.personalPaymentAt, "DD MMM. YYYY HH:mm") : '');
                            if (props.financeType === 6) {
                                handler.setValue('availableEndAt', row.availableEndAt !== '-' ? moment(row.availableEndAt, "DD MMM. YYYY HH:mm") : '');
                                handler.setValue('isFifpl', row.isFifpl);
                            }
                            handler.setValue('promotionCodeStripe', row.promotionCodeStripe);
                            showDialog();
                        }}>
                            <Edit/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (!row.cancelAt && row.isLock) &&
                    <>
                        <Tooltip title={'Désinscription'} placement="left">
                            <IconButton onClick={() => setRegistrationCancel(row.id)} disabled={row.isStateInvoiced}>
                                <PersonAddDisabled/>
                            </IconButton>
                        </Tooltip>

                        {((hasRight(authenticationReducer, 'ROLE_CRM_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN')) && props.financeType !== 6) &&
                        <Tooltip title={'Modifier uniquement le commercial'} placement="left">
                            <IconButton onClick={() => {
                                changeCommercialList();
                                setIsEditCommercialOnly(true);
                                setRegistrationEditing(row);
                                changeYear(row.year);
                                changeOrganism(row.organismId, true, row.formationId, row.formationSessionId);
                                changeCommercial(row.commercialId);
                                handler.setValue('available', row.availableAt !== '-');
                                handler.setValue('availableAt', row.availableAt !== '-' ? moment(row.availableAt, "DD MMM. YYYY HH:mm") : moment());
                                setIsCheck(row.availableAt !== '-');
                                showDialog();
                            }}>
                                <RateReviewIcon/>
                            </IconButton>
                        </Tooltip>}
                    </>
                }
                {
                    (row.cancelAt && row.isLock) &&
                    <Tooltip title={'Annuler la désinscription'} placement="left">
                        <IconButton onClick={() => setReRegistrationCancel(row.id)}>
                            <Autorenew/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'CRM_SESSION_EDIT')) &&
                    <Tooltip title={'Voir les logs de connexion'} placement="left">
                        <IconButton onClick={() => setShowLogs(row.id)}>
                            <Assignment/>
                        </IconButton>
                    </Tooltip>
                }
                {
                    (hasRight(authenticationReducer, 'ROLE_SUPER_ADMIN') || hasRight(authenticationReducer, 'ROLE_CRM_SUPER_ADMIN') || hasRight(authenticationReducer, 'CRM_SESSION_EDIT')) &&
                    <Tooltip title={'Voir les scores effectués par les admins'} placement="left">
                        <IconButton onClick={() => setShowScoreAdmin(row.id)}>
                            <Visibility />
                        </IconButton>
                    </Tooltip>
                }
            </div>
        );
    };

    const registration = () => {

        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);

            let datas = handler.getData();
            datas['financeType'] = props.financeType;

            Api.post({
                    route: registrationEditing.id ? 'crm_prospect_registration_edit' : 'crm_prospect_registration_add',
                    data: datas,
                    params: {
                        prospect: props.match.params.id,
                        registration: registrationEditing.id
                    }
                },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoading(false);

                    if (response.status === 201) {
                        setDialog(false);
                        if (response.data && response.data.repercussion) {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Réinscription enregistrée. Attention il est possible que les logs de connexion ne sont pas récupéré en totalité.',
                                    variant: 'info',
                                })
                            );
                        }
                        else {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Inscription enregistrée.',
                                    variant: 'success',
                                })
                            );
                        }
                        if (props.info.get && !props.info.get.clientAt) {
                            props.info.get.clientAt = moment().format('lll');
                            props.info.set({...props.info.get});
                        }
                    }
                    else if (response.status === 200) {
                        setDialog(false);
                        dispatch(
                            SnackbarOpen({
                                text: 'Inscription modifiée.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                        if (response.error.message) {
                            dispatch(SnackbarOpen({text: response.error.message, variant: 'error'}));
                        }
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }

                    dispatch(TableReload('crm_prospect_registration_list'));
                    dispatch(TableReload('crm_prospect_prospect_activity_list'));
                });
        }
    };

    const changeCommercialList = (id) => {
        let prospectId = id ?? props.match.params.id;
        if (prospectId) {
            Api.get({
                    route: 'crm_prospect_commercial_list',
                    params: {id: prospectId}
                },
                (response) => {
                    if (response && response.data) {
                        setOptionCommercials(response.data);
                    }
                });
        }
    }

    return (
        <>
            <TableComponent
                noCache={true}
                sortOrder={{name: 'id', direction: 'desc'}}
                id={'crm_prospect_registration_list'}
                title={props.title}
                columns={
                    props.financeType === 6 ?
                        [
                            ...columnsRegistrations.slice(0, -1).filter(column => column.name !== "available"),
                            {
                                name: "personalPaymentTypeName",
                                label: "Type de paiement",
                                options: { filter: true, sort: true }
                            },
                            columnsRegistrations.slice(-1)[0]
                        ]
                        :
                        columnsRegistrations
                    }
                actionFirst={{
                    label: props.label,
                    onClick: () => {
                        setIsEditCommercialOnly(false);
                        setRegistrationEditing({});
                        handler.reset();
                        setIsCheck(false);
                        changeYear(parseInt(moment().format('YYYY')));
                        changeOrganism((optionOrganisms[0] ? optionOrganisms[0].value : ''), true);
                        changeCommercial( props.info.get.commercialId ? props.info.get.commercialId : '', true);
                        changeCommercialList();
                        showDialog();
                    }
                }}
                promiseData={(resolve) => {
                    if (props.match.params.id) {
                        Api.get({route: 'crm_prospect_registration_list', params: {prospect: props.match.params.id}, data: {financeType: props.financeType}}, (response) => {
                            if (response.data) {
                                let data = response.data;
                                for (let index in data) {
                                    data[index].formationName = <span style={{ width: 270, display: 'block' }}>{data[index].formationName}</span>;
                                    data[index].createdAt = moment(data[index].createdAt).format('ll');
                                    data[index].available = getCheckbox(data[index]);
                                    data[index].action = getAction(data[index]);
                                    data[index].startAt = data[index].startAt ? moment(data[index].startAt).format('ll') : '-';
                                    data[index].endAt = data[index].endAt ? moment(data[index].endAt).format('ll') : '-';
                                    data[index].availableAt = data[index].availableAt ? moment(data[index].availableAt).format('lll') : '-';
                                    data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt).format('ll') : '-';
                                    data[index].personalPaymentAt = data[index].personalPaymentAt ? moment(data[index].personalPaymentAt).format('ll') : '-';
                                    data[index].availableEndAt = data[index].availableEndAt ? moment(data[index].availableEndAt).format('ll') : '-';
                                    data[index].personalPaymentTypeName = data[index].personalPaymentTypeName ? data[index].personalPaymentTypeName : '-';

                                    switch (data[index]['state']) {
                                        case 1:
                                            data[index]['stateText'] = <span style={{color: '#28A745'}}>En cours</span>;
                                            break;
                                        case 2:
                                            data[index]['stateText'] = <span style={{color: '#17a2b8'}}>Bientôt disponible</span>;
                                            break;
                                        case 3:
                                            data[index]['stateText'] = <span style={{color: '#000000'}}>Expirée</span>;
                                            break;
                                        case 4:
                                            data[index]['stateText'] = <span style={{color: '#ffc107'}}>En attend DPC</span>;
                                            break;
                                        case 5:
                                            data[index]['stateText'] = <span style={{color: '#000000'}}>Terminée</span>;
                                            break;
                                        case 6:
                                            data[index]['stateText'] = <span style={{color: '#67327b'}}>Pré-inscription</span>;
                                            break;
                                        case 7:
                                            data[index]['stateText'] = <span style={{color: '#DC3545'}}>Désinscrit</span>;
                                            break;
                                        case 8:
                                            data[index]['stateText'] = <span style={{color: '#ffc107'}}>Attente financement personnel</span>;
                                            break;
                                        case 9:
                                            data[index]['stateText'] = <span style={{color: '#DC3545'}}>Litige stripe</span>;
                                            break;
                                    }
                                }
                                resolve(data);
                            }
                            else {
                                resolve([]);
                            }
                        })
                    }
                    else {
                        resolve([]);
                    }
                }}
            />

            <SweetAlert
                show={sweetAlertFormationGroup}
                title={'Groupe Formation'}
                text={'Vous devez choisir le groupe de formation du contact avant de pouvoir l\'inscrire à une formation.'}
                type={'warning'}
                confirmButtonText={'Ok'}
                onConfirm={() => setSweetAlertFormationGroup(false)}
            />

            <SweetAlert
                show={registrationCancel > 0}
                title={'Désinscription'}
                text={'Vous êtes sur le point de désinscription définitivement l\'inscription numéro ' + registrationCancel + '. \n\r Êtes-vous sûr de vouloir continuer ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={cancel}
                onCancel={() => setRegistrationCancel(0)}
            />

            <SweetAlert
                show={reRegistrationCancel > 0}
                title={'Résinscription'}
                text={'Vous êtes sur de vouloir réinscrire ce client à cette formation ?'}
                type={'warning'}
                showCancelButton={true}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                onConfirm={reEnrollment}
                onCancel={() => setReRegistrationCancel(0)}
            />

            <SweetAlert
                show={registrationDelete > 0}
                title={'Supprimer'}
                text={'Êtes-vous sur de vouloir supprimer cette inscription ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={remove}
                onCancel={() => setRegistrationDelete(0)}
            />

            <Dialog open={dialog} maxWidth={'xl'} onClose={() => setDialog(false)}>
                <DialogTitle style={{fontSize: 15}}>{registrationEditing.id ? isEditCommercialOnly ? 'Modifier le commercial de l\'inscription' : 'Modifier une inscription' : 'Ajouter une inscription'}</DialogTitle>
                <DialogContent style={{minWidth: '30vw'}}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={2}>
                            <SelectComponent name={'year'} handler={handler} options={optionYears} onChange={(value) => changeYear(value, true)} disabled={isEditCommercialOnly} />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <SelectComponent name={'organism'} handler={handler} options={optionOrganisms} onChange={(value) => changeOrganism(value, true)} disabled={isEditCommercialOnly}/>
                        </Grid>
                        <Grid item xs={12} sm={5}>
                            <SelectComponent name={'commercial'} handler={handler} options={optionCommercials} disabled={isCrmAdmin} onChange={(value) => changeCommercial(value, true)}/>
                        </Grid>
                        <Grid item xs={12}>
                            <SelectAutocompleteComponent name={'formation'} handler={handler} options={optionsFormation} onChange={changeFormation} disabled={isEditCommercialOnly}/>
                        </Grid>
                        {props.financeType === 6 &&
                            <>
                                <Grid item xs={12}>
                                    <SelectComponent name={'personalPaymentType'} handler={handler} options={optionsPersonalPaymentType} disabled={isEditCommercialOnly || registrationEditing.id}/>
                                </Grid>

                                {optionsPromotionCode && optionsPromotionCode.length > 0 &&
                                    <Grid item xs={12}>
                                        <SelectComponent name={'promotionCodeStripe'} nullable={true} handler={handler} options={optionsPromotionCode} disabled={isEditCommercialOnly || registrationEditing.id}/>
                                    </Grid>
                                }

                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CheckboxComponent name={'isFifpl'} handler={handler} direction={'left'} disabled={isEditCommercialOnly} />
                                </Grid>

                            </>

                        }
                        {
                            props.financeType !== 6 &&
                            <Grid item xs={12} sm={12} md={props.financeType === 4 ? 4 : 12} lg={props.financeType === 4 ? 4 : 12} xl={props.financeType === 4 ? 4 : 12}>
                                <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true} disabled={isEditCommercialOnly}/>
                            </Grid>
                        }

                        {props.financeType === 4 &&
                            <>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <DatePickerComponent name={'startCpfAt'} handler={handler}/>
                                </Grid>
                                <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                    <DatePickerComponent name={'endCpfAt'} handler={handler}/>
                                </Grid>
                            </>
                        }

                        {(registrationEditing && registrationEditing.id) &&
                            <>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <CheckboxComponent name={'available'} handler={handler} direction={'left'} disabled={isEditCommercialOnly || !hasRightAccountant} onChange={(val) => setIsCheck(val)}/>
                                </Grid>
                                {isCheck && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <DateTimePickerComponent name={'availableAt'} handler={handler} disabled={isEditCommercialOnly || !hasRightAccountant}/>
                                </Grid>}
                                {(isCheck && props.financeType === 6) && <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                    <DateTimePickerComponent name={'availableEndAt'} handler={handler} disabled={isEditCommercialOnly || !hasRightAccountant}/>
                                </Grid>}
                                {(isCheck && props.financeType === 6) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                    <DateTimePickerComponent name={'personalPaymentAt'} handler={handler} disabled={isEditCommercialOnly || !hasRightAccountant}/>
                                </Grid>}
                            </>
                        }

                    </Grid>
                </DialogContent>
                <DialogActions>
                    {
                        props.financeType !== 6 &&
                        (fullSessions ?
                            <ButtonComponent color={'#db770a'} label={'Cacher les sessions passées'} onClick={() => loadSession(false)} disabled={loading}/> :
                            <ButtonComponent color={'#28a745'} label={'Afficher les sessions passées'} onClick={() => loadSession(true)} disabled={loading}/>
                        )
                    }
                    <ButtonComponent color={'#5E6E82'} label={'Annuler'} onClick={() => setDialog(false)} disabled={loading}/>
                    <ButtonComponent label={'Enregistrer'} onClick={registration} disabled={loading}/>
                </DialogActions>
            </Dialog>

            <Dialog open={showLogs ? true : false} maxWidth={'xl'} fullWidth onClose={() => setShowLogs(false)}>
                <DialogTitle style={{fontSize: 15}}>Logs de connexion</DialogTitle>
                <DialogContent>
                    <RegistrationLogs prospect={props.match.params.id} registration={showLogs} />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent label={'OK'} onClick={() => setShowLogs(false)}/>
                </DialogActions>
            </Dialog>

            <Dialog open={showScoreAdmin ? true : false} maxWidth={'xl'} fullWidth onClose={() => setShowScoreAdmin(false)}>
                <DialogTitle style={{fontSize: 15}}>Score Admin</DialogTitle>
                <DialogContent>
                    <RegistrationScoreAdmin prospect={props.match.params.id} registration={showScoreAdmin} />
                </DialogContent>
                <DialogActions>
                    <ButtonComponent label={'OK'} onClick={() => setShowScoreAdmin(false)}/>
                </DialogActions>
            </Dialog>
        </>
    )
}


