import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { Fade, Dialog, DialogActions, DialogContent, IconButton, makeStyles, Tooltip, Box } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import Grid from "@material-ui/core/Grid";
import SelectComponent from "../../../Component/SelectComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TitleComponent from '../../../Component/TitleComponent';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LazyBackground from '../Catalog/Component/LazyBackground';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import TextFieldComponent from '../../../Component/TextFieldComponent';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import ImgSvgComponent from '../../../Component/LearnerImgSvgComponent';
import { CongratulationIcon } from '../../../Component/SvgComponent';

const Register = (props) => {
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isTall, setIsTall] = React.useState(true);
    const classes = useStyles(theme)();
    const [load, setLoad] = React.useState(false);
    const dispatch = useDispatch();
    const [loadingForm, setLoadingForm] = React.useState(false);
    const [optionsSessionFormation, setOptionsSessionFormation] = React.useState([]);
    const [activeFinanceType, setActiveFinanceType] = React.useState();
    const [formationSelected, setFormationSelected] = React.useState();
    const [personalPrice, setPersonalPrice] = React.useState(0);
    const [paymentTypeActive, setPaymentTypeActive] = React.useState();
    const [optionPersonalPaymentType, setOptionPersonalPaymentType] = React.useState([]);
    const [registerBtnLabel, setRegisterBtnLabel] = React.useState();
    const [promo, setPromo] = React.useState({ percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0 });

    const [form, setForm] = React.useState({
        sessionFormation: {
            name: 'sessionFormation',
            label: 'Session',
            textHelper: 'Choisissez une session.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        personalPaymentType: {
            name: 'personalPaymentType',
            label: 'Type de paiement',
            textHelper: 'Saisissez un type.',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        promotionCodeStripe: {
            name: 'promotionCodeStripe',
            label: 'Code promotionnel',
            textHelper: 'Entrer un code promotionel',
            type: 'text',
            defaultValue: '',
            options: {}
        }
    });

    const [registerSuccess, setRegisterSuccess] = React.useState({
        isSuccess: '',
        formationName: '',
        duration: '',
        session: '',
        financeType: '',
    });

    const handler = formHandlerInit(form, setForm);

    const loadSession = (formationId, financeType) => {
        setOptionsSessionFormation([]);
        handler.reset();
        handler.setFormLoading(true);
        setLoadingForm(true);
        Api.get({
            route: 'learner_catalog_formation_session_formation_list',
            params: { formation: formationId },
            data: { financeType: financeType.id }
        },
            (response) => {
                handler.setFormLoading(false);
                setLoadingForm(false);
                if (response && response.data) {
                    setOptionsSessionFormation(response.data);
                }
            });
    };

    const register = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            setLoadingForm(true);

            let data = handler.getData();

            Api.post({
                route: 'learner_catalog_register',
                data: { ...data, formation: formationSelected.id, financeType: parseInt(activeFinanceType) },
            },
                (response) => {
                    handler.setFormLoading(false)
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setLoadingForm(false);

                    if (response.status === 201) {
                        window.dataLayer.push({
                            'event': 'eec.add',
                            'items': {
                                'numero_formation': formationSelected.id,
                                'nom_formation': formationSelected.name,
                                'duree_formation': formationSelected.hours
                            },
                            'add_type': "réinscription"
                        })

                        if (response.data.sessionIdStripe) {
                            props.history.push(getRoutePathname('payment_stripe'), { sessionIdStripe: response.data.sessionIdStripe, clientSecret: response.data.clientSecret });
                        }
                        else {
                            setRegisterSuccess({
                                isSuccess: true,
                                formationName: formationSelected.name,
                                duration: formationSelected.hours[parseInt(activeFinanceType)] ?? formationSelected.hours['default'],
                                session: response.data.session,
                                financeType: response.data.financeType
                            });
                        }
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        if (formationSelected && formationSelected.id && theme.id === 2) {
            window.dataLayer.push({
                'event': 'eec.detail',
                'duree_formation': formationSelected.hours + " heures",
                'format_formation': "E-learning",
                'titre_formation': formationSelected.name,
                'numero_formation': formationSelected.id,
                'type_page': "catalogue",
            })
        }

    }, [formationSelected]);

    React.useEffect(() => {
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'learner_catalog_formation_get',
            params: { id: props.match.params.id }
        },
            (response) => {
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    setFormationSelected(response.data);
                    setOptionPersonalPaymentType(response.data.optionPersonalPaymentType);
                    setActiveFinanceType(response.data.financeTypes[0].id);
                    if (parseInt(response.data.financeTypes[0].id) != 6) {
                        loadSession(response.data.id, response.data.financeTypes[0]);
                    }
                    if (response.data.description) {
                        response.data.description = response.data.description.replace(/\n/g, "<br/>")
                    }
                    if (response.data.objectives) {
                        response.data.objectives = response.data.objectives.replace(/\n/g, "<br/>")
                    }
                }, 1000);
            });
    }, []);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Formation',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_catalog'), label: 'Catalogue de formations' },
            ],
            title: formationSelected && <Box zIndex={3} textAlign={'center'} borderRadius={'10px'} border={'2px solid #E6E9F0'} padding={'15px 50px'} bgcolor={'#FFF'} width={'fit-content'} margin={'10px auto -55px'}>
                <p style={{ width: '100%', padding: '0 0 10px 0', margin: 0, fontSize: 17, fontWeight: 700, color: '#5F6577' }}>{formationSelected ? formationSelected.name : ''}</p>
                <p style={{ fontSize: 13, color: '#FFF', background: theme.colorPrimary, borderRadius: 5, padding: '10px 30px', width: 'fit-content', margin: 'auto auto -25px auto', fontWeight: 'bold', boxShadow: '0px 0px 4px #00000073' }}>
                    {formationSelected.isEpp === 1 ? 'Programme intégré' : 'Formation continue'}
                </p>
            </Box>
        });
    }, [formationSelected]);

    React.useEffect(() => {
        if ((parseInt(activeFinanceType) === 6 && parseInt(paymentTypeActive) === 1)) {
            setRegisterBtnLabel('Procéder au paiement');
        }
        else {
            setRegisterBtnLabel('Je m\'inscris');
        }
        if (formationSelected) {
            setPersonalPrice(formationSelected.personalPrice['default']);
            if (paymentTypeActive) {
                setPersonalPrice(formationSelected.personalPrice[paymentTypeActive]);
            }
        }

        if (promo && promo.percent) {
            changePriceByPromo(promo.percent);
        }

    }, [paymentTypeActive, activeFinanceType]);

    const changePriceByPromo = (percent) => {
        if (formationSelected && paymentTypeActive) {
            let originalPrice = formationSelected.personalPrice[paymentTypeActive];
            let discountAmount = originalPrice * (percent / 100);
            let remisedPrice = originalPrice - discountAmount;
            setPromo({
                percent: percent,
                remisedPrice: remisedPrice,
                originalPrice: originalPrice,
                discountAmount: discountAmount
            });
        }
    };

    const applyCode = () => {
        let data = handler.getData();

        if (!data.personalPaymentType) {
            handler.setError('personalPaymentType', 'Veuillez entrer un type de paiement avant de valider');
            return;
        }

        if (data.promotionCodeStripe) {
            handler.setFormLoading(true);
            setLoadingForm(true);
            Api.get({
                route: 'stripe_promotion_code_verify',
                data: {
                    organism: theme.id,
                    code: data.promotionCodeStripe,
                    formation: formationSelected.id
                },
            },
                (response) => {
                    if (response.status === 200) {
                        changePriceByPromo(response.data.percent);
                    }
                    else if (response.status === 400) {
                        handler.setValue('promotionCodeStripe', '');
                        handler.setErrorApi(response.error);
                        setPromo({ percent: null, remisedPrice: 0, originalPrice: 0, discountAmount: 0 });
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    handler.setFormLoading(false);
                    setLoadingForm(false);
                });
        }
        else {
            handler.setError('promotionCodeStripe', 'Veuillez entrer un code avant de valider');
        }
    };

    return (

        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <div className={classes.container}>
                {formationSelected &&
                    <Grid container spacing={2} justifyContent="center">
                        <Grid item xs={12} sm={4} md={3} lg={3} xl={3} style={{ width: 'max-content', maxWidth: 'max-content' }}>
                            <Box className={classes.cardRoot}>
                                <Box className={`${classes.pictureRoot}`}>
                                    {(!load) && <img src="/asset/images/preload_image.png" alt={'Image formation'} />}
                                    {(formationSelected.image) && <img src={learnerHomeFormationImageBanner + formationSelected.image} alt={'Image formation'} onLoad={() => { setLoad(true) }} />}
                                </Box>
                                {(activeFinanceType === '1' && formationSelected.code) &&
                                    <p style={{ alignItems: 'center', display: 'flex', gap: 5, margin: '10px' }} >
                                        <span style={{ fontSize: 13, fontWeight: 500 }}>Code ANDPC</span>
                                        <IconButton style={{ color: '#FFF', background: theme.colorPrimary, borderRadius: 5, padding: '5px 15px' }} aria-label="copy" size="small"
                                            onClick={() => {
                                                if (navigator && navigator.clipboard && navigator.clipboard.writeText) {
                                                    navigator.clipboard
                                                        .writeText(formationSelected.code)
                                                        .then(r => dispatch(
                                                            SnackbarOpen({
                                                                text: 'Code copié dans le presse papier',
                                                                variant: 'success',
                                                            })
                                                        ))
                                                }
                                            }}>
                                            <span style={{ fontSize: 13, fontWeight: 500 }} >{formationSelected.code} </span>
                                            <FileCopyIcon fontSize="small" />
                                        </IconButton>
                                    </p>
                                }
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={8} md={9} lg={9} xl={9}>
                            <Box className={classes.cardRoot} style={{ textAlign: 'justify', width: 'auto', padding: '30px 70px' }}>
                                {formationSelected.description ?
                                    <p className={classes.dialogText}>
                                        <span className={classes.boxTitle}>Description : </span>
                                        <span dangerouslySetInnerHTML={{ __html: formationSelected.description }} />
                                    </p> : <></>}
                                {formationSelected.objectives ?
                                    <p className={classes.dialogText}>
                                        <span className={classes.boxTitle}>Objectifs : </span>
                                        <span dangerouslySetInnerHTML={{ __html: formationSelected.objectives }} />
                                    </p> : <></>}
                                {!formationSelected.hours && <p className={classes.dialogText} style={{ textAlign: 'left' }}><span className={classes.boxTitle}>Temps de formation</span><br />{formationSelected.hours} heures</p>}
                            </Box>
                        </Grid>
                    </Grid>
                }
                <br /><br /><br />
                <div className={classes.boxForm} style={{ background: '#fff' }}>
                    <div>
                        <p className={classes.boxFormTitle}>Choisir un mode de financement :</p>
                        <div style={{ display: 'flex', justifyContent: 'center', margin: 0 }}>
                            {formationSelected && formationSelected.financeTypes.map((financeType) =>
                                <ShadowBoxComponent
                                    key={financeType.id}
                                    className={financeType.id === activeFinanceType ? classes.buttonFinanceActive : classes.buttonFinance}
                                    style={{ width: isTall ? 100 : 85, padding: !isTall && 6, border: `2px solid ${theme.colorPrimary}` }}
                                    onClick={() => {
                                        setActiveFinanceType(financeType.id);
                                        if (parseInt(financeType.id) !== 6) {
                                            loadSession(formationSelected.id, financeType);
                                        }
                                    }}>
                                    <span>{financeType.label}</span>
                                </ShadowBoxComponent>
                            )}
                        </div>
                        <hr style={{ color: theme.colorPrimary, borderRadius: 100, width: 100, border: '2px solid' }} />
                        {activeFinanceType === '6' ?
                            <>
                                <p className={classes.boxFormTitle}>Choisir un type de paiement :</p>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <SelectComponent name={'personalPaymentType'} handler={handler} options={optionPersonalPaymentType} onChange={(val) => setPaymentTypeActive(val)} />
                                    </Grid>
                                </Grid>
                                <p className={classes.boxFormTitle}>Code promotionnel : </p>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <TextFieldComponent name={'promotionCodeStripe'} handler={handler} />
                                        <ButtonComponent style={{ margin: '10px 0', background: theme.colorPrimary, fontSize: 10 }} label="Valider le code" disabled={loadingForm} onClick={applyCode} />
                                    </Grid>
                                </Grid>
                                {formationSelected && promo && promo.percent ?
                                    <p style={{ margin: '0', fontSize: '16px', color: '#333', padding: '20px 0' }}>
                                        Prix initial : <span style={{ fontSize: '16px', fontWeight: 600 }}>{promo.originalPrice}€</span><br />
                                        Remise ({promo.percent}%) : <span style={{ fontSize: '16px', fontWeight: 600 }}> {promo.discountAmount}€</span><br />
                                        Total : <span style={{ fontSize: '16px', fontWeight: 600 }}>{promo.remisedPrice}€</span>
                                    </p>
                                    :
                                    <p style={{ fontSize: 16, margin: 0, }}>Prix : <span style={{ fontWeight: 600 }}>{personalPrice} €</span></p>
                                }
                            </>
                            :
                            <>
                                <p className={classes.boxFormTitle}>Choisir une session :</p>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                        <SelectComponent name={'sessionFormation'} handler={handler} options={optionsSessionFormation} nullable={true} />
                                    </Grid>
                                </Grid>
                            </>
                        }
                        <br />
                        <div style={{ width: '95%', display: 'flex', justifyContent: 'flex-end' }}>
                            <ButtonComponent className={classes.button} color={'#5E6E82'} label={'Revenir au catalogue'} onClick={() => {
                                props.history.push(getRoutePathname('learner_catalog'))
                            }} disabled={loadingForm} />
                            <ButtonComponent className={classes.button} color={theme.colorPrimary} label={registerBtnLabel} onClick={register} disabled={loadingForm} />
                        </div>
                    </div>
                    {(screenReducer.size >= 600) &&
                        <>
                            <ImgSvgComponent style={{ height: '250%', left: 0, transform: 'translate(-51%,-58%)' }} />
                            <ImgSvgComponent style={{ height: '250%' }} />
                        </>
                    }
                </div>
            </div>

            <Dialog
                open={registerSuccess.isSuccess}
                maxWidth={registerSuccess.financeType === 1 ? 'xl' : 'sm'}
                onClose={() => {
                    setRegisterSuccess({
                        isSuccess: false,
                        formationName: '',
                        duration: '',
                        session: ''
                    });
                    props.history.push(getRoutePathname('learner_catalog'));
                }}>
                <DialogContent style={{ color: '#5E6E82', padding: '20px 24px 8px 24px' }}>
                    <CongratulationIcon />
                    <h1 style={{ color: theme.colorPrimary, textAlign: 'center', margin: '20px 0', fontSize: 40 }}>Félicitations !</h1>
                    <div style={{ textAlign: registerSuccess.financeType === 1 ? 'left' : 'center' }}>
                        {registerSuccess.financeType === 1 ?
                            <>
                                <p>Votre inscription à la formation <span className={classes.boxTitle} style={{ fontSize: 'initial' }} >{registerSuccess.formationName}</span> d’une durée de {registerSuccess.duration} heures à bien été prise en compte mais n’est pas encore finalisée.</p>
                                <p>Afin de la compléter, veuillez suivre les étapes suivantes : </p>
                                <ol>
                                    <li>Rendez-vous sur: <a target="_blank" className={classes.boxTitle} style={{ fontSize: 'initial' }} href='https://www.agencedpc.fr/professionnel/login'>https://www.agencedpc.fr/professionnel/login</a>, connectez-vous ou créez un compte.</li>
                                    <li>Cliquez sur la rubrique « Inscription »</li>
                                    <li>Dans le menu à gauche, cliquez sur « Rechercher actions »</li>
                                    <li>Dans le champ « Référence de l’actions » copiez/collez le code suivant :
                                        <span className={classes.boxTitle} style={{ fontSize: 'initial' }}>
                                            {formationSelected.code}
                                            <Tooltip title="Copier le code">
                                                <IconButton aria-label="copy" size="small"
                                                    onClick={() => {
                                                        navigator.clipboard.writeText(formationSelected.code);
                                                        dispatch(
                                                            SnackbarOpen({
                                                                text: 'Code copié dans le presse papier',
                                                                variant: 'success',
                                                            })
                                                        );
                                                    }}>
                                                    <FileCopyIcon fontSize="small" />
                                                </IconButton>
                                            </Tooltip>
                                        </span>
                                    </li>
                                    <li>Cliquez sur « Détail action de DPC » puis sélectionnez une session et finalisez votre inscription.</li>
                                </ol>
                                <p className={classes.boxTitle} style={{ fontSize: 'initial' }}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.<br />Notre équipe reste à votre disposition pour tout renseignement complémentaire. </p>
                            </>
                            :
                            <>
                                <p>Votre inscription à la formation <span className={classes.boxTitle} style={{ fontSize: 'initial' }}>{registerSuccess.formationName}</span> d’une durée de {registerSuccess.duration} heures à bien été prise en compte.</p>
                                <p>Un courriel vous a été envoyé contenant toutes les informations nécessaires à la finalisation de votre inscription.</p>
                                <p className={classes.boxTitle} style={{ fontSize: 'initial' }}>{theme.name} vous remercie pour votre confiance et vous souhaite une excellente formation.</p>
                            </>
                        }
                    </div>
                </DialogContent>
                <DialogActions style={{ padding: '8px 24px 20px 24px' }}>
                    <ButtonComponent color={'#5E6E82'} label={'Revenir au catalogue'}
                        onClick={() => {
                            setRegisterSuccess({
                                isSuccess: false,
                                formationName: '',
                                duration: '',
                                session: '',
                                financeType: ''
                            });
                            props.history.push(getRoutePathname('learner_catalog'));
                        }} />
                    <ButtonComponent label={'Aller au tableau de bord'} color={theme.colorPrimary} onClick={() => {
                        props.history.push(getRoutePathname('learner_home'))
                    }} />
                </DialogActions>
            </Dialog>
        </ContentViewLearnerComponent>
    );
};

const useStyles = (theme) => makeStyles(({
    boxFormTitle: {
        fontSize: 15,
        fontWeight: 600,
        margin: 0,
        color: theme.colorPrimary
    },
    pictureRoot: {
        background: '#393939',
        width: 230,
        height: 160,
        borderRadius: 11,
        position: 'relative',
        overflow: 'hidden',
        margin: 10,
        '& img': {
            position: 'absolute',
            width: '120%',
            transform: 'translate(-50%,-50%)',
            top: '50%',
            left: '50%',
        }
    },
    cardRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 10,
        display: 'grid',
        padding: 5,
        width: 'max-content',
        background: '#FFF',
        alignItems: 'center',
    },
    MuiOutlinedInput: {
        '& .MuiOutlinedInput-root': {
            borderRadius: '20px !important',
        },
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'left',
        background: '#4f93a5',
        width: 'max-content',
        padding: '7px 20px'
    },
    boxTitle: {
        fontSize: 17,
        fontWeight: 700,
        color: theme.colorPrimary,
        margin: '2px 0',
        display: 'block'
    },
    cardHour: {
        width: 'max-content',
        fontSize: 12,
        alignItems: 'center',
        display: 'flex',
        gap: 5
    },
    cardHourIcon: {
        fontSize: 18,
        color: '#5F6577'
    },
    dialogText: {
        fontSize: 13,
        width: '100%',
        '& p, h1, h2, h3': {
            margin: 0,
        }
    },
    buttonFinance: {
        margin: '0px 10px',
        padding: 7,
        textAlign: 'center',
        cursor: 'pointer',
        color: theme.colorPrimary,
        fontWeight: 600,
        fontSize: 12,
        transition: 'all .4s !important',
        boxShadow: 'none',
        '&:hover': {
            backgroundColor: theme.colorPrimary,
            color: '#FFFFFF'
        }
    },
    buttonFinanceActive: {
        margin: '0px 10px',
        padding: 7,
        textAlign: 'center',
        cursor: 'pointer',
        fontSize: 12,
        fontWeight: 600,
        backgroundColor: theme.colorPrimary,
        boxShadow: 'none',
        color: '#FFFFFF'
    },
    dialogPaper: {
        borderRadius: 0,
        width: '100%',
        height: '100%'
    },
    boxForm: {
        margin: 'auto',
        fontSize: 13,
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        padding: 25,
        background: '#FFF',
        position: 'relative',
        overflow: 'hidden',
        '& >div': {
            zIndex: 1,
            position: 'relative',
            width: '100%',
            flexDirection: 'column',
            gap: 10,
            alignItems: 'center',
            display: 'flex',
            '& .MuiInputBase-root': {
                background: '#FFF'
            }
        }
    },
    container: {
        width: '100%',
        padding: '50px 0 0 0',
        boxSizing: 'border-box',
    },
    circle: {
        width: 180,
        height: 180,
        borderRadius: 10,
    },
    button: {
        fontSize: 10,
        margin: 3
    }
}));

export default Register;
