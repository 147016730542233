import React from 'react';
import { Box, Fade, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, makeStyles } from "@material-ui/core";
import { Dialog, DialogActions, DialogContent } from "@material-ui/core";
import ButtonComponent from "../../../../../../Component/ButtonComponent";
import Api from "../../../../../../Api";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../../Action/LinearProgressMainLayoutAction";
import { formHandlerInit } from '../../../../../../Tool/FormHandlerCommon';
import DatePickerComponent from '../../../../../../Component/DatePickerComponent';
import TextFieldComponent from '../../../../../../Component/TextFieldComponent';
import { dispatch } from '../../../../../../App';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import SelectComponent from '../../../../../../Component/SelectComponent';
import moment from 'moment';

function Invoice(props) {
    const classes = useStyles();
    const registration = props.registration;
    const [loading, setLoading] = React.useState(true);
    const [totalTTC, setTotalTTC] = React.useState(0);
    const [tva, setTva] = React.useState(props?.sessionFormation?.tva ?? 0);
    const [price, setPrice] = React.useState(registration.initialAmount ?? '0');
    const [formInvoiceAt, setFormInvoiceAt] = React.useState({
        invoiceAt: {
            name: 'invoiceAt',
            label: 'Date de la facture',
            textHelper: 'Saisissez la date de la facture',
            type: 'date',
            defaultValue: moment(),
            options: { validation: ['required', 'date'] }
        },
        destinationType: {
            name: 'destinationType',
            label: 'Destination',
            textHelper: '',
            type: 'integer',
            defaultValue: 2,
            value: 2,
            options: {},
        },
        destinationFor: {
            name: 'destinationFor',
            label: 'Autre',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: {},
        }
    });
    const handlerInvoiceAt = formHandlerInit(formInvoiceAt, setFormInvoiceAt);
    React.useEffect(handlerInvoiceAt.start, []);
    const [form, setForm] = React.useState({
        amount: {
            name: 'amount',
            label: 'Total HT',
            textHelper: '',
            type: 'float',
            defaultValue: registration.initialAmount ?? '0',
            options: { validation: ['required'] },
        },
        tva: {
            name: 'tva',
            label: 'TVA(%)',
            type: 'float',
            defaultValue: props?.sessionFormation?.tva ?? 0,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            dispatch(LinearProgressMainLayoutActivate());
            handler.setFormLoading(true);
            setLoading(true);
            let data = {
                ...handler.getData(),
                contract: registration.contractId,
                registration: registration.id,
                institutionInvoiceRegistrationContract: registration.institutionInvoiceRegistrationContract
            }
            Api.post({
                route: 'institution_health_financial_management_billing_session_formation_invoice',
                params: { sessionFormation: props.sessionFormation.id },
                data: {
                    lines: [data],
                    invoiceAt: handlerInvoiceAt.getData().invoiceAt,
                    type: 4,
                    destinationType: handlerInvoiceAt.getData().destinationType
                }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    handler.setFormLoading(false);
                    setLoading(false);

                    if (response.status === 200) {
                        props.close();
                        props.reloadList();
                        dispatch(
                            SnackbarOpen({
                                text: 'Session formation facturée.',
                                variant: 'success',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    };

    React.useEffect(() => {
        const tvaPrice = (parseFloat(price) * (parseFloat(tva) / 100)).toFixed(2);
        let ttc = parseFloat(price) + parseFloat(tvaPrice);
        setTotalTTC(ttc);
    }, [price, tva]);

    React.useEffect(() => { setLoading(false) }, []);
    return (
        <>
            <Dialog open={props.open} className={classes.dialogStyle} maxWidth={'xl'} onClose={props.close}>
                <Box style={{ display: 'grid', gap: 10, gridTemplateColumns: 'auto', padding: 10 }}>
                    <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ fontSize: 15, color: '#5E6E82', }}>
                            <Box style={{ display: 'grid', gridTemplateColumns: 'max-content auto', alignItems: 'center', gap: 10 }}>
                                <h1 style={{ fontSize: 20, margin: 0 }}>Facture - {registration?.firstname} {registration?.lastname}</h1>
                                <hr style={{ margin: 0, opacity: 0.5 }} />
                            </Box>
                            <Box style={{ padding: '10px 20px' }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DatePickerComponent name={'invoiceAt'} handler={handlerInvoiceAt} />
                                        <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content auto', alignItems: 'center', gap: 10 }}>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                            <h1 style={{ fontSize: 13, margin: 0 }}>À destination de</h1>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <DestinationAction registration={registration} handler={handlerInvoiceAt} {...props} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={9} lg={9}>
                                        <TextFieldComponent name={`amount`} handler={handler} disabled={true} onChange={(price) => setPrice(price == '' ? 0 : price)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={3} lg={3}>
                                        <TextFieldComponent name={`tva`} handler={handler} disabled={true} onChange={(tva) => setTva(tva == '' ? 0 : tva)} />
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content auto', alignItems: 'center', gap: 10 }}>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                            <h1 style={{ fontSize: 13, margin: 0 }}>Total TTC : {totalTTC.toFixed(2)}€</h1>
                                            <hr style={{ margin: 0, opacity: 0.5 }} />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Box>
                        <DialogActions>
                            <ButtonComponent disabled={loading} onClick={props.close} color={'#5E6E82'} label={'Annuler'} />
                            <ButtonComponent disabled={loading} onClick={save} label={'Générer la facture'} />
                        </DialogActions>
                    </Box>
                </Box>
            </Dialog>
        </>
    );
}

const DestinationAction = (props) => {
    const classes = useStyles();
    const handler = props.handler;
    const [destination, setDestination] = React.useState(1);

    const option = [
        { value: 1, label: 'À l\'apprennent' },
        { value: 2, label: 'À l\'établissement' },
        { value: 3, label: 'L\'établissement a l\'intention du prospect' }
        //{ value: 4, label: 'Autre' }
    ];

    const onChange = (value) => {
        setDestination(value);
        handler.form[`destinationFor`].options = value == 4 ? { validation: ['required'] } : {}
    }

    React.useEffect(() => {
        setDestination(2);
        handler.setValue(`destinationType`, 2)
    }, [props.open]);

    return (
        <Box className={classes.destinationRoot}>
            {destination != 4 ? <p style={{ textAlign: 'center', fontWeight: 'bold' }}>{option[destination - 1].label}</p> : <TextFieldComponent name={`destinationFor`} handler={handler} />}
            <div className={!props.disabled ? 'select-root' : 'selectOff-root'}>
                <SelectComponent name={`destinationType`} handler={handler} options={option} onChange={(value) => { onChange(value) }} disabled={props.disabled} />
            </div>
        </Box>
    )
}

const useStyles = makeStyles({
    th: {
        fontWeight: 900,
        fontSize: 15,
        padding: 12,
        textAlign: 'center',
        color: '#5E6E82',
    },
    td: {
        fontSize: 13,
        padding: 5,
        textAlign: 'center',
        color: '#5E6E82',
        '& .MuiFormHelperText-root': {
            display: 'none'
        }
    },
    selectPrice: {
        display: 'flex',
        alignItems: 'center',
        gap: 5,
        margin: '0 0 10px 0',
        '& p': {
            textAlign: 'center',
            width: '100%',
            margin: 0,
            background: 'transparent',
            border: '2px solid #6D7C8E54',
            borderRadius: 5,
            color: '#6D7C8E',
            cursor: 'pointer',
            padding: '5px 0px',
            transition: 'all .5s',
            '&:hover': {
                background: '#6D7C8E54 !important',
                color: '#6D7C8E !important',
            }
        }
    },
    destinationRoot: {
        display: 'grid',
        gridTemplateColumns: 'auto max-content',
        '& p': {
            padding: '7.1px 14px',
            fontSize: 13,
            margin: 0,
            border: '1px solid rgba(0, 0, 0, 0.23)',
            borderRadius: '5px 0px 0px 5px'
        },
        '& .MuiFormHelperText-root': {
            display: 'none'
        },
        '& .MuiTextField-root': {
            width: 170
        },
        '& fieldset': {
            borderRadius: '5px 0px 0px 5px'
        },
        '& .selectOff-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E823B !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
        '& .select-root': {
            '& label, legend': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
                opacity: 0
            },
            '& fieldset': {
                top: 0,
                borderRadius: '0px 5px 5px 0px',
                borderColor: '#5E6E82 !important'
            },
            '& .MuiFormControl-root': {
                width: 38
            },
        },
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 500,
            padding: 17
        },
        '& .MuiDialogContent-root': {
            overflowY: 'initial'
        },
        '& #deposit': {
            textAlign: 'center'
        }
    },
    containerCheckbox: {
        display: 'flex',
        justifyContent: 'space-around'
    },
    priceRoot: {
        width: '100%',
        color: '#6D7C8E',
        textAlign: 'center',
        '& tbody': {
            position: 'relative',
            '& td:nth-of-type(1)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '&  tr:first-of-type td:nth-of-type(1)': {
                borderRadius: '10px 10px 0 0',
            },
            '& tr:last-of-type td:nth-of-type(1)': {
                borderRadius: '0 0 10px 10px',
            },
            '& td': {
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& select': {
            padding: '8.4px 14px !important',
        },
        '& .MuiFormControl-root': {
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& input': {
                padding: 8,
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
    },
});

export default Invoice;
