import React from "react";
import { useDispatch } from "react-redux";
import { Box, makeStyles } from "@material-ui/core";
import { theme } from "../../../../App";
import BreadcrumbsComponent from "../../../../Component/BreadcrumbsComponent";
import { getRoutePathname } from "../../../../Config/Route";
import ViewManagerComponent from "../../../InstitutionHealth/CRM/InstitutionManager/Component/ViewManagerComponent";
import ContentViewLearnerComponent from "../../../../Component/ContentViewLearnerComponent";

const InstitutionManager = (props) => {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Mon établissement',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_institution_list'), label: 'Mes établissements' },
            ]
        });
    }, []);

    return (
        <ContentViewLearnerComponent loading={false} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <Box className={classes.styleBreadcrumbs} >
                <ViewManagerComponent isLearner={true} {...props} />
            </Box>
        </ContentViewLearnerComponent>
    )
}

const useStyles = (theme) => makeStyles(({
    styleBreadcrumbs: {
        margin: 'auto !important',
        maxWidth: 1240,
        '& .loaderContent': {
            transform: 'translateY(50%) !important',
        }
    },
}));

export default InstitutionManager;