import Api from "../../../Api";
import { Box, Fade, makeStyles } from "@material-ui/core";
import React from "react";
import activity from "react-useanimations/lib/activity";
import ButtonComponent from "../../../Component/ButtonComponent";
import UseAnimations from "react-useanimations";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import { dispatch } from "../../../App";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";

const FormationChapterCourseTimer = (props) => {
    const classes = useStyles();
    const [listChapters, setListChapters] = React.useState([]);
    const [totalTimer, setTotalTimer] = React.useState({ duration: 0 });
    const [loading, setLoading] = React.useState(false);
    const [timerChapters, setTimerChapters] = React.useState([]);
    const [form, setForm] = React.useState({});
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            const data = handler.form;
            let newData = convertedData(data);
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            Api.post({
                route: 'content_manager_formation_formation_chapter_timer',
                data: { list: newData },
                params: { formation: props.match.params.id }
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    props.getChapter()
                    dispatch(
                        SnackbarOpen({
                            text: 'Temps modifié.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }
    const onChangeValue = (obj) => {
        const data = handler.form;
        const baseName = obj.name.split('-').slice(0, -1).join('-');
        const updatedData = {};

        for (let key in data) {
            if (key.startsWith(baseName)) {
                updatedData[key] = key === obj.name
                    ? { ...data[key], value: obj.duration }
                    : { ...data[key] };
            }
        }

        const durationInSeconds = Object.keys(updatedData).reduce((total, key) => {
            let value = parseInt(updatedData[key].value);
            if (key.endsWith('-h')) {
                return total + value * 3600;
            } else if (key.endsWith('-m')) {
                return total + value * 60;
            } else if (key.endsWith('-s')) {
                return total + value;
            }
            return total;
        }, 0);

        const newObj = { courseId: obj.courseId, duration: durationInSeconds };
        setTimerChapters((prev) => {
            const updatedChapters = { ...prev };
            if (updatedChapters[obj.id]) {
                updatedChapters[obj.id] = updatedChapters[obj.id].map(course => {
                    if (course.courseId === newObj.courseId) {
                        return { ...course, duration: newObj.duration };
                    }
                    return course;
                });
            }
            setTotalTimer(() => {
                const allCourses = Object.values(updatedChapters).flat();
                const totalDuration = allCourses
                    .filter(course => course.duration !== null)
                    .reduce((sum, course) => sum + course.duration, 0);

                return { duration: totalDuration }
            });
            return updatedChapters;
        });
    }

    const getChapter = () => {
        if (props.match.params.id) {
            Api.get({
                route: 'content_manager_formation_formation_chapter_list',
                data: { type: 'timer' },
                params: { formation: props.match.params.id }
            }, (response) => {
                if (response.status === 200) {
                    let data = response.data;
                    setListChapters(data)
                    for (let index in data) {
                        setTimerChapters((prev) => {
                            return {
                                ...prev,
                                [data[index].id]: data[index].courses
                            };
                        });
                    }
                }
            })
        }
    }

    const convertedData = (data) => {
        const courseDurations = {};
        const totalDurationsByChapter = {};

        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const { courseId, chapterCourseId, value } = data[key];
                const type = key.split('-').slice(-1)[0];
                const baseKey = key.replace(/-[hms]$/, '');

                if (!courseDurations[baseKey]) {
                    courseDurations[baseKey] = { duration: 0, courseId, chapterCourseId };
                }

                if (!totalDurationsByChapter[chapterCourseId]) {
                    totalDurationsByChapter[chapterCourseId] = 0;
                }

                let secondsToAdd = 0;
                if (type === 'h') {
                    secondsToAdd = value * 3600;
                } else if (type === 'm') {
                    secondsToAdd = value * 60;
                } else if (type === 's') {
                    secondsToAdd = parseInt(value);
                }

                courseDurations[baseKey].duration += secondsToAdd;
                totalDurationsByChapter[chapterCourseId] += secondsToAdd;
            }
        }

        const newConvertedData = {
            course: courseDurations,
            totalsByChapter: totalDurationsByChapter,
        };

        return newConvertedData;
    }

    const convertedDuration = (data) => {
        const duration = data.duration;
        const hours = Math.floor(duration / 3600);
        const minutes = Math.floor((duration % 3600) / 60);
        const seconds = duration % 60;

        return { hours: hours, minutes: minutes, seconds: seconds }
    }

    React.useEffect(() => {
        if (listChapters.length !== 0) {
            setForm((prevData) => {
                console.log(listChapters)
                const newData = listChapters.reduce((acc, chapter) => {
                    chapter.courses.forEach(course => {
                        const keyH = `order-${chapter.order}-id-${course.courseId}-course-duration-h`;
                        const duration = convertedDuration(course);
                        acc[keyH] = {
                            name: keyH,
                            label: 'Heure',
                            textHelper: 'Saisissez la duration du cours.',
                            type: 'integer',
                            value: duration.hours != 0 ? duration.hours : "0",
                            courseId: course.id,
                            chapterCourseId: chapter.id,
                            options: { validation: ['required'] }
                        };
                        const keyM = `order-${chapter.order}-id-${course.courseId}-course-duration-m`;
                        acc[keyM] = {
                            name: keyM,
                            label: 'Minute',
                            textHelper: 'Saisissez la duration du cours.',
                            type: 'integer',
                            value: duration.minutes != 0 ? duration.minutes : "0",
                            courseId: course.id,
                            chapterCourseId: chapter.id,
                            options: { validation: ['required'], max: 60 }
                        };
                        const keyS = `order-${chapter.order}-id-${course.courseId}-course-duration-s`;
                        acc[keyS] = {
                            name: keyS,
                            label: 'Seconde',
                            textHelper: 'Saisissez la duration du cours.',
                            type: 'integer',
                            value: duration.seconds != 0 ? duration.seconds : "0",
                            courseId: course.id,
                            chapterCourseId: chapter.id,
                            options: { validation: ['required'], max: 60 }
                        };
                    });
                    return acc;
                }, {});
                return { ...prevData, ...newData };
            });

            setTotalTimer(() => {
                const allChapters = Object.values(listChapters).flat();
                const totalDurationChapters = allChapters
                    .filter(course => course.duration !== null)
                    .reduce((sum, course) => sum + course.duration, 0);

                return { duration: totalDurationChapters }
            });
        }
    }, [listChapters]);

    React.useEffect(() => {
        if (Object.keys(handler.form).length != 0) {
            setLoading(false);
        }
    }, [handler]);

    React.useEffect(() => {
        setLoading(true);
        getChapter();
    }, []);

    return (
        <Box style={{ padding: 0, width: '95%', height: '100%', color: 'rgb(94, 110, 130)', display: 'grid', gridTemplateRows: 'max-content auto max-content', gap: 14 }}>
            <p style={{ width: 'fit-content', margin: 0, fontSize: 22, fontWeight: 'bolder', padding: '15px 0 0 0' }}>Gestion du temps | <span style={{ fontSize: 15, fontWeight: 'initial' }}>Durée total : {(totalTimer && totalTimer.duration >= 0) ? convertedDuration(totalTimer).hours + 'heure ' + convertedDuration(totalTimer).minutes + 'm ' + convertedDuration(totalTimer).seconds + 's' : 'Champs manquants'}</span></p>
            <Box style={{ background: '#EFF2F4', borderRadius: 15, border: '1px solid rgba(94, 110, 130, 0.18)', overflow: 'hidden auto', position: 'relative' }}>
                <div style={{ display: 'flex', gap: 20, flexDirection: 'column', padding: 20 }}>
                    {loading ?
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Box className={classes.loader} style={{ width: '100%', height: '100%', display: 'flex', position: 'absolute', top: 0, left: 0 }}>
                                <Box className={classes.loaderContent}>
                                    <Box className={classes.loaderActivity}>
                                        <UseAnimations animation={activity} size={70} />
                                    </Box>
                                </Box>
                            </Box>
                        </Fade>
                        : listChapters.map((chapter, index) => {
                            const totalDuration = { duration: timerChapters[chapter.id] ? timerChapters[chapter.id].reduce((sum, current) => sum + current.duration, 0) : null };
                            const chapterDuration = (totalDuration && totalDuration.duration >= 0) ? convertedDuration(totalDuration).hours + 'heure ' + convertedDuration(totalDuration).minutes + 'm ' + convertedDuration(totalDuration).seconds + 's' : 'Champs manquants';
                            return (
                                <Box key={index} style={{ background: 'rgb(255, 255, 255)', padding: 30, borderRadius: 10, display: 'flex', flexDirection: 'column', gap: 18 }}>
                                    <p style={{ fontWeight: ' bold', margin: 0 }}>{chapter.name ? chapter.name : chapter.chapterName} | <span style={{ fontSize: 15, fontWeight: 'initial' }}>Durée du chapitre : {chapterDuration}</span></p>
                                    {chapter.courses.length !== 0 &&
                                        < div style={{ margin: 'auto', display: 'flex', flexDirection: 'column', gap: 10, width: '95%' }}>
                                            {chapter.courses.map((course, index) => (
                                                <p key={index} style={{ display: 'block', margin: 0, padding: 10, border: '2px solid rgba(94, 110, 130, 0.31)', borderRadius: 5, display: 'grid', alignItems: 'center', gridTemplateColumns: 'auto 270px' }}>
                                                    <span>{course.name ? course.name : course.course}</span>
                                                    <span className={classes.contnaireTextField}>
                                                        <TextFieldComponent name={'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-h'} handler={handler} onChange={(value) => { onChangeValue({ id: chapter.id, courseId: course.courseId, duration: value, name: 'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-h' }) }} disabled={loading} />
                                                        <TextFieldComponent name={'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-m'} handler={handler} onChange={(value) => { onChangeValue({ id: chapter.id, courseId: course.courseId, duration: value, name: 'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-m' }) }} disabled={loading} />
                                                        <TextFieldComponent name={'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-s'} handler={handler} onChange={(value) => { onChangeValue({ id: chapter.id, courseId: course.courseId, duration: value, name: 'order-' + chapter.order + '-id-' + course.courseId + '-course-duration-s' }) }} disabled={loading} />
                                                    </span>
                                                </p>
                                            ))}
                                        </div>
                                    }
                                </Box>
                            )
                        })}
                </div>
            </Box >
            <Box style={{ alignItems: 'center', justifyContent: 'end', display: 'flex', gap: 5, padding: '0px 0 15px 0' }}>
                <ButtonComponent label={'Annuler'} color={'rgb(94, 110, 130)'} onClick={() => { props.setOpen(false) }} />
                <ButtonComponent label={'Enregistrer'} onClick={() => { save() }} disabled={loading} />
            </Box>
        </Box >
    )
}
const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    contnaireTextField: {
        display: 'flex',
        gap: 5,
        '& .MuiFormHelperText-root': {
            display: 'none'
        },
        '& input': {
            textAlign: 'center'
        }
    }
})
export default FormationChapterCourseTimer;