import { Box, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, Fade, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../../Action/LinearProgressMainLayoutAction";
import React from "react";
import Api from "../../../../../Api";
import { dispatch } from "../../../../../App";
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { SnackbarOpen } from "../../../../../Action/SnackbarAction";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import SelectComponent from "../../../../../Component/SelectComponent";
import TextFieldComponent from "../../../../../Component/TextFieldComponent";
import { formHandlerInit } from "../../../../../Tool/FormHandlerCommon";
import ButtonComponent from "../../../../../Component/ButtonComponent";
import { getRoutePathname } from "../../../../../Config/Route";
import activity from "react-useanimations/lib/activity";
import UseAnimations from "react-useanimations";

const InvoiceDialogComponent = (props) => {
    const classes = useStyles();
    const [invoices, setInvoices] = React.useState([]);
    const [isExpand, setIsExpand] = React.useState(true);
    const [invoicesAfter, setInvoicesAfter] = React.useState([]);
    const [creditBalance, setCreditBalance] = React.useState({});
    const [balanceUse, setBalanceUse] = React.useState(0);
    const [showConfirmValid, setShowConfirmValid] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    const getInvoicePrepare = () => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true)
        if (props.match.params.institution) {
            Api.get({
                route: 'institution_health_financial_management_billing_institution_contract_invoice_prepare',
                params: { institution: props.match.params.institution, contract: props.match.params.id }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false)
                if (response.status === 200) {
                    let data = response.data;
                    setInvoices(data.invoicesToAdd)
                    setInvoicesAfter(data.registrationsAfter)
                } else {
                    props.setDialog(false)
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                        })
                    );
                }
            });
        }
    }

    const save = () => {
        if (balanceUse < 0) {
            dispatch(
                SnackbarOpen({
                    text: 'Attention, ajustez les soldes utilisés.',
                    variant: 'warning',
                })
            );
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);

            let postData = {
                route: 'institution_health_financial_management_billing_institution_contract_invoice_add',
                data: { invoicesToAdd: invoices, invoicesAfter: invoicesAfter },
                params: { institution: props.match.params.institution, contract: props.match.params.id },
                text: 'Facturer.'
            }

            Api.post({
                route: postData.route,
                data: postData.data,
                params: postData.params
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                if (response.status === 200) {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_contract_invoice_list', { institution: props.match.params.institution, id: props.match.params.id }));
                    dispatch(
                        SnackbarOpen({
                            text: postData.text,
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    }

    React.useEffect(() => {
        if (props.dialog) {
            getInvoicePrepare()
        }
    }, [props.dialog]);

    React.useEffect(() => {
        setBalanceUse((props.institution.creditBalance ?? 0) - Object.values(creditBalance).map(Number).reduce((acc, val) => acc + val, 0))
    }, [props.institution.creditBalance, creditBalance]);

    return (
        <>
            <Dialog open={props.dialog} className={classes.dialogStyle} onClose={() => { props.setDialog(false); setInvoices([]) }} maxWidth={'xl'}>
                <DialogTitle style={{ fontSize: 15, fontWeight: 'bold', color: '#5E6E82', }}>
                    <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <span>Prévisualisation des factures</span>
                        <span style={{ lineHeight: '14px', textAlign: 'right' }}>
                            Solde : {props.institution.creditBalance ?? 0}€ <br />
                            <span style={{ fontSize: 12 }}>
                                Solde restant : <span style={{ color: (balanceUse < 0) ? '#E05059' : '' }}>{balanceUse.toFixed(2)}€</span>
                            </span>
                        </span>
                    </span>
                </DialogTitle>
                <DialogContent >
                    <table className={classes.tableRoot}>
                        {(invoices.length != 0) &&
                            <thead>
                                <tr>
                                    <th colSpan={2}>Facturation</th>
                                    <th>Montant HT</th>
                                    <th>Montant TVA</th>
                                    <th>Montant TTC</th>
                                    <th style={{ width: 250 }}>Destination</th>
                                    <th style={{ width: 118 }}>Solde créditeur</th>
                                </tr>
                            </thead>
                        }
                        {loading ?
                            <Fade in={true} {...{ timeout: 1000 }}>
                                <Box className={classes.loader}>
                                    <Box className={classes.loaderContent}>
                                        <Box className={classes.loaderActivity}>
                                            <UseAnimations animation={activity} size={70} />
                                        </Box>
                                    </Box>
                                </Box>
                            </Fade> :
                            <>
                                {(invoices.length != 0) &&
                                    Object.keys(invoices).map((key, i) => (
                                        <tbody key={key} style={{ position: 'relative' }}>
                                            <TbodyComponent
                                                index={key}
                                                name={`Facture-N°${i + 1}`}
                                                invoice={invoices[key]}
                                                setCreditBalance={setCreditBalance}
                                                disabled={props.institution.creditBalance === 0 || props.institution.creditBalance === null}
                                                setInvoices={setInvoices}
                                            />
                                        </tbody>
                                    ))
                                }
                                {(invoicesAfter.length != 0) &&
                                    <tbody style={{ position: 'relative' }}>
                                        <tr>
                                            <td style={{ width: 45 }}>
                                                <IconButton onClick={() => setIsExpand(!isExpand)} style={{ borderRadius: 5, padding: 5, width: '100%', background: '#5e6e8263' }}>
                                                    <ExpandLessIcon style={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)', color: '#FFF', transition: 'all .4s' }} />
                                                </IconButton>
                                            </td>
                                            <td colSpan={10}><b>Facturation après formation</b></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={10} style={{ padding: 0 }}>
                                                {isExpand &&
                                                    <table className={classes.tableRoot2}>
                                                        <thead>
                                                            <tr>
                                                                <th>Nom/Prénom</th>
                                                                <th style={{ minWidth: 145 }}>Formation</th>
                                                                <th>Financement</th>

                                                                <th>CP Établissement</th>
                                                                <th>CP Organisme</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {invoicesAfter.map((data, i) => (
                                                                <tr key={i}>
                                                                    <td>{`${data.prospectName ?? ""}`}</td>
                                                                    <td>
                                                                        <Tooltip title={data.formationName ?? ""}>
                                                                            <p className={'formationName'}>{`${data.formationName ?? "-"}`}</p>
                                                                        </Tooltip>
                                                                    </td>
                                                                    <td><b>{data.financeTypeName ?? '-'}</b></td>
                                                                    <td>
                                                                        {(data.feeInstitutionAfter && data.feeInstitutionAfter != 0) ? data.feeInstitutionAfter : '0'} €
                                                                    </td>
                                                                    <td>
                                                                        {(data.feeSupport && data.feeSupport != 0) ? data.feeSupport : '0'} €
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                }
                                            </td>
                                        </tr>

                                    </tbody>
                                }
                                {(invoices.length === 0 && invoicesAfter.length === 0) &&
                                    <tbody>
                                        <tr>
                                            <td colSpan={10} style={{ position: 'initial', padding: 0 }}>
                                                <p className={classes.error}>Aucune facture</p>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </>
                        }
                    </table>
                </DialogContent>
                <DialogActions>
                    <ButtonComponent onClick={() => { props.setDialog(false); setInvoices([]) }} color={'#5E6E82'} label={'Annuler'} />
                    <ButtonComponent disabled={loading} onClick={() => setShowConfirmValid(true)} color={'#1c7d32'} label={'Valider le(s) facture(s)'} />
                </DialogActions>
            </Dialog >
            <SweetAlert
                show={showConfirmValid}
                title={'Validation des factures'}
                text={'Êtes-vous sûr de vouloir valider ces factures ?'}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => { save(); setShowConfirmValid(false); }}
                onCancel={() => setShowConfirmValid(false)}
            />
        </>
    )
}

const TbodyComponent = (props) => {
    let data = props.invoice;
    const [isExpand, setIsExpand] = React.useState(true);
    const classes = useStyles();
    const [destination, setDestination] = React.useState(2);
    const destinationOption = [
        { value: 1, label: 'À l\'apprennent' },
        { value: 2, label: 'À l\'établissement' },
        { value: 3, label: 'L\'établissement a l\'intention du prospect' }
    ];

    const [form, setForm] = React.useState({
        creditBalance: {
            name: 'creditBalance',
            label: 'Solde créditeur',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        destination: {
            name: 'destination',
            label: 'Destination',
            type: 'integer',
            defaultValue: 2,
            options: {}
        },
    })
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);
    const onChangeData = (index, value) => {
        props.setCreditBalance((prevData) => {
            return { ...prevData, [index]: value };
        });
    }
    React.useEffect(() => {
        destination == 1 && handler.setValue('creditBalance', 0)
    }, [destination]);

    React.useEffect(() => {
        props.setInvoices((prevData) => {
            let newData = {
                ...prevData[props.index],
                destination: handler.form.destination.value,
                creditBalance: handler.form.creditBalance.value
            };
            return { ...prevData, [props.index]: newData };
        });
    }, [handler.form]);

    return (
        <>
            <tr>
                <td style={{ width: 45 }}>
                    <IconButton onClick={() => setIsExpand(!isExpand)} style={{ borderRadius: 5, padding: 5, width: '100%', background: '#5e6e8263' }}>
                        <ExpandLessIcon style={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)', color: '#FFF', transition: 'all .4s' }} />
                    </IconButton>
                </td>
                <td><b>{props.name}</b></td>
                <td>{data.totalAmountHt ?? '0'}€</td>
                <td>{data.totalTvaAmount ?? '0'}€</td>
                <td><b>{data.totalAmountTtc ?? '0'}€</b></td>
                <td>
                    <SelectComponent name="destination" handler={handler} onChange={(val) => { setDestination(val) }} options={destinationOption} />
                </td>
                <td>
                    <TextFieldComponent name="creditBalance" handler={handler} onChange={(val) => { onChangeData(`creditBalance_${props.index}`, val) }} disabled={destination == 1 || !data.isFirst || props.disabled} />
                </td>
            </tr>
            <tr>
                <td colSpan={10} style={{ padding: 0 }}>
                    {isExpand &&
                        <table className={classes.tableRoot2}>
                            <thead>
                                <tr>
                                    <th>Nom/Prénom</th>
                                    <th style={{ minWidth: 145 }}>Formation</th>
                                    <th>Financement</th>
                                    <th>Frais d’inscription</th>

                                    <th>CP Établissement (Avant formation)</th>

                                    <th>Dépassement pédagogique</th>
                                    <th style={{ width: 100 }}>Total</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.registrations.length != 0 ?
                                    data.registrations.map((registration, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{`${registration.prospectName ?? ""}`}</td>
                                                <td>
                                                    <Tooltip title={registration.formationName ?? ""}>
                                                        <p className={'formationName'}>{`${registration.formationName ?? "-"}`}</p>
                                                    </Tooltip>
                                                </td>
                                                <td><b>{registration.financeTypeName ?? '-'}</b></td>
                                                <td>
                                                    {(registration.inscriptionFee && registration.inscriptionFee != 0) ? registration.inscriptionFee : '0'} €<br />
                                                    <b>Remise : {registration.inscriptionDiscount ? registration.inscriptionDiscount : '0'} €</b>
                                                </td>

                                                <td>
                                                    {(registration.feeInstitutionBefore && registration.feeInstitutionBefore != 0) ? registration.feeInstitutionBefore : '0'} €<br />
                                                </td>

                                                <td>
                                                    {(registration.educationalExceeding && registration.educationalExceeding != 0) ? registration.educationalExceeding : '0'} €
                                                </td>
                                                <td>
                                                    <b>{(registration.totalPrice && registration.totalPrice != 0) ? registration.totalPrice : '0'} €</b>
                                                </td>
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={10}>Aucune facture</td></tr>
                                }
                            </tbody>
                        </table>
                    }
                </td>
            </tr>
        </>
    )
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        '& path': {
            stroke: '#5E6E82'
        }
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double#E5E7EA', borderRadius: 100
    },
    dialogStyle: {
        '& .MuiPaper-root': {
            width: 1400,
            padding: 0,
            margin: 0,
            height: '90%',
            maxHeight: 760,
            overflow: 'initial'
        },
        '& .MuiDialogContent-root': {
            background: '#EFF2F4',
            borderTop: '1px solid #00000014',
            borderBottom: '1px solid #00000014',
        },
        '& #depositAmount': {
        }
    },
    tableRoot: {
        width: '100%',
        color: '#5E6E82',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& .MuiFormControl-root': {
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& select': {
                background: 'transparent !important',
                color: '#5E6E82',
            },
            '& input': {
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
    },
    tableRoot2: {
        color: '#5E6E82',
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#FFF',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#FFF',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
}))

export default InvoiceDialogComponent;