import React from 'react';
import { MenuItem, Tab, Tabs, makeStyles, ListItemText, Menu, withStyles, IconButton, Tooltip, Box } from "@material-ui/core";
import { dispatch, theme } from "../../../App";
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { getRouteByPathname, getRoutePathname } from '../../../Config/Route';
import { getMenu } from "../../../Config/MenuInstitution";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import MenuIcon from '@material-ui/icons/Menu';
import { useSelector } from 'react-redux';
import Api from '../../../Api';
import { AuthenticationLogout } from '../../../Action/AuthenticationAction';
import { SelectOrganism } from '../../../Action/SelectOrganismAction';

export default function TabsNavigation(props) {
    const classes = useStyles(theme)();
    const [value, setValue] = React.useState(0);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer)
    const [newMenus, setNewMenus] = React.useState([]);
    const [restMenus, setRestMenus] = React.useState([]);
    const [isRoute, setIsRoute] = React.useState(false);

    const logout = () => {
        dispatch(AuthenticationLogout());
        dispatch(SelectOrganism({ organism: {} }));
        Api.get({ route: 'authentication_logout' }, () => { });
    };

    React.useEffect(() => {
        let path = props.location.pathname;
        let currentRoute = getRouteByPathname(path);
        setIsRoute((currentRoute.id !== 'institution_health_home') && (Object.keys(selectOrganismReducer.organism).length > 0));
    }, [props.location.pathname, selectOrganismReducer]);

    React.useEffect(() => {
        let currentRoute = getRouteByPathname(props.location.pathname);
        if ((Object.keys(currentRoute).length !== 0) && (newMenus.length !== 0)) {
            let foundIndex = null;
            for (let i = 0; i < newMenus.length; i++) {
                const item = newMenus[i];
                if (item.id && (item.id.startsWith(currentRoute.id) || (item.children && item.children.some(childItem => childItem.id.startsWith(currentRoute.id))))) {
                    foundIndex = i;
                    break;
                }
            }

            if (foundIndex !== null) {
                setValue(foundIndex)
            } else {
                setValue(0)
            }
        }
    }, [props.location.pathname, newMenus]);

    React.useEffect(() => {
        let menus = getMenu();
        let objetNull = { id: null, label: null };
        menus = [objetNull, ...menus];
        const numberList = screenReducer.screen === 'XL' ? 0 : screenReducer.screen === 'LG' ? 0 : screenReducer.screen === 'MD' ? 0 : 0;
        let firstArray = menus.slice(0, numberList);
        let restArray = menus.slice(!isRoute ? 0 : numberList);
        setNewMenus(firstArray)
        setRestMenus(restArray)
        props.setDrawerRoute(restArray)
    }, [screenReducer.screen, isRoute]);

    return (
        <>
            {props.isRoute && <Tabs value={value} className={classes.tabs}>
                {newMenus.map((element, index) => (
                    <TabStyledMenu element={element} key={index} index={index} data={value} {...props} />
                ))}
            </Tabs>}
            <Box style={{ background: 'rgba(94, 110, 130, 0.87)', borderRadius: 20, margin: '0px 13px', padding: '0px 5px', height: 'fit-content' }}>
                {props.isRoute &&
                    <Tooltip title="Espace personnel" aria-label="Espace personnel">
                        <IconButton component="span" className={classes.appBarLogout} onClick={() => { props.history.push(getRoutePathname('institution_health_admin_user', { id: authenticationReducer.id })) }}>
                            <AccountCircleIcon className={classes.appBarLogoutIcon} />
                        </IconButton>
                    </Tooltip>
                }
                <Tooltip title="Déconnexion" aria-label="Déconnexion">
                    <IconButton component="span" className={classes.appBarLogout} onClick={logout}>
                        <PowerSettingsNewIcon className={classes.appBarLogoutIcon} />
                    </IconButton>
                </Tooltip>
            </Box>
            {((Object.keys(selectOrganismReducer.organism).length !== 0) && (restMenus.length !== 0)) &&
                <>
                    <Box className={classes.appBarMenu}>
                        <Tooltip title="Menu" aria-label="Menu">
                            <IconButton component="span" className={classes.appBarMenuOpen} onClick={() => { props.setIsOpen(!props.isOpen) }}>
                                {props.isOpen ?
                                    <MenuOpenIcon className={classes.appBarLogoutIcon} /> :
                                    <MenuIcon className={classes.appBarLogoutIcon} />
                                }
                            </IconButton>
                        </Tooltip>
                    </Box>
                </>
            }
        </>
    )
}

const TabStyledMenu = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles(theme)();
    const [value, setValue] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    React.useEffect(() => {
        let currentRoute = getRouteByPathname(props.location.pathname);
        let childrens = props.element.children;
        for (let index in childrens) {
            if (currentRoute.id && currentRoute.id.startsWith(childrens[index].id)) {
                setValue(parseInt(index))
                break;
            } else setValue(null)
        }
    }, [props.location.pathname]);

    return !props.element.children ?
        <Tab style={{ display: props.index === 0 ? 'none' : 'block' }} icon={props.element.label} key={props.index} className={props.data === props.index ? classes.styleRoute : ''} onClick={() => { props.history.push(props.element.path); props.setIsOpen(false) }} />
        : <Tab
            onClick={Boolean(anchorEl) ? handleClose : handleClick}
            className={props.data === props.index ? classes.styleRoute : ''}
            icon={
                <>
                    {props.element.label}
                    <StyledMenu
                        id="customized-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClick={() => { handleClose(); props.setIsOpen(false) }}
                    >
                        {props.element.children.map((element, index) => (
                            <StyledMenuItem key={index} selected={index === value} autoFocus={value === null} onClick={() => { props.history.push(element.path); props.setIsOpen(false) }}>
                                <ListItemText primary={element.label} />
                            </StyledMenuItem>
                        ))}
                    </StyledMenu>
                </>
            }
        />

}

const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));

const StyledMenuItem = withStyles({
    root: {
        '&:focus': {
            backgroundColor: '#5E6E82 !important',
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: '#fff',
            },
        },
        '& .MuiMenuItem-root': {
            padding: '0 20px',
        },
        '& .MuiTypography-body1': {
            fontSize: 12,
            color: '#5E6E82',
        }
    },
})(MenuItem);

const useStyles = (theme) => makeStyles({
    tabs: {
        minHeight: 'initial',
        '& .MuiTab-wrapper': {
            fontSize: 12,
            textTransform: 'initial'
        },
        '& .MuiTab-root': {
            minWidth: 'initial',
            minHeight: 20
        },
        '& .MuiTabs-indicator': {
            backgroundColor: '#5E6E82',
        }
    },
    styleRoute: {
        color: '#5E6E82',
        fontWeight: 'bold !important',
    },
    appBarLogout: {
        padding: 5,
        margin: '0',
        color: '#FFF'
    },
    appBarLogoutIcon: {
        fontSize: 17,
        color: '#FFF'
    },
    appBarMenu: {
        background: 'rgba(94, 110, 130, 0.87)',
        margin: 0,
        height: '100%',
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center',
        width: 40,
    },
    appBarMenuOpen: {
        width: '100%',
        padding: 0,
        height: '100%',
        borderRadius: 0,
    }
});
