import React from 'react';
import { Fade, makeStyles } from "@material-ui/core";
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import { theme } from "../App";

const LoadLearnerComponent = (props) => {
    const classes = useStyles(theme)();
    return (
        <Fade in={true} {...{ timeout: 1000 }}>
            <div className={classes.loader}>
                <div className={'spinner-box'}>
                    <div className={'circle-border'}>
                        <div className={'circle-core'}></div>
                    </div>
                </div>
                <div className={'loader'}>
                    <div className={'wave'}></div>
                    <div className={'wave'}></div>
                    <div className={'wave'}></div>
                </div>
                <UseAnimations className={'animations'} animation={activity} size={50} />
            </div>
        </Fade>
    )
}

const useStyles = (theme) => makeStyles(style => ({
    loader: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        '& path': {
            stroke: `${theme.colorPrimary} !important`
        },
        '& .circle-border ': {
            width: 70,
            height: 70,
            padding: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
            background: `linear-gradient(0deg, ${theme.colorPrimary + '40'} 33%, ${theme.colorPrimary} 100%)`,
            animation: '$spin .8s linear 0s infinite'
        },
        '& .circle-core': {
            width: '100%',
            height: '100%',
            backgroundColor: '#FFF',
            borderRadius: '50%',
        },
        '& .animations': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
        },

        '& .loader': {
            width: '200%',
            height: '200%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: -1
        },

        '& .wave': {
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '100%',
            height: '100%',
            background: 'none',
            border: `4px solid ${theme.colorPrimary + '40'}`,
            borderRadius: '100%',
            transform: 'translate(-50%, -50%) scale(0)',
            animation: '$expand 3s infinite ease-in-out'
        },

        '& .wave:nth-child(2)': {
            animationDelay: '1s'
        },
        '& .wave:nth-child(3)': {
            animationDelay: '2s'
        },

    },

    '@keyframes spin': {
        'from': {
            transform: 'rotate(0)'
        },
        'to': {
            transform: 'rotate(359deg)'
        }
    },

    '@keyframes expand': {
        '0%': {
            transform: 'translate(-50%, -50%) scale(0)',
            opacity: 1
        },
        '100%': {
            transform: 'translate(-50%, -50%) scale(1.5)',
            opacity: 0
        }
    },
}));

export default LoadLearnerComponent;