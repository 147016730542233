import React from 'react';
import { Box, Breadcrumbs as BreadcrumbsMUI, makeStyles } from "@material-ui/core";
import { Home, NavigateNext } from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import { useSelector } from "react-redux";
import { theme } from "../App";
import LoadLearnerComponent from './LoadLearnerComponent';

function ContentViewLearnerComponent(props = {
    loading: false,
    onChildrenStyle: false,
    breadcrumbs: { currentPage: '', links: [], image: '', title: '', html: '' }
}) {
    const [isSmallDrawer, setIsSmallDrawer] = React.useState(false);
    const classes = useStyles(theme, isSmallDrawer)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);

    React.useEffect(() => {
        setIsSmallDrawer(screenReducer.size >= 500);
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));

    }, [screenReducer.size]);

    return (
        <div className={`${props.className ? props.className : ''} ${classes.contentView} ${isSmallView && classes.contentViewSmall} ContentView-root`}>
            <>
                <Box className={`${classes.headerRoot} header-root`}>
                    <div className={props.breadcrumbs.img ? classes.img2 : classes.img}>
                        <img src={props.breadcrumbs.img ?? '/asset/images/headerLearner.webp'} />
                    </div>
                    <div style={screenReducer.size <= 1330 ? { padding: '15px 20px', display: 'grid', gridTemplateRows: 'auto max-content', width: '90%', margin: 'auto' } :
                        { padding: '15px 0px', gap: 30, display: 'grid', gridTemplateColumns: 'auto max-content', width: screenReducer.size >= 1550 ? 1450 : '90%', margin: 'auto' }}>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                            {<div className={`${classes.breadcrumbs} ${isSmallView && classes.breadcrumbsSmall} Breadcrumbs-root`}>
                                <BreadcrumbsMUI separator={<NavigateNext className={'icon-bc'} style={{ margin: 0, cursor: 'default' }} />} aria-label="breadcrumb">
                                    {
                                        props.breadcrumbs.links && props.breadcrumbs.links.map((link, key) => {
                                            return (
                                                <Typography key={key} onClick={() => props.history.push(link.path)} style={{ cursor: 'pointer', fontWeight: 700, fontSize: isSmallView ? 14 : 15 }}>
                                                    {key === 0 && <Home className={'icon-bc'} />}
                                                    {link.label}
                                                </Typography>
                                            )
                                        })
                                    }
                                    {props.breadcrumbs.currentPage && <Typography style={{ fontWeight: 500, fontSize: isSmallView ? 14 : 15 }}>
                                        {(props.breadcrumbs.links && (props.breadcrumbs.links?.length != 0)) ? '' : <Home className={'icon-bc'} />}
                                        {props.breadcrumbs.currentPage}
                                    </Typography>
                                    }
                                </BreadcrumbsMUI>
                            </div>}
                            {(props.breadcrumbs.title && !props.loading) && <Box style={{ position: 'relative', zIndex: 11, margin: 'auto 0' }}>{props.breadcrumbs.title}</Box>}
                        </Box>
                        {(props.breadcrumbs.html && !props.loading) && <Box style={{ zIndex: 10 }}>{props.breadcrumbs.html}</Box>}
                    </div>
                </Box>
                <Box className={`${classes.mainRoot} main-root`}>
                    {props.loading ?
                        <LoadLearnerComponent />
                        :
                        <>
                            <SvgComponent />
                            <div style={!props.onChildrenStyle ? { width: screenReducer.size >= 1216 ? 1116 : '90%', margin: 'auto', padding: '50px 0' } : { height: '100%' }}>
                                {props.children}
                            </div>
                        </>
                    }
                </Box>
            </>
        </div >
    );
}

const SvgComponent = () => {
    return (
        <svg className='ContentSvg-root' xmlns="http://www.w3.org/2000/svg" width={"2177"} height={"998.82"} viewBox={"0 0 2177 998.82"} style={{ width: '100%', position: 'absolute', zIndex: -10, opacity: 0.03 }}>
            <defs>
                <clipPath id={"clip-path"}>
                    <rect id={"Rectangle_160"} data-name={"Rectangle 160"} width={"2177"} height={"998.82"} fill={theme.colorPrimary} />
                </clipPath>
            </defs>
            <g id="Groupe_424" data-name="Groupe 424" clip-path="url(#clip-path)">
                <path id={"Tracé_678"} data-name="Tracé 678" d="M.117,485.865q0-170.583.007-341.164c0-11.338-.615-9.816,10.49-10.184,36.9-1.219,73.674-3.228,109.772-11.8,35.538-8.442,69.828-20.383,103.561-33.928,46.347-18.612,92.4-37.928,138.954-56.031C400.118,18.282,438.59,7.742,478.475,2.542,524.065-3.4,568.561,1.236,612.263,14.8c40.574,12.593,77.905,30.725,109.262,59.324,30.574,27.887,50.92,61.8,62.289,100.836,13.452,46.193,14.383,93.15,6.777,140.228C769.772,444.057,711.431,555.681,624.059,653.6c-57.3,64.219-123.694,117.8-200.389,158.98-54.511,29.273-112.223,49.93-173.74,59.92-64.369,10.453-128.108,8.359-190.407-11.776C41.5,854.9,24.338,846.568,6.7,839.568c-5.19-2.062-6.717-5.343-6.7-10.725Q.269,725.708.117,622.572V485.865" transform="translate(0 0)" fill={theme.colorPrimary} />
                <path id={"Tracé_679"} data-name="Tracé 679" d="M888.7,409.768c0,64.924-.082,129.848.144,194.771.02,5.554-1.9,8.127-6.914,10.364-41.632,18.592-84.824,24.9-130.207,17.378-59.485-9.864-113.677-31.451-161.275-67.785-18.936-14.454-35.267-31.272-44.586-53.378-9.559-22.668-7.5-44.82,9.419-62.813,43.729-46.5,76.2-100.352,109.568-153.717,14.864-23.77,30.37-47.136,50.088-67.531,32.176-33.283,71.654-45.353,117.762-38.827,17.641,2.5,33.991,8.4,49.921,15.869,4.463,2.094,6.224,4.652,6.209,9.69-.2,65.326-.13,130.652-.13,195.981" transform="translate(1288.159 363.438)" fill={theme.colorPrimary} />
            </g>
        </svg>

    )
}

const useStyles = (theme, isSmallDrawer) => makeStyles(style => ({
    contentView: {
        padding: '0',
        position: 'relative',
        height: '100%',
        width: '100%',
        margin: '0 auto',
        display: 'grid',
        gridTemplateRows: 'max-content auto',
        '& .Breadcrumbs-root:nth-of-type(1)': {
            display: 'flex',
            alignItems: 'center',
            flexWrap: 'wrap',
            height: 'auto',
            '& div:nth-of-type(2)': {
                margin: '0 0 0 auto',
            }
        }
    },
    contentViewSmall: {
        padding: '0',
    },
    mainRoot: {
        backgroundColor: '#FCFCFC',
        height: '100%',
        borderRadius: '40px 40px 0 0',
        position: 'relative',
        overflow: 'hidden',
        zIndex: 10
    },
    breadcrumbs: {
        color: '#FFF',
        width: '100%',
        boxSizing: 'border-box',
        zIndex: 2,
        position: 'relative',
        '& h2': {
            margin: 0,
            fontSize: 23,
            fontWeight: 'bold',
            color: '#FFF',
        },
        '& p': {
            margin: 0,
            fontSize: 13,
            fontWeight: 300,
            color: '#FFF',
        },
        '& .icon-bc': {
            color: '#FFF',
            fontSize: 18,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
        },
        '& svg': {
            color: "#5E6E82",
            fontSize: 18
        }
    },
    breadcrumbsSmall: {
        color: '#FFF',
        width: '100%',
        boxSizing: 'border-box',
        zIndex: 2,
        position: 'relative',
        '& h2': {
            margin: 0,
            fontSize: 16,
            fontWeight: 'bold',
            color: '#FFF',
        },
        '& p': {
            margin: 0,
            fontSize: 13,
            fontWeight: 300,
            color: '#FFF',
        },
        '& .icon-bc': {
            color: '#FFF',
            fontSize: 16,
            verticalAlign: 'sub',
            fontWeight: 400,
            margin: 0,
            marginRight: 10,
            cursor: 'pointer'
        },
        '& .MuiBreadcrumbs-root': {
            color: '#5E6E82',
        },
        '& svg': {
            color: "#5E6E82",
            fontSize: 18
        }
    },
    headerRoot: {
        backgroundColor: '#00000096',
        backgroundSize: 'cover',
        width: '100%',
        color: '#fff',
        height: 'fit-content',
        display: 'flex',
        flexDirection: 'column',
        position: 'relative',
        gap: 5,
        '&::after': {
            content: "' '",
            width: '100%',
            height: '200%',
            position: 'absolute',
            background: 'linear-gradient(90deg, #39393963, 30%, #393939b0, 70%, #262626d6)',
            zIndex: 1
        },
        '&::before': {
            content: "' '",
            width: '100%',
            height: '200%',
            position: 'absolute',
            background: `linear-gradient(90deg, ${theme.colorPrimary}CC, 30%, ${theme.colorPrimary}C4, 70%, ${theme.colorPrimary})`,
            zIndex: 1
        },
    },
    img: {
        width: '100%',
        height: '200%',
        position: 'absolute',
        overflow: 'hidden',
        background: '#383838',
        '& img': {
            position: 'absolute',
            width: '100%',
            transform: 'translate(-50%,-0%)',
            top: '0%',
            left: '50%',
            filter: 'blur(3px)',
        },
    },
    img2: {
        width: '100%',
        height: '200%',
        position: 'absolute',
        overflow: 'hidden',
        background: '#383838',
        '& img': {
            position: 'absolute',
            width: '60%',
            transform: 'translate(-0%,-40%)',
            top: '0%',
            left: '0%',
            filter: 'blur(3px)',
        },
    }
}));

export default ContentViewLearnerComponent;
