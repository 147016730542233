import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import SearchIcon from '@material-ui/icons/Search';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import { getRoutePathname } from '../../../Config/Route';
import { Box, Fade, makeStyles, InputAdornment, TextField } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api, { learnerHomeFormationImageBanner } from "../../../Api";
import CardFormation from "./Component/CardFormation";
import Grid from "@material-ui/core/Grid";
import { inArray } from '../../../Tool/ArrayCommon';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';
import BoxLearnerComponent from '../../../Component/BoxLearnerComponent';
import LearnerImgSvgComponent from '../../../Component/LearnerImgSvgComponent';

const Catalog = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const classes = useStyles(theme)();
    const dispatch = useDispatch();
    const [cardSmall, setCardSmall] = React.useState('');
    const [sizeView, setSizeView] = React.useState('');
    const [formationsCatalogLeaner, setFormationsCatalogLeaner] = React.useState([]);
    const [formationsCatalogLeanerNotAvailable, setFormationsCatalogLeanerNotAvailable] = React.useState([]);
    const [isTall, setIsTall] = React.useState(true);
    const [countSpaces, setCountSpaces] = React.useState({
        formationsCatalogLeaner: '',
        formationsCatalogLeanerNotAvailable: ''
    });
    const [thematicsList, setThematicsList] = React.useState([]);
    const [thematicActive, setThematicActive] = React.useState();

    const cardSpace = (countSpaces) => {
        return (
            <div style={{ display: 'flex', gridColumn: `span ${countSpaces}` }} >
                <Fade in={true} {...{ timeout: 500 }}>
                    <Box className={classes.cardAccesRoot}>
                        <Box style={{ textAlign: 'center', margin: (countSpaces === 1) ? 'auto' : '', zIndex: 1, position: 'relative' }}>
                            <h1 style={{ color: theme.colorPrimary, margin: 5, fontSize: (countSpaces != 1) ? 40 : 30 }}>Tableau de bord</h1>
                        </Box>
                        <p className={classes.textLink} style={{ margin: countSpaces === 1 ? 0 : 10, zIndex: 1, position: 'relative' }}
                            onClick={() => { props.history.push(getRoutePathname('learner_home')) }}
                        ><span style={{ padding: '5px 50px' }}>{(countSpaces != 1) ? 'Revenir au Tableau de bord' : 'Accéder'}</span></p>
                        {(countSpaces != 1) && <LearnerImgSvgComponent style={{ width: '100%' }} />}
                    </Box>
                </Fade>
            </div>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.get({
            route: 'learner_catalog_list',
            data: {
                organism: theme.id
            }
        },
            (response) => {
                setTimeout(() => {
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setFormationsCatalogLeaner(response.data.available);
                    setFormationsCatalogLeanerNotAvailable(response.data.notAvailable);
                    let data = [];
                    let listThematic = [];
                    let dataAvailable = response.data.available;
                    let dataNotAvailable = response.data.notAvailable;

                    for (let index in dataAvailable) {
                        for (let i in dataAvailable[index].thematics) {
                            if (!(inArray(dataAvailable[index].thematics[i].id, data))) {
                                data.push(dataAvailable[index].thematics[i].id);
                                listThematic.push(dataAvailable[index].thematics[i]);
                            }
                        }
                    }
                    for (let key in dataNotAvailable) {
                        for (let k in dataNotAvailable[key].thematics) {
                            if (!(inArray(dataNotAvailable[key].thematics[k].id, data))) {
                                data.push(dataNotAvailable[key].thematics[k].id);
                                listThematic.push(dataNotAvailable[key].thematics[k]);
                            }
                        }
                    }
                    setThematicsList(listThematic);
                }, 1000);
            });
    }, []);

    const countEmptySpaces = (data, grid) => {
        const newData = data?.filter((prevData) => (!thematicActive || inArray(thematicActive, prevData.thematicsId)));
        const formationsLength = newData?.length;
        const remainder = formationsLength % grid;
        return remainder === 0 ? 0 : grid - remainder;
    };

    React.useEffect(() => {
        setCountSpaces({
            formationsCatalogLeaner: countEmptySpaces(formationsCatalogLeaner, cardSmall.grid),
            formationsCatalogLeanerNotAvailable: countEmptySpaces(formationsCatalogLeanerNotAvailable, cardSmall.grid)
        })
    }, [formationsCatalogLeaner, formationsCatalogLeanerNotAvailable, thematicActive, cardSmall]);

    React.useEffect(() => {
        setBreadcrumbs({
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ],
            title: <p style={{ color: '#FFF', margin: 0 }}>
                <span style={{ fontSize: screenReducer.size >= 540 ? 50 : 30, display: 'block', fontWeight: 'bold' }}>Catalogue de formations</span>
            </p>
        });
        setSizeView(screenReducer.screen);
        if (screenReducer.screen) {
            let isTallScreen = (screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
            if (isTallScreen !== isTall) setIsTall(isTallScreen);
        }
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));

        setCardSmall((prev) => {
            const values = {
                1: { width: 265, grid: 1 },
                2: { width: 548, grid: 2 },
                3: { width: 832, grid: 3 },
                default: { width: 'auto', grid: 4 }
            };

            const conditions = [
                { condition: screenReducer.size <= 610, type: 1 },
                { condition: screenReducer.size <= 925, type: 2 },
                { condition: screenReducer.size <= 1202, type: 3 },
            ];

            const valueType = conditions.find(cond => cond.condition)?.type || 'default';
            return {
                grid: values[valueType].grid,
                width: values[valueType].width
            };
        });

    }, [screenReducer]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <Box>
                {((thematicsList.length > 0) && (formationsCatalogLeanerNotAvailable || formationsCatalogLeaner)) &&
                    <p style={{ textAlign: 'left', fontSize: 18, fontWeight: 600, margin: '5px 0' }}>Recherchez par thématique</p>
                }
                {(formationsCatalogLeanerNotAvailable || formationsCatalogLeaner) &&
                    <div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row' }}>
                        {thematicsList?.map((thematic) => (
                            <div
                                key={thematic.id}
                                className={classes.btnThematic}
                                onClick={() => {
                                    if (thematicActive === thematic.id) {
                                        setThematicActive(null);
                                    }
                                    else {
                                        setThematicActive(thematic.id);
                                    }
                                }}
                                style={thematicActive === thematic.id
                                    ? { border: `2px solid ${theme.colorPrimary}`, color: '#FFF', background: theme.colorPrimary } :
                                    { border: `2px solid ${theme.colorPrimary}`, color: theme.colorPrimary }
                                }
                            >
                                <span style={{ fontWeight: 500 }}>{thematic.label}</span>
                            </div>
                        ))}
                    </div>
                }
            </Box>
            {formationsCatalogLeanerNotAvailable &&
                <>
                    <BoxLearnerComponent
                        title={'Formations bientôt disponibles'}
                        styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                        styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
                    >
                        <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width, padding: '20px 0' }}>
                            {formationsCatalogLeanerNotAvailable.map((formationCatalogLeaner, index) => (
                                (!thematicActive || inArray(thematicActive, formationCatalogLeaner.thematicsId)) &&
                                <div style={{ display: 'flex' }} key={index}>
                                    <CardFormation
                                        notAvailable={true}
                                        show={true}
                                        hours={formationCatalogLeaner.hours}
                                        image={learnerHomeFormationImageBanner + formationCatalogLeaner.image}
                                        title={formationCatalogLeaner.name}
                                        description={formationCatalogLeaner.description}
                                        financeTypes={formationCatalogLeaner.financeTypes}
                                        untilAvailable={formationCatalogLeaner.untilAvailable}
                                        isEPP={formationCatalogLeaner.isEPP}
                                        onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formationCatalogLeaner.id })) }}
                                    />
                                </div>
                            ))}
                            {(countSpaces.formationsCatalogLeanerNotAvailable != 0) && cardSpace(countSpaces.formationsCatalogLeanerNotAvailable)}
                        </div>
                    </BoxLearnerComponent>
                </>}

            <BoxLearnerComponent
                title={<div style={{ display: 'flex', alignItems: 'center' }}><span>Formations disponibles</span><Search data={formationsCatalogLeaner} setData={setFormationsCatalogLeaner} {...props} /></div>}
                styleContent={{ flexDirection: 'column', alignItems: 'center' }}
                styleContentSmall={{ flexDirection: 'column', alignItems: 'center' }}
            >
                {formationsCatalogLeaner ?
                    <div style={{ display: 'grid', gridTemplateColumns: `repeat(${cardSmall.grid}, 1fr)`, gap: 20, margin: 'auto', width: cardSmall.width, padding: '20px 0' }}>
                        {formationsCatalogLeaner.map((formationCatalogLeaner, index) => (
                            (!thematicActive || inArray(thematicActive, formationCatalogLeaner.thematicsId)) &&
                            <div style={{ display: 'flex' }} key={index}>
                                <CardFormation
                                    show={true}
                                    hours={formationCatalogLeaner.hours}
                                    image={learnerHomeFormationImageBanner + formationCatalogLeaner.image}
                                    title={formationCatalogLeaner.name}
                                    description={formationCatalogLeaner.description}
                                    financeTypes={formationCatalogLeaner.financeTypes}
                                    untilAvailable={formationCatalogLeaner.untilAvailable}
                                    isEPP={formationCatalogLeaner.isEPP}
                                    onClick={() => { props.history.push(getRoutePathname('learner_register', { id: formationCatalogLeaner.id })) }}
                                />
                            </div>
                        ))}
                        {(countSpaces.formationsCatalogLeaner != 0) && cardSpace(countSpaces.formationsCatalogLeaner)}
                    </div> : <p className={classes.error}>Aucune formations</p>}
            </BoxLearnerComponent>
        </ContentViewLearnerComponent>
    );
};

const Search = (props) => {
    const [originalData, setOriginalData] = React.useState(props.data);

    const handleFieldFilter = (event) => {
        const searchValue = event.target.value.toLowerCase();
        const filteredData = originalData.filter(item =>
            item.name.toLowerCase().includes(searchValue)
        );

        props.setData(filteredData);
    };


    React.useEffect(() => {
        if (originalData.length === 0) {
            setOriginalData(props.data);
        }
    }, [props.data]);

    return (
        <TextField
            style={{ margin: '0 0 0 auto' }}
            type="text"
            placeholder="Rechercher"
            onChange={handleFieldFilter}
            InputProps={{
                'aria-label': 'description',
                startAdornment: (
                    <InputAdornment position="start">
                        <SearchIcon style={{ color: 'rgb(94, 110, 130)' }} />
                    </InputAdornment>
                ),
            }}
        />
    );
};


const useStyles = (theme) => makeStyles(({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    title: {
        fontSize: 20,
        fontWeight: 500,
        color: '#fff',
        textAlign: 'left',
        background: '#4f93a5',
        width: 'max-content',
        padding: '7px 20px'
    },
    btnThematic: {
        cursor: 'pointer',
        width: 'max-content',
        padding: '5px 10px',
        margin: 5,
        borderRadius: 5,
        fontSize: 12,
        transition: 'all .4s !important',
        '&:hover': {
            color: '#FFF !important',
            background: theme.colorPrimary + '99 !important',
        }
    },
    error: {
        fontSize: 30,
        width: '100%',
        color: '#4d4c4c2e',
        textAlign: 'center',
        textTransform: 'uppercase',
        fontWeight: 'bold'
    },
    cardAccesRoot: {
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        gap: 10,
        padding: 40,
        width: '100%',
        background: '#FFF',
        alignItems: 'center',
        transition: 'all .4s !important',
        flexDirection: 'column',
        justifyContent: 'center',
        position: 'relative',
        overflow: 'hidden'
    },
    textLink: {
        width: 'fit-content',
        cursor: 'pointer',
        fontWeight: 'bold',
        margin: 'auto',
        background: theme.colorPrimary,
        color: '#FFF',
        padding: 5,
        borderRadius: 10,
        transition: 'all .4s !important',
        '& span': {
            borderRadius: 6,
            padding: '5px 25px',
            border: '3px solid #FFF',
            display: 'block',
            width: 'max-content',
            fontSize: 13
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
}));

export default Catalog;
