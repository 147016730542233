import React from "react";
import BreadcrumbsComponent from "../../../../Component/BreadcrumbsComponent";
import { getRoutePathname } from "../../../../Config/Route";
import { Box, Fade, Grid, LinearProgress, makeStyles, Typography, } from "@material-ui/core";
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import { theme } from "../../../../App";
import moment from "moment";
import Api from "../../../../Api";
import TableComponent from "../../../../Component/TableComponent";
import { cacheTableServer } from "../../../../Cache";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { useSelector } from "react-redux";
import ContentViewLearnerComponent from "../../../../Component/ContentViewLearnerComponent";

function LinearProgressWithLabel(props) {
    const getColor = (value) => {
        if (value >= 0 && value < 33) {
            return '#dc3545';
        }
        else if (value >= 33 && value < 66) {
            return '#db770a';
        }
        else if (value >= 66) {
            return '#28a745';
        }
    };
    const useStyles = makeStyles({
        root: {
            backgroundColor: '#e5e3e3',
            '& .MuiLinearProgress-barColorPrimary': { backgroundColor: getColor(props.value) }
        }
    });
    const classes = useStyles();

    return (
        <Box display="flex" alignItems="center">
            <Box width="100%" mr={1}>
                <LinearProgress className={classes.root} variant="determinate" {...props} />
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

const InstitutionRegistrationList = (props) => {
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);

    const columns = [
        {
            name: "lastname",
            label: "Nom",
            options: { filter: true, sort: true }
        },
        {
            name: "firstname",
            label: "Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "email",
            label: "Email",
            options: { filter: true, sort: true }
        },
        {
            name: "phone",
            label: "Numéro de téléphone",
            options: { filter: true, sort: true }
        },
        {
            name: "formationGroupName",
            label: "Groupe de formation",
            options: { filter: false, sort: true }
        },
        {
            name: "formationName",
            label: "Formation",
            options: { filter: true, sort: true }
        },
        {
            name: "financeTypeName",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "commercialName",
            label: "Commercial",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionStartAt",
            label: "Début de session",
            options: { filter: true, sort: true }
        },
        {
            name: "sessionEndAt",
            label: "Fin de session",
            options: { filter: true, sort: true }
        },
        {
            name: "createdAt",
            label: "Date création",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAt",
            label: "Date d'inscription",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Date de désinscription",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: false, sort: false }
        },
        {
            name: "completion",
            label: "Complétion",
            options: { filter: true, sort: true }
        },
        {
            name: "originName",
            label: "Origine de la registration",
            options: { filter: true, sort: true }
        },
    ];

    const [formSearch, setFormSearch] = React.useState({
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe de formation',
            textHelper: 'Trier par groupe de formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].formationGroup ? cacheTableServer['institution_health_crm_institution_registration_list'].formationGroup : 'all',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email',
            textHelper: 'Trier par email.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].email ? cacheTableServer['institution_health_crm_institution_registration_list'].email : '',
            options: {}
        },
        formation: {
            name: 'formation',
            label: 'Formation',
            textHelper: 'Trier par formation.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].formation ? cacheTableServer['institution_health_crm_institution_registration_list'].formation : 'all',
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Type de financement',
            textHelper: 'Trier par type de financement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].financeType ? cacheTableServer['institution_health_crm_institution_registration_list'].financeType : 'all',
            options: {}
        },
        commercial: {
            name: 'commercial',
            label: 'Commercial',
            textHelper: 'Trier par commercial.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].commercial ? cacheTableServer['institution_health_crm_institution_registration_list'].commercial : 'all',
            options: {}
        },
        sessionStartAt: {
            name: 'sessionStartAt',
            label: 'Début de session',
            textHelper: 'Trier par début de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].sessionStartAt ? cacheTableServer['institution_health_crm_institution_registration_list'].sessionStartAt : '',
            options: {}
        },
        sessionEndAt: {
            name: 'sessionEndAt',
            label: 'Fin de session',
            textHelper: 'Trier par fin de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].sessionEndAt ? cacheTableServer['institution_health_crm_institution_registration_list'].sessionEndAt : '',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].state ? cacheTableServer['institution_health_crm_institution_registration_list'].state : 'all',
            options: {}
        },
        origin: {
            name: 'origin',
            label: 'Origine',
            textHelper: 'Trier par origine.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_crm_institution_registration_list'] && cacheTableServer['institution_health_crm_institution_registration_list'].origin ? cacheTableServer['institution_health_crm_institution_registration_list'].origin : 'all',
            options: {}
        }
    });
    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const table = () => {
        return (
            <>
                <TableComponent
                    id={'institution_health_crm_institution_registration_list'}
                    title={<h1 style={{ margin: 0, fontSize: 15, display: 'flex', alignItems: 'center', gap: 5, }}><AssignmentIndIcon style={{ width: 20, height: 20 }} />Inscriptions</h1>}
                    columns={columns}
                    promiseServerData={(resolve, options) => {
                        let data = handlerSearch.getData();
                        let filters = {
                            limit: options.rowsPerPage,
                            offset: options.page * options.rowsPerPage,
                            sortName: options.sortOrder.name,
                            sortDirection: options.sortOrder.direction,
                            formationGroup: (data.formationGroup === 'all' || !data.formationGroup) ? '' : data.formationGroup,
                            commercial: (data.commercial === 'all' || !data.commercial) ? '' : data.commercial,
                            financeType: (data.financeType === 'all' || !data.financeType) ? '' : data.financeType,
                            state: (data.state === 'all' || !data.state) ? '' : data.state,
                            origin: (data.origin === 'all' || !data.origin) ? '' : data.origin,
                            email: data.email ? data.email : '',
                            sessionStartAt: data.sessionStartAt ? data.sessionStartAt : '',
                            sessionEndAt: data.sessionEndAt ? data.sessionEndAt : '',
                            organism: authenticationReducer.organism
                        };

                        Api.get({
                            route: 'institution_health_crm_institution_registration_list',
                            params: { institution: props.match.params.institution },
                            data: filters
                        },
                            (response) => {
                                if (response.status === 200) {
                                    let data = response.data.data;
                                    for (let index in data) {
                                        data[index].phone = data[index].phone ? data[index].phone : '-';
                                        data[index].commercialName = data[index].commercialName ? data[index].commercialName : '-';
                                        data[index].completion = <div style={{ width: 150 }}><LinearProgressWithLabel value={data[index].completion} /></div>;
                                        data[index].createdAt = data[index].createdAt ? moment(data[index].createdAt.date).format('lll') : '-';
                                        data[index].sessionStartAt = data[index].sessionStartAt ? moment(data[index].sessionStartAt.date).format('lll') : '-';
                                        data[index].sessionEndAt = data[index].sessionEndAt ? moment(data[index].sessionEndAt.date).format('lll') : '-';
                                        data[index].availableAt = data[index].availableAt ? moment(data[index].availableAt.date).format('lll') : '-';
                                        data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt.date).format('lll') : '-';
                                        switch (data[index]['state']) {
                                            case 1:
                                                data[index]['stateText'] = <span style={{ color: '#28A745' }}>Terminer</span>;
                                                break;
                                            case 2:
                                                data[index]['stateText'] = <span style={{ color: '#AD732C' }}>Annulée</span>;
                                                break;
                                            case 3:
                                                data[index]['stateText'] = <span style={{ color: '#DC3545' }}>Supprimée</span>;
                                                break;
                                            case 4:
                                                data[index]['stateText'] = <span style={{ color: '#35A2EB' }}>Facturée</span>;
                                                break;
                                            case 5:
                                                data[index]['stateText'] = <span style={{ color: '#000000' }}>Non terminée</span>;
                                                break;
                                            case 6:
                                                data[index]['stateText'] = <span style={{ color: '#000000' }}>Pré-inscription</span>;
                                                break;
                                        }
                                    }
                                    resolve(data, response.data.count);
                                }
                                else {
                                    resolve([]);
                                }
                            });
                    }}
                    search={false}
                />
            </>
        )
    }

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Inscriptions',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_institution_list'), label: 'Mes établissements' },
                { path: getRoutePathname('learner_institution_manager', { institution: props.match.params.institution }), label: 'Mon établissement' },
            ]
        });
    }, []);

    return (
        <ContentViewLearnerComponent loading={false} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <Box style={{ width: '100%' }}>
                <Box className={classes.styleTable} style={{ width: '90%', margin: '35px auto' }}>
                    {table()}
                </Box>
            </Box>
        </ContentViewLearnerComponent>
    )
}

const useStyles = (theme) => makeStyles(({
    styleTable: {
        '& .MuiButton-text': { width: 'max-content', }
    }
}));

export default InstitutionRegistrationList;