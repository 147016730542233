import React from 'react';
import { theme } from '../../../../App';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { Box, Slide, makeStyles } from '@material-ui/core';
import { getRoutePathname } from '../../../../Config/Route';
import TabsComponent from '../../../../Component/TabsComponent';
import Info from './Billing/Info';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import Api from '../../../../Api';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { TableReload } from '../../../../Action/TableAction';
import RegistrationsANDPC from './Billing/RegistrationsANDPC/RegistrationsANDPC';
import RegistrationsFIFPL from './Billing/RegistrationsFIFPL/RegistrationsFIFPL';
import RegistrationsOPCO from './Billing/RegistrationsOPCO/RegistrationsOPCO';
import RegistrationsINSTITUTION from './Billing/RegistrationsINSTITUTION/RegistrationsINSTITUTION';
import Activity from './Billing/Activity';

function BillingFormation(props) {

    const classes = useStyles(theme)();
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [sessionFormation, setSessionFormation] = React.useState({});
    const [reloadList, setReloadList] = React.useState(false);

    const getData = () => {
        Api.get({
            route: 'institution_health_financial_management_billing_session_formation_get',
            params: { sessionFormation: props.match.params.id },
            data: { organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                if (response.status === 200) {
                    setSessionFormation(response.data ?? {});
                    dispatch(TableReload('institution_health_financial_management_billing_registration_opco_list'));
                    dispatch(TableReload('institution_health_financial_management_billing_registration_fifpl_list'));
                    dispatch(TableReload('institution_health_financial_management_billing_registration_institution_list'));
                    dispatch(TableReload('institution_health_financial_management_billing_registration_andpc_list'));
                    dispatch(TableReload('institution_health_financial_management_billing_session_formation_activity_list'));
                    setReloadList(false)
                    setReadyContent(true);
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_formation_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        setReloadList(true)
        getData()
    }, []);

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Facturation',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Session formation.',
            links: [
                { path: getRoutePathname('institution_health_financial_management_billing_formation_list'), label: 'Facturation formation' }
            ]
        });
    }, []);

    return (
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent}>
                    <>
                        <Box className={classes.tabsStyle}>
                            <TabsComponent
                                tabDefault={'general'}
                                tabs={[
                                    { id: 'general', label: 'Général' },
                                    { id: 'registrationsANDPC', label: 'Inscriptions ANDPC' },
                                    { id: 'registrationsOPCO', label: 'Inscriptions OPCO' },
                                    //{ id: 'registrationsFIFPL', label: 'Inscriptions FIFPL' },
                                    { id: 'registrationsINSTITUTION', label: 'Inscriptions Établissement' },
                                    { id: 'activity', label: 'Historique' },
                                ]}
                            >
                                <div key={'general'}>
                                    <Info sessionFormation={sessionFormation} reload={reloadList} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>
                                <div className={'tab-root'} key={'registrationsANDPC'}>
                                    <RegistrationsANDPC sessionFormation={sessionFormation} billing={sessionFormation.billingANDPC} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>
                                <div className={'tab-root'} key={'registrationsOPCO'}>
                                    <RegistrationsOPCO sessionFormation={sessionFormation} billing={sessionFormation.billingOPCO} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>
                                {/*<div className={'tab-root'} key={'registrationsFIFPL'}>
                                    <RegistrationsFIFPL sessionFormation={sessionFormation} billing={sessionFormation.billingFIFPL} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>*/}
                                <div className={'tab-root'} key={'registrationsINSTITUTION'}>
                                    <RegistrationsINSTITUTION sessionFormation={sessionFormation} billing={sessionFormation.billingInstitut} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>
                                <div className={'tab-root'} key={'activity'}>
                                    <Activity sessionFormation={sessionFormation} reloadList={() => { setReloadList(!reloadList); getData() }} {...props} />
                                </div>
                            </TabsComponent>
                        </Box>
                    </>
                </Box>
            </ContentViewComponent>
        </Box>
    );
}

const useStyles = (theme) => makeStyles(style => ({
    contentView: {
        padding: 2
    },
    tabsStyle: {
        color: '#5E6E82',
        height: '100%',
        '& div.tab-root': {
            height: '100%',
            '& div': {
                //height: '100%',
            }
        },
        '& >div': {
            boxShadow: 'none',
            borderRadius: 0,
            display: 'grid',
            gridTemplateRows: 'max-content auto max-content',
            height: '100%',
            overflow: 'initial',
            '& .MuiTabScrollButton-root': {
                display: 'none'
            },
            '& .MuiTabs-flexContainer': {
                position: 'relative',
                background: 'transparent !important',
                height: 'fit-content !important',
                zIndex: 2,
                gap: 2,
                '& button.MuiButtonBase-root.Mui-selected': {
                    color: '#FFF !important',
                    fontWeight: 'bold'
                },
                '& button.MuiButtonBase-root': {
                    background: '#8c99aa45 !important',
                    height: 'fit-content',
                    padding: '0 20px',
                    minHeight: 40
                }
            },
            '& .MuiTabs-indicator': {
                background: '#728092 !important',
                height: '100%',
                zIndex: 1
            },
            '& header .MuiTabs-root': {
                width: 'fit-content',
                overflow: 'hidden',
                borderRadius: 5,
                height: 'fit-content',
                minHeight: 'auto'
            },
            '& .MuiBox-root': {
                height: '100%',
            },
            '& >div.MuiTypography-root': {
                borderRadius: 5,
                boxShadow: '0 0px 3px 0px rgba(0,0,0,0.3)',
                height: '100%',
                position: 'relative',
            },
            '& header': {
                zIndex: 10,
                margin: '20px 0',
                borderRadius: 5,
                boxShadow: '0 0px 3px 0px rgba(0,0,0,0.3)'
            },
            '&:after': {
                content: '" "',
                margin: '15px 0'
            }
        }
    },
    TableComponent: {
        height: '100%',
        width: '95%',
        margin: '0 auto',
        '& >div': {
            '& .MuiPaper-root': {
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                padding: 10,
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
}))
export default BillingFormation;