import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { theme } from '../App';

function CarouselViewComponent(props) {
    const classes = useStyles(theme)();
    const [activeStep, setActiveStep] = React.useState(0);
    const [data, setData] = React.useState([]);
    const [maxSteps, setMaxSteps] = React.useState(0);
    const activeCardRef = React.useRef(null);
    const [activeHeight, setActiveHeight] = React.useState(0);

    const getClassName = (index) => {
        if (index === activeStep) return 'carousel-card active';
        if (index === activeStep - 1) return 'carousel-card left';
        if (index === activeStep + 1) return 'carousel-card right';
        return 'carousel-card';
    };

    const handleNext = () => {
        if (activeStep < maxSteps - 1) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    React.useEffect(() => {
        if (props.data) {
            setMaxSteps(props.data.length);
            setData(props.data);
        }
    }, [props.data]);

    React.useEffect(() => {
        if (activeCardRef.current) {
            setActiveHeight(activeCardRef.current.offsetHeight);
        }
    }, [activeStep, activeCardRef.current]);

    return (
        data.length ?
            <div className={`${classes.CarouselRoot} Carousel-root`}>
                <Paper square elevation={0} className={`${classes.PaperRoot} Paper-root`} style={{ height: activeHeight }}>
                    {data.map((item, index) => (
                        <div key={index} ref={index === activeStep ? activeCardRef : null} className={`${getClassName(index)} PaperMain-root`}>
                            {props.view && props.view(item, index, activeStep)}
                        </div>
                    ))}
                </Paper>
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="text"
                    activeStep={activeStep}
                    nextButton={
                        <Button
                            size="small"
                            onClick={handleNext}
                            style={{ opacity: activeStep === maxSteps - 1 ? 0.5 : 1 }}
                            disabled={activeStep === maxSteps - 1}
                        >
                            Suivant
                            {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                        </Button>
                    }
                    backButton={
                        <Button
                            size="small"
                            onClick={handleBack}
                            style={{ opacity: activeStep === 0 ? 0.5 : 1 }}
                            disabled={activeStep === 0}
                        >
                            {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                            Précédent
                        </Button>
                    }
                />
            </div> : <p className='CarouselError-root'>{props.errorText ?? 'Aucun résultat'}</p>
    );
}


const useStyles = (theme) => makeStyles({
    CarouselRoot: {
        '& .MuiMobileStepper-root': {
            width: 264,
            margin: 'auto',
            padding: '8px 0',
            color: '#FFF',
            background: theme.colorPrimary,
            borderRadius: '0 0 12px 12px',
            zIndex: 100,
            position: 'relative',
            '& .MuiButton-root': {
                color: '#FFF',
                fontSize: 11
            }
        }
    },
    PaperRoot: {
        display: 'flex',
        alignItems: 'center',
        background: 'transparent',
        color: '#5F6577',
        height: 400,
        position: 'relative',
        '& >div': {
            margin: 'auto',
            '& >div': {
                borderRadius: '17px 17px 0 0',
                '& button': {
                    borderRadius: 0
                }
            }
        }
    },
});

export default CarouselViewComponent;