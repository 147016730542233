import React from 'react';
import UseAnimations from 'react-useanimations';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Fade, IconButton, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import { dispatch, theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import Api from '../../../Api';
import { Fullscreen, FullscreenExit } from '@material-ui/icons';
import { getRoutePathname } from '../../../Config/Route';
import { ScreenFull } from '../../../Action/ScreenAction';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

const ResourceCourse = (props) => {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const [loading, setLoading] = React.useState(true);
    const [url, setUrl] = React.useState();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setLoading(true);
        let url = Api.route({
            route: 'learner_registration_course_view',
            data: { resource: props.match.params.resource },
            params: { registration: props.match.params.registration, course: props.match.params.course }
        });
        setUrl(url);

        Api.post({
            route: 'learner_registration_course_add_resources_seen',
            data: {
                ressourceId: props.match.params.resource,
                registrationId: props.match.params.registration
            }
        }, (response) => {});

        setBreadcrumbs({
            currentPage: 'Cours optionnel',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_registration', { id: props.match.params.registration }), label: 'Sommaire' }
            ]
        });
    }, []);

    // Iframe Scale
    const [iFrameScale, setIFrameScale] = React.useState(1);
    const classes = useStyles(theme, iFrameScale)();

    const generateIFrameScale = () => {

        if (!isScale) return;

        let orientation = window.screen.orientation && window.screen.orientation.angle === 90;

        if (screenReducer.full) {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (screenReducer.full ? 0 : 58)) / iFrameHeight;

            if (orientation) {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            } else {
                if ((iFrameWidth / iFrameHeight) < (window.innerWidth / window.innerHeight)) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(ratioWidth);
                }
            }
        } else {
            let ratioWidth = window.innerWidth / iFrameWidth;
            let ratioHeight = (window.innerHeight - (window.innerHeight / 3)) / iFrameHeight;

            if (orientation) {
                if (window.innerHeight < iFrameHeight) {
                    setIFrameScale(ratioHeight);
                } else {
                    setIFrameScale(1);
                }
            } else {
                let r = 1;
                if (window.innerHeight < (iFrameHeight + 200)) {
                    r = ratioHeight;
                }
                if (window.innerWidth < iFrameWidth && ratioHeight > ratioWidth) {
                    r = ratioWidth;
                }

                setIFrameScale(r);
            }
        }
    };
    React.useEffect(generateIFrameScale, [screenReducer.full, screenReducer.size, screenReducer.orientation]);
    React.useEffect(() => {
        if (screenReducer.orientation && isScale) {
            setTimeout(() => dispatch(ScreenFull(true)), 50);
        }
    }, [screenReducer.orientation]);

    let iFrameWidth = 920;
    let iFrameHeight = 640;
    let styleContent = { width: iFrameWidth, height: iFrameHeight, zIndex: 101, position: 'relative', transform: 'scale(' + iFrameScale + ')' }
    let isScale = true;

    return (
        <ContentViewLearnerComponent className={screenReducer.full && classes.contentViewLearner} loading={false} breadcrumbs={breadcrumbs} {...props}>
            <div style={{ textAlign: 'center', margin: 'auto', height: '100%' }}>
                <Fade in={true} {...{ timeout: 1000 }}>
                    <div style={{ textAlign: 'center', margin: 'auto', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', }}>
                        {screenReducer.full && <div style={{ height: '100%', width: '100%', top: 0, backgroundColor: '#000', position: 'absolute', zIndex: 100 }} />}
                        <div className={`${classes.styleIframe} ${screenReducer.full && classes.styleIframeFull}`} style={styleContent}>
                            {
                                isScale && <>
                                    {
                                        screenReducer.full ?
                                            <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(false))}>
                                                <FullscreenExit className={classes.btnFullScreenIcon} />
                                            </IconButton> :
                                            <IconButton component="span" className={classes.btnFullScreen} onClick={() => dispatch(ScreenFull(true))}>
                                                <Fullscreen className={classes.btnFullScreenIcon} />
                                            </IconButton>
                                    }
                                </>
                            }
                            <iframe
                                onLoad={() => setLoading(false)}
                                style={{
                                    border: 0,
                                    height: 640,
                                    width: 920,
                                    textAlign: 'center',
                                    backgroundColor: '#FFFFFF',
                                    boxShadow: '0px 0px 5px 0px rgba(0, 0, 0, 0.25)',
                                    borderRadius: 5,
                                }}
                                src={url}
                            />
                        </div>
                    </div>
                </Fade>
            </div>
        </ContentViewLearnerComponent>
    );
};

const useStyles = (theme, iFrameScale) => makeStyles(({
    loader: {
        textAlign: 'center',
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        zIndex: 102,
        width: '100%',
        background: '#9e9e9ede',
        height: '100%'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    contentViewLearner: {
        '& .main-root': { position: 'initial !important' }
    },
    styleIframe: {
        '& iframe': {
            marginTop: '0 !important',
            boxShadow: 'rgba(0, 0, 0, 0.25) 0px 0px 5px 0px !important',
            borderRadius: '5px !important',
        }
    },
    styleIframeFull: {
        position: 'absolute !important',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%) scale(${iFrameScale}) !important`
    },
    btnFullScreen: {
        right: 60,
        top: 10,
        position: 'absolute',
        padding: 10,
        margin: 0,
        marginRight: 12,
        color: '#9e9e9ebf',
        zIndex: 3
    },
    btnFullScreenIcon: {
        fontSize: 30
    }
}));

export default ResourceCourse;