import React from 'react';
import { Box, Grid, makeStyles } from "@material-ui/core";
import { useSelector } from 'react-redux';
import ShadowBoxComponent from './ShadowBoxComponent';
import { dispatch, theme } from '../App';
import Api, { LibraryResourceImage } from '../Api';
import { formHandlerInit } from '../Tool/FormHandlerCommon';
import SelectComponent from './SelectComponent';
import ButtonComponent from './ButtonComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../Action/LinearProgressMainLayoutAction';
import { cloneJson } from '../Tool/CloneCommon';
import { SnackbarOpen } from '../Action/SnackbarAction';
import Skeleton from '@material-ui/lab/Skeleton';
import MediaLinkComponent from './MediaLinkComponent';
import { getRoutePathname } from '../Config/Route';

function FeaturedResourceComponent(props) {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(theme, screenReducer)();
    const [isSmallView, setSmallView] = React.useState(false);
    const [info, setInfo] = React.useState([]);
    const [load, setLoad] = React.useState(false);
    const [isLock, setIsLock] = React.useState(true);
    const [form, setForm] = React.useState({
        order1: {
            name: 'order1',
            label: 'Block 1',
            textHelper: 'Choisissez la ressource pour le block 1',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        order2: {
            name: 'order2',
            label: 'Block 2',
            textHelper: 'Choisissez la ressource pour le block 2',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        order3: {
            name: 'order3',
            label: 'Block 3',
            textHelper: 'Choisissez la ressource pour le block 3',
            type: 'text',
            defaultValue: '',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);
    const [optionLibraryResource, setOptionLibraryResource] = React.useState([]);

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutActivate());
        if (props.organism && props.formationGroup) {
            Api.get({
                route: 'marketing_manager_featured_resource_info',
                data: {
                    organismId: props.organism,
                    formationGroupId: props.formationGroup
                }
            },
                (response) => {
                    if (response.status === 200) {
                        setInfo(response.data);
                        handler.setValue('order1', response.data['1'] ? response.data['1'].id : '');
                        handler.setValue('order2', response.data['2'] ? response.data['2'].id : '');
                        handler.setValue('order3', response.data['3'] ? response.data['3'].id : '');
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (props.isAdmin || (response.data['1'] || response.data['2'] || response.data['3'])) {
                            setIsLock(false);
                        }
                        setTimeout(() => {
                            setLoad(true);
                        }, 1000);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }, [props.organism]);
    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        if (props.isAdmin) {
            Api.get({
                route: 'select_library_resource',
            },
                (response) => {
                    if (response && response.data) {
                        setOptionLibraryResource(response.data);
                    }
                });
        }
    }, []);

    const save = () => {
        dispatch(LinearProgressMainLayoutActivate());
        let blocks = [
            { resourceId: info[1] ? info[1].id : '', order: 1 },
            { resourceId: info[2] ? info[2].id : '', order: 2 },
            { resourceId: info[3] ? info[3].id : '', order: 3 }
        ];

        Api.post({
            route: 'marketing_manager_featured_resource_edit',
            data: {
                organismId: props.organism,
                formationGroupId: props.formationGroup,
                blocks: blocks
            },
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: 'Enregistrement des ressources à la une a été un succès.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
            } else {
                dispatch(
                    SnackbarOpen({
                        text: 'Une erreur inattendue s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const [isFullBlock, setIsFullBlock] = React.useState(false);
    const handlerChange = (resourceId, inputName) => {

        dispatch(LinearProgressMainLayoutActivate());
        let order = inputName.split('order')[1];
        let infoTemp = cloneJson(info);

        if (!resourceId) {
            delete infoTemp[order];
            setInfo(infoTemp);
            dispatch(LinearProgressMainLayoutDeactivate());
        }
        else {
            Api.get({
                route: 'marketing_manager_featured_resource_resource_get',
                params: { id: parseInt(resourceId) },
                data: { order: order }
            },
                (response) => {
                    if (response.status === 200) {
                        handler.setValue(inputName, response.data.id);
                        infoTemp[order] = response.data;
                        setInfo(infoTemp);
                    } else if (response.status === 404) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                    dispatch(LinearProgressMainLayoutDeactivate());
                });
        }
    };

    React.useEffect(() => {
        if (info[1] && info[2] && info[3]) {
            setIsFullBlock(true);
        }
        else {
            setIsFullBlock(false);
        }
    }, [info]);

    if (isLock) {
        return (<></>)
    }
    else {
        return (
            <>
                {props.isAdmin &&
                    <>
                        <Grid container justifyContent="center" alignItems="center" spacing={2}>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order1'} handler={handler} options={optionLibraryResource} nullable={true} onChange={handlerChange} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order2'} handler={handler} options={optionLibraryResource} nullable={true} onChange={handlerChange} />
                            </Grid>
                            <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                                <SelectComponent name={'order3'} handler={handler} options={optionLibraryResource} nullable={true} onChange={handlerChange} />
                            </Grid>
                        </Grid>
                    </>
                }
                {(!(info[1] || info[2] || info[3]) && props.isAdmin) ?
                    <p style={{ textAlign: 'center' }}>Ce groupe de formation ne possède pas de ressources ...</p> :
                    <fieldset style={
                        props.isAdmin ? { maxWidth: 700, margin: '19px auto', border: `3px solid ${theme.colorPrimary}`, borderRadius: 10, position: 'relative' } :
                            { width: screenReducer.size <= 1330 ? 'auto' : 700, margin: screenReducer.size <= 1330 ? '19px auto' : '19px 0', border: '3px solid #FFF', borderRadius: 10, position: 'relative' }
                    }>
                        <legend style={{ margin: '0 0 0 auto' }}>
                            {props.isAdmin ?
                                <p className={classes.titleP} style={{ color: theme.colorPrimary }}>
                                    Apreçu de la vue
                                </p>
                                :
                                <p className={classes.titleP} >{(info[1] || info[2] || info[3]) ? 'Suggestions du mois' : ''}</p>
                            }
                        </legend>
                        {load
                            ?
                            <Grid id={'stepFeaturedResources'} container spacing={2} justifyContent="flex-end" direction='column' style={{ width: '100%', margin: 'auto', maxHeight: ((screenReducer.size <= 991) || !isFullBlock) ? 'initial' : 250 }}>
                                {Object.values(info).map((data, index) => (
                                    data && <Grid key={index} style={{ width: '100%' }} item xs={12} sm={12} md={((screenReducer.size <= 991) || !isFullBlock) ? 12 : 6} lg={((screenReducer.size <= 991) || !isFullBlock) ? 12 : 6} xl={((screenReducer.size <= 991) || !isFullBlock) ? 12 : 6}>
                                        {
                                            data.format === 6 || data.format === 2 || props.isAdmin ?
                                                <MediaLinkComponent
                                                    path={data.link}
                                                    type={data.format}
                                                    isLibrary={true}
                                                >
                                                    <ShadowBoxComponent className={`${classes.box} info_${index}`} style={{ height: ((parseInt(data.order) === 1) && (screenReducer.screen === 'LG' || screenReducer.screen === 'XL') && (isFullBlock)) ? 217 : (parseInt(data.order) === 1) ? 'auto' : 100, overflow: 'hidden' }}>
                                                        <div className={`${classes.boxImage} onHover`} style={{
                                                            backgroundImage: data.image ? `linear-gradient(80deg, rgba(255, 255, 255, 0.81) 0%, rgb(255, 255, 255) 80%), url(${LibraryResourceImage + data.image})` : '',
                                                            backgroundColor: '#FFF',
                                                            backgroundSize: 'cover',
                                                        }}>
                                                        </div>
                                                        <div style={{ position: 'relative', zIndex: 1 }}>
                                                            {data.type && <div className={`${classes.type} type-root`}>{data.type}</div>}
                                                            <div className={`boxtitle-root`}>
                                                                {data.title && <div className={`${classes.title} title-root`}>{data.title}</div>}
                                                                {data.subTitle && <div className={`${classes.subTitle} subTitle-root`}>{data.subTitle}</div>}
                                                            </div>
                                                        </div>
                                                    </ShadowBoxComponent>
                                                </MediaLinkComponent>
                                                :
                                                <ShadowBoxComponent
                                                    className={`${classes.box} info_${index}`}
                                                    style={{ height: ((parseInt(data.order) === 1) && (screenReducer.screen === 'LG' || screenReducer.screen === 'XL') && (isFullBlock)) ? 217 : (parseInt(data.order) === 1) ? 'auto' : 100, overflow: 'hidden' }}
                                                    onClick={() => {
                                                        props.history ?
                                                            props.history.push(getRoutePathname('learner_resource_type_resource', { id: data.typeId, resource: data.id }))
                                                            :
                                                            window.location.href = '/learner/resource-type/' + data.typeId + '/resource/' + data.id;

                                                    }}
                                                >
                                                    <div className={`${classes.boxImage} onHover`} style={{
                                                        backgroundImage: data.image ? `linear-gradient(80deg, rgba(255, 255, 255, 0.81) 0%, rgb(255, 255, 255) 80%), url(${LibraryResourceImage + data.image})` : '',
                                                        backgroundColor: '#FFF',
                                                        backgroundSize: 'cover',
                                                    }}>
                                                    </div>
                                                    <div className={`boxinfo-root`} style={{ position: 'relative', zIndex: 1 }}>
                                                        {data.type && <div className={`${classes.type} type-root`}>{data.type}</div>}
                                                        <div className={`boxtitle-root`}>
                                                            {data.title && <div className={`${classes.title} title-root`}>{data.title}</div>}
                                                            {(data.subTitle && (parseInt(data.order) === 1)) && <div className={`${classes.subTitle} subTitle-root`}>{data.subTitle}</div>}
                                                        </div>
                                                    </div>
                                                </ShadowBoxComponent>
                                        }
                                    </Grid>
                                ))}
                            </Grid>
                            :
                            <div style={{ width: '100%', margin: 'auto', }}>
                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 220, marginBottom: screenReducer.screen === 'XS' ? -5 : 0 }} />
                                    </Grid>
                                    <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                                        <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 105, marginBottom: 10 }} />
                                        <Skeleton animation="wave" variant="rect" style={{ height: screenReducer.screen === 'XS' ? 100 : 105 }} />
                                    </Grid>
                                </Grid>
                            </div>
                        }
                    </fieldset>
                }
                {props.isAdmin &&
                    <>
                        <br />
                        <ButtonComponent
                            style={{ float: 'right', margin: 5 }}
                            label={'Enregistrer'}
                            onClick={save}
                        />
                    </>
                }
            </>
        );
    }

}

const useStyles = (theme, screenReducer) => makeStyles(({
    box: {
        cursor: 'pointer',
        transition: 'all .3s ease-in-out',
        borderRadius: 10,
        '&.info_0': {
            display: 'grid',
            '& .type-root': {
                color: '#FFF',
                background: theme.colorPrimary
            },
            '&:after': {
                content: '"Accéder"',
                color: theme.colorPrimary,
                border: '2px solid',
                borderRadius: 5,
                zIndex: 1,
                position: 'relative',
                fontSize: 13,
                padding: '3px 15px',
                display: 'block',
                width: 'fitContent',
                fontWeight: 'bold',
                margin: 'auto 0 0 auto'
            }
        },
        '&.info_1, &.info_2': {
            '& .onHover': {
                background: '#FFF !important',
            },
            '& .boxinfo-root': {
                display: 'grid',
                gridTemplateRows: 'max-content auto',
                height: '100%',
                '& .type-root': {
                    background: '#FFF !important',
                    margin: '0 0 -11px',
                    zIndex: 1,
                    borderRadius: '5px 5px 5px 0'
                },
                '& .boxtitle-root': {
                    border: '2px solid ' + theme.colorPrimary,
                    borderRadius: 5,
                    display: 'grid',
                    '& .title-root': {
                        padding: '0 10px',
                        margin: 'auto 0',
                        fontSize: 13
                    },
                    '& .subTitle-root': {
                        display: 'none'
                    }
                }
            }
        },
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },

    boxImage: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'all .3s ease-in-out',
    },
    type: {
        color: theme.colorPrimary,
        textAlign: 'left',
        margin: '0 0 5px 0',
        width: 'fit-content',
        padding: '3px 15px',
        fontSize: 12,
        fontWeight: 600,
        border: '2px solid' + theme.colorPrimary,
        borderRadius: 5
    },
    title: {
        textAlign: 'left',
        fontWeight: 600,
        fontSize: 15,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 2,
        lineHeight: '17px',
    },
    subTitle: {
        textAlign: 'left',
        fontSize: 13,
        display: '-webkit-box',
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        '-webkit-line-clamp': 3,
        lineHeight: '17px',
    },
    titleP: {
        width: 'max-content',
        margin: 0,
        padding: '0 10px',
        fontWeight: 500,
    },
}));

export default FeaturedResourceComponent;
