import React from 'react';
import Api from "../../../../Api";
import { Box, IconButton, Tooltip, makeStyles, Drawer as DrawerMUI, Grid } from '@material-ui/core';
import TableComponent from '../../../../Component/TableComponent';
import { formHandlerInit } from '../../../../Tool/FormHandlerCommon';
import TextFieldComponent from '../../../../Component/TextFieldComponent';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import FilterListIcon from "@material-ui/icons/FilterList";
import { useDispatch, useSelector } from 'react-redux';
import { TableReload } from '../../../../Action/TableAction';
import { cacheTablePage, cacheTableServer } from "../../../../Cache";
import DatePickerComponent from '../../../../Component/DatePickerComponent';
import ButtonComponent from '../../../../Component/ButtonComponent';
import SubjectIcon from '@material-ui/icons/Subject';
import SelectComponent from '../../../../Component/SelectComponent';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import { getRoutePathname } from '../../../../Config/Route';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import moment from 'moment';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import { AttachMoney, MoneyOff } from '@material-ui/icons';

export default function InvoiceList(props) {

    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const classes = useStyles();
    const dispatch = useDispatch();
    const [dataEdit, setDataEdit] = React.useState(null);
    const [isOpen, setIsOpen] = React.useState(false);
    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isScreen, setIsScreen] = React.useState(true);
    const [filtersData, setFiltersData] = React.useState([]);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const currentYear = new Date().getFullYear();

    //Options
    const [optionYears, setOptionYears] = React.useState([]);
    const [optionsState, setOptionsState] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Annuler' },
        { value: 2, label: 'Payer' },
        { value: 3, label: 'Non payer' },
    ]);

    const [optionsType, setOptionsType] = React.useState([
        { value: 'all', label: 'Tous' },
        { value: 1, label: 'Personnel' },
        { value: 2, label: 'Établissement' },
        { value: 3, label: 'Organisme payeur' },
    ]);

    React.useEffect(() => {
        // Year
        let yearsTMP = [];
        const nextYear = currentYear + 1;
        yearsTMP.push({ label: nextYear, value: nextYear });
        for (let i = currentYear; i > currentYear - 5; i--) {
            yearsTMP.push({ label: i, value: i });
        }
        setOptionYears(yearsTMP);
        setReadyContent(true);
    }, []);

    const invoiceColumns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "label",
            label: "Numéro",
            options: { filter: true, sort: true }
        },
        {
            name: "estimateLabel",
            label: "Numéro de devis",
            options: { filter: true, sort: true }
        },
        {
            name: "type",
            label: "Type de facture",
            options: { filter: true, sort: true }
        },
        {
            name: "institutionName",
            label: "Nom de l'établissement",
            options: { filter: true, sort: true }
        },
        {
            name: "financeType",
            label: "Type de financement",
            options: { filter: true, sort: true }
        },
        {
            name: "billingAt",
            label: "Date de facturation",
            options: { filter: true, sort: true }
        },
        {
            name: "paidAt",
            label: "Date de paiement",
            options: { filter: true, sort: true }
        },
        {
            name: "cancelAt",
            label: "Date d'annulation",
            options: { filter: true, sort: true }
        },
        {
            name: "state",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "amountHt",
            label: "Prix HT",
            options: { filter: true, sort: true }
        },
        {
            name: "amountTtc",
            label: "Prix TTC",
            options: { filter: true, sort: true }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    const getAction = (row) => {
        let route = '';
        let data = {};
        if (row?.sessionFormation) {
            route = 'institution_health_financial_management_billing_formation'
            data = { id: row?.sessionFormation }
        } else {
            route = 'institution_health_financial_management_billing_institution_contract_invoice_list'
            data = { institution: row?.institution, id: row?.contract }
        }
        return (
            <div style={{ minWidth: 90 }}>
                <Tooltip title={'Visualiser'} placement="left">
                    <IconButton onClick={() => {
                        window.open(getRoutePathname(route, data))
                    }}>
                        <LibraryBooksIcon />
                    </IconButton>
                </Tooltip>

                {!row.cancelAt && <Tooltip title={row.isPaid ? 'Annuler le paiement' : 'Valider le paiement'} placement="left">
                    <IconButton onClick={() => {
                        setDataEdit(row);
                    }}>
                        {row.isPaid ? <MoneyOff /> : <AttachMoney />}
                    </IconButton>
                </Tooltip>}
            </div>
        );
    };

    const [formSearch, setFormSearch] = React.useState({
        label: {
            name: 'label',
            label: 'Numéro',
            textHelper: 'Saisissez un numéro.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].label ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].label : '',
            options: {}
        },
        institution: {
            name: 'institution',
            label: 'Établissement',
            textHelper: 'Rechercher un établissement.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].institution ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].institution : '',
            options: {}
        },
        year: {
            name: 'year',
            label: 'Année',
            textHelper: 'Trier par année.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].year ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].year : currentYear,
            options: {}
        },
        type: {
            name: 'type',
            label: 'Type',
            textHelper: 'Trier par type',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].type ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].type : 'all',
            options: {}
        },
        state: {
            name: 'state',
            label: 'État',
            textHelper: 'Trier par état',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].state ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].state : 'all',
            options: {}
        },
        startAt: {
            name: 'startAt',
            label: 'Début de session',
            textHelper: 'Trier par début de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].startAt ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].startAt : '',
            options: {}
        },
        endAt: {
            name: 'endAt',
            label: 'Fin de session',
            textHelper: 'Trier par fin de session.',
            type: 'text',
            defaultValue: cacheTableServer['institution_health_financial_management_billing_invoice_list'] && cacheTableServer['institution_health_financial_management_billing_invoice_list'].endAt ? cacheTableServer['institution_health_financial_management_billing_invoice_list'].endAt : '',
            options: {}
        },
    });

    const handlerSearch = formHandlerInit(formSearch, setFormSearch);
    React.useEffect(handlerSearch.start, []);

    const inputSearchChange = (index, value) => {
        handlerSearch.setValue(index, value);
        cacheTablePage['institution_health_financial_management_billing_invoice_list'] = 0;
        cacheTableServer['institution_health_financial_management_billing_invoice_list'].page = 0;
        cacheTableServer['institution_health_financial_management_billing_invoice_list'][index] = value;
        dispatch(TableReload('institution_health_financial_management_billing_invoice_list'));
    };

    const filter = () => {
        return (
            <Box style={{ width: '90%', height: '100%', margin: 'auto', }}>
                <Box className={classes.filterStyle}>FILTRE<hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    {!isScreen &&
                        <IconButton style={{ padding: 5 }} onClick={() => setIsOpen(false)}>
                            <HighlightOffIcon />
                        </IconButton>
                    }
                </Box>
                <Grid container spacing={2} style={{ width: '100%', margin: 0 }}>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'year'} handler={handlerSearch} options={optionYears} onChange={(val) => inputSearchChange('year', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'type'} handler={handlerSearch} options={optionsType} onChange={(val) => { inputSearchChange('type', val); }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <SelectComponent name={'state'} handler={handlerSearch} options={optionsState} onChange={(val) => { inputSearchChange('state', val); }} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'label'} handler={handlerSearch} onChange={(val) => inputSearchChange('label', val)} />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextFieldComponent name={'institution'} handler={handlerSearch} onChange={(val) => inputSearchChange('institution', val)} />
                    </Grid>
                </Grid>
                <>
                    <Box className={classes.filterStyle}>
                        <hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                        <p style={{ margin: 0, padding: '0 10px' }}>Session</p>
                        <hr style={{ width: '70%', border: '#5E6E82 1px solid', borderRadius: 10, opacity: 0.4 }} />
                    </Box>
                    <Grid container spacing={1} style={{ width: '100%', margin: 0 }}>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DatePickerComponent name={'startAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('startAt', val)} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12}>
                            <DatePickerComponent name={'endAt'} handler={handlerSearch} onChange={(val) => inputSearchChange('endAt', val)} />
                        </Grid>
                    </Grid>
                </>
            </Box>
        )
    }

    const savePaid = (data) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_invoice_paid',
            data: { isPaid: !data.isPaid, id: data.id },
            params: { institution: data.institution, contract: data.contract }
        }, (response) => {
            dispatch(LinearProgressMainLayoutDeactivate());
            dispatch(TableReload('institution_health_financial_management_billing_invoice_list'))
            if (response.status === 200) {
                dispatch(
                    SnackbarOpen({
                        text: !data.isPaid ? 'Paiement valider' : 'Paiement Annuler',
                        variant: 'success',
                        duration: 4000
                    })
                );
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const GetActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    color={"#5E6E82"}
                    onClick={() => setIsOpen(!isOpen)}
                    label={<span style={{ display: 'flex', gap: 5, alignItems: 'center' }}><FilterListIcon style={{ color: '#FFFFFF' }} />Filtre</span>}
                />
            </Box>
        )
    }

    React.useEffect(() => {
        dispatch(LinearProgressMainLayoutDeactivate());
        setBreadcrumbs({
            title: 'Suivi de facturation',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: 'Liste de suivi de facturation.',
            html: <GetActionMore />
        });
    }, [isOpen, filtersData]);



    React.useEffect(() => {
        if (screenReducer.screen) {
            setIsScreen(screenReducer.screen === 'LG' || screenReducer.screen === 'XL');
        }
    }, [screenReducer.screen]);

    return (
        <Box className={classes.styleContentView}>
            <DrawerMUI
                variant="permanent"
                className={`${classes.drawer} ${isOpen ? classes.drawerOpen : classes.drawerClose}`}
                style={!isScreen ? { position: 'absolute', zIndex: 10 } : {}}
                open={isOpen}>
                {filter()}
            </DrawerMUI>
            <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
                <Box className={classes.TableComponent} style={{ width: '95%' }}>
                    <TableComponent
                        id={'institution_health_financial_management_billing_invoice_list'}
                        title={
                            <h1 style={{ margin: 0, fontSize: 17, display: 'flex', alignItems: 'center', gap: 5 }}>
                                <SubjectIcon style={{ width: 20, height: 20 }} />
                                Liste des factures
                            </h1>
                        }
                        search={false}
                        columns={invoiceColumns}
                        promiseServerData={(resolve, options) => {

                            let data = handlerSearch.getData();
                            let filters = {
                                limit: options.rowsPerPage,
                                offset: options.page * options.rowsPerPage,
                                sortName: options.sortOrder.name,
                                sortDirection: options.sortOrder.direction,
                                year: data.year ? data.year : '',
                                label: data.label ? data.label : '',
                                institution: data.institution ? data.institution : '',
                                type: (data.type === 'all' || !data.type) ? '' : data.type,
                                state: (data.state === 'all' || !data.state) ? '' : data.state,
                                startAt: data.startAt ? data.startAt : '',
                                endAt: data.endAt ? data.endAt : '',
                            };

                            Api.get({
                                route: 'institution_health_financial_management_billing_invoice_list',
                                data: { ...filters, organism: selectOrganismReducer.organism.value }
                            },
                                (response) => {
                                    if (response.status === 200) {
                                        let data = response.data.data;
                                        for (let index in data) {
                                            data[index].action = getAction(data[index]);
                                            data[index].label = data[index].label ? data[index].label : '-';
                                            data[index].estimateLabel = data[index].estimateLabel ? data[index].estimateLabel : '-';
                                            data[index].type = data[index].type ? data[index].type : '-';
                                            data[index].createdAt = data[index].createdAt ? moment(data[index].createdAt).format('lll') : '-';
                                            data[index].billingAt = data[index].billingAt ? moment(data[index].billingAt).format('lll') : '-';
                                            data[index].paidAt = data[index].paidAt ? moment(data[index].paidAt).format('lll') : '-';
                                            data[index].cancelAt = data[index].cancelAt ? moment(data[index].cancelAt).format('lll') : '-';
                                            data[index].amountTtc = <span style={{ color: '#007bff', fontWeight: 'bold' }}>{data[index].amountTtc ?? 0} €</span>;
                                            data[index].amountHt = <span style={{ color: '#007bff', fontWeight: 'bold' }}>{data[index].amountHt ?? 0} €</span>;

                                            let stateColor = '#000000de'
                                            if (data[index].state === 'Payé') {
                                                stateColor = '#28a745'
                                            } else if (data[index].state === 'Annuler') {
                                                stateColor = '#DC3545'
                                            } else if (data[index].state === 'Non payé') { stateColor = '#db770a' }
                                            data[index].state = <span style={{ color: stateColor, fontWeight: 'bold' }}>{data[index].state ?? '-'} </span>;
                                        }
                                        resolve(data, response.data.count);
                                    } else resolve([]);
                                });
                        }}
                    />
                </Box>
                <SweetAlert
                    show={dataEdit ? true : false}
                    title={dataEdit?.isPaid ? 'Annuler le paiement' : 'Valider le paiement'}
                    text={
                        dataEdit?.isPaid ?
                            `Êtes-vous sûr de vouloir annuler le paiement ?` :
                            `Êtes-vous sûr de vouloir valider le paiement ?`
                    }
                    type={'warning'}
                    cancelButtonText={'Non'}
                    confirmButtonText={'Oui'}
                    showCancelButton={true}
                    onConfirm={() => {
                        setDataEdit(null);
                        savePaid(dataEdit)
                    }}
                    onCancel={() => setDataEdit(null)}
                />
            </ContentViewComponent>
        </Box>
    );
}

const drawerOpenWidth = 340;
const drawerCloseWidth = 0;
const useStyles = makeStyles(style => ({
    TableComponent: {
        height: '90%',
        width: '95%',
        margin: 'auto',
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                height: '100%',
                display: 'grid',
                gridTemplateRows: ' min-content min-content auto min-content min-content',
                overflow: 'hidden',
                '& div .MuiTable-root': {
                    position: 'absolute'
                }
            }
        },
        '& .MuiTableCell-head:last-child': {
            width: 145,
            textAlign: 'center'
        }
    },
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        '& .ContentView-root': {
            height: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto',
            width: '100%',
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    drawer: {
        width: drawerOpenWidth,
        height: '100%',
        flexShrink: 0,
        whiteSpace: 'nowrap',
        boxShadow: '-1px 0px 2px rgba(0,0,0,0.2)',
        background: '#FFFFFF',
        overflowX: 'hidden',
        zIndex: 1,
        boxSizing: 'border-box',
        '& .MuiDrawer-paperAnchorDockedLeft': {
            border: 0
        },
        '& .MuiDrawer-paper': {
            height: '100%',
            boxSizing: 'border-box',
            width: '100%',
            overflowX: 'hidden',
            position: 'relative'
        }
    },
    drawerOpen: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.enteringScreen,
        }),
        width: drawerOpenWidth
    },
    drawerClose: {
        transition: style.transitions.create('width', {
            easing: style.transitions.easing.sharp,
            duration: style.transitions.duration.leavingScreen,
        }),
        width: drawerCloseWidth
    },
    filterStyle: {
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        padding: '19px 19px 10px 19px',
        color: '#5E6E82', opacity: 0.7, fontSize: 14
    }
}))
