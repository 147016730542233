import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { dispatch, theme } from "../../../App";
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import CopyrightIcon from '@material-ui/icons/Copyright';
import { AuthenticationLogout } from '../../../Action/AuthenticationAction';
import Api from '../../../Api';
import { getRoutePathname } from '../../../Config/Route';
import { useSelector } from 'react-redux';

export default function Footer(props) {
    const classes = useStyles(theme)();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const goToTop = (e) => { e.target.closest('#layout').scrollTo(0, 0); }

    return (
        <div className={classes.footer}>
            <Grid container spacing={2} style={{ margin: 'auto', marginTop: 20, width: (screenReducer.size >= 1550) && !props?.pageRegistration ? 1450 : '90%' }}>
                <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                    <img src={'/asset/images/' + theme.logo} alt="logo" style={{ height: theme.id ? 35 : 25, margin: '15px 0px' }} />
                    <div style={{ cursor: 'pointer', width: 'fit-content', }} onClick={() => window.open('mailto:' + theme.mail)}><EmailIcon className={classes.icon} /><span>{theme.mail}</span></div>
                    <div style={{ cursor: 'pointer', width: 'fit-content', }} onClick={() => window.open('tel:' + theme.phone)}><PhoneIcon className={classes.icon} /><span>{theme.phone}</span></div>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <p className={classes.title}>Liens utiles</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_home')); }}>Tableau de bord</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_catalog')); }}>Catalogue</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_library_resource')); }}>Ressources</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_community')); }}>Forum</p>
                </Grid>
                <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
                    <p className={classes.title}>Mon compte</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_my_info')); }}>Mes informations</p>
                    <p className={classes.link} onClick={(e) => { goToTop(e); props.history.push(getRoutePathname('learner_document')); }}>Mes documents</p>
                    <p className={classes.link} onClick={() => window.open(theme.faq, "_blank")}>FAQ</p>
                    <p className={classes.link} onClick={() => window.open('mailto:' + theme.tutor_mail)}>Contact tuteur</p>
                    <p className={classes.link}
                        onClick={() => {
                            dispatch(AuthenticationLogout());
                            Api.get({ route: 'authentication_logout' }, () => { });
                        }}>
                        Déconnexion
                    </p>
                </Grid>
            </Grid>
            <p className={classes.copyright}><CopyrightIcon style={{ verticalAlign: 'bottom', fontSize: 9 }} /> {new Date().getFullYear()} Santé Formapro - Tous droits réservés</p>
        </div>
    );
}

const useStyles = (theme) => makeStyles({
    footer: {
        width: '100%',
        borderTop: '1px solid #DADADA',
        fontSize: 13,
        color: '#5E6E82',
        margin: 'auto 0 0 0 !important',
        height: 'initial !important',
        background: '#FFF',
        position: 'relative',
        zIndex: 1
    },
    title: {
        fontSize: 17,
        fontWeight: 700,
        color: theme.colorPrimary,
    },
    icon: {
        fontSize: 13,
        verticalAlign: 'middle',
        marginRight: 3
    },
    link: {
        cursor: 'pointer',
        color: '#ABABAB',
        width: 'fit-content',
        margin: 5,
        fontWeight: 600,
        '&:hover': {
            opacity: 0.6
        }
    },
    copyright: {
        textAlign: 'center',
        fontSize: 9,
        color: '#ABABAB',
        padding: 10
    }
});
