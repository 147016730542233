import { Box, Checkbox, Fade, IconButton, Tooltip, Zoom, makeStyles, withStyles } from '@material-ui/core';
import { Assignment, PersonAddDisabled } from '@material-ui/icons';
import WarningIcon from '@material-ui/icons/Warning';
import moment from 'moment';
import React from 'react';
import UseAnimations from 'react-useanimations';
import activity from "react-useanimations/lib/activity";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../../../Action/LinearProgressMainLayoutAction';
import { SnackbarOpen } from '../../../../../../Action/SnackbarAction';
import Api from '../../../../../../Api';
import { dispatch } from '../../../../../../App';
import TableComponent from '../../../../../../Component/TableComponent';
import { getRoutePathname } from '../../../../../../Config/Route';
import { hourBySecondClassic } from "../../../../../../Tool/convert";
import { TableReload } from "../../../../../../Action/TableAction";
import CircularProgress from "@material-ui/core/CircularProgress";
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import Action from './Action';
import { useSelector } from 'react-redux';

const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: '#5f5f5f',
        color: '#FFF',
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
        maxWidth: 'none',
    },
}))(Tooltip);

function ProgressUnit(props) {

    const getInfo = (value, type) => {
        let color = '#DADADA';
        let status = '';

        if (value.isCompleted) {
            color = '#28a745';
            status = 'Complété'
        }
        else {
            color = '#dc3545';
            status = 'Non complété'
        }

        if (value.error.length > 0) {
            let foundErrorDate = value.error.some(element => element.includes('date'));
            if (foundErrorDate) {
                color = '#dc3545';
            }
            else {
                color = '#f59912';
            }
            status = 'Erreur potentielle détecté'
        }

        if (props.state === 2 || props.state === 3) {
            color = '#DADADA';
        }

        if (type === 'color') {
            return color;
        }
        else if (type === 'status') {
            return status;
        }
    };

    const getTooltip = (value) => {

        return <div style={{ fontSize: 11 }}>
            <p style={{ fontSize: 13, textAlign: 'center' }}>Unité {value.orderBy}: {value.type}</p>
            <p style={{ margin: 2 }}>Status: {getInfo(value, 'status')}</p>
            <p style={{ margin: 2 }}>Complétion : {value.completionCourse} %</p>
            {
                (value.error && value.error.length > 0) &&
                <>
                    {value.error.map((error, i) => {
                        return (
                            <p key={i} style={{ display: 'flex', alignItems: 'center' }}>
                                <WarningIcon color="secondary" fontSize="small" style={{ padding: 5 }} />
                                <span dangerouslySetInnerHTML={{ __html: error }} />
                            </p>
                        )
                    })}
                </>
            }
        </div>
    }

    return <>
        <Box display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-evenly">
            {(props.value && props.value.length > 0) ?
                props.value.map((prop, i) => (
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <HtmlTooltip title={(props.state === 2 || props.state === 3) ? '' : getTooltip(prop)} TransitionComponent={Zoom} arrow key={i}>
                            <span
                                style={{
                                    width: 25,
                                    height: 13,
                                    color: '#fff',
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    borderRadius: 6,
                                    border: '1px solid' + getInfo(prop, 'color'),
                                    background: getInfo(prop, 'color') + 'e6',
                                    marginRight: 3,
                                    marginBottom: 3,
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center'
                                }}>
                                {prop.orderBy}
                            </span>
                        </HtmlTooltip>
                        <span style={{ fontSize: 11, fontWeight: 600 }}>{prop.completionCourse}%</span>
                    </div>

                ))
                :
                <Tooltip title={'Unitées non renseignées'}>
                    <WarningIcon color="secondary" fontSize="small" />
                </Tooltip>
            }
        </Box>
    </>
}

function RegistrationsANDPC(props = {
    reloadList: () => { }
}) {
    const classes = useStyles();
    const selectOrganismReducer = useSelector(state => state.SelectOrganismReducer);
    const [propsReady, setPropsReady] = React.useState(false);
    const [isLock, setIsLock] = React.useState(true);
    const [dialog, setDialog] = React.useState(false);
    const [registration, setRegistration] = React.useState([]);
    const [disabledCheckbox, setDisabledCheckbox] = React.useState(false);
    const [isBillable, setIsBillable] = React.useState(true);
    const [loading, setLoading] = React.useState(false);

    let columns = [
        {
            name: "id",
            label: "Référence",
            options: { filter: true, sort: true }
        },
        {
            name: "prospectName",
            label: "Nom / Prénom",
            options: { filter: true, sort: true }
        },
        {
            name: "finishAtFormat",
            label: "Formation terminée le",
            options: { filter: true, sort: true }
        },
        {
            name: "availableAtFormat",
            label: "Date d\'inscription",
            options: { filter: true, sort: true }
        },
        {
            name: "durationTotalSessionText",
            label: "Temps Total",
            options: { filter: true, sort: true }
        },
        {
            name: "durationTotalText",
            label: "-- Temps --",
            options: { filter: true, sort: true }
        },
        {
            name: "stateText",
            label: "État",
            options: { filter: true, sort: true }
        },
        {
            name: "completionByUnitComponent",
            label: "Complétion des unités",
            options: { filter: true, sort: false }
        },
        {
            name: "action",
            label: "Action",
            options: { filter: false, sort: false }
        }
    ];

    if (Object.keys(props.sessionFormation).length > 0) {
        if (props.billing.registrations.length > 0) {
            let registrations = props.billing.registrations;
            let processedKeys = new Set();

            for (let index in registrations) {
                let certificates = registrations[index]['offlineCertificate'];
                for (let key in certificates) {
                    if (!processedKeys.has(key)) {
                        columns.splice(columns.length - 1, 0,
                            {
                                name: certificates[key].type,
                                label: "Certificat " + certificates[key].type,
                                options: { filter: true, sort: true }
                            }
                        );
                        processedKeys.add(key);
                    }
                }
            }
        }
    }


    const cancel = (data, isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        Api.post({
            route: 'institution_health_financial_management_billing_session_formation_billable_cancel',
            data: { type: 1, isBillable: isBillable, organismId: selectOrganismReducer.organism.value },
            params: { uuid: data.institutionInvoiceRegistrationContract, sessionFormation: props.match.params.id },
        },
            (response) => {
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Le contact a bien été désinscrit de la formation.',
                            variant: 'success',
                        })
                    );
                    props.reloadList();
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
                dispatch(LinearProgressMainLayoutDeactivate());
            });
    };

    const getAction = (row) => {
        return (
            <div style={{ width: 'max-content' }}>
                {/*<Tooltip title={'Visualiser la fiche contact'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname('institution_health_crm_institution_employee', { institution: row.institutionId, id: row.employee }), '_blank').focus();
                        }} disabled={row.state.value === 3}>
                            <Assignment />
                        </IconButton>
                    </span>
                </Tooltip>*/}
                <Tooltip title={'Facture avant formation'} placement="left">
                    <span>
                        <IconButton onClick={() => {
                            window.open(getRoutePathname(
                                !row.isValidated ?
                                    'institution_health_financial_management_billing_institution_contract'
                                    : 'institution_health_financial_management_billing_institution_contract_invoice_list'
                                , { institution: row.institutionId, id: row.contractId }))
                        }} disabled={[2, 3].includes(row.state.value)} >
                            <LibraryBooksIcon />
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={row.isBillable ? 'Passer en facturable' : 'Passer en non facturable'} placement="left">
                    <span>
                        <IconButton
                            disabled={!isBillable || row.state.value === 9}
                            onClick={() => { cancel(row, !row.isBillable); }}>
                            {row.isBillable ?
                                <PersonAddIcon />
                                : <PersonAddDisabled />
                            }
                        </IconButton>
                    </span>
                </Tooltip>
            </div>
        );
    };

    const getCheckbox = (certificate, registration) => {
        return (
            disabledCheckbox ? <CircularProgress size={15} className={classes.circularProgress} /> :
                <Checkbox
                    key={certificate.type + new Date().getTime()}
                    defaultChecked={!!certificate.isReceived}
                    onChange={(event) => {
                        dispatch(LinearProgressMainLayoutActivate());
                        setDisabledCheckbox(true);
                        dispatch(TableReload('institution_health_financial_management_billing_registration_andpc_list'));
                        Api.post({
                            route: 'institution_health_financial_management_billing_registration_edit',
                            data: {
                                'type': certificate.type,
                                'isReceived': event.target.checked,
                            },
                            params: { registration: registration.id }
                        },
                            (response) => {
                                dispatch(LinearProgressMainLayoutDeactivate());

                                if (response.status === 200) {
                                    setDisabledCheckbox(false);
                                    props.reloadList();
                                    dispatch(
                                        SnackbarOpen({
                                            text: 'Modification enregistrée.',
                                            variant: 'success',
                                        })
                                    );
                                }
                                else {
                                    dispatch(
                                        SnackbarOpen({
                                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                            variant: 'error',
                                        })
                                    );
                                }
                            });
                    }}
                />
        );
    };

    React.useEffect(() => {
        setPropsReady(true);
        setIsBillable(props.billing.isBillable);
    }, [props.billing]);

    const getDataTable = (registrations, billing) => {
        let r = registrations;
        for (let index in r) {
            r[index]['finishAtFormat'] = r[index]['finishAt'] !== '-' ? moment(r[index]['finishAt']).format('lll') : '-';
            r[index]['availableAtFormat'] = r[index]['availableAt'] !== '-' ? moment(r[index]['availableAt']).format('lll') : '-';
            r[index]['durationTotalSessionText'] = <span>{hourBySecondClassic(r[index]['durationTotalSession'])}</span>;
            r[index]['durationTotalText'] = <span>{hourBySecondClassic(r[index]['durationTotal'])}</span>;
            r[index]['completionByUnitComponent'] = <div style={{ width: 180 }}><ProgressUnit value={r[index]['completionByUnit']} state={r[index]['state']} /></div>;
            r[index]['stateText'] = <span style={{ color: r[index]['state']['color'] }}>{r[index]['state']['text']}</span>;
            r[index]['action'] = getAction(r[index], billing);
            let certificates = r[index]['offlineCertificate'];
            for (let key in certificates) {
                r[index][certificates[key].type] = getCheckbox(certificates[key], r[index]);
            }
        }
        return r;
    };

    const getIsBillable = () => {
        return (
            <span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                {(props.billing && props.billing.state) && (
                    <>
                        {
                            (() => {
                                let chipColor = '#28a745';
                                switch (props.billing.state) {
                                    case 1:
                                        chipColor = '#007bff';
                                        break;
                                    case 2:
                                        chipColor = '#28a745';
                                        break;
                                    case 3:
                                        chipColor = '#db770a';
                                        break;
                                    case 4:
                                        chipColor = '#9c27b0';
                                        break;
                                }
                                return (
                                    <span style={{ background: chipColor }} className={classes.chip}>{props.billing.stateText}</span>
                                );
                            })()
                        }
                    </>
                )}
            </span>
        )
    }

    const billable = (isBillable) => {
        dispatch(LinearProgressMainLayoutActivate());
        setLoading(true);
        Api.post({
            route: 'institution_health_financial_management_billing_session_formation_billable',
            params: { sessionFormation: props.match.params.id },
            data: { isBillable: isBillable, financeTypeId: 1, organismId: selectOrganismReducer.organism.value }
        },
            (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);

                if (!isBillable) setIsLock(true);
                setIsBillable(isBillable);
                props.reloadList();
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: isBillable ? 'Session formation facturable.' : 'Session formation non facturable.',
                            variant: 'success',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }

    return (
        <>
            {
                propsReady ?
                    <div className={classes.tabStyle}>
                        {(props && props.billing && props.billing.registrations) &&
                            <>
                                <TableComponent
                                    id={'institution_health_financial_management_billing_registration_andpc_list'}
                                    minHeight={'calc(100vh - 515px)'}
                                    title={<span style={{ display: 'flex', alignItems: 'center', gap: 5 }}>Liste des inscriptions ANDPC {getIsBillable()}</span>}
                                    onBeforeRefresh={props.reloadList}
                                    columns={columns}
                                    actionThird={{
                                        label: isBillable ? 'Passer en non facturable' : 'Passer en facturable',
                                        onClick: () => { billable(isBillable ? false : true) },
                                        disabled: isBillable ? (loading || props.billing.state === 2 || props.billing.state === 4 || props.billing.registrations.length === 0) : (loading || props.billing.registrations.length === 0),
                                        color: isBillable ? '#DC3545' : '#35A2EB'
                                    }}
                                    actionSecond={{
                                        label: 'Action',
                                        onClick: () => setDialog(true),
                                        disabled: loading || props.billing.registrations.length === 0,
                                        color: '#728092'
                                    }}
                                    search={false}
                                    promiseData={(resolve) => {
                                        if (props.billing && props.billing.registrations) {
                                            setTimeout(() => {
                                                resolve(getDataTable(props.billing.registrations, props.billing));
                                            }, 50);
                                        }
                                        else {
                                            resolve([])
                                        }
                                    }}
                                />
                                <Action open={dialog} onClick={(value) => setDialog(value)} {...props} />
                            </>
                        }
                    </div>

                    :

                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Box className={classes.loader}>
                            <Box className={classes.loaderContent}>
                                <Box className={classes.loaderActivity}>
                                    <UseAnimations animation={activity} size={70} />
                                </Box>
                            </Box>
                        </Box>
                    </Fade>
            }
            <SweetAlert
                show={registration.length != 0}
                title={'Désinscription'}
                text={
                    'Êtes-vous sûr de vouloir désinscrit ?'
                }
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    cancel(registration.id);
                    setRegistration([])
                }}
                onCancel={() => setRegistration([])}
            />
        </>
    );
};

const useStyles = makeStyles({
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center',
        width: '100%',
        height: '100%',
        display: 'flex',
        marginTop: 50,
        marginBottom: 50
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF', border: '5px double rgb(206, 200, 200)', borderRadius: 100
    },
    chip: {
        color: '#fff',
        fontSize: 13,
        padding: '3px 10px',
        display: 'block',
        width: 'fit-content'
    },
    tabStyle: {
        position: 'relative',
        height: '100%',
        '& span': {
            margin: 0,
        },
        '& >div': {
            height: '100%',
            '& .MuiPaper-root': {
                boxShadow: 'none',
                borderRadius: 0,
                height: '100%',
            }
        }
    }
});

export default RegistrationsANDPC;
