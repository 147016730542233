import React from 'react';
import { Box, Drawer as DrawerMUI, Fade, Grid, IconButton, Slide, Tooltip, colors, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import ContentViewComponent from '../../../../Component/ContentViewComponent';
import { getRoutePathname } from '../../../../Config/Route';
import ButtonComponent from '../../../../Component/ButtonComponent';
import Api from '../../../../Api';
import { SnackbarOpen } from '../../../../Action/SnackbarAction';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from '../../../../Action/LinearProgressMainLayoutAction';
import moment from 'moment';
import ReplayIcon from '@material-ui/icons/Replay';
import CircularProgress from "@material-ui/core/CircularProgress";
import InvoiceRegistrationList from './InvoiceRegistrationList';

export default function BillingInstitutionInvoice(props) {
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles()();
    const dispatch = useDispatch();
    const [isOpenDiscount, setIsOpenDiscount] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [loading, setLoading] = React.useState(false);
    const [reload, setReload] = React.useState(false);
    const [institution, setInstitution] = React.useState([]);
    const [invoicesList, setInvoicesList] = React.useState([]);
    const [invoicesAfterList, setInvoicesAfterList] = React.useState([]);

    const getActionMore = () => {
        return (
            <Box style={{ display: 'flex', gap: 12 }}>
                <ButtonComponent
                    onClick={() => {
                        dispatch(LinearProgressMainLayoutActivate());
                        setReload(true);
                        getInstitution(props.match.params.id);
                    }}
                    disabled={reload}
                    color={"#5E6E82"}
                    style={{ padding: 4, minWidth: 40 }}
                    label={reload ? <CircularProgress size={18} /> : <ReplayIcon style={{ color: '#FFF' }} />}
                />
            </Box>
        )
    }

    const getInstitution = (id) => {
        if (props.match.params.institution) {
            Api.get({
                route: 'institution_health_financial_management_billing_institution_contract_invoice_list',
                params: { institution: props.match.params.institution, contract: id }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoading(false);
                setReload(false);
                if (response.status === 200) {
                    let data = response.data;
                    setInstitution(data);
                    setInvoicesList((data.invoices && data.invoices.length != 0) ? data.invoices : []);
                    setInvoicesAfterList((data.registrationAfterList && data.registrationAfterList.length != 0) ? data.registrationAfterList : []);
                } else {
                    props.history.push(getRoutePathname('institution_health_financial_management_billing_institution_contract_list', { institution: props.match.params.institution }));
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'warning',
                        })
                    );
                }
            });
        }
    }

    const saveConvention = (data, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);

        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_invoice_convention',
            data: { institutionInvoiceRegistrationContract: data.institutionInvoiceRegistrationContract, isConvention: checked },
            params: { institution: props.match.params.institution, contract: props.match.params.id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: checked ? 'Convention valider' : 'Convention Annuler',
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const savePaid = (data, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);
        const id = data.id

        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_invoice_paid',
            data: { id: id, isPaid: checked },
            params: { institution: props.match.params.institution, contract: props.match.params.id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: checked ? 'Paiement valider' : 'Paiement Annuler',
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    const saveANDPC = (data, checked, setIsLoading) => {
        dispatch(LinearProgressMainLayoutActivate());
        setIsLoading(true);
        const id = data.id

        Api.post({
            route: 'institution_health_financial_management_billing_institution_contract_invoice_andpc_participate',
            data: { institutionInvoiceRegistrationContract: data.institutionInvoiceRegistrationContract, isValidated: checked },
            params: { institution: props.match.params.institution, contract: props.match.params.id }
        }, (response) => {
            setIsLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());
            if (response.status === 200) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: checked ? 'Participation valider' : 'Participation Annuler',
                        variant: 'success',
                        duration: 10000
                    })
                );
            } else if (response.status === 400) {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error.message,
                        variant: 'warning',
                        duration: 10000
                    })
                );
            } else {
                getInstitution(props.match.params.id);
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    }

    React.useEffect(() => {
        setBreadcrumbs({
            title: institution.name ? institution.name : '-',
            context: 'Portail',
            path: getRoutePathname('institution_health_home'),
            description: <p style={{ margin: 0, fontWeight: 'initial' }}>Suivi de contrat {institution.title ? <>-<b>{institution.title}</b></> : ''}</p>,
            links: props.match.params.id ? [
                { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation établissement' },
                { path: getRoutePathname('institution_health_financial_management_billing_institution_contract_list', { institution: props.match.params.institution }), label: 'Liste des contrats' }
            ] : [
            { path: getRoutePathname('institution_health_financial_management_billing_institution_list'), label: 'Facturation établissement' },
        ],
            html: getActionMore()
        });
}, [institution, reload]);

React.useEffect(() => {
    dispatch(LinearProgressMainLayoutActivate());
    setLoading(true);
    getInstitution(props.match.params.id);
}, [props.match.params.id]);

return (
    <>
        <Box className={classes.styleContentView}>
            <ContentViewComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>

                <Box style={{ position: 'relative', overflow: 'hidden auto' }}>
                    <InvoiceRegistrationList
                        invoicesList={invoicesList}
                        invoicesAfterList={invoicesAfterList}
                        institution={institution}
                        loading={loading || reload}
                        saveConvention={saveConvention}
                        savePaid={savePaid}
                        saveANDPC={saveANDPC}
                        getLoading={() => {
                            dispatch(LinearProgressMainLayoutActivate());
                            setLoading(true);
                            getInstitution(props.match.params.id)
                        }}
                        {...props}
                    />
                </Box>

                <Box>
                    <Box className={classes.total}>
                        {(institution.discounts && (institution.discounts.length != 0)) ?
                            <>
                                <Box style={{ position: 'relative' }}>
                                    <Slide direction="right" in={isOpenDiscount} mountOnEnter unmountOnExit>
                                        <Box className={classes.discountPopup}>
                                            {institution.discounts.map((discount, index) => (
                                                <Box key={index} style={{ border: '1px solid rgba(163, 173, 183, 0.61)', borderRadius: 5, }}>
                                                    <p className='date-root'>Date : du <span>{moment(discount.startAt).format('ll')}</span> au <span>{discount.endAt ? moment(discount.endAt).format('ll') : '-'}</span></p>
                                                    {(discount.percentage || discount.value) &&
                                                        <p className='discount-root'>
                                                            {discount.percentage && <span>Réduction : {discount.percentage}%</span>}
                                                            {discount.value && <span>Réduction : {discount.value}€</span>}
                                                        </p>
                                                    }
                                                    <p className='comment-root'>commentaire :<span>{discount.comment}</span></p>
                                                </Box>
                                            ))}
                                        </Box>
                                    </Slide>
                                    <ButtonComponent onClick={() => { setIsOpenDiscount(!isOpenDiscount) }} label={'Remise'} color={"#5E6E82"} />
                                </Box>
                            </> : ''
                        }
                        <Box className='TextField-root'>
                            Tva : {institution.tva ?? 0} %
                        </Box>
                    </Box>
                </Box>
            </ContentViewComponent >
        </Box >
    </>
)
}

const useStyles = () => makeStyles(style => ({
    styleContentView: {
        height: '100%',
        display: 'flex',
        flexDirection: 'row-reverse',
        color: '#5E6E82',
        position: 'relative',
        overflow: 'hidden',
        '& .MuiCircularProgress-svg': {
            color: '#FFF'
        },
        '& .ContentView-root': {
            height: '100%',
            width: '100%',
            display: 'grid',
            gridTemplateRows: 'min-content auto min-content',
            padding: 0,
            '& .Breadcrumbs-root:nth-of-type(1)': {
                margin: 0,
                display: 'flex',
                alignItems: 'center',
                flexWrap: 'wrap',
                height: 'auto',
                padding: '15px 55px',
                borderBottom: '1px solid rgba(163, 173, 183, 0.38)',
                '& div:nth-of-type(2)': {
                    margin: '0 0 0 auto',
                }
            }
        }
    },
    creditStyle: {
        background: '#E5E7EA',
        position: 'relative',
        padding: 10,
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        '& input': {
            textAlign: 'center'
        },
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    circularProgress: {
        margin: 0,
        '& svg': { color: '#FFF' }
    },
    discountPopup: {
        position: 'absolute',
        bottom: 40,
        border: '1px solid rgb(163, 173, 183)',
        minHeight: 100,
        minWidth: 270,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        '& .date-root': {
            background: '#E5E7EA',
            borderBottom: '1px solid',
            padding: 10,
            fontSize: 12,
            margin: 0,
            '& span': {
                fontWeight: 'bold'
            }
        },
        '& .discount-root': {
            margin: '0 10px',
            borderBottom: '1px solid',
            padding: 10
        },
        '& .comment-root': {
            display: 'flex',
            flexDirection: 'column',
            padding: 10,
            borderRadius: 5,
            fontSize: 12,
            gap: 5,
            margin: 0,
            '& span': {
                display: 'block',
                height: '100%',
                background: 'rgb(239, 243, 244)',
                border: '1px solid rgba(163, 173, 183, 0.36)',
                borderRadius: 5,
                padding: '5px 10px',
                fontSize: 14
            }
        }
    },
    total: {
        margin: 0,
        display: 'flex',
        padding: '10px 55px',
        flexWrap: 'wrap',
        alignItems: 'center',
        borderTop: '1px solid rgba(163, 173, 183, 0.38)',
        background: '#FFF',
        gap: 5,
        '& .TextField-root': {
            display: 'flex',
            alignItems: 'center',
            fontWeight: 'bold',
            gap: 5,
            width: 84,
            margin: '0 0 0 auto',
            '& .MuiFormHelperText-root': {
                display: 'none'
            }
        },
    },
}))
