import React from 'react';
import {BrowserRouter, Switch, Route, Redirect} from "react-router-dom";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from '@date-io/date-fns';
import {fr} from "date-fns/locale";
import momentFR from "moment-locale-fr";
import moment from 'moment';
import {getRouterLayout, getPathDefault, updateRouting} from "./Config/Route";
import {useDispatch, useSelector} from "react-redux";
import {ScreenChange} from "./Action/ScreenAction";
import Api from "./Api";
import {OrganismIndex, Organisms} from "./Organism";
import {AuthenticationLogout} from "./Action/AuthenticationAction";
import SnackbarComponent from "./Component/SnackbarComponent";
import 'sweetalert/dist/sweetalert.css';
import {Memory} from "./Action/MemoryAction";
import MediaComponent from "./Component/MediaComponent";
import Maintenance from "./View/Maintenance/Maintenance";
import ShadowBoxComponent from "./Component/ShadowBoxComponent";
import {makeStyles} from "@material-ui/core";
import ButtonComponent from "./Component/ButtonComponent";
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow';
import TicComponent from "./Component/TicComponent";
import FireworksComponent from './Component/FireworksComponent';

const VERSION = '2.5.0';
export let dispatch;

// Moment
moment.updateLocale('fr', momentFR);
moment.locale('fr');

// Themes
export let theme = {};

function App() {
    dispatch = useDispatch();

    const classes = useStyles();
    const [routerLayout, setRouterLayout] = React.useState(false);
    const [isReady, setReady] = React.useState(false);
    const [isNotUpdate, setNotUpdate] = React.useState(false);
    const [versionBack, setVersionBack] = React.useState(false);
    const [isMaintenance, setMaintenance] = React.useState(false);
    const authenticationReducer = useSelector(state => state.AuthenticationReducer);
    const loginPageReducer = useSelector(state => state.LoginPageReducer);

    // GTM RDP
    React.useEffect(() => {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-N3FXDD9');
    }, []);

    // GTM SFP
    React.useEffect(() => {
        (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({
                'gtm.start':
                    new Date().getTime(), event: 'gtm.js'
            });
            var f = d.getElementsByTagName(s)[0],
                j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src =
                'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', 'GTM-KRL7VKS');
    }, []);

    // Theme
    React.useEffect(() => {
        let organism = '';
        if (authenticationReducer.organism) organism = OrganismIndex[authenticationReducer.organism];
        else organism = window.location.host.split('.')[1];

        if (Organisms[organism]) theme = Organisms[organism];
        else theme = Organisms['santeformapro'];
        const favicon = document.getElementById("favicon");
        favicon.href = '/asset/images/' + theme.favicon;
    }, [authenticationReducer]);

    // Screen
    const resize = () => {
        let screen = '';

        if (window.innerWidth < 576) screen = 'XS';
        if (window.innerWidth >= 576 && window.innerWidth < 768) screen = 'SM';
        if (window.innerWidth >= 768 && window.innerWidth < 992) screen = 'MD';
        if (window.innerWidth >= 992 && window.innerWidth < 1200) screen = 'LG';
        if (window.innerWidth >= 1200) screen = 'XL';

        dispatch(ScreenChange({
            screen: screen,
            size: window.innerWidth,
            orientation: window.screen.orientation && window.screen.orientation.angle === 90
        }));
    };
    React.useEffect(() => {
        window.removeEventListener('resize', resize)
        window.addEventListener('resize', resize);
        window.addEventListener('orientationchange', resize);
        resize();
    }, []);

    // Security
    React.useEffect(() => {
        Api.get({route: 'default'}, (response) => {
            if (!response.data.authenticated && authenticationReducer.email) dispatch(AuthenticationLogout());
            setMaintenance(response.data.maintenance);

            setVersionBack(response.data.version);

            if (response.data.version === VERSION) {
                setReady(true);
            }
            else {
                setNotUpdate(true);
            }
        });

        setTimeout(() => window.location.reload(true), 21600000);
    }, []);
    React.useEffect(() => {
        updateRouting(authenticationReducer, loginPageReducer);
        setRouterLayout(getRouterLayout());
    }, [authenticationReducer]);

    // Memory
    React.useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);
        const registerFormation = queryParams.get('registerFormation');
        if (registerFormation) {
            dispatch(Memory({registerFormation: registerFormation}))
        }
    }, []);

    // Update
    const updateRender = () => {
        return <ShadowBoxComponent className={classes.shadowBox}>
            <img src={'/asset/images/' + theme.logo} alt="logo" className={classes.logo}/>
            <h1>Mise à jour</h1>
            <p>Nous constatons que vous ne disposez pas de la dernière version de la plateforme.</p>
            <p>Afin de mettre à niveau votre plateforme, cliquez simplement sur le bouton ci-dessous.</p>
            <h5>{VERSION}v <DoubleArrowIcon style={{color: '#28a745', verticalAlign: 'bottom', fontSize: 15}}/> {versionBack}v</h5>
            <ButtonComponent type="submit" label={'Mettre à jour ma plateforme'} className={classes.button} onClick={() => {
                window.location.reload(true);
            }}/>
            <p style={{fontSize: 11}}>
                Si le problème persiste, vous pouvez prendre contact avec nous par email ou par téléphone. <br/><br/>
                {theme.phone} <br/>
                {theme.mail}
            </p>
        </ShadowBoxComponent>;
    };

    // Catch incoming url
    React.useEffect(() => {
        localStorage.setItem('urlOrigin', window.location.pathname);
    }, []);

    return (
        isMaintenance ? <Maintenance/> : isNotUpdate ? updateRender() :
            (isReady && routerLayout) && <>
                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
                    <BrowserRouter>
                        <Switch>
                            {
                                Object.keys(routerLayout).map((keyLayout) => (
                                    routerLayout[keyLayout].map((route) => {
                                            return <Route exact={true} key={'layout_' + route.id} path={route.path} render={props => <route.layout {...props} routes={routerLayout[keyLayout]}/>}/>
                                        }
                                    )
                                ))
                            }
                            <Route component={() => <Redirect to={getPathDefault()}/>}/>
                        </Switch>
                    </BrowserRouter>
                </MuiPickersUtilsProvider>

                <FireworksComponent/>
                <SnackbarComponent/>
                <MediaComponent/>
                <TicComponent/>

                <div style={{
                    position: 'absolute',
                    zIndex: 1000,
                    bottom: 0,
                    right: 0,
                    fontSize: 12,
                    padding: '5px 10px',
                    color: 'rgb(104 104 104)'
                }}>{VERSION}</div>
            </>
    );
}

const useStyles = makeStyles({
    shadowBox: {
        padding: 25,
        height: 430,
        width: 380,
        left: 'calc(50vw - 190px)',
        top: 'calc(50vh - 215px)',
        textAlign: 'center',
        boxSizing: 'border-box',
        position: 'absolute',
        transition: 'all 700ms',
        transitionProperty: 'opacity, left',
        opacity: 1
    },
    button: {
        width: '100%'
    },
    logo: {
        width: '80%',
        margin: 'auto'
    }
});

export default App;
