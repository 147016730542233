import { Dialog, DialogActions, DialogContent, IconButton, makeStyles, Popover, Tooltip, Typography } from "@material-ui/core";
import React from "react";
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SweetAlert from 'sweetalert-react/lib/SweetAlert';
import ListIcon from '@material-ui/icons/List';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { dispatch } from "../../../../App";
import { GetActionAndpcCheck, GetActionConventionCheck, GetActionCreditCheck, GetActionPaidCheck } from "./Component/GetActionComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../../Api";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import TextFieldComponent from "../../../../Component/TextFieldComponent";
import ButtonComponent from "../../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { getRoutePathname } from "../../../../Config/Route";

const InvoiceRegistrationList = (props) => {
    const classes = useStyles();
    const invoicesList = props.invoicesList;
    const invoicesAfterList = props.invoicesAfterList;
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [actionRow, setActionRow] = React.useState({});
    const [isExpand, setIsExpand] = React.useState(true);

    return (
        <>
            <table className={classes.tableRoot}>
                {invoicesList.length != 0 &&
                    <thead>
                        <tr>
                            <th colSpan={2}>Référence</th>

                            <th>Montant HT</th>
                            <th>Montant TVA</th>
                            <th>Montant TTC</th>
                            <th style={{ width: 90 }}>payer</th>
                            <th style={{ width: 170 }}>Action</th>
                            <th style={{ width: 80 }}>PDF</th>
                        </tr>
                    </thead>
                }
                {invoicesList.length != 0 &&
                    invoicesList.map((data, index) => (
                        <tbody key={index} style={{ position: 'relative' }}>
                            <TbodyComponent data={data} {...props} />
                        </tbody>
                    ))
                }
                {invoicesAfterList.length != 0 &&
                    <tbody style={{ position: 'relative' }}>
                        <tr>
                            <td style={{ width: 45 }}>
                                <IconButton onClick={() => setIsExpand(!isExpand)} style={{ borderRadius: 5, padding: 5, width: '100%', background: '#5e6e8263' }}>
                                    <ExpandLessIcon style={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)', color: '#FFF', transition: 'all .4s' }} />
                                </IconButton>
                            </td>
                            <td colSpan={10}><b>Facturation après formation</b></td>
                        </tr>
                        <tr>
                            <td colSpan={10} style={{ padding: 0 }}>
                                {isExpand &&
                                    <table className={classes.tableRoot2}>
                                        <thead>
                                            <tr>
                                                <th>Nom/Prénom</th>
                                                <th style={{ minWidth: 145 }}>Formation</th>
                                                <th>Financement</th>
                                                <th>Coût pédagogique</th>
                                                <th>CP Établissement</th>
                                                <th>CP Organisme</th>
                                                <th style={{ width: 70 }}>Convention</th>
                                                <th style={{ width: 70 }}>Participation ANDPC</th>
                                                <th style={{ width: 170 }}>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {invoicesAfterList.map((data, i) => (
                                                <tr key={i} className={data.isDeleted ? classes.cancel : ''} >
                                                    <td>{`${data.prospectName ?? ""}`}</td>
                                                    <td>
                                                        <Tooltip title={data.formationName ?? ""}>
                                                            <p className={'formationName'}>{`${data.formationName ?? "-"}`}</p>
                                                        </Tooltip>
                                                    </td>
                                                    <td><b>{data.financeTypeName ?? '-'}</b></td>
                                                    <td>{data.educationalFee ?? '0'} €</td>
                                                    <td>{data.feeInstitutionAfter ?? '0'} €</td>
                                                    <td>{data.feeSupport ?? '0'} €</td>
                                                    <td>
                                                        <GetActionConventionCheck
                                                            type={'p2'}
                                                            loading={props.loading || loading || data.isDeleted}
                                                            data={data}
                                                            save={props.saveConvention}
                                                            invoicesList={invoicesList}
                                                            dataAfterList={invoicesAfterList}
                                                        />
                                                    </td>
                                                    <td>
                                                        {data.financeType == 1 &&
                                                            <GetActionAndpcCheck
                                                                loading={props.loading || loading || data.isDeleted}
                                                                data={data}
                                                                invoicesList={invoicesList}
                                                                save={props.saveANDPC}
                                                            />
                                                        }
                                                    </td>
                                                    <td>
                                                        <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                                                            <div style={{
                                                                background: (props.loading || loading || data.isDeleted) ? 'rgba(0, 0, 0, 0.12)' : '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden', width: 70
                                                            }}>
                                                                <Tooltip title={'Facturation sessions formation'}>
                                                                    <IconButton disabled={props.loading || loading || data.isDeleted} onClick={() => {
                                                                        window.open(getRoutePathname('institution_health_financial_management_billing_formation', { id: data.sessionFormation }))
                                                                    }} style={{ borderRadius: 0, padding: '5px', width: '100%', textAlign: 'center', fontSize: 13, color: '#FFF' }}>
                                                                        <LibraryBooksIcon />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </div>
                                                            <div style={{
                                                                background: (props.loading || loading || data.isDeleted) ? 'rgba(0, 0, 0, 0.12)' : '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden', width: '100%'
                                                            }}>
                                                                <IconButton disabled={props.loading || loading || data.isDeleted} onClick={() => {
                                                                    setShowConfirm(true);
                                                                    setActionRow(data)
                                                                }} style={{ borderRadius: 0, padding: '9px 5px', width: '100%', textAlign: 'center', fontSize: 13, color: '#FFF' }}>
                                                                    <span>Retirer</span>
                                                                </IconButton>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                }
                            </td>
                        </tr>
                    </tbody>
                }
                {(invoicesList.length === 0 && invoicesAfterList.length === 0) &&
                    <tbody>
                        <tr>
                            <td colSpan={10} style={{ position: 'initial', padding: 0 }}>
                                <p className={classes.error}>Aucune facture</p>
                            </td>
                        </tr>
                    </tbody>
                }
            </table>
            <SweetAlert
                show={showConfirm}
                title={'Retirer'}
                text={`[${actionRow.prospectName}]\nÊtes-vous sûr de vouloir retirer cette inscription ?`}
                type={'warning'}
                cancelButtonText={'Non'}
                confirmButtonText={'Oui'}
                showCancelButton={true}
                onConfirm={() => {
                    setShowConfirm(false);
                    dispatch(LinearProgressMainLayoutActivate());
                    setLoading(true)
                    Api.post({
                        route: 'institution_health_financial_management_billing_institution_contract_registration_delete',
                        params: { institution: props.match.params.institution, uuid: actionRow.institutionInvoiceRegistrationContract }
                    },
                        (response) => {
                            dispatch(LinearProgressMainLayoutDeactivate());
                            if (response.status === 200) {
                                props.getLoading();
                                setLoading(false)
                                dispatch(
                                    SnackbarOpen({
                                        text: 'Facture annuler.',
                                        variant: 'success',
                                    })
                                );
                            } else {
                                props.getLoading();
                                setLoading(false)
                                dispatch(
                                    SnackbarOpen({
                                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                        variant: 'error',
                                    })
                                );
                            }
                        });
                }}
                onCancel={() => setShowConfirm(false)}
            />
        </>
    )
}

const TbodyComponent = (props) => {
    const data = props.data
    const isAllCredit = data.registrations.every(registration => registration.creditLabel || registration.isDeleted);
    const registrationFirstList = data.registrations.filter(registration => registration.creditLock && !registration.creditLabel && !registration.isDeleted);
    const registrationPersonnal = data.registrations.filter(registration => registration.financeType == 6);

    const [isExpand, setIsExpand] = React.useState(true);
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [showConfirm, setShowConfirm] = React.useState(false);
    const [showRegistrationFirst, setShowRegistrationFirst] = React.useState(false);
    const [typeShow, setTypeShow] = React.useState({});
    const [actionRow, setActionRow] = React.useState({});
    const [isPaid, setIsPaid] = React.useState(false);
    const [isAddCredit, setIsAddCredit] = React.useState(false);
    const [addCreditList, setAddCreditList] = React.useState([]);
    const [conventionList, setConventionList] = React.useState([]);
    const [anchorEl, setAnchorEl] = React.useState(null);
    // Form
    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const downloadInvoice = (row) => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_contract_invoice_download',
            params: { institution: props.match.params.institution, contract: props.match.params.id, invoice: row.id },
            data: { isEstimate: row.isEstimate }
        });
        window.open(url, '_blank');
    };

    const downloadCredit = (row) => {
        const url = Api.route({
            route: 'institution_health_financial_management_billing_institution_contract_credit_download',
            params: { institution: props.match.params.institution, contract: props.match.params.id, credit: row.id },
        });
        window.open(url, '_blank');
    };

    const getAction = (row) => {
        const notCreditList = row.registrations.filter(registration => !registration.creditLabel && !registration.isDeleted);
        return ((registrationPersonnal.length != 0 && !data.isPaid) ?
            <>
                <div style={{
                    background: (isAllCredit || props.loading || loading) ? 'rgba(0, 0, 0, 0.12)' : '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden', width: '100%'
                }}>
                    <IconButton disabled={props.loading || loading} onClick={() => {
                        setTypeShow({ title: 'Annuler le devis', text: `${row.label}\nÊtes-vous sûr de vouloir annuler le devis ?` });
                        setActionRow(row);
                        setShowConfirm(true);
                    }} style={{ borderRadius: 0, padding: '9px 5px', width: '100%', textAlign: 'center', fontSize: 13, color: '#FFF' }}>
                        <span>Annuler le devis</span>
                    </IconButton>
                </div>
            </>
            : <>
                <div style={{
                    background: (props.loading || loading || isAllCredit) ? 'rgba(0, 0, 0, 0.12)' : '#5E6E82', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden', width: '100%'
                }}>
                    <IconButton disabled={isAllCredit || props.loading || loading} onClick={() => {
                        if (registrationFirstList.length != 0 && !isPaid) {
                            setShowRegistrationFirst(true)
                        } else {
                            if (isPaid && notCreditList.length > 1) {
                                setIsAddCredit(!isAddCredit)
                                setIsExpand(true);
                            } else {
                                setTypeShow({ title: 'Créer un Avoir', text: `[Complet]\n${row.label}\nÊtes-vous sûr de vouloir créer un avoir pour cette facture ?` });
                                setActionRow(row);
                                setShowConfirm(true);
                            }
                        }
                        setAddCreditList([])
                    }} style={{ borderRadius: 0, padding: '9px 5px', width: '100%', textAlign: 'center', fontSize: 13, color: '#FFF' }}>
                        {isAddCredit ? <span>Annuler l'avoir</span> : <span>Créer un Avoir</span>}
                    </IconButton>
                </div>
                {(isAddCredit) &&
                    <div style={{
                        background: (addCreditList.length === 0 || props.loading || isAllCredit) ? 'rgba(0, 0, 0, 0.12)' : '#28a745', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden', width: 60
                    }}>
                        <Tooltip title={'Valider'}>
                            <span>
                                <IconButton disabled={isAllCredit || props.loading || addCreditList.length === 0} onClick={() => {
                                    setTypeShow({
                                        title: 'Créer un Avoir',
                                        text: `${notCreditList.length === addCreditList.length ? '[Complet]' : '[Partiel]'}\n${row.label}\nÊtes-vous sûr de vouloir créer un avoir pour cette facture ?`
                                    });
                                    setActionRow(row);
                                    setShowConfirm(true);
                                }} style={{ borderRadius: 0, padding: '5px', width: '100%', textAlign: 'center', fontSize: 13, color: '#FFF' }}>
                                    <CheckCircleOutlineIcon style={{ color: '#FFF' }} />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </div>
                }
            </>
        )
    }

    const saveCredit = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            dispatch(LinearProgressMainLayoutActivate());
            setLoading(true);
            Api.post({
                route: 'institution_health_financial_management_billing_institution_contract_invoice_credit',
                data: { creditList: addCreditList },
                params: { institution: props.match.params.institution, contract: props.match.params.id, invoice: actionRow.id }
            },
                (response) => {
                    dispatch(LinearProgressMainLayoutDeactivate());
                    if (response.status === 200) {
                        props.getLoading();
                        dispatch(
                            SnackbarOpen({
                                text: (registrationPersonnal.length != 0 && !data.isPaid) ? 'Devis annuler.' : 'Avoir créer.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        props.getLoading();
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else {
                        props.getLoading();
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
        }
    }

    React.useEffect(() => {
        setConventionList(() => {
            const newData = data.registrations.map(item => ({
                id: item.id,
                isConvention: item.isConvention,
                isDeleted: item.isDeleted
            }));
            return newData;
        });
        setIsPaid(data.isPaid);
    }, [data, data.isPaid]);

    return (
        <>
            <tr>
                <td style={{ width: 45 }}>
                    <IconButton disabled={isAddCredit} onClick={() => setIsExpand(!isExpand)} style={{ borderRadius: 5, padding: 5, width: '100%', background: '#5e6e8263' }}>
                        <ExpandLessIcon style={{ transform: isExpand ? 'rotate(180deg)' : 'rotate(0deg)', color: '#FFF', transition: 'all .4s' }} />
                    </IconButton>
                </td>
                <td><b>{data.label ?? '-'}</b></td>
                <td>{data.amountHt ?? '0'}€</td>
                <td>{data.tvaAmount ?? '0'}€</td>
                <td>
                    <b>{data.amountTtc ?? '0'}€</b>
                    {data.creditBalance != 0 && <><br /><b>Solde utilisés :</b> {data.creditBalance}€</>}
                </td>
                <td>
                    <GetActionPaidCheck
                        loading={(data.amountTtc <= data.creditBalance) || isAddCredit || props.loading || isAllCredit}
                        dataAfterList={props.invoicesAfterList}
                        isDevis={registrationPersonnal.length != 0}
                        save={props.savePaid}
                        conventionList={conventionList}
                        setLoading={setLoading}
                        setIsPaid={setIsPaid}
                        data={data}
                    />
                </td>
                <td>
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        {getAction(data)}
                    </div>
                </td>
                <td>
                    <div style={{ display: 'flex', gap: 5, alignItems: 'center' }}>
                        <div>
                            <Tooltip title={'Avoir'} placement="left">
                                <span style={{ width: '100%' }}>
                                    <IconButton disabled={data.creditList.length === 0} onClick={handleClick} style={{ borderRadius: 5, padding: 5, width: '100%', background: data.creditList.length === 0 ? 'rgba(0, 0, 0, 0.12)' : '#5e6e82', flex: 'initial' }}>
                                        <ListIcon style={{ color: '#FFF' }} />
                                    </IconButton>
                                </span>
                            </Tooltip>
                            <Popover
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'center',
                                }}
                            >
                                <Typography className={classes.typography}>
                                    {data.creditList.length != 0 &&
                                        data.creditList.map((credit, index) => (
                                            <Tooltip key={index} title={'Télécharger'} placement="left">
                                                <IconButton onClick={() => { downloadCredit(credit) }} style={{ borderRadius: 5, padding: '5px 10px', width: '100%', background: '#5e6e82' }}>
                                                    <p>{credit.label}</p><CloudDownloadIcon style={{ color: '#FFF' }} />
                                                </IconButton>
                                            </Tooltip>
                                        ))
                                    }
                                </Typography>
                            </Popover>
                        </div>
                        <Tooltip title={'Télécharger'} placement="left">
                            <IconButton onClick={() => { downloadInvoice(data) }} style={{ borderRadius: 5, padding: 5, width: '100%', background: '#5e6e82', flex: 'initial' }}>
                                <CloudDownloadIcon style={{ color: '#FFF' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </td>
            </tr>
            <tr>
                <td colSpan={10} style={{ padding: 0 }}>
                    {isExpand &&
                        <table className={classes.tableRoot2}>
                            <thead>
                                <tr>
                                    <th rowSpan={2}>Nom/Prénom</th>
                                    <th style={{ minWidth: 145 }} rowSpan={2}>Formation</th>
                                    <th rowSpan={2}>Financement</th>
                                    <th rowSpan={2}>Coût pédagogique</th>
                                    <th rowSpan={2}>Frais d’inscription</th>

                                    <th colSpan={2}>CP Établissement</th>

                                    <th rowSpan={2}>CP Organisme</th>
                                    <th rowSpan={2}>Dépassement pédagogique</th>
                                    <th rowSpan={2} style={{ width: 100 }}>Total<sup>(HT)</sup></th>

                                    <th rowSpan={2} style={{ width: 130 }}>Convention</th>
                                    {isAddCredit && <th rowSpan={2} style={{ width: 50 }}>Action</th>}
                                </tr>
                                <tr>
                                    <th>Avant formation</th>
                                    <th>Après formation</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data?.registrations.length != 0 ?
                                    data.registrations.map((registration, index) => {
                                        const price = registration.price;
                                        const idExists = registrationFirstList.some(item => item.id === registration.id);
                                        const isDeleted = registration.creditLabel ? true : false || registration.isDeleted;
                                        const isNotCredit = !idExists && !registration.isFirst && !registration.creditLabel;
                                        return (
                                            <tr key={index} className={isDeleted ? classes.cancel : ''}>
                                                <td>{`${registration.lastname ?? ""} ${registration.firstname ?? ""}`}</td>
                                                <td>
                                                    <Tooltip title={registration.formationName ?? ""}>
                                                        <p className={'formationName'}>{`${registration.formationName ?? "-"}`}</p>
                                                    </Tooltip>
                                                </td>
                                                <td><b>{registration.financeTypeName ?? '-'}</b></td>
                                                <td style={price.educationalFee.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.educationalFee && price.educationalFee.value != 0) ? price.educationalFee.value : '0'} €
                                                </td>
                                                <td style={price.inscriptionFee.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.inscriptionFee && price.inscriptionFee.value != 0) ? price.inscriptionFee.value : '0'} €<br />
                                                    <b>Remise : {price.inscriptionDiscount.value ? price.inscriptionDiscount.value : '0'} €</b>
                                                </td>

                                                <td style={price.feeInstitutionBefore.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.feeInstitutionBefore && price.feeInstitutionBefore.value != 0) ? price.feeInstitutionBefore.value : '0'} €
                                                </td>

                                                <td style={price.feeInstitutionAfter.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.feeInstitutionAfter && price.feeInstitutionAfter.value != 0) ? price.feeInstitutionAfter.value : '0'} €
                                                </td>

                                                <td style={price.feeSupport.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.feeSupport && price.feeSupport.value != 0) ? price.feeSupport.value : '0'} €
                                                </td>
                                                <td style={price.educationalExceeding.inInvoice ? { color: !isDeleted ? (isPaid ? '#28a745' : '#9C27B0') : '', fontWeight: 'bold' } : {}}>
                                                    {(price.educationalExceeding && price.educationalExceeding.value != 0) ? price.educationalExceeding.value : '0'} €
                                                </td>
                                                <td>
                                                    <b>{(registration.totalPrice && registration.totalPrice != 0) ? registration.totalPrice : '0'} €</b>
                                                </td>

                                                <td>
                                                    <GetActionConventionCheck
                                                        type={'p1'}
                                                        loading={isAddCredit || props.loading || isDeleted || loading}
                                                        data={registration}
                                                        //invoicesList={props.invoicesList}
                                                        dataAfterList={props.invoicesAfterList}
                                                        invoiceData={data}
                                                        setConventionList={setConventionList}
                                                        save={props.saveConvention}
                                                    />
                                                </td>
                                                {(isAddCredit) &&
                                                    <td>
                                                        {
                                                            <Tooltip title={registration.isDeleted ? 'Cette inscription a été annulée.': !isNotCredit ? 'Cette inscription est dans une autre facture.' : ''}>
                                                                <div style={{ background: '#E5E7EA', height: '100%', width: '100%', justifyContent: 'center', display: 'flex', borderRadius: 5, overflow: 'hidden' }}>
                                                                    <GetActionCreditCheck
                                                                        data={registration}
                                                                        loading={props.loading || !isNotCredit}
                                                                        onChange={(value) => setAddCreditList(value)}
                                                                    />
                                                                </div>
                                                            </Tooltip>
                                                        }
                                                    </td>
                                                }
                                            </tr>
                                        )
                                    }) : <tr><td colSpan={20}>Aucune facture</td></tr>
                                }
                            </tbody>
                        </table>
                    }
                </td>
            </tr>
            <SweetAlert
                show={showRegistrationFirst}
                title={`Attention`}
                text={`${registrationFirstList.length ?? 0} inscription sont liés à une facture.`}
                type={'warning'}
                showCancelButton={false}
                confirmButtonText={'Retour'}
                onConfirm={() => setShowRegistrationFirst(false)}
            />
            <Dialog open={showConfirm} maxWidth={'lg'} onClose={() => setShowConfirm(false)}>
                <DialogContent>
                    <div style={{ width: '70vw', maxWidth: 480, textAlign: 'center', color: '#575757' }}>
                        <ErrorOutlineIcon style={{ color: '#F8BB86', fontSize: 75 }} />
                        <h1 style={{ margin: 0 }}>{typeShow.title ?? '-'}</h1>
                        <p style={{ whiteSpace: 'pre-line', fontWeight: 'bold', color: '#797979' }}>{typeShow.text ?? '-'}</p>
                        <TextFieldComponent name={'comment'} handler={handler} multiline={true} />
                    </div>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'center', padding: '0 8px 20px 8px' }}>
                    <ButtonComponent disabled={loading} onClick={() => setShowConfirm(false)} color={'#5E6E82'} label={'non'} />
                    <ButtonComponent disabled={loading} onClick={saveCredit} label={'oui'} />
                </DialogActions>
            </Dialog>
        </>
    )
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '5px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
    typography: {
        padding: 10,
        display: 'flex',
        flexDirection: 'column',
        gap: 6,
        '& button p': {
            fontSize: 12,
            color: '#FFF',
            margin: 0,
            padding: '0 10px 0 0'
        }
    },
    cancel: {
        '& td,th': {
            background: '#dfcbb999 !important',
        },
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    tableRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
    tableRoot2: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#FFF',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#FFF',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    ErrorIcon: {
        position: 'absolute',
        padding: 1,
        top: '50%',
        right: -1,
        transform: 'translate(50%,-50%)',
        color: '#E5E7EA',
        borderRadius: 100,
        background: '#E05059',
        zIndex: 1
    }
}))

export default InvoiceRegistrationList;