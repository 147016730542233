import { Box, Checkbox, DialogActions, IconButton, Slide, Tooltip, makeStyles } from '@material-ui/core';
import TextFieldComponent from "../../../../../Component/TextFieldComponent";
import React from "react";
import SelectComponent from '../../../../../Component/SelectComponent';
import { formHandlerInit } from '../../../../../Tool/FormHandlerCommon';
import BackspaceIcon from '@material-ui/icons/Backspace';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import EditIcon from '@material-ui/icons/Edit';
import ErrorIcon from '@material-ui/icons/Error';
import moment from 'moment';
import ButtonComponent from '../../../../../Component/ButtonComponent';

const CardComponent = (props) => {
    const classes = useStyles();
    const data = props.data;
    const handler = props.handler;
    const [values, setValues] = React.useState('');
    const [error, setError] = React.useState([]);

    const calculateDiscountHT = (price, discount, isPercentage, isNegative) => {
        const amount = isPercentage ? price * (discount / 100) : discount;

        return isNegative ? price - amount : price + amount;
    };

    const calculateTTC = (price) => {
        return price + (price * ((props.tva ?? 0) / 100));
    }

    const getInitialValues = () => {
        const fields = [
            { key: 'inscriptionFee', discountKey: 'inscriptionDiscount' },
            { key: 'educationalFee' },
            { key: 'feeSupport' },
            { key: 'feeInstitutionBefore' },
            { key: 'feeInstitutionAfter' }
        ];

        let values = {
            inscriptionFee: 0,
            educationalFee: 0,
            feeSupportHt: 0,
            feeInstitutionBeforeHt: 0,
            feeInstitutionAfterHt: 0,
            totalHt: 0,
            remainHt: 0
        };

        fields.forEach(field => {
            const { key, discountKey } = field;
            const value = parseFloat(handler.form[`${key}`]?.value || 0);

            if (discountKey) {
                const discount = parseFloat(handler.form[`${discountKey}`]?.value || 0);
                const isPercentage = handler.form[`${discountKey}`]?.isPercentage;

                if (key === 'inscriptionFee') {
                    values.inscriptionFee += calculateDiscountHT(value, discount, isPercentage, true);
                    values['inscriptionFeeTTC'] = calculateTTC(values.inscriptionFee);
                    onChangeError(values.inscriptionFee, `inscriptionFee`)
                }
            } else {
                if (key === 'educationalFee') {
                    values.educationalFee += value;
                    values['educationalFeeTTC'] = calculateTTC(values.educationalFee);
                    onChangeError(values.educationalFee, `educationalFee`)
                }

                if (key === 'feeSupport') {
                    values.feeSupportHt += value;
                    values['feeSupportTTC'] = calculateTTC(values.feeSupportHt);
                }

                if (key === 'feeInstitutionBefore') {
                    values.feeInstitutionBeforeHt += value;
                    values['feeInstitutionBeforeTTC'] = calculateTTC(values.feeInstitutionBeforeHt);
                }
                
                if (key === 'feeInstitutionAfter') {
                    values.feeInstitutionAfterHt += value;
                    values['feeInstitutionAfterTTC'] = calculateTTC(values.feeInstitutionAfterHt);
                }
            }
        });

        values.totalHt = values.inscriptionFee + values.educationalFee;
        values['totalTTC'] = calculateTTC(values.totalHt);

        values.remainHt = values.educationalFee - values.feeInstitutionBeforeHt - values.feeInstitutionAfterHt - values.feeSupportHt
        values['remainTTC'] = calculateTTC(values.remainHt);
        onChangeError(values.remainHt, `remainHt`)

        handler.form[`educationalExceeding`].value = values.remainHt;

        return values;
    };

    const updateErrorList = (prev, input, value) => {
        const alreadyInErrors = prev.includes(input);

        if (typeof value === 'number' && value < 0) {
            return alreadyInErrors ? prev : [...prev, input];
        } else {
            return alreadyInErrors ? prev.filter(item => item !== input) : prev;
        }
    };

    const onChangeError = (value, input) => {
        setError((prev) => updateErrorList(prev, input, value));
    };

    const onChange = (value, name, type) => {
        if (type === 'unit') {
            handler.form[name].isPercentage = value == 1;
        } else {
            handler.form[name].value = value;
        }
        setValues(getInitialValues());
    };

    React.useEffect(() => {
        setValues(getInitialValues());
    }, [handler.form, props.tva]);

    return (
        <Box style={{ color: '#5E6E82' }}>
            <Box style={{ borderBottom: '1px solid #A3ADB7', padding: '0px', display: 'grid', gridTemplateColumns: 'auto max-content', alignItems: 'center', gap: 0 }}>
                <p style={{ margin: '15px 20px', fontWeight: 'bold', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>Formation : {data.formationName ?? '-'}</p>
                <Box onClick={() => { props.onClose() }} style={{ borderRadius: 5, cursor: 'pointer', margin: '15px 20px 15px 0' }}>
                    <BackspaceIcon style={{ margin: 'auto', display: 'block', color: '#E05059', fontSize: 25 }} />
                </Box>
            </Box>
            <Box style={{ padding: '10px 20px 0px' }}>
                <p style={{ margin: 0, fontSize: 12 }}>Nom/Prénom</p>
                <p style={{ margin: 0, fontWeight: 'bold' }}>{`${data.firstname ?? ''} ${data.lastname ?? ''}`}</p>
            </Box>
            <Box style={{ padding: '10px 20px 5px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold', }}>Session : {(data.sessionStartAt && data.sessionStartAt) ? `${moment(data.sessionStartAt.date).format('ll')} au ${moment(data.sessionEndAt.date).format('ll')}` : <b>-</b>} {data.formationProgramTypeName ? ' / ' + data.formationProgramTypeName : ''}</p>
                <p style={{ margin: 0, fontSize: 12, fontWeight: 'bold', }}>(tva: {(props.tva ?? 0)}%)</p>
            </Box>
            <Box style={{ padding: '0px 20px 20px' }}>
                <table className={classes.priceRoot}>
                    <thead>
                        <tr>
                            <th></th>
                            <th style={{ textAlign: 'center' }}>P.U. HT</th>
                            <th style={{ textAlign: 'center' }}>REMISE</th>
                            <th style={{ textAlign: 'center' }}>Montant<sup>HT</sup></th>
                            <th style={{ textAlign: 'center' }}>Montant<sup>TTC</sup></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><div>Frais d’inscription</div></td>
                            <td><div style={{ justifyContent: 'center' }}><span><TextFieldComponent name={`inscriptionFee`} handler={handler} onChange={(value) => onChange(value, `inscriptionFee`, null)} disabled={props.loading} /></span></div></td>
                            <td><div><span style={{ margin: 'auto', transform: 'translate(10%)' }}><SelectDiscount name={`inscriptionDiscount`} handler={handler} onChange={onChange} disabled={props.loading} /></span></div></td>
                            {
                                <td style={{ position: 'relative', border: error.some((prev) => prev === `inscriptionFee`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                    <span>{values.inscriptionFee?.toFixed(2)}</span>
                                    {error.some((prev) => prev === `inscriptionFee`) && <ErrorIcon className={classes.ErrorIcon} />}
                                </td>
                            }
                            <td style={{ position: 'relative' }}><span>{values.inscriptionFeeTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div>Coût pédagogique</div></td>
                            <td><div style={{ justifyContent: 'center' }}><span><TextFieldComponent name={`educationalFee`} handler={handler} onChange={(value) => onChange(value, `educationalFee`, null)} disabled={props.loading} /></span></div></td>
                            <td></td>
                            <td style={{ position: 'relative', border: error.some((prev) => prev === `educationalFee`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                <span>{values.educationalFee?.toFixed(2)}</span>
                                {error.some((prev) => prev === `educationalFee`) && <ErrorIcon className={classes.ErrorIcon} />}
                            </td>
                            <td><span>{values.educationalFeeTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            {(data.financeType != "6" && data.financeType != "3") &&
                                <>
                                    <td><div>CP Organisme - <b>{data.financeTypeName}</b></div></td>
                                    <td><div style={{ justifyContent: 'center' }}><span><TextFieldComponent name={`feeSupport`} handler={handler} onChange={(value) => onChange(value, `feeSupport`, null)} disabled={props.loading} /></span></div></td>
                                    <td></td>
                                    <td><span>{values.feeSupportHt != 0 && '-'}{values.feeSupportHt?.toFixed(2)}</span></td>
                                    <td><span>{values.feeSupportTTC != 0 && '-'}{values.feeSupportTTC?.toFixed(2)}</span></td>
                                </>
                            }
                        </tr>
                        <tr>
                            <td><div>CP Établissement <br /><b>Avant formation</b></div></td>
                            <td><div style={{ justifyContent: 'center' }}><span><TextFieldComponent name={`feeInstitutionBefore`} handler={handler} onChange={(value) => onChange(value, `feeInstitutionBefore`, null)} disabled={props.loading} /></span></div></td>
                            <td rowSpan={data.financeType === "6" ? 1 : 2}></td>
                            <td><span>{values.feeInstitutionBeforeHt != 0 && '-'}{values.feeInstitutionBeforeHt?.toFixed(2)}</span></td>
                            <td><span>{values.feeInstitutionBeforeTTC != 0 && '-'}{values.feeInstitutionBeforeTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            {data.financeType != "6" &&
                                <>
                                    <td><div>CP Établissement <br /><b>Après formation</b></div></td>
                                    <td><div style={{ justifyContent: 'center' }}><span><TextFieldComponent name={`feeInstitutionAfter`} handler={handler} onChange={(value) => onChange(value, `feeInstitutionAfter`, null)} disabled={props.loading} /></span></div></td>
                                    <td><span>{values.feeInstitutionAfterHt != 0 && '-'}{values.feeInstitutionAfterHt?.toFixed(2)}</span></td>
                                    <td><span>{values.feeInstitutionAfterTTC != 0 && '-'}{values.feeInstitutionAfterTTC?.toFixed(2)}</span></td>
                                </>
                            }
                        </tr>
                        <tr>
                            <td><div>Dépassement pédagogique</div></td>
                            <td></td>
                            <td></td>
                            {
                                <td style={{ position: 'relative', border: error.some((prev) => prev === `remainHt`) ? '3px solid #e05059' : '0px solid #e05059' }}>
                                    <span>{values.remainHt?.toFixed(2)}</span>
                                    {error.some((prev) => prev === `remainHt`) && <ErrorIcon className={classes.ErrorIcon} />}
                                </td>
                            }
                            <td><span>{values.remainTTC?.toFixed(2)}</span></td>
                        </tr>
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                        </tr>
                        <tr>
                            <td><div><b>TOTAL</b></div></td>
                            <td></td>
                            <td></td>
                            <td><span>{values.totalHt?.toFixed(2)}</span></td>
                            <td><span>{values.totalTTC?.toFixed(2)}</span></td>
                        </tr>
                    </tbody>
                </table>
            </Box>
            <DialogActions style={{ background: '#E5E7EA' }}>
                <ButtonComponent label={'Enregistrer'} onClick={() => { props.onValid(values) }} disabled={error.length != 0} />
            </DialogActions>
        </Box>
    )
}

const SelectDiscount = (props) => {
    const [form, setForm] = React.useState({
        unit: {
            name: 'unit',
            label: 'unité',
            type: 'integer',
            defaultValue: props.handler.form[props.name].isPercentage ? 1 : 2,
            options: {}
        }
    });
    const handler = formHandlerInit(form, setForm);
    const optionUnit = [
        { value: 1, label: '%' },
        { value: 2, label: '€' }
    ];

    React.useEffect(() => {
        handler.form.unit.value = props.handler.form[props.name].isPercentage ? 1 : 2;
    }, [props.handler.form[props.name].isPercentage]);

    React.useEffect(handler.start, []);
    return (
        <>
            <TextFieldComponent name={props.name} handler={props.handler} onChange={(value) => { props.onChange && props.onChange(value, props.name, null) }} disabled={props.disabled} />
            <div className={'select-root'}>
                <SelectComponent name={'unit'} handler={handler} options={optionUnit} onChange={(value) => { props.onChange && props.onChange(value, props.name, 'unit') }} disabled={true} />
            </div>
        </>
    )
}


const useStyles = makeStyles({
    costValid: {
        background: 'rgb(40, 167, 69) !important',
        color: '#FFF',
        position: 'relative',
        transition: 'all .4s',
        '&:hover': {
            background: 'rgb(28, 125, 50) !important',
        },
    },
    costInValid: {
        background: 'rgb(221, 78, 78) !important',
        color: '#FFF',
        position: 'relative',
        transition: 'all .4s',
        '&:hover': {
            background: 'rgb(179, 65, 65) !important',
        },
        '&:hover::before': {
            opacity: 0
        }
    },
    priceRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th:nth-of-type(1)::before': {
                width: '95%',
                height: 5,
                content: "' '",
                background: '#E5E7EA',
                position: 'absolute',
                top: 0,
                left: 0,
                borderRadius: 100,
            },
            '& th:nth-of-type(n+2)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& th:nth-of-type(2)': {
                borderRadius: '5px 0 0 0',
            },
            '& th:last-child': {
                borderRadius: '0 5px 0 0',
            },
        },
        '& tbody': {
            '& tr:nth-of-type(7) td:nth-of-type(2)': {
                borderRadius: '0px 0 0 5px',
            },
            '& tr:nth-of-type(7) td:last-child': {
                borderRadius: '0 0px 5px 0',
            },
            '& tr:nth-of-type(3) td:nth-of-type(2)': {
                borderRadius: '5px 0 0 0px',
            },
            '& tr:nth-of-type(3) td:last-child': {
                borderRadius: '0 5px 0px 0',
            },
            '& tr:nth-of-type(1) td:nth-of-type(2)': {
                borderRadius: '0 0 0 5px',
            },
            '& tr:nth-of-type(1) td:last-child': {
                borderRadius: '0 0 5px 0',
            },
            '& tr:nth-of-type(2) td, tr:nth-of-type(8) td': {
                padding: 5,
                background: '#FFF'
            },
            '& td:nth-of-type(n+2)': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .4s',
            },
            '& tr:last-of-type td:nth-of-type(2)': {
                borderRadius: '5px 0 0 5px',
            },
            '& tr:last-of-type td:last-child': {
                borderRadius: '0 5px 5px 0',
            },
            '& td': {
                '& >div': {
                    fontSize: 15
                },
                '& p': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 5,
                    margin: 0,
                    flexWrap: 'wrap',
                    fontSize: 15
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        },
        '& .MuiFormControl-root': {
            width: 60,
            '& label, legend, .MuiFormHelperText-root': {
                display: 'none'
            },
            '& select': {
                padding: 0,
                background: 'transparent !important',
                color: '#5E6E82',
            },
            '& input': {
                padding: 6,
                textAlign: 'center'
            },
            '& fieldset': {
                top: 0
            }
        },
        '& .select-root': {
            '& fieldset': {
                padding: 0,
                display: 'none'
            },
            '& .MuiFormControl-root': {
                width: 11
            },
            '& .MuiSelect-icon': {
                right: -6,
                display: 'none'
            }
        },
    },
    IconButtonRoot: {
        display: 'flex',
        height: '100%',
        '& .MuiIconButton-root': {
            padding: '0 20px',
            borderRadius: 0
        }
    },
    DescriptionPopup: {
        position: 'absolute',
        bottom: 60,
        border: '1px solid rgb(163, 173, 183)',
        minWidth: 280,
        background: '#FFF',
        borderRadius: '5px',
        padding: 13,
        gap: 11,
        display: 'flex',
        flexDirection: 'column',
        right: 5,
        '& textarea': {
            overflow: 'hidden auto !important',
            minHeight: 100,
            maxHeight: 200
        }
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '12px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
    ErrorIcon: {
        position: 'absolute',
        padding: 1,
        top: '50%',
        right: -1,
        transform: 'translate(50%,-50%)',
        color: '#E5E7EA',
        borderRadius: 100,
        background: '#E05059',
        zIndex: 1
    }
})

export default CardComponent;