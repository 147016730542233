import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import UseAnimations from "react-useanimations";
import activity from "react-useanimations/lib/activity";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { Refresh } from "@material-ui/icons";
import PaginationComponent from '../../../Component/PaginationComponent';
import { Avatar, Box, Drawer as DrawerMUI, makeStyles, Slide } from "@material-ui/core";
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import CommentIcon from '@material-ui/icons/Comment';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import EditorComponent from "../../../Component/EditorComponent";
import Grid from "@material-ui/core/Grid";
import { NavigateBefore } from "@material-ui/icons";
import Badge from '@material-ui/core/Badge';
import CommunityComment from "./CommunityComment";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import IconButton from "@material-ui/core/IconButton";
import { theme } from "../../../App";
import moment from "moment";
import TitleComponent from '../../../Component/TitleComponent';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

function CommunityPost(props) {
    const dispatch = useDispatch();
    const classes = useStyles(theme)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [load, setLoad] = React.useState(false);
    const [loadImg, setLoadImg] = React.useState(false);
    const [isSmallView, setSmallView] = React.useState(false);
    const [group, setGroup] = React.useState([]);
    const [prospectList, setProspectList] = React.useState([]);
    const [commentList, setCommentList] = React.useState([]);
    const [communityPost, setCommunityPost] = React.useState([]);
    const [isComment, setIsComment] = React.useState(false);
    const [isOpenEdito, setIsOpenEdito] = React.useState(false);

    const [form, setForm] = React.useState({
        comment: {
            name: 'comment',
            label: 'Écrire un commentaire',
            textHelper: 'Saisissez un commentaire.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
    });
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        }
        else {
            setLoad(true)
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            Api.post({
                route: 'learner_community_comment_add',
                data: handler.getData(),
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost,
                }
            }, (response) => {
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 201) {
                    loadData()
                    handler.reset();
                    dispatch(
                        SnackbarOpen({
                            text: 'Commentaire enregistrer.',
                            variant: 'success',
                        })
                    );
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const loadData = () => {
        setLoad(true)
        dispatch(LinearProgressMainLayoutActivate());
        if (props.match.params.communityPost) {
            Api.get({
                route: 'learner_community_comment_list',
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost
                }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        for (let index in data.commentList) {
                            data.commentList[index].createdAt = moment(data.commentList[index].createdAt).format("lll");
                            if (data.commentList[index].responsProspect)
                                data.commentList[index].responsProspect.createdAt = moment(data.commentList[index].responsProspect.createdAt).format("lll");
                        }
                        setGroup(data.group);
                        setCommentList(data.commentList);
                        setProspectList(data.prospectList);
                        setLoad(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    } else {
                        setGroup([]);
                        setProspectList([]);
                        setCommentList([]);
                    }
                });
        } else {
            setGroup([]);
            setProspectList([]);
            setCommentList([]);
        }

        if (props.match.params.communityGroup && props.match.params.communityPost) {
            Api.get({
                route: 'learner_community_post_get',
                params: {
                    communityGroup: props.match.params.communityGroup,
                    communityPost: props.match.params.communityPost
                }
            },
                (response) => {
                    if (response.status === 200) {
                        let data = response.data;
                        data.createdAt = moment(data.createdAt).format("lll");
                        setCommunityPost(data);
                        setIsComment(data.isComment)
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());
                    } else if (response.status === 400) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    } else if (response.status === 404) {
                        props.history.push(getRoutePathname('learner_community'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                });
        } else {
            setCommunityPost([]);
        }
    }

    const viewFormComment = (isTitle) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <Box className={classes.boxComponent} style={{ textAlign: 'center' }}>
                    <Grid container spacing={2} style={{ width: '90%', margin: 'auto' }}>
                        {isTitle &&
                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                <p className={classes.titleForm}>Répondre au poste</p>
                            </Grid>
                        }
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <EditorComponent name={'comment'} handler={handler} />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <ButtonComponent className={classes.buttonComment} label={'Commenter'} onClick={() => { save() }} disabled={load} />
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        )
    }

    const viewList = (dataList) => {
        return (
            <Fade in={true} {...{ timeout: 500 }}>
                <>
                    <CommunityComment
                        data={dataList}
                        loadData={loadData}
                        isComment={isComment}
                        params={{
                            communityGroup: props.match.params.communityGroup,
                            communityPost: props.match.params.communityPost
                        }}
                    />
                </>
            </Fade>
        )
    }

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    React.useEffect(() => {
        loadData();
        setBreadcrumbs({
            currentPage: 'Post',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_community'), label: 'Forum' },
                { path: getRoutePathname('learner_community_group', { communityGroup: props.match.params.communityGroup }), label: 'Groupe' },
            ]
        });
    }, []);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <Box style={{ textAlign: 'center', maxWidth: 1400, margin: 'auto' }}>
                <Box style={{ width: '95%', margin: 'auto', position: 'relative' }}>
                    <br />
                    <Box style={{ display: 'flex' }}>
                        <ShadowBoxComponent className={classes.shadowBoxBack} onClick={() => { props.history.push(getRoutePathname('learner_community_group', { communityGroup: props.match.params.communityGroup })); }}>
                            <NavigateBefore className={classes.shadowBoxBackIcon} />
                            <span className={classes.titleGroup} >Retour</span>
                        </ShadowBoxComponent>
                        <Box style={{ color: "#5E6E82", width: 'fit-content', borderLeft: "1px solid rgb(223, 223, 223)", textAlign: "left", padding: "0px 5px", margin: '0 0 0 auto' }}>
                            {load ? <><CircularProgress size={20} className={classes.circularProgress} /> <span className={classes.span}>Mise à jour des données.</span></>
                                :
                                <Box style={{ fontSize: 10 }}>
                                    <IconButton style={{ padding: 5, margin: 3 }} onClick={() => { loadData(); }}>
                                        <Refresh />
                                    </IconButton>
                                    Recharger les commentaires
                                </Box>
                            }
                        </Box>
                    </Box>
                    <br />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={9} xl={9}>
                            <Box>
                                <Fade in={true} {...{ timeout: 500 }}>
                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} >
                                        <Box className={classes.boxComponent}>
                                            <Grid container spacing={3}>
                                                <Grid item xs={12} sm={12} md={12} lg={10} xl={10} style={{ display: 'flex' }}>
                                                    {communityPost.status === 'Admin' ?
                                                        <Badge color="secondary" badgeContent={'A'} showZero >
                                                            <Avatar className={classes.avatar}>{communityPost.firstname?.charAt().toUpperCase()}{communityPost.lastname?.charAt().toUpperCase()}</Avatar>
                                                        </Badge> : (!communityPost.pseudo) ?
                                                            <Avatar className={classes.avatar}>{communityPost.firstname?.charAt().toUpperCase()}{communityPost.lastname?.charAt().toUpperCase()}</Avatar> :
                                                            <Avatar className={classes.avatar}>{communityPost.pseudo.charAt().toUpperCase()}</Avatar>
                                                    }
                                                    <Box style={{ padding: '0 10px' }}>
                                                        <p style={{ margin: 0, fontSize: "1rem", fontWeight: "bold", color: "#495666" }}>{communityPost.title}</p>
                                                        <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} >
                                                            <KeyboardReturnIcon style={{ width: 15, height: 15, transform: "translate(0,37%)" }} /> post créer par
                                                            {communityPost.isPostProspect ?
                                                                <span className={classes.nameProspect}> vous </span> :
                                                                <span className={classes.nameProspect}> {communityPost.status === 'Admin' ? 'un administrateur' : !communityPost.pseudo ? communityPost.firstname?.charAt().toUpperCase() + '.' + communityPost.lastname : communityPost.pseudo} </span>}
                                                            le {communityPost.createdAt}
                                                        </p>
                                                        <p className={classes.subTitle} style={{ margin: 0, padding: "10px 0" }} dangerouslySetInnerHTML={{ __html: communityPost.subTitle }} />
                                                    </Box>
                                                </Grid>

                                                <Grid item xs={12} sm={12} md={12} lg={2} xl={2}>
                                                    <Box style={{ margin: 0, fontSize: 12, color: "#7B7B7B", padding: "5px 0 0 0" }} >
                                                        <p style={{ margin: 0, textAlign: "right" }}><CommentIcon style={{ transform: "translate(-18%,41%)" }} />{communityPost.numComment > 99 ? '99+' : communityPost.numComment} Commentaires</p>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                        <br />
                                    </Grid>
                                </Fade>
                            </Box>
                            <br />
                            <PaginationComponent
                                dataList={commentList}
                                pageNumber={10}
                                viewList={viewList}
                                error={'Aucun commentaire'}
                            />
                            <br />
                            {isComment && viewFormComment(true)}
                            <br />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={3} xl={3}>
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12} style={{ margin: "auto" }}>
                                <Box className={classes.boxComponent}>
                                    <Box className={classes.cardImage}>
                                        {!loadImg && <img src="/asset/images/preload_image.png" className={classes.cardImageLoad} alt={'Image formation'} />}
                                        <img src={group.image ? "/api/learner/home/formation-image/" + group.image : "/asset/images/discussion_group.webp"} className={classes.cardImageFormation} alt={'Image formation'} onLoad={() => setLoadImg(true)} />
                                    </Box>
                                    <p style={{ fontWeight: 700, fontSize: 22, margin: "0", padding: "10px 0 0 0", width: "98%" }}>{group.name}</p>
                                    <p style={{ margin: 0, fontSize: 11, fontWeight: "bold" }} > Groupe créer le {moment(group.createdAt).format("lll")}</p>
                                </Box >
                            </Grid>
                            <br />
                            <Grid item xs={12} sm={12} md={6} lg={12} xl={12} style={{ margin: "auto", position: 'sticky', top: 22 }}>
                                <Box>
                                    <Box className={classes.boxComponent}>
                                        <TitleComponent title={'Membres'} />
                                        <Box style={{ maxHeight: '39vw', overflow: 'scroll', }}>
                                            {prospectList.length >= 0 ?
                                                <p className={classes.textError}><b>Aucun Membres</b></p>
                                                :
                                                Object.keys(prospectList).map((dataList, index) =>
                                                    prospectList[dataList].firstname &&
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ display: 'flex', margin: 'auto', padding: 10, alignItems: 'center' }}>
                                                        {(!prospectList[dataList].pseudo) ?
                                                            <>
                                                                <Avatar className={classes.avatar}>{prospectList[dataList].firstname.charAt().toUpperCase()}{prospectList[dataList].lastname.charAt().toUpperCase()}</Avatar>
                                                                <p className={classes.boxName}>{prospectList[dataList].firstname} {prospectList[dataList].lastname}</p>
                                                            </> :
                                                            <>
                                                                <Avatar className={classes.avatar}>{prospectList[dataList].pseudo.charAt().toUpperCase()}</Avatar>
                                                                <p className={classes.boxName}>{prospectList[dataList].pseudo}</p>
                                                            </>
                                                        }
                                                    </Grid>
                                                )
                                            }
                                        </Box>
                                    </Box >
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                    {/*isComment &&
                            <Box style={{
                                position: 'fixed', bottom: 34, right: 23,
                            }}>
                                <Box className={classes.editoPopup} style={{ width: isOpenEdito ? 700 : 45, height: isOpenEdito ? 320 : 45, borderRadius: isOpenEdito ? 20 : '100px 100px 30px 100px', }}>
                                    {viewFormComment(false)}
                                </Box>
                                <Box
                                    onClick={() => { setIsOpenEdito(!isOpenEdito) }}
                                    style={{
                                        width: 45, height: 45,
                                        background: theme.colorPrimary,
                                        borderRadius: '100px 100px 30px 100px',
                                        border: '2px solid' + theme.colorPrimary,
                                        display: 'flex',
                                        alignItems: 'center',
                                        cursor: 'pointer',
                                        position: 'absolute',
                                        right: 0, bottom: 0,
                                    }}
                                ><CommentIcon style={{ color: '#FFF', margin: 'auto', transform: 'translate(-1%,5%)' }} /></Box>
                            </Box>
                                */}
                </Box>
            </Box>
        </ContentViewLearnerComponent>
    );
}

const useStyles = (theme) => makeStyles(({
    subTitle: {
        '& p,h1,h2,h3': { margin: 0 }
    },
    boxComponent: {
        color: '#5E6E82',
        borderRadius: 5,
        backgroundColor: "#FFFFFF",
        border: "2px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: 22,
        '& .MuiBadge-colorSecondary': {
            color: theme.colorPrimary,
            backgroundColor: "#F6F6F6",
            border: '1px solid',
            transform: 'scale(1) translate(20%, -43%)'
        },
    },
    title: {
        margin: 0,
        fontSize: 21,
        fontWeight: "bold",
        color: "#495666"
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        padding: 0,
        textAlign: 'center',
        transition: 'all 700ms',
        opacity: 1,
        overflow: 'hidden',
    },
    linearProgress: {
        position: 'absolute',
        bottom: 0,
        left: 0,
        width: '100%',
        height: 1,
        '& .MuiLinearProgress-bar': {
            backgroundColor: theme.colorPrimary
        },
        '& .MuiLinearProgress-root': {
            backgroundColor: '#FFFFFF'
        }
    },
    buttonV1: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        boxShadow: "initial",
        borderRadius: "0px 5px 5px 0px",
        border: "1px solid rgb(3, 169, 244)"
    },
    buttonV2: {
        textTransform: "initial",
        fontSize: 15,
        padding: "5px 33px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(3, 169, 244)",
        color: "rgb(3, 169, 244)"
    },
    sweetAlert: {
        '& .sa-icon': {
            margin: '0px auto !important',
        },
        '& .sweet-alert h2': {
            lineHeight: '23px !important',
            margin: "0 !important"
        }
    },
    shadowBoxBack: {
        height: 40,
        width: "max-content",
        fontSize: 14,
        textAlign: 'start',
        marginTop: 0,
        padding: 8,
        cursor: 'pointer',
        boxShadow: "0 0 0 0",
        background: "transparent"
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle',
        fontSize: "1.8rem",
        transform: "translate(0, -10%)"
    },
    titleGroup: {
        fontSize: 20,
        fontWeight: 600,
        textAlign: "left",
    },
    buttonComment: {
        borderRadius: "5px",
        padding: "0px 59px",
        fontSize: 15,
        textTransform: "initial",
        fontWeight: "bolder",
        background: "#c8dfe5 !important",
        boxShadow: "0 0",
        border: "4px solid #c8dfe5",
        color: "#5E6E82"
    },
    buttonPost: {
        textTransform: "initial",
        fontSize: 11,
        padding: "1px 10px",
        backgroundColor: "transparent !important",
        boxShadow: "none",
        border: "solid 1px rgb(79, 147, 165)",
        color: "rgb(79, 147, 165)",
        margin: "0px 39px"
    },
    circularProgress: {
        margin: 10,
        color: theme.colorPrimary
    },
    span: {
        margin: '0 10px',
        fontSize: 10,
        height: 10,
        display: 'inline-block',
        verticalAlign: 'bottom',
        padding: '18px 0',
        color: '#5E6E82'
    },
    nameProspect: {
        color: theme.colorPrimary
    },
    BoxComment: {
        color: "#5E6E82",
        borderBottom: "7px solid rgb(223, 223, 223)",
        textAlign: "left",
        padding: '22px 0px',
        width: "93%",
        margin: "auto"
    },
    avatar: {
        backgroundColor: theme.colorPrimary,
        fontSize: 14,
        width: 40,
        height: 40,
    },
    titleForm: {
        color: "#5E6E82",
        fontSize: 20,
        margin: "auto",
        fontWeight: "bold",
        borderLeft: "10px solid",
        padding: " 10px 30px",
        textAlign: "left",
    },
    cardImageLoad: {
        zIndex: 1,
    },
    cardImage: {
        margin: 'auto',
        borderRadius: 10,
        position: 'relative',
        width: '100%',
        border: '3px solid rgb(223, 223, 223)',
        overflow: 'hidden',
        '& img': {
            width: '100%',
            display: 'block',
        }
    },
    boxName: {
        margin: '0px 10px',
        fontSize: "1rem",
        fontWeight: "bold",
        color: "#495666",
        alignItems: 'center',
        display: 'flex'
    },
    textError: {
        textAlign: 'center',
        color: "#666",
        textTransform: "uppercase",
        fontSize: 20,
        opacity: 0.1,
        width: "100%"
    },
    editoPopup: {
        transition: 'all .5s', overflow: 'hidden',
        display: 'flex',
        background: '#FFF',
        border: '2px solid' + theme.colorPrimary,
        '& >div': {
            width: '90%',
            margin: 'auto',
            padding: 5,
            border: 'none',
            '& .rdw-editor-main': {
                minHeight: 100,
                background: '#FFFFFF',
            },
            '& .MuiGrid-item': {
                padding: 0
            }
        },
    }
}));

export default CommunityPost;