import React from 'react';
import ContentViewComponent from "../../../Component/ContentViewComponent";
import { Box, makeStyles, DialogActions, Dialog, IconButton, DialogTitle, DialogContent } from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { theme } from "../../../App";
import Api, { contentManagerFormationImageBanner } from "../../../Api";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import MultipleSelectComponent from "../../../Component/MultipleSelectComponent";
import SelectComponent from "../../../Component/SelectComponent";
import EditorComponent from "../../../Component/EditorComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { useDispatch, useSelector } from "react-redux";
import { getRoutePathname } from "../../../Config/Route";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import Slide from "@material-ui/core/Slide";
import TabsComponent from "../../../Component/TabsComponent";
import ANDPC from "./ANDPC";
import FormationChapter from "./FormationChapter";
import FormationResource from "./FormationResource";
import FormationQuestion from "./FormationQuestion";
import FormationQuestionEpp from "./FormationQuestionEpp";
import CheckboxComponent from "../../../Component/CheckboxComponent";
import FIFPL from "./FIFPL";
import CPF from "./CPF";
import FormationSession from './FormationSession';
import FormationVirtualClassroom from './FormationVirtualClassroom';
import Unit from './Unit';
import PERSONAL from './PERSONAL';
import { BorderColor } from '@material-ui/icons';
import UploadComponent from '../../../Component/UploadComponent';

function Formation(props) {
    const dispatch = useDispatch();
    const [isReadyContent, setReadyContent] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const classes = useStyles(screenReducer)();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [isPrePost, setPrePost] = React.useState(false);
    const [isSurveyEpp, setIsSurveyEpp] = React.useState(false);
    const [isOpenEpp, setIsOpenEpp] = React.useState(false);
    const [isOpenPrePost, setIsOpenPrePost] = React.useState(false);
    const [isVirtualClassroom, setIsVirtualClassroom] = React.useState(false);
    const [isOpenChapter, setIsOpenChapter] = React.useState(true);
    const [isOpenResource, setIsOpenResource] = React.useState(false);
    const [isUnitFormat, setIsUnitFormat] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [optionFormationGroup, setOptionFormationGroup] = React.useState([]);
    const [optionOrganism, setOptionOrganism] = React.useState([]);
    const [optionFinanceType, setOptionFinanceType] = React.useState([]);
    const [showUploadBanner, setShowUploadBanner] = React.useState(false);
    const [loadBanner, setLoadBanner] = React.useState(false);
    const [load, setLoad] = React.useState(false);
    const [optionFormationProgramType, setOptionFormationProgramType] = React.useState([]);
    const [form, setForm] = React.useState({
        id: {
            name: 'id',
            label: 'ID',
            textHelper: 'Saisissez l\'ID de la formation.',
            type: 'integer',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom de la formation.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        previousName: {
            name: 'previousName',
            label: 'Nom de l\'année N-1',
            textHelper: 'Saisissez le nom de la formation (Année N-1).',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        description: {
            name: 'description',
            label: 'Description',
            textHelper: 'Saisissez la description de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        orientation: {
            name: 'orientation',
            label: 'Orientation',
            textHelper: 'Saisissez l\'orientation de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        descriptionOffline: {
            name: 'descriptionOffline',
            label: 'Description Hors Connexion',
            textHelper: 'Saisissez la description de la formation hors connexion.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        formationGroup: {
            name: 'formationGroup',
            label: 'Groupe',
            textHelper: 'Choisissez le groupe de la formation.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        formationGroupCible: {
            name: 'formationGroupCible',
            label: 'Groupe ciblé',
            textHelper: 'Choisissez le groupe ciblé de la formation.',
            type: 'array',
            defaultValue: '',
            options: { validation: ['required'] }
        },
        duration: {
            name: 'duration',
            label: 'Durée en seconde',
            textHelper: 'Saisissez la durée totale de la formation (C+CV+HC en second).',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        sellingArgument: {
            name: 'sellingArgument',
            label: 'Argument de vente',
            textHelper: 'Saisissez un argument de vente.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        introductionEpp: {
            name: 'introductionEpp',
            label: 'Introduction questionnaire EPP',
            textHelper: 'Saisissez une introduction pour le questinnaire EPP.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        durationOffline: {
            name: 'durationOffline',
            label: 'Durée en seconde',
            textHelper: 'Saisissez la durée de la formation hors connexion (en second).',
            type: 'integer',
            defaultValue: '',
            options: {}
        },
        objectives: {
            name: 'objectives',
            label: 'Objectifs',
            textHelper: 'Saisissez l\'objectifs de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        organismFormation: {
            name: 'organismFormation',
            label: 'Organisme',
            textHelper: 'Saisissez l\'organisme de la formation.',
            type: 'array',
            defaultValue: props.isInstitution ? [5] : '',
            options: {}
        },
        formationProgramType: {
            name: 'formationProgramType',
            label: 'Type de programme',
            textHelper: 'Saisissez le type de programme de la formation.',
            type: 'string',
            defaultValue: '',
            options: {}
        },
        priceHT: {
            name: 'priceHT',
            label: 'Prix',
            textHelper: 'Saisissez le prix de la formation.',
            type: 'float',
            defaultValue: '',
            options: {}
        },
        surveySatisfaction: {
            name: 'surveySatisfaction',
            label: 'Questionnaire de satisfaction',
            textHelper: 'Activez ou désactivez ce questionnaire.',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyPre: {
            name: 'surveyPre',
            label: 'Questionnaire Pre',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyPost: {
            name: 'surveyPost',
            label: 'Questionnaire Post',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        surveyEpp: {
            name: 'surveyEpp',
            label: 'Questionnaire Pre/Post EPP',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        honorCertificate: {
            name: 'honorCertificate',
            label: 'Attesttation sur l\'honneur suivi de formation',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        financeType: {
            name: 'financeType',
            label: 'Types de financement',
            textHelper: 'Saisissez les types de financement de la formation.',
            type: 'array',
            defaultValue: '',
            options: {}
        },
        forcedUnlock: {
            name: 'forcedUnlock',
            label: 'Débloquage des cours',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        isResponsive: {
            name: 'isResponsive',
            label: 'Formation disponible sur tablette / mobile',
            textHelper: '',
            type: 'boolean',
            defaultValue: true,
            options: {}
        },
        isVirtualClassroom: {
            name: 'isVirtualClassroom',
            label: 'Classe virtuelle',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        isUnitFormat: {
            name: 'isUnitFormat',
            label: 'Nouveau format en unités',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        isDiplomaFormation: {
            name: 'isDiplomaFormation',
            label: 'Formation diplômante',
            textHelper: '',
            type: 'boolean',
            defaultValue: false,
            options: {}
        },
        presentationVideoLink: {
            name: 'presentationVideoLink',
            label: 'Vidéo de présentation',
            textHelper: 'Saisissez le lien de la présentation de la formation.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        banner: {
            name: 'banner',
            label: 'Image banniere de formation',
            textHelper: '',
            type: 'file',
            defaultValue: '',
            options: {}
        }
    });
    const getFormFormation = (id) => {
        Api.get({
            route: 'content_manager_formation_get',
            params: { id: id }
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    setImage(data.id + '.webp');
                    handler.setDataApi(data);
                    setIsVirtualClassroom(data.isVirtualClassroom);
                    setIsUnitFormat(data.isUnitFormat);
                    (data.surveyPre === true || data.surveyPost === true) ? setPrePost(true) : setPrePost(false);
                    (data.surveyEpp === true) ? setIsSurveyEpp(true) : setIsSurveyEpp(false);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('content_manager_formation_list'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }

                setTimeout(() => setReadyContent(true), 250);
            });
    }
    const [image, setImage] = React.useState('');
    const handler = formHandlerInit(form, setForm);

    const save = () => {
        if (handler.checkError()) {
            const errorLabels = Object.values(handler.form).filter(item => item.error).map(item => item.label);
            dispatch(
                SnackbarOpen({
                    text: errorLabels.length === 1 ? 'Le champ "' + errorLabels + '" est obligatoire' : 'Les champs "' + errorLabels.join(', ') + '" sont obligatoires',
                    variant: 'error',
                })
            );
        } else {
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            if (isEdition) {
                if (props.match.params.id) {
                    Api.post({
                        route: 'content_manager_formation_edit',
                        data: handler.getData(),
                        params: { id: props.match.params.id }
                    }, (response) => {
                        handler.setFormLoading(false);
                        setLoading(false);
                        dispatch(LinearProgressMainLayoutDeactivate());

                        if (response.status === 200) {
                            dispatch(
                                SnackbarOpen({
                                    text: 'Formation modifiée.',
                                    variant: 'success',
                                })
                            );
                            getFormFormation(props.match.params.id);
                        } else if (response.status === 400) {
                            handler.setErrorApi(response.error);
                        } else {
                            dispatch(
                                SnackbarOpen({
                                    text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                    variant: 'error',
                                })
                            );
                        }
                    });
                }
            } else {
                let datas = handler.getData();
                Api.post({
                    route: 'content_manager_formation_add',
                    data: datas
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 201) {
                        props.history.push(getRoutePathname(props.isInstitution ? 'institution_health_content_manager_formation_list' : 'content_manager_formation', { id: datas.id }));
                        dispatch(
                            SnackbarOpen({
                                text: 'Formation enregistrée.',
                                variant: 'success',
                            })
                        );
                    } else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    } else {
                        dispatch(
                            SnackbarOpen({
                                text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }
        }
    };
    const remove = () => {
        handler.setFormLoading(true);
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.delete({
            route: 'content_manager_formation_delete',
            params: { id: props.match.params.id }
        }, (response) => {
            handler.setFormLoading(false);
            setLoading(false);
            dispatch(LinearProgressMainLayoutDeactivate());

            if (response.status === 200) {
                props.history.push(getRoutePathname('content_manager_formation_list'));
                dispatch(
                    SnackbarOpen({
                        text: 'Formation supprimé.',
                        variant: 'success',
                    })
                );
            } else if (response.status === 400) {
                handler.setErrorApi(response.error);
                if (response.error && response.error.message) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'error',
                        })
                    );
                }
            } else {
                dispatch(
                    SnackbarOpen({
                        text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                        variant: 'error',
                    })
                );
            }
        });
    };

    const download = () => {
        const url = Api.route({ route: 'content_manager_formation_download', params: { id: props.match.params.id } });
        window.open(url, '_blank');
    }

    const mainFields = React.useRef(null);
    const BoxMainFields = React.useRef(null);
    const moreFields = React.useRef(null);
    const BoxMoreFields = React.useRef(null);
    const [isMoreFields, setIsMoreFields] = React.useState(false);
    const [setpMoreFields, setSetpMoreFields] = React.useState(1);
    const fieldsAction = () => {
        if (isMoreFields) {
            BoxMainFields.current.style.height = mainFields.current.clientHeight + 'px';
            setTimeout(() => BoxMainFields.current.style.height = 'auto', 1000);

            BoxMoreFields.current.style.height = moreFields.current.clientHeight + 'px';
            setTimeout(() => BoxMoreFields.current.style.height = '0px', 100);
            setIsMoreFields(false);
        } else {
            BoxMainFields.current.style.height = mainFields.current.clientHeight + 'px';
            setTimeout(() => BoxMainFields.current.style.height = '0px', 100);

            BoxMoreFields.current.style.height = moreFields.current.clientHeight + 'px';
            setTimeout(() => BoxMoreFields.current.style.height = 'auto', 1000);
            setIsMoreFields(true);
        }
    }
    // ALL
    React.useEffect(handler.start, []);
    React.useEffect(() => {
        Api.get({
            route: 'select_formation_groups'
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormationGroup(response.data);
                }
            });
        Api.get({
            route: 'select_organisms',
            data: { 'isFull': true }
        },
            (response) => {
                if (response && response.data) {
                    setOptionOrganism(response.data);
                }
            });
        Api.get({
            route: 'select_finance_types',
            data: { 'isFull': true, 'isInstitution': props.isInstitution ? true : false }
        },
            (response) => {
                if (response && response.data) {
                    setOptionFinanceType(response.data);
                }
            });
        Api.get({
            route: 'select_formation_program_types',
        },
            (response) => {
                if (response && response.data) {
                    setOptionFormationProgramType(response.data);
                }
            });
        setReadyContent(false);


        if (props.match.params.id) {
            setIsEdition(true);
            setBreadcrumbs({
                title: 'Formation',
                context: 'Gestion de contenu',
                path: props.isInstitution ? getRoutePathname('institution_health_home') : '',
                description: '',
                links: [
                    { path: getRoutePathname(props.isInstitution ? 'institution_health_content_manager_formation_list' : 'content_manager_formation_list'), label: 'Liste des formations' }
                ]
            });

            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            getFormFormation(props.match.params.id);
        } else {
            setIsEdition(false);
            setReadyContent(true);
            setBreadcrumbs({
                title: 'Ajouter une formation',
                context: 'Gestion de contenu',
                path: props.isInstitution ? getRoutePathname('institution_health_home') : '',
                description: ''
            });
        }
    }, [props.match.params.id]);

    const uploadBanner = () => {
        let data = handler.getData();
        if (data.banner) {
            dispatch(LinearProgressMainLayoutActivate());
            setLoadBanner(true);
            setLoad(false);
            setImage('');
            Api.post({
                route: 'content_manager_formation_banner',
                file: { banner: data.banner },
                params: { id: props.match.params.id }
            }, (response) => {
                dispatch(LinearProgressMainLayoutDeactivate());
                setLoadBanner(false);
                setLoad(true);
                setImage(props.match.params.id + '.webp');
                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Upload réussi.',
                            variant: 'success',
                        })
                    );
                    setShowUploadBanner(false);
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: response.error && response.error.message ? response.error.message : 'Une erreur inattendu s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
        else {
            setShowUploadBanner(false);
        }
    };

    return (
        <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
            <div>
                <Grid container spacing={2}>
                    <Fade in={true} {...{ timeout: 1000 }}>
                        <Grid style={{
                            maxWidth: (!isEdition || screenReducer.screen !== 'XL' || screenReducer.size <= 1320) ? '100%' : '70%',
                            flexBasis: (!isEdition || screenReducer.screen !== 'XL' || screenReducer.size <= 1320) ? '100%' : '70%', padding: 10
                        }}
                            item xs={12} sm={12} md={12} lg={isEdition ? 8 : 12} xl={isEdition ? 8 : 12}>
                            <ShadowBoxComponent style={{ minHeight: '100%' }} className={classes.shadowBox}>
                                <Box style={{ display: 'grid', gridTemplateColumns: screenReducer.screen !== 'XL' ? '' : '300px auto', }}>
                                    <Fade in={true} {...{ timeout: 1000 }}>
                                        <Box className={classes.boxRight} style={{ padding: screenReducer.screen !== 'XL' ? '20px' : '10px 40px 0px 20px', display: 'flex', alignItems: 'center', }}>
                                            <Grid container spacing={2} >
                                                <Fade in={true} {...{ timeout: 1000 }}>
                                                    <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <ShadowBoxComponent style={{ width: '83%', boxShadow: 'none', background: '#51637540', margin: 'auto', padding: 5, borderRadius: 18, display: 'flex', }}>
                                                            {!load && <img src="/asset/images/preload_image.png" className={classes.imagePreload} alt={'Image formation'} />}
                                                            <img className={classes.image} src={image ? (contentManagerFormationImageBanner + image) : ''} onLoad={() => setLoad(true)} style={{ display: load ? 'block' : 'none' }} alt={'Image formation'} />

                                                            <IconButton
                                                                style={{ position: 'absolute', bottom: '-5px', right: '-5px', background: '#dadada', padding: 10 }}
                                                                size="small"
                                                                onClick={() => {
                                                                    setShowUploadBanner(true);
                                                                }}
                                                            >
                                                                <BorderColor fontSize="small" />
                                                            </IconButton>

                                                        </ShadowBoxComponent>
                                                    </Grid>
                                                </Fade>
                                                <Fade in={true} {...{ timeout: 1000 }}>
                                                    <Grid style={{ minHeight: '100%', order: (screenReducer.screen === 'XL') ? 0 : 2 }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <ButtonComponent disabled={!isSurveyEpp} label={'questionnaire EPP'} style={{ width: '100%' }} onClick={() => { setIsOpenEpp(true) }} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <ButtonComponent disabled={!isPrePost} label={'questionnaire Pre/Post'} style={{ width: '100%' }} onClick={() => { setIsOpenPrePost(true) }} />
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Fade>
                                                <Fade in={true} {...{ timeout: 1000 }}>
                                                    <Grid style={{ minHeight: '100%' }} item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <Box className={classes.checkBox}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'surveyEpp'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'surveyPre'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'surveyPost'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'surveySatisfaction'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'honorCertificate'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'forcedUnlock'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'isResponsive'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'isVirtualClassroom'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'isUnitFormat'} handler={handler} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                                <CheckboxComponent name={'isDiplomaFormation'} handler={handler} />
                                                            </Grid>
                                                        </Box>
                                                    </Grid>
                                                </Fade>
                                            </Grid>
                                        </Box>
                                    </Fade>
                                    <Fade in={true} {...{ timeout: 750 }}>
                                        <Box style={{ padding: screenReducer.screen !== 'XL' ? '20px' : '10px 20px 0 0', display: 'flex', flexDirection: 'column' }}>
                                            <Box style={{
                                                fontSize: 22, color: 'rgb(94, 110, 130)', margin: '0 0 12px 0', fontWeight: 'bold',
                                                display: 'flex',
                                                overflow: 'hidden',
                                                alignItems: 'center',
                                            }}>
                                                <h1 className={classes.title} >{isEdition ? <>Édition de la formation</> : <>Création d'une formation</>}</h1>
                                            </Box>
                                            <div ref={BoxMainFields} style={{ transition: '.5s all', overflow: 'hidden', height: 'auto' }}>
                                                <Grid container spacing={2} style={{ margin: '0 -8px' }} ref={mainFields}>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextFieldComponent name={'id'} handler={handler} disabled={isEdition} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextFieldComponent name={'name'} handler={handler} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <TextFieldComponent name={'previousName'} handler={handler} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <MultipleSelectComponent name={'financeType'} handler={handler} options={optionFinanceType} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <SelectComponent name={'formationGroup'} handler={handler} options={optionFormationGroup} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <MultipleSelectComponent name={'formationGroupCible'} handler={handler} options={optionFormationGroup} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <MultipleSelectComponent name={'organismFormation'} handler={handler} options={optionOrganism} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                                        <SelectComponent name={'formationProgramType'} handler={handler} options={optionFormationProgramType} nullable={true} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <TextFieldComponent name={'duration'} handler={handler} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <TextFieldComponent name={'priceHT'} handler={handler} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
                                                        <TextFieldComponent name={'durationOffline'} handler={handler} />
                                                    </Grid>
                                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                                        <TextFieldComponent name={'orientation'} handler={handler} multiline={true} />
                                                    </Grid>
                                                </Grid>
                                            </div>
                                            <Box className={classes.boxIconButton} style={{ padding: '0', transition: '.5s all', }}>
                                                <IconButton disabled={loading} className={classes.buttonArrowDrop} onClick={() => { fieldsAction() }}>
                                                    <Box className={classes.boxArrowDrop} >
                                                        <ArrowDropDownIcon className={classes.arrowDropDown} style={{
                                                            position: 'absolute',
                                                            transform: isMoreFields ? 'translate(-50%,-68%) rotate(180deg)' : 'translate(-50%,-100%) rotate(180deg)',
                                                            top: '50%',
                                                            left: '50%',
                                                        }} />
                                                        <p style={{ transform: isMoreFields ? 'translate(-50%,-0%)' : 'translate(-50%,-100%)' }}>{isMoreFields ? 'CHAMPS PRÉCÉDANT' : 'PLUS DE CHAMPS'}</p>
                                                        <ArrowDropDownIcon className={classes.arrowDropDown} style={{
                                                            position: 'absolute',
                                                            transform: isMoreFields ? 'translate(-50%,0%)' : 'translate(-50%,-30%)',
                                                            top: '50%',
                                                            left: '50%',
                                                        }} />
                                                    </Box>
                                                </IconButton>
                                            </Box>
                                            <Box style={{
                                                display: 'flex', alignItems: 'center', width: 'fit-content',
                                                margin: isMoreFields ? '0 auto' : '-40px auto 0 auto', visibility: isMoreFields ? 'visible' : 'hidden', opacity: isMoreFields ? 1 : 0
                                            }}>
                                                <Box className={classes.select} style={{ borderBottom: setpMoreFields === 1 ? "2px solid rgb(94, 110, 130)" : "", }}>
                                                    <IconButton className={classes.settings} onClick={() => { setSetpMoreFields(1) }}>
                                                        <p>Description</p>
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.select} style={{ borderBottom: setpMoreFields === 2 ? "2px solid rgb(94, 110, 130)" : "", }}>
                                                    <IconButton className={classes.settings} onClick={() => { setSetpMoreFields(2) }}>
                                                        <p>Objectifs</p>
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.select} style={{ borderBottom: setpMoreFields === 3 ? "2px solid rgb(94, 110, 130)" : "", }}>
                                                    <IconButton className={classes.settings} onClick={() => { setSetpMoreFields(3) }}>
                                                        <p>Argument de vente</p>
                                                    </IconButton>
                                                </Box>
                                                <Box className={classes.select} style={{ borderBottom: setpMoreFields === 4 ? "2px solid rgb(94, 110, 130)" : "", }}>
                                                    <IconButton className={classes.settings} onClick={() => { setSetpMoreFields(4) }}>
                                                        <p>Vidéo de présentation</p>
                                                    </IconButton>
                                                </Box>
                                            </Box>
                                            <div ref={BoxMoreFields} style={{ transition: '.5s all', overflow: 'hidden', height: 0 }}>
                                                <div ref={moreFields}>
                                                    {setpMoreFields === 1 && <Fade in={true} {...{ timeout: 750 }}>
                                                        <Grid container spacing={2} style={{ margin: '0 -8px' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'description'}>
                                                                <EditorComponent name={'description'} handler={handler} multiline={true} />
                                                            </Grid>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'description'}>
                                                                <EditorComponent name={'descriptionOffline'} handler={handler} multiline={true} />
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>}
                                                    {setpMoreFields === 2 && <Fade in={true} {...{ timeout: 750 }}>
                                                        <Grid container spacing={2} style={{ margin: '0 -8px' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'objectives'}>
                                                                <EditorComponent name={'objectives'} handler={handler} multiline={true} />
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>}
                                                    {setpMoreFields === 3 && <Fade in={true} {...{ timeout: 750 }}>
                                                        <Grid container spacing={2} style={{ margin: '0 -8px' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'sellingArgument'}>
                                                                <EditorComponent name={'sellingArgument'} handler={handler} multiline={true} />
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>}
                                                    {setpMoreFields === 4 && <Fade in={true} {...{ timeout: 750 }}>
                                                        <Grid container spacing={2} style={{ margin: '5px -8px' }}>
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={'sellingArgument'}>
                                                                <TextFieldComponent name={'presentationVideoLink'} handler={handler} />
                                                            </Grid>
                                                        </Grid>
                                                    </Fade>}
                                                </div>
                                            </div>
                                            <DialogActions style={{ margin: 'auto 0 10px 0' }}>
                                                {isEdition && <ButtonComponent label={'Télécharger en PDF'} color={'rgb(53, 162, 235)'} onClick={download} disabled={loading} />}
                                                {isEdition && <ButtonComponent label={'Supprimer'} color={'#dc3545'} onClick={remove} disabled={loading} />}
                                                <ButtonComponent label={'Enregistrer'} onClick={save} disabled={loading} />
                                            </DialogActions>
                                        </Box>
                                    </Fade>
                                </Box>
                            </ShadowBoxComponent>
                        </Grid>
                    </Fade>
                    {isEdition &&
                        <Fade in={true} {...{ timeout: 1000 }}>
                            <Grid style={{
                                height: (screenReducer.screen !== 'XL' || screenReducer.size <= 1320) ? 600 : 'auto', minHeight: '100%',
                                maxWidth: (screenReducer.screen !== 'XL' || screenReducer.size <= 1320) ? '100%' : '30%',
                                flexBasis: (screenReducer.screen !== 'XL' || screenReducer.size <= 1320) ? '100%' : '30%', padding: 10
                            }} item xs={12} sm={12} md={12} lg={4} xl={4}>
                                <ShadowBoxComponent style={{ minHeight: '100%', padding: '12px', gridTemplateRows: 'max-content auto', display: 'grid', }} className={classes.shadowBox}>
                                    <Box style={{ display: 'flex', margin: '0px', alignItems: 'center', padding: '0 20px 15px', }}>
                                        <Box className={classes.select}>
                                            <IconButton className={classes.settings} style={{ borderBottom: isOpenChapter ? "2px solid " + theme.colorPrimary : "", }} onClick={() => { setIsOpenChapter(true); setIsOpenResource(false) }}>
                                                <p style={{ fontSize: 17, fontWeight: 600, padding: '10px 15px', color: isOpenChapter && theme.colorPrimary, opacity: isOpenChapter ? 1 : 0.5 }} >Chapitres</p>
                                            </IconButton>
                                        </Box>
                                        <Box className={classes.select}>
                                            <IconButton className={classes.settings} style={{ borderBottom: isOpenResource ? "2px solid " + theme.colorPrimary : "", }} onClick={() => { setIsOpenChapter(false); setIsOpenResource(true) }}>
                                                <p style={{ fontSize: 17, fontWeight: 600, padding: '10px 15px', color: isOpenResource && theme.colorPrimary, opacity: isOpenResource ? 1 : 0.5 }} >Ressources</p>
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box style={{ position: 'relative' }}>
                                        {isOpenChapter && <FormationChapter props={props} />}
                                        {isOpenResource && <FormationResource props={props} />}
                                    </Box>
                                </ShadowBoxComponent>
                            </Grid>
                        </Fade>
                    }
                </Grid>
                {
                    isEdition &&
                    <Slide direction={'up'} in={true} {...{ timeout: 500 }}>
                        <div style={{ padding: '20px 0' }}>
                            <TabsComponent
                                tabDefault={'andpc'}
                                tabs={[
                                    { id: 'andpc', label: 'Gestion ANDPC' },
                                    { id: 'personal', label: 'Gestion Personnel' },
                                    { id: 'fifpl', label: 'Gestion FIFPL' },
                                    { id: 'cpf', label: 'Gestion CPF' },
                                    { id: 'session', label: 'Sessions' },
                                    { id: 'unit', label: 'Unités', disabled: !isUnitFormat },
                                    { id: 'virtualClassroom', label: 'Classes virtuelles' }
                                ]}
                            >
                                <div key={'andpc'}>
                                    <ANDPC {...props} />
                                </div>
                                <div key={'personal'}>
                                    <PERSONAL {...props} />
                                </div>
                                <div key={'fifpl'}>
                                    <FIFPL {...props} />
                                </div>
                                <div key={'cpf'}>
                                    <CPF {...props} />
                                </div>
                                <div key={'session'}>
                                    <FormationSession {...props} />
                                </div>
                                <div key={'virtualClassroom'}>
                                    <FormationVirtualClassroom {...props} />
                                </div>
                                <div key={'unit'}>
                                    {isUnitFormat && <Unit {...props} />}
                                </div>
                            </TabsComponent>
                        </div>
                    </Slide>
                }
                <Dialog open={isOpenEpp} fullWidth={true} className={classes.subMenu} onClose={() => { setIsOpenEpp(false) }}>
                    <FormationQuestionEpp props={props} setIsOpen={setIsOpenEpp} />
                </Dialog>
                <Fade in={isOpenPrePost} {...{ timeout: 500 }} >
                    <Box className={classes.subMenu2}>
                        {isOpenPrePost && <FormationQuestion props={props} setIsOpen={setIsOpenPrePost} />}
                        <div className={classes.subMenu2BoxOnClick} />
                    </Box>
                </Fade>

                <Dialog open={showUploadBanner} fullWidth={true} onClose={() => { setShowUploadBanner(false) }}>
                    <DialogTitle style={{ fontSize: 15, paddingBottom: 0 }}>
                        Upload de l'image de formation
                    </DialogTitle>
                    <DialogContent>
                        <UploadComponent name={'banner'} handler={handler} accept=".webp" preview={image ? (contentManagerFormationImageBanner + image) : ''} />
                    </DialogContent>
                    <p style={{ fontSize: 13, textAlign: 'center', margin: 5 }}>
                        Format WEBP et taille de 283 x 283 pixel obligatoire
                    </p>
                    <DialogActions>
                        <ButtonComponent disabled={loadBanner} color={'#5E6E82'} label={'Annuler'} onClick={() => setShowUploadBanner(false)} />
                        <ButtonComponent disabled={loadBanner} label={'Enregistrer'} onClick={uploadBanner} />
                    </DialogActions>
                </Dialog>
            </div>
        </ContentViewComponent >
    );
}

const useStyles = (screenReducer) => makeStyles({
    title: {
        fontSize: 18,
        margin: 0,
        fontWeight: 600,
    },
    shadowBox: {
        marginBottom: 10,
        '& .description .rdw-editor-main': {
            minHeight: 98,
            maxHeight: 180
        },
        '& .objectives .rdw-editor-main': {
            minHeight: 300,
            maxHeight: 300
        },
        '& .sellingArgument .rdw-editor-main': {
            minHeight: 300,
            maxHeight: 300
        },
        '& .MuiFormControl-root': {
            '& .MuiFormLabel-root': {
                fontFamily: 'Trebuchet MS',
                color: '#516375 !important',
                fontWeight: 'bold',
                fontSize: 13,
                padding: '0px 12px 0px 0px',
                display: 'flex',
                alignItems: 'center'
            },
            '& .MuiInputLabel-outlined': {
                fontSize: 13,
                opacity: 0.7,
            },
            '& legend': {
                fontFamily: 'Trebuchet MS',
                color: '#516375 !important',
                fontWeight: 'bold',
                fontSize: 11,
                display: 'flex',
                alignItems: 'center'
            },
            '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
                transform: 'translate(17px, -5px) scale(0.75) !important',
                opacity: 1,
            }
        },
        '& .MuiSvgIcon-root': {
            fontSize: '1.4rem',
        },
        '& #orientation': {
            maxHeight: 79
        },
    },
    buttonSave: {
        margin: 7,
        bottom: 0,
        right: 0,
        position: 'absolute'
    },
    buttonDelete: {
        margin: 7,
        bottom: 0,
        right: 130,
        position: 'absolute'
    },
    imagePreload: {
        padding: 0,
        width: '100%',
        borderRadius: 12,
    },
    image: {
        width: '100%',
        borderRadius: 12,
    },
    subMenu: {
        '& .MuiPaper-root': {
            width: '90%', height: '90%',
            background: 'transparent',
            borderRadius: 4,
            flexDirection: screenReducer.screen === 'XL' ? 'row' : 'column',
            maxWidth: 'initial',
            margin: 0,
        }
    },
    subMenu2: {
        position: 'fixed',
        zIndex: 100,
        height: '100%',
        width: '100%',
        top: 0,
        left: 0,
        overflow: 'hidden',
    },
    subMenu2BoxOnClick: {
        position: 'absolute',
        top: 0,
        height: '100%',
        width: '100%',
        background: 'rgba(0, 0, 0, 0.5)',
        zIndex: -1,
    },
    checkBox: {
        minHeight: '100%',
        '& .MuiCheckbox-root': {
            margin: '0 0 0 auto',
            padding: '5px 0px'
        }
    },
    boxRight: {
        position: 'relative',
        '&::before': {
            content: '" "',
            height: '80%',
            width: screenReducer.screen !== 'XL' ? 0 : 2,
            backgroundColor: '#51637540',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            right: '7%',
            top: '50%',
            borderRadius: 10
        }
    },
    buttonArrowDrop: {
        padding: 0,
        display: 'block',
        width: 150,
        borderRadius: 0,
        height: 30,
        overflow: 'hidden',
        margin: 'auto',
        '& .MuiIconButton-label': {
            display: 'block',
            backgroundColor: '#FFF',
            height: '100%',
        },
    },
    boxIconButton: {
        position: 'relative',
        '&::before': {
            content: '" "',
            height: 2,
            width: '90%',
            backgroundColor: '#51637540',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
            left: '50%',
            top: '50%',
            borderRadius: 10,
        }
    },
    boxArrowDrop: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        width: '100%',
        height: '100%',
        '& p': {
            position: 'absolute',
            margin: 0,
            padding: 0,
            fontWeight: 'bold',
            fontSize: 12,
            width: '100%',
            color: '#516375',
            top: '50%',
            left: '50%',
        },
    },
    arrowDropDown: {
        transitionProperty: 'all',
        transitionDuration: '.3s',
        margin: 'auto',
        display: 'block',
        color: '#516375',
        height: 47,
        width: '100%',
        transform: 'translate(0px,-16px)',
    },
    select: {
        margin: '0',
        borderRadius: 0,
        overflow: 'hidden',
        height: 35,
    },
    settings: {
        padding: 0,
        display: 'block',
        width: '100%',
        height: '100%',
        borderRadius: 0,
        overflow: 'hidden',
        '& .MuiIconButton-label': {
            transitionProperty: 'all',
            transitionDuration: '.3s',
            height: '100%',
            justifyContent: 'initial',
            padding: '0',
        },
        '& p': {
            margin: 0,
            padding: 5,
            fontWeight: 'bold',
            fontSize: 12,
            color: 'rgb(94, 110, 130)',
        },
    },
});

export default Formation;
