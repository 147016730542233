import { Box, Dialog, Fade, IconButton, makeStyles, Tooltip } from "@material-ui/core";
import BackspaceIcon from '@material-ui/icons/Backspace';
import EditIcon from '@material-ui/icons/Edit';
import CancelPresentationIcon from '@material-ui/icons/CancelPresentation';
import ErrorIcon from '@material-ui/icons/Error';
import React from "react";
import CardComponent from "./Component/CardComponent";
import { formHandlerInit } from "../../../../Tool/FormHandlerCommon";
import { SnackbarOpen } from "../../../../Action/SnackbarAction";
import { dispatch } from "../../../../App";

const BillingRegistrationList = (props) => {
    const datas = props.dataList;
    const classes = useStyles();
    const [openDialog, setOpenDialog] = React.useState(false);
    const [row, setRow] = React.useState({});
    const [form, setForm] = React.useState({
        educationalFee: {
            name: 'educationalFee',
            label: 'Coût pédagogique',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        feeSupport: {
            name: 'feeSupport',
            label: 'Prise en charge',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        feeInstitutionBefore: {
            name: 'feeInstitutionBefore',
            label: 'Prise en charge établissement',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        feeInstitutionAfter: {
            name: 'feeInstitutionAfter',
            label: 'Prise en charge établissement',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        inscriptionFee: {
            name: 'inscriptionFee',
            label: 'Frais d’inscription',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
        inscriptionDiscount: {
            name: 'inscriptionDiscount',
            label: 'Remise frais d’inscription',
            type: 'float',
            defaultValue: '0',
            isPercentage: false,
            options: {}
        },
        educationalExceeding: {
            name: 'educationalExceeding',
            label: 'Dépassement pédagogique',
            type: 'float',
            defaultValue: '0',
            options: {}
        },
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    function parseDiscount(discount) {
        if (typeof discount === 'string') {
            if (discount.includes('%')) {
                return { value: Number(discount.replace('%', '')), isPercentage: true };
            } else if (discount.includes('€')) {
                return { value: Number(discount.replace('€', '')), isPercentage: false };
            }
        }
        return { value: discount ? Number(discount) : '', isPercentage: false };
    }

    const transformDataForm = (input) => {
        const data = Object.keys(input).reduce((acc, key) => {
            let value = input[key].value;
            if (value === "" || value === null || value === undefined) {
                value = "";
            } else if (input[key].hasOwnProperty("isPercentage")) {
                value = input[key].isPercentage ? `${value}%` : `${value}€`;
            }
            acc[key] = value;
            return acc;
        }, {});

        return data;
    };

    React.useEffect(() => {
        if (row && row.price) {
            handler.setDataApi(row.price);
            const inscriptionDiscount = parseDiscount(row.price.inscriptionDiscount);
            handler.form.inscriptionDiscount.value = inscriptionDiscount.value
            handler.form.inscriptionDiscount.isPercentage = inscriptionDiscount.isPercentage
        }
    }, [row]);

    return (
        <>
            <table className={classes.tableRoot}>
                <thead>
                    <tr>
                        <th rowSpan={2}>Nom/Prénom</th>
                        <th style={{ minWidth: 145 }} rowSpan={2}>Formation</th>
                        <th rowSpan={2}>Financement</th>
                        <th rowSpan={2}>Coût pédagogique</th>
                        <th rowSpan={2}>Frais d’inscription</th>

                        <th colSpan={2}>CP Établissement</th>

                        <th rowSpan={2}>CP Organisme</th>
                        <th rowSpan={2}>Dépassement pédagogique</th>

                        <th rowSpan={2} style={{ width: 130 }}>Action</th>
                    </tr>
                    <tr>
                        <th>Avant formation</th>
                        <th>Après formation</th>
                    </tr>
                </thead>
                {datas.length != 0 ?
                    datas.map((data, index) => (
                        <tbody key={index}>
                            <TbodyComponent data={data} setOpenDialog={setOpenDialog} setRow={setRow} {...props} />
                        </tbody>
                    )) : <p className={classes.error}>Ajouter une inscription</p>
                }
            </table>
            <Dialog open={openDialog} className={classes.dialogStyle} onClose={() => { setOpenDialog(false); setRow({}); handler.reset() }} maxWidth={'xl'}>
                <CardComponent
                    data={row}
                    onClose={() => {
                        setOpenDialog(false);
                        setRow({});
                        handler.reset()
                    }}
                    onValid={() => {
                        if (handler.checkError()) {
                            console.log('error');
                            dispatch(
                                SnackbarOpen({
                                    text: 'Veuillez entrer tous les champs.',
                                    variant: 'error',
                                })
                            );
                        } else {
                            props.setBillingList((prevData) => {
                                const data = { id: row.id, ...transformDataForm(handler.form) };
                                const updatedData = prevData.map((item) =>
                                    item.id === data.id ? { ...item, price: data } : item
                                );
                                return updatedData;
                            });
                            setOpenDialog(false);
                            setRow({});
                            handler.reset();
                        }
                    }}
                    handler={handler}
                    {...props}
                />
            </Dialog>
        </>
    )
}

const TbodyComponent = (props) => {
    const data = props.data;
    const price = data.price;
    const [isError, setIsError] = React.useState(false)
    const classes = useStyles();

    const calculate = (data) => {
        const feeInstitutionBefore = data.feeInstitutionBefore ?? 0;
        const feeInstitutionAfter = data.feeInstitutionAfter ?? 0; 
        const educationalExceeding = data.educationalFee - feeInstitutionBefore - feeInstitutionAfter - data.feeSupport;
        return educationalExceeding;
    };

    //error
    React.useEffect(() => {
        const error = (price.feeInstitutionBefore <= 0 && (data.financeType != "6" && price.feeInstitutionAfter <= 0) && (data.financeType != "6" && price.feeSupport <= 0));
        setIsError(error || (price.feeInstitutionBefore <= 0 && data.financeType == "6"))
        props.setError((prevData) =>
            (calculate(price) < 0 || error || (price.feeInstitutionBefore <= 0 && data.financeType == "6"))
                ? [...new Set([...prevData, data.id])]
                : prevData.filter(item => item != data.id)
        );
    }, [calculate(price), price]);

    return (
        <tr>
            <td>{`${data.lastname ?? ""} ${data.firstname ?? ""}`}</td>
            <td>
                <Tooltip title={data.formationName ?? ""}>
                    <p className={'formationName'}>{`${data.formationName ?? "-"}`}</p>
                </Tooltip>
            </td>
            <td><b>{data.financeTypeName ?? '-'}</b> {data.formationProgramTypeName ?? ''}</td>
            <td>
                {(price.educationalFee && price.educationalFee != 0) ? price.educationalFee : '0'} €
            </td>
            <td>
                {(price.inscriptionFee && price.inscriptionFee != 0) ? price.inscriptionFee : '0'} €<br />
                <b>Remise : {price.inscriptionDiscount ? price.inscriptionDiscount : '0€'}</b>
            </td>

            <td style={{ position: 'relative', border: (price.feeInstitutionBefore <= 0 && props.isError && isError) ? '3px solid #e05059' : '0px solid #e05059' }}>
                {(price.feeInstitutionBefore && price.feeInstitutionBefore != 0) ? price.feeInstitutionBefore : '0'} €
            </td>
            {data.financeType != "6" ?
                <td style={{ position: 'relative', border: (price.feeInstitutionAfter <= 0 && props.isError && isError) ? '3px solid #e05059' : '0px solid #e05059' }}>
                    {(price.feeInstitutionAfter && price.feeInstitutionAfter != 0) ? price.feeInstitutionAfter : '0'} €
                </td> :
                <td style={{ background: '#5E6E8282' }}></td>
            }

            {(data.financeType != "6" && data.financeType != "3") ?
                <td style={{ position: 'relative', border: (price.feeSupport <= 0 && props.isError && isError) ? '3px solid #e05059' : '0px solid #e05059' }}>
                    {(price.feeSupport && price.feeSupport != 0) ? price.feeSupport : '0'} €
                </td> :
                <td style={{ background: '#5E6E8282' }}></td>
            }
            <td style={{ position: 'relative', border: calculate(price) < 0 ? '3px solid #e05059' : '0px solid #e05059' }}>
                <b>{calculate(price).toFixed(2)} €</b>
                {calculate(price) < 0 &&
                    <Tooltip title={'Attention, le dépassement ne doit pas être négatif.'} placement="left">
                        <ErrorIcon className={classes.ErrorIcon} />
                    </Tooltip>}
            </td>

            <td>
                <Box style={{ display: 'grid', gridTemplateColumns: 'auto max-content' }}>
                    <div style={{ background: 'rgb(109, 124, 142)', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: '5px 0 0 5px', overflow: 'hidden' }}>
                        <IconButton onClick={() => { props.setOpenDialog(true); props.setRow(data) }} style={{ borderRadius: 0, padding: 5, width: '100%', fontSize: 13, color: '#FFF' }}>
                            Modifier
                        </IconButton>
                    </div>
                    <div style={{ background: '#E05059', height: '100%', justifyContent: 'center', display: 'flex', borderRadius: '0 5px 5px 0', overflow: 'hidden' }}>
                        <Tooltip title={'Retirer'} placement="left">
                            <IconButton onClick={() => { props.removeData(data); props.setError((prevData) => prevData.filter(item => item != data.id)) }} style={{ borderRadius: 0, padding: 5, width: '100%' }}>
                                <CancelPresentationIcon style={{ color: '#FFF' }} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </Box>
            </td>
        </tr>
    )
}

const useStyles = makeStyles(style => ({
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            padding: 10,
            width: '50px !important',
            height: '50px !important'
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        margin: 'auto',
        background: '#FFF',
        border: '5px double rgb(206, 200, 200)',
        borderRadius: 100
    },
    checkbox: {
        display: 'flex',
        alignItems: 'center',
        '& .MuiIconButton-root': {
            padding: '5px !important',
            borderRadius: '0 !important'
        },
        '& .MuiCheckbox-colorSecondary.Mui-checked': {
            color: '#5E6E82'
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    deletedAt: {
        '& td': {
            background: '#dfcbb9 !important',
        },
    },
    tableRoot: {
        width: '100%',
        '& thead': {
            position: 'relative',
            '& th': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .2s',
                textAlign: 'center'
            },
        },
        '& tbody': {
            '& tr td': {
                background: '#E5E7EA',
                padding: 10,
                fontSize: 13,
                transition: 'all .2s',
                textAlign: 'center',
                position: 'relative'
            },
            '& td': {
                '& >div, p': {
                    margin: 0,
                    textAlign: 'center'
                },
                '& p.formationName': {
                    margin: 'auto',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    position: 'absolute',
                    width: '85%',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%,-50%)'
                },
                '& span': {
                    fontWeight: 'bold',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 3,
                }
            }
        }
    },
    error: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%,-50%)',
        fontSize: 30,
        fontWeight: 'bold',
        opacity: 0.3,
        margin: 0,
        width: '100%',
        textAlign: 'center',
    },
    ErrorIcon: {
        position: 'absolute',
        padding: 1,
        top: '50%',
        right: -1,
        transform: 'translate(50%,-50%)',
        color: '#E5E7EA',
        borderRadius: 100,
        background: '#E05059',
        zIndex: 1
    }
}))

export default BillingRegistrationList;