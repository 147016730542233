import React from 'react'
import ContentViewComponent from '../../../Component/ContentViewComponent'
import Fade from "@material-ui/core/Fade";
import Grid from "@material-ui/core/Grid";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import TextFieldComponent from "../../../Component/TextFieldComponent";

import Api from "../../../Api";
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import { useDispatch } from "react-redux";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import TabsComponent from '../../../Component/TabsComponent';
import RelatedFormations from './component/RelatedFormations';
import { set } from 'draft-js/lib/DefaultDraftBlockRenderMap';


export default function FinanceType(props) {

    const dispatch = useDispatch();

    const [isReadyContent, setReadyContent] = React.useState(false);
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [isEdition, setIsEdition] = React.useState(false);
    const [loading, setLoading] = React.useState(false);

    //UX related variables
    const[financeTypeName, setFinanceTypeName] = React.useState('');
    const[submitButtonText, setSubmitButtonText] = React.useState('Enregistrer');
    const[isSubmitButtonDisabled, setIsSubmitButtonDisabled] = React.useState(false);

    const [form, setForm] = React.useState({
        name: {
            name: 'name',
            label: 'Nom',
            textHelper: 'Saisissez le nom du financement.',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required'] }
        }
    });
    const handler = formHandlerInit(form, setForm);

    

    //--------- Use Effect ---------//

    React.useEffect(handler.start, []);
    React.useEffect(() => {
        if (props.match.params.id) {
            setLoading(true);
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setBreadcrumbs({
                title: 'Éditer un type de financement',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_finance_type_list'), label: 'Liste des financements' }
                ]
            });
            setIsEdition(true);

            Api.get({
                route: 'administrator_finance_type_get',
                params: { id: props.match.params.id }
                },
                (response) => {
                    setLoading(false);
                    handler.setFormLoading(false);
                    setReadyContent(true);
                    dispatch(LinearProgressMainLayoutDeactivate());

                    if (response.status === 200) {
                        handler.setDataApi(response.data);
                    }
                    else if (response.status === 404) {
                        props.history.push(getRoutePathname('administrator_finance_type_list'));
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                }
            );

        }
        else {
            setBreadcrumbs({
                title: 'Ajouter un type de financement',
                context: 'Administrateur',
                description: '',
                links: [
                    { path: getRoutePathname('administrator_finance_type_list'), label: 'Liste des financements' }
                ]
            });
            setIsEdition(false);
            setReadyContent(true);
            handler.reset();
        }
    }, [props.match.params.id]);

    //--------- Function save ---------//

    function save(){
        if(handler.checkError()){

        }
        else{
            handler.setFormLoading(true);
            setLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            setIsSubmitButtonDisabled(true);
            setSubmitButtonText('Enregistrement en cours...');

            if(isEdition){
                Api.post({ route: 'administrator_finance_type_edit', params: { id: props.match.params.id }, data: handler.getData() }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setSubmitButtonText('Enregistrer');
                    if (response.status === 200) {
                        setFinanceTypeName(response.data.name);
                        dispatch(
                            SnackbarOpen({
                                text: 'Les modifications ont bien été pris en compte.',
                                variant: 'success',
                            })
                        );
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else if (response.status === 409) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'warning',
                            })
                        );
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                });
            }else{
                Api.post({
                    route: 'administrator_finance_type_add',
                    data: handler.getData()
                }, (response) => {
                    handler.setFormLoading(false);
                    setLoading(false);
                    dispatch(LinearProgressMainLayoutDeactivate());
                    setSubmitButtonText('Enregistrer');
                    if (response.status === 201) {
                        dispatch(
                            SnackbarOpen({
                                text: 'Le financement a bien été ajouté.',
                                variant: 'success',
                            })
                        );
                        props.history.push(getRoutePathname('administrator_finance_type_list'));
                    }
                    else if (response.status === 400) {
                        handler.setErrorApi(response.error);
                    }
                    else {
                        dispatch(
                            SnackbarOpen({
                                text: 'Une erreur inattendue s\'est produite.',
                                variant: 'error',
                            })
                        );
                    }
                })
            }
        }
    }
    
  return (
    <ContentViewComponent loading={!isReadyContent} breadcrumbs={breadcrumbs} {...props}>
        <Fade in={isReadyContent} {...{ timeout: 500 }}>
            <div>
                <ShadowBoxComponent >
                    <TitleComponent title={isEdition ? `Formulaire d\'édition du type de financement : ${financeTypeName}` : 'Formulaire de création d\'un type de financement'} />
                    <Grid container spacing={3}>
                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                            <TextFieldComponent name={'name'} handler={handler} />
                        </Grid>
                    </Grid>
                    <ButtonComponent label={submitButtonText} onClick={save} disabled={loading} />
                </ShadowBoxComponent>
                { isEdition &&
                    <ShadowBoxComponent>
                        <TabsComponent
                            tabDefault={'relatedFormations'}
                            tabs={[
                                { id: 'relatedFormations', label: 'Formations financées' },
                            ]}
                        >
                            <div key={'relatedFormations'}>
                                <RelatedFormations {...props} />
                            </div>
                        </TabsComponent>
                    </ShadowBoxComponent>
                }
            </div>
        </Fade>
    </ContentViewComponent>
  )
}
