import React from 'react';
import Fade from "@material-ui/core/Fade";
import ShadowBoxComponent from "../../../Component/ShadowBoxComponent";
import { useDispatch, useSelector } from "react-redux";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { makeStyles } from '@material-ui/core/styles';
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import { theme } from "../../../App";
import RadioComponent from "../../../Component/RadioComponent";
import { cloneJson } from "../../../Tool/CloneCommon";
import { Box } from '@material-ui/core';
import CountdownEpp2 from './Component/CountdownEpp2';
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

const StayTime = (props) => {
    const seconds = (props.duration - props.timeSpent);
    const [initTime, setInitTime] = React.useState(seconds);
    const minutes = Math.floor(initTime / 60);
    const remainingSeconds = initTime % 60;
    React.useEffect(() => {
        const interval = setInterval(() => {
            setInitTime(initTimes => {
                const updatedTime = initTimes - 1;
                if (updatedTime <= 0) {
                    clearInterval(interval);
                }
                return updatedTime;
            });
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <span style={{ background: ((minutes <= 0) && (remainingSeconds <= 0)) ? '#6FD08C' : theme.colorPrimary, fontWeight: 'bold', color: '#FFF', padding: '6px 15px', borderRadius: 6, fontSize: 11, margin: '0 5px', display: 'flex', gap: 5, alignItems: 'center' }}>
            {((minutes <= 0) && (remainingSeconds <= 0)) ? 'Temps validé' : minutes + ' min ' + remainingSeconds + 's restant'} <AccessTimeIcon style={{ fontSize: 16 }} />
        </span>
    )
}

function RegistrationSurveyEpp(props) {
    const dispatch = useDispatch();
    const classes = useStyles();
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [loading, setLoading] = React.useState(true);
    const [show, setShow] = React.useState(true);
    const [isSmallView, setSmallView] = React.useState(false);
    const [isCurrentSurveyCompleted, setCurrentSurveyCompleted] = React.useState(false);
    const [isPreSurveyCompleted, setPreSurveyCompleted] = React.useState(false);
    const [currentForm, setCurrentForm] = React.useState();
    const [prevForm, setPrevForm] = React.useState();
    const [scores, setScores] = React.useState();
    const [countUp, setCountUp] = React.useState(false);
    const [responses, setResponses] = React.useState();
    const [intro, setIntro] = React.useState();
    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const [recommendation, setRecommendation] = React.useState(false);
    const [recommendationPost, setRecommendationPost] = React.useState(false);
    const [infoEpp2, setInfoEpp2] = React.useState({});
    const [info, setInfo] = React.useState({});
    const [isScreen, setIsScreen] = React.useState(false);
    const [isFinishCountdown, setIsFinishCountdown] = React.useState(false);
    const checkboxRefs = React.useRef([]);
    const [showContent, setShowContent] = React.useState(false);

    const goBack = () => {
        setShow(false);
        setTimeout(() => {
            props.history.push(getRoutePathname('learner_registration', { id: props.match.params.registration }));
        }, 600);
    };
    const handler = formHandlerInit(currentForm, setCurrentForm);
    const prevHandler = formHandlerInit(prevForm, setPrevForm);


    React.useEffect(handler.start, []);
    React.useEffect(prevHandler.start, []);

    const scrollToTop = () => {
        divRef.current.scrollIntoView({ behavior: 'smooth' });
    };

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
            let checkbox = [];
            for (let index in handler.form) {
                if (handler.form[index].error) {
                    checkbox.push(checkboxRefs.current[handler.form[index].orderBy - 1])
                }
            }
            checkbox[0].scrollIntoView({ behavior: 'smooth', block: 'center', });
            dispatch(
                SnackbarOpen({
                    text: 'Certaines questions n\'ont pas été répondues.',
                    variant: 'error',
                })
            );
        }
        else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());
            let data = handler.getData();
            Api.post({
                route: 'learner_registration_survey_epp_add',
                data: { "response": data, "type": props.match.params.type },
                params: { registration: props.match.params.registration }
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());
                if (response.status === 200) {
                    getScore();
                    dispatch(
                        SnackbarOpen({
                            text: 'L\'enregistrement du questionnaire a été un succès.',
                            variant: 'success',
                        })
                    );
                    setCurrentSurveyCompleted(true);
                    setCountUp(true);
                    getRecommendation(handler.form, prevHandler.form);
                }
                else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                    if (response.error.message) {
                        dispatch(
                            SnackbarOpen({
                                text: response.error.message,
                                variant: 'error',
                            })
                        );
                    }
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
        }
    };

    const download = (type, download) => {
        const url = Api.route({
            route: 'learner_registration_survey_epp_download',
            data: { download: download },
            params: { registration: props.match.params.registration, type: type },
        });
        window.open(url, '_blank');
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {
        setLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'learner_registration_survey_epp_get',
            params: { registration: props.match.params.registration, type: props.match.params.type }
        },
            (response) => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let dataReceive = response.data;
                    let data = cloneJson(dataReceive);
                    let dataClone = cloneJson(dataReceive);
                    getScore();
                    setCurrentForm(data.questions);
                    setPrevForm(dataClone.questions);
                    setPreSurveyCompleted(data.isPreSurveyCompleted)
                    setCurrentSurveyCompleted(data.isCurrentSurveyCompleted);
                    setResponses(data.responses);
                    setInfo(response.data);
                    setIntro(dataReceive.introduction);
                    setInfoEpp2({ timeSpent: response.data.timeSpentEpp2, duration: response.data.durationEpp2, isDeploymentTimerEpp2BeforeSession: response.data.isDeploymentTimerEpp2BeforeSession });
                    if (response.data.durationEpp2 < response.data.timeSpentEpp2) {
                        setIsFinishCountdown(true);
                    }
                }
                else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    const getScore = () => {
        Api.get({
            route: 'learner_registration_survey_epp_score',
            params: { registration: props.match.params.registration, type: props.match.params.type }
        },
            (response) => {
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let dataReceive = response.data;
                    let data = cloneJson(dataReceive);
                    setScores(data.scores);

                }
                else if (response.status === 404) {
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                }
                else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }
    const viewAudit = (type) => {
        return (scores &&
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ padding: '20px', marginBottom: '10px' }}>
                {isCurrentSurveyCompleted && <>
                    <p style={{ margin: '10px 0px', fontSize: '20px', fontWeight: 'bold' }}>ANALYSE DE VOTRE AUDIT</p>
                    <br /><br />
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.boxScoreAudit}>
                            <Box>
                                <p style={{ textTransform: 'uppercase', fontSize: 11 }}><b>Score<br /> individuel</b></p>
                                <p style={{ fontSize: 25 }}><b>{scores[type].scoreProspect}%</b></p>
                            </Box>
                            <p style={{ width: '88%', margin: 'auto', padding: '19px 0' }}>Ce score reflète le niveau de conformité de vos pratiques réelles, par rapport aux pratiques attendues.</p>
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} lg={6} xl={6} className={classes.boxScoreAudit}>
                            <Box>
                                <p style={{ textTransform: 'uppercase', fontSize: 11 }}><b>Score<br /> moyen</b></p>
                                <p style={{ fontSize: 25 }}><b>{scores[type].scoreAllProspect}%</b></p>
                            </Box>
                            <p style={{ width: '88%', margin: 'auto', padding: '19px 0' }}>
                                Ce score reflète le niveau moyen de conformité des pratiques réelles des
                                professionnels participants à cette action de DPC, par rapport aux pratiques attendues.
                            </p>
                        </Grid>
                    </Grid></>}
                {recommendation &&
                    <>
                        <p>Afin de vous permettre de mettre en œuvre des actions correctives concernant les indicateurs en non-conformités, votre plan d’action individuel formalisé va vous être envoyé par e-mail. Il est également téléchargeable ici : </p>
                        <ButtonComponent label={'Télécharger mon plan d\'action individualisé'} className={classes.button} style={{ float: 'none' }} onClick={() => download(type, 'audit')} />
                    </>
                }
                <br /><br />
                <Box className={classes.boxContact}>
                    <p>
                        Si vous ressentez le besoin d’être accompagné(e) dans votre démarche EPP ou dans
                        la mise en place de votre plan d’action, nous vous invitions à nous contacter :
                    </p>
                    <p style={{ padding: '0 23px' }}>
                        - par e-mail : {scores[type].organism.email}<br />
                        - par téléphone : {scores[type].organism.phone}
                    </p>
                </Box>
            </Grid>
        )
    }

    React.useEffect(() => {
        if (!loading) {
            handler.setDataApi(getResponsesByType(props.match.params.type, responses));
            prevHandler.setDataApi(getResponsesByType("pre", responses));
            isCurrentSurveyCompleted ? setCountUp(true) : setCountUp(false);
            getRecommendation(handler.form, prevHandler.form);
        }
    }, [responses]);

    const getRecommendation = (responsePre, responsePost) => {
        for (let index in responsePre) {
            if (responsePre[index].value == 'false' && responsePre[index].recommendation) {
                setRecommendation(true)
            }
        }
        for (let index in responsePost) {
            if (responsePost[index].value == 'false' && responsePost[index].recommendation) {
                setRecommendationPost(true)
            }
            ;
        }
    }

    const getResponsesByType = (type, responses) => {
        for (let index in responses) {
            if (index === type) {
                return responses[index];
            }
        }
    }

    const countdownFinish = () => { setIsFinishCountdown(true) };

    const handlePlay = () => {
        setTimeout(() => {
            setShowContent(true);
        }, 2000);
    };

    const [isButtonTop, setIsButtonTop] = React.useState(false);
    const divRef = React.useRef(null);

    React.useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsButtonTop(false)
                    }
                    else setIsButtonTop(true);
                });
            },
            {
                root: null,
                rootMargin: '0px',
                threshold: 0.3,
            }
        );

        if (divRef.current) observer.observe(divRef.current);
    }, [loading]);

    React.useEffect(() => {
        setBreadcrumbs({
            currentPage: 'Questionnaire pre-formation',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
                { path: getRoutePathname('learner_registration', { id: props.match.params.registration }), label: 'Sommaire' }
            ],
            html: <>
                {(((props.match.params.type === 'pre') || (props.match.params.type === 'post')) && info && info.durationEpp && info.financeType !== '6') &&
                    <div style={{ display: 'flex' }} >
                        {props.match.params.type === 'pre' ?
                            <StayTime duration={info.durationEpp.durationEpp1} timeSpent={info.timeSpentEpp.timeSpentEpp1} {...props} />
                            : <StayTime duration={info.durationEpp.durationEpp2} timeSpent={info.timeSpentEpp.timeSpentEpp2} />
                        }
                    </div>
                }
            </>
        });
    }, [screenReducer, info]);

    React.useEffect(() => {
        setIsScreen(screenReducer.size <= 1210);
    }, [screenReducer.size]);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} {...props}>
            <div className={classes.container}>
                <div>
                    <Fade in={show} {...{ timeout: 1000 }}>
                        <div style={{ whiteSpace: 'pre-line' }}>
                            <div ref={divRef}>
                                <ShadowBoxComponent className={classes.shadowBoxBack} onClick={goBack} style={isSmallView ? { width: 'calc(100% - 30px)', marginLeft: 15 } : {}}>
                                    <ArrowLeftIcon className={classes.shadowBoxBackIcon} />
                                    <span className={classes.shadowBoxBackText}>Revenir au sommaire</span>
                                </ShadowBoxComponent>
                            </div>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                {
                                    isPreSurveyCompleted ?
                                        <>
                                            <div style={{ position: 'fixed', right: 20, background: '#fff', padding: 0, margin: 0 }}>
                                                {
                                                    ((infoEpp2 && infoEpp2.duration > 0 && !isFinishCountdown && !isCurrentSurveyCompleted && !infoEpp2.isDeploymentTimerEpp2BeforeSession)) &&
                                                    <CountdownEpp2 time={infoEpp2.timeSpent} duration={infoEpp2.duration} isFinish={countdownFinish} />
                                                }
                                            </div>
                                            <TitleComponent title={'Audit clinique : grille d\'évaluation 2'} />

                                            <video
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    width: '100%',
                                                    height: isSmallView ? 'calc(40vh - 100px)' : 'calc(60vh - 100px)',
                                                }}
                                                src="https://data.santeformapro.com/mp4/video_EPP.mp4"
                                                onPlay={handlePlay}
                                                autoPlay
                                                muted
                                                loop
                                                controls
                                                preload="metadata"
                                            />

                                            {!showContent && <p className={classes.textPlay}>Lancez la vidéo pour déverrouiller l'accès au questionnaire.</p>}

                                            <div className={`${classes.blurContainer} ${showContent ? classes.playing : ''}`}>
                                                <p style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px' }}>{intro ? <><span style={{ margin: '0', fontSize: '20px', fontWeight: 'bold' }}>INTRODUCTION</span><br />
                                                    <span className={classes.subTitle} style={{ margin: 0, padding: "10px 0", whiteSpace: 'initial' }} dangerouslySetInnerHTML={{ __html: intro }} /></> : <>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation ainsi que votre progression.</>}</p>

                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {showContent &&
                                                            Object.keys(handler.form).map((id, index) => (
                                                                <Grid ref={(el) => (checkboxRefs.current[index] = el)} key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px' }}>
                                                                    {
                                                                        handler.form[id].component === 'checkBox' ?
                                                                            <RadioComponent key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} hasFeedback={false} disabled={isCurrentSurveyCompleted}
                                                                                scoreFeedback={scores ? scores['post'][handler.form[id].name] : null} CountUp={countUp} />
                                                                            : ""
                                                                    }
                                                                </Grid>
                                                            ))
                                                        }
                                                        {
                                                            (recommendation && showContent) &&
                                                            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.recommendation}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item lg={isSmallView ? 12 : 6}>
                                                                        <p style={{ margin: '0px', fontSize: '20px', fontWeight: 'bold' }}>RECOMMANDATION(S)</p>
                                                                    </Grid>
                                                                    {/* <Grid item lg={isSmallView ? 12 : 6}>
                                                                            <ButtonComponent label={'Télécharger les recommandations'} className={classes.button} onClick={() => download('post', 'recommendation')} />
                                                                        </Grid> */}
                                                                </Grid>
                                                                {
                                                                    Object.keys(handler.form).map((id, index) => (
                                                                        handler.form[id].value == 'false' && handler.form[id].recommendation ?
                                                                            <div style={{ margin: '10px 0' }} key={index}>
                                                                                <p style={{ margin: '0', fontSize: '16px', fontWeight: 'bold', padding: '5px 0' }}>{handler.form[id].orderBy + ') ' + handler.form[id].label}</p>
                                                                                <p style={{ margin: '0', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: handler.form[id].recommendation }} />
                                                                            </div>
                                                                            : ''
                                                                    ))
                                                                }
                                                            </Grid>
                                                        }
                                                        {viewAudit('post')}
                                                    </Grid>
                                                </Grid>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <TitleComponent title={'Audit clinique : grille d\'évaluation 1'} />

                                            <video
                                                style={{
                                                    border: 'none',
                                                    borderRadius: '8px',
                                                    width: '100%',
                                                    height: isSmallView ? 'calc(40vh - 100px)' : 'calc(60vh - 100px)',
                                                }}
                                                src="https://data.santeformapro.com/mp4/video_EPP.mp4"
                                                onPlay={handlePlay}
                                                autoPlay
                                                muted
                                                loop
                                                controls
                                                preload="metadata"
                                            />

                                            {!showContent && <p className={classes.textPlay}>Lancez la vidéo pour déverrouiller l'accès au questionnaire.</p>}

                                            <div className={`${classes.blurContainer} ${showContent ? classes.playing : ''}`}>
                                                <p style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px' }}>{intro ? <><span style={{ margin: '0', fontSize: '20px', fontWeight: 'bold' }}>INTRODUCTION</span><br />
                                                    <span className={classes.subTitle} style={{ margin: 0, padding: "10px 0", whiteSpace: 'initial' }} dangerouslySetInnerHTML={{ __html: intro }} /></> : <>Ce questionnaire d’évaluation des connaissances a pour objectif de mesurer l’efficacité de la formation ainsi que votre progression.</>}
                                                </p>
                                                {
                                                    isCurrentSurveyCompleted && <p style={{ textAlign: 'center' }}><b>Vous avez déjà répondu à ce questionnaire.</b><br /><b>Vous pouvez uniquement le consulter à nouveau.</b></p>
                                                }
                                                <Grid container>
                                                    {showContent &&
                                                        Object.keys(handler.form).map((id, index) => (
                                                            <Grid ref={(el) => (checkboxRefs.current[index] = el)} key={id} item xs={12} sm={12} md={12} lg={12} xl={12} style={{ backgroundColor: 'rgba(159,159,159,0.1)', padding: '20px', marginBottom: '10px' }}>
                                                                {

                                                                    handler.form[id].component === 'checkBox' ?
                                                                        <RadioComponent key={id} name={handler.form[id].name} options={handler.form[id].optionsCheckBox} handler={handler} disabled={isCurrentSurveyCompleted}
                                                                            scoreFeedback={scores ? scores[props.match.params.type][handler.form[id].name] : null} CountUp={countUp} />
                                                                        : ""
                                                                }
                                                            </Grid>
                                                        ))
                                                    }
                                                    {
                                                        (recommendation && showContent) &&
                                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} className={classes.recommendation}>
                                                            <p style={{ margin: '10px 0px', fontSize: '20px', fontWeight: 'bold' }}>RECOMMANDATION(S)</p>
                                                            {
                                                                Object.keys(handler.form).map((id, index) => (
                                                                    handler.form[id].value == 'false' && handler.form[id].recommendation ?
                                                                        <div key={index} style={{ margin: '10px 0' }}>
                                                                            <p style={{ margin: '0', fontSize: '16px', fontWeight: 'bold', padding: '5px 0', }}>{handler.form[id].orderBy + ') ' + handler.form[id].label}</p>
                                                                            <p style={{ margin: '0', fontSize: '14px' }} dangerouslySetInnerHTML={{ __html: handler.form[id].recommendation }} />
                                                                        </div>
                                                                        : ''
                                                                ))
                                                            }
                                                            {/* <ButtonComponent label={'Télécharger les recommandations'} className={classes.button} style={{ float: 'none' }} onClick={() => download(props.match.params.type, 'recommendation')} /> */}
                                                        </Grid>

                                                    }
                                                    {isCurrentSurveyCompleted && viewAudit(props.match.params.type)}
                                                </Grid>
                                            </div>
                                        </>
                                }
                                <div className={classes.buttonGroup}>
                                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={isCurrentSurveyCompleted} />
                                </div>
                            </ShadowBoxComponent>
                            {isButtonTop &&
                                <ShadowBoxComponent
                                    onClick={scrollToTop}
                                    style={
                                        isScreen ? { width: 'max-content', position: 'sticky', bottom: 10, margin: '0px 0px 10px auto', cursor: 'pointer' } :
                                            { width: 'max-content', position: 'sticky', bottom: 10, margin: '-50px 0px 10px auto', transform: 'translate(70px)', cursor: 'pointer' }
                                    }>
                                    <ArrowLeftIcon style={{ transform: 'rotate(90deg)' }} className={classes.shadowBoxBackIcon} />
                                </ShadowBoxComponent>
                            }
                        </div>
                    </Fade>
                </div >
            </div >
        </ContentViewLearnerComponent>
    );
}

const useStyles = makeStyles({
    container: {
        textAlign: 'center',
        margin: 'auto',
        '& div': {
            whiteSpace: 'normal !important',
        }
    },
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        marginTop: 'calc(50vh - 60px)',
        transform: 'translateY(-50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div':
        {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBoxBack: {
        height: 40,
        width: 215,
        fontSize: 14,
        textAlign: 'start',
        marginTop: 25,
        padding: 8,
        cursor: 'pointer'
    },
    shadowBoxBackIcon: {
        verticalAlign: 'middle'
    },
    shadowBoxBackText: {
        verticalAlign: 'middle'
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    },
    boxScoreAudit: {
        textAlign: 'center',
        '& p': {
            margin: 0,
        },
        '& .MuiBox-root': {
            background: 'rgb(79, 147, 165)',
            color: '#FFF',
            margin: 'auto',
            width: 100,
            borderRadius: 10,
            padding: '15px 0',
        }
    },
    recommendation: {
        backgroundColor: 'rgba(159,159,159,0.1)',
        padding: '20px',
        marginBottom: '10px',
        '& p, ul, ol, h1, h2, h3': { margin: 0 }
    },
    boxContact: {
        background: 'rgba(159,159,159,0.1)',
        padding: '20px',
        '& p': {
            margin: 0,
        }
    },
    subTitle: {
        '& p, ul, ol, h1, h2, h3': { margin: 0 }
    },
});

export default RegistrationSurveyEpp;
