import React from 'react';
import { Grid, makeStyles } from "@material-ui/core";
import { theme } from "../../../../App";
import { useSelector } from 'react-redux';
import { LibraryResourceTypeImage } from '../../../../Api';

function CardResourceType(props) {
    const classes = useStyles(theme)();
    const [isSmallView, setSmallView] = React.useState(false);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [load, setLoad] = React.useState(false);

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);

    return (
        <div className={classes.box} style={{ width: (screenReducer.size >= 420) ? '300px' : '100%', }} onClick={props.onClick ? props.onClick : ''}>
            {props.libraryResourceType.image &&
                <>
                    {!load && <img src="/asset/images/image_type_resource_preload.png" className={classes.imageTypePreload} alt={'image'} />}
                    {/*<img style={{ objectFit: 'contain' }} width="120px" height="150px" src={LibraryResourceTypeImage + props.libraryResourceType.image} alt='image' onLoad={() => setLoad(true)} />*/}
                </>
            }
            {props.libraryResourceType.label &&
                <p style={{ textAlign: 'center', fontWeight: 500, margin: 5, fontSize: 13, border: '2px solid #E6E9F0', borderRadius: 17, padding: 5, borderRadius: 10, padding: '8px 20px', background: '#F6F6F6' }}>
                    {props.libraryResourceType.label}
                </p>
            }
        </div>
    );
}

const useStyles = (theme) => makeStyles(({
    box: {
        margin: 10,
        flexDirection: 'column',
        alignItems: 'center',
        cursor: 'pointer',
        border: '2px solid #E6E9F0',
        borderRadius: 17,
        display: 'flex',
        padding: 5,
        background: '#FFF',
        transition: 'all .4s !important',
        '&:hover': {
            transform: 'scale(1.03)'
        }
    },
    imageTypePreload: {
        width: 100,
        height: 70,
        //position: 'absolute',
        background: '#fefbf5'
    }
}));

export default CardResourceType;
