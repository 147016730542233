import React from 'react';
import UseAnimations from 'react-useanimations';
import BreadcrumbsComponent from '../../../Component/BreadcrumbsComponent';
import ShadowBoxComponent from '../../../Component/ShadowBoxComponent';
import { Fade, makeStyles } from "@material-ui/core";
import { useSelector, useDispatch } from 'react-redux';
import { theme } from "../../../App";
import activity from "react-useanimations/lib/activity";
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import { getRoutePathname } from "../../../Config/Route";
import { formHandlerInit } from "../../../Tool/FormHandlerCommon";
import Grid from "@material-ui/core/Grid";
import TextFieldComponent from "../../../Component/TextFieldComponent";
import DatePickerComponent from "../../../Component/DatePickerComponent";
import TitleComponent from "../../../Component/TitleComponent";
import ButtonComponent from "../../../Component/ButtonComponent";
import { LinearProgressMainLayoutActivate, LinearProgressMainLayoutDeactivate } from "../../../Action/LinearProgressMainLayoutAction";
import Api from "../../../Api";
import { SnackbarOpen } from "../../../Action/SnackbarAction";
import ContentViewLearnerComponent from '../../../Component/ContentViewLearnerComponent';

const MyInfo = (props) => {

    const [breadcrumbs, setBreadcrumbs] = React.useState({});
    const classes = useStyles();
    const [loading, setLoading] = React.useState(true);
    const screenReducer = useSelector(state => state.ScreenReducer);
    const [isSmallView, setSmallView] = React.useState(false);
    const dispatch = useDispatch();
    const [show, setShow] = React.useState(true);
    const [form, setForm] = React.useState({
        lastname: {
            name: 'lastname',
            label: 'Nom',
            textHelper: 'Saisissez votre nom.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        firstname: {
            name: 'firstname',
            label: 'Prénom',
            textHelper: 'Saisissez votre prénom.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        email: {
            name: 'email',
            label: 'Email (Modifiable uniquement sur demande)',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: { validation: ['required', 'email'] }
        },
        formationGroupName: {
            name: 'formationGroupName',
            label: 'Profession (Modifiable uniquement sur demande)',
            textHelper: '',
            type: 'text',
            defaultValue: '',
            options: { validation: [] }
        },
        phone: {
            name: 'phone',
            label: 'Téléphone',
            textHelper: 'Saisissez votre numéro de téléphone.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        mobile: {
            name: 'mobile',
            label: 'Téléphone Mobile',
            textHelper: 'Saisissez votre numéro de téléphone mobile.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        ips: {
            name: 'ips',
            label: 'IPS',
            textHelper: 'Saisissez votre Identifiant Professionnel de Santé. (ADELI ou RPPS)',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        address: {
            name: 'address',
            label: 'Adresse',
            textHelper: 'Saisissez votre adresse.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        zipCode: {
            name: 'zipCode',
            label: 'Code postal',
            textHelper: 'Saisissez votre code postal de résidence.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        city: {
            name: 'city',
            label: 'Ville',
            textHelper: 'Saisissez votre ville de résidence.',
            type: 'text',
            defaultValue: '',
            options: {}
        },
        birthdayAt: {
            name: 'birthdayAt',
            label: 'Date de naissance',
            textHelper: 'Saisissez votre date de naissance.',
            type: 'date',
            defaultValue: '',
            options: { validation: ['date'] }
        }
    });
    const handler = formHandlerInit(form, setForm);
    React.useEffect(handler.start, []);

    const save = () => {
        if (handler.checkError()) {
            console.log('Error');
        } else {
            handler.setFormLoading(true);
            dispatch(LinearProgressMainLayoutActivate());

            let data = handler.getData();

            Api.post({
                route: 'learner_my_info_edit',
                data: data
            }, (response) => {
                handler.setFormLoading(false);
                setLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    dispatch(
                        SnackbarOpen({
                            text: 'Les modifications ont bien été pris en compte.',
                            variant: 'success',
                        })
                    );
                } else if (response.status === 400) {
                    handler.setErrorApi(response.error);
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });

        }
    };

    React.useEffect(() => {
        setSmallView((screenReducer.screen === 'XS' || screenReducer.screen === 'SM'));
    }, [screenReducer.screen]);
    React.useEffect(() => {

        setBreadcrumbs({
            currentPage: 'Mes informations',
            links: [
                { path: getRoutePathname('learner_home'), label: 'Tableau de bord' },
            ]
        });

        setLoading(true);
        handler.setFormLoading(true);
        dispatch(LinearProgressMainLayoutActivate());

        Api.get({
            route: 'learner_my_info_get'
        },
            (response) => {
                setLoading(false);
                handler.setFormLoading(false);
                dispatch(LinearProgressMainLayoutDeactivate());

                if (response.status === 200) {
                    let data = response.data;
                    handler.setDataApi(data);
                } else if (response.status === 404) {
                    props.history.push(getRoutePathname('learner_home'));
                    dispatch(
                        SnackbarOpen({
                            text: response.error.message,
                            variant: 'warning',
                        })
                    );
                } else {
                    dispatch(
                        SnackbarOpen({
                            text: 'Une erreur inattendue s\'est produite.',
                            variant: 'error',
                        })
                    );
                }
            });
    }, []);

    return (
        <ContentViewLearnerComponent loading={loading} breadcrumbs={breadcrumbs} onChildrenStyle={true} {...props}>
            <div style={{ textAlign: 'center', maxWidth: '80%', margin: 'auto' }}>
                <div>
                    <Fade in={show} {...{ timeout: 1000 }}>
                        <div>
                            <ShadowBoxComponent className={classes.shadowBox}>
                                <TitleComponent title={'Mes informations'} />

                                <Grid container spacing={2}>
                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'lastname'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'firstname'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                        <TextFieldComponent name={'address'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'zipCode'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'city'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'phone'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'mobile'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'email'} handler={handler} disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'formationGroupName'} handler={handler} disabled />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <DatePickerComponent name={'birthdayAt'} handler={handler} />
                                    </Grid>

                                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                                        <TextFieldComponent name={'ips'} handler={handler} />
                                    </Grid>
                                </Grid>

                                <div className={classes.buttonGroup}>
                                    <ButtonComponent label={'Enregistrer'} className={classes.button} onClick={save} disabled={loading} />
                                </div>
                            </ShadowBoxComponent>
                        </div>
                    </Fade>
                </div>
            </div>
        </ContentViewLearnerComponent>
    );
};

const useStyles = makeStyles({
    loader: {
        textAlign: 'center'
    },
    loaderContent: {
        transform: 'translateY(50%)',
        width: 270,
        margin: 'auto'
    },
    loaderLogo: {
        width: '80%',
        marginLeft: '3%',
        marginBottom: 5
    },
    loaderActivity: {
        '& > div': {
            margin: 'auto',
            width: '15% !important'
        }
    },
    shadowBox: {
        margin: '25px 0px',
        width: '100%',
        textAlign: 'left'
    },
    buttonGroup: {
        minHeight: 35,
        padding: '20px 0px 5px'
    },
    button: {
        float: 'right'
    }
});

export default MyInfo;